import { SuccessFeeEnum } from './success-fee-enum';
import { SuccessFee } from './success-fee';

export default class SuccessFeeExtension {
	public static determineClassForStyling(successFee: SuccessFee): string {
		switch (successFee.value) {
			case SuccessFeeEnum.REGULAR:
				return 'Regular';
			case SuccessFeeEnum.REDUCED_BECAUSE_AGENT_INVOLVED:
			case SuccessFeeEnum.REDUCED_BECAUSE_NATIONALITY:
				return 'ReducedFee';
			case SuccessFeeEnum.OTHER:
				return 'Other';
		}
	}

	public static createSuccessFeeLabel(successFee: SuccessFee): string {
		switch (successFee.value) {
			case SuccessFeeEnum.REGULAR:
				return 'Regular fee';
			case SuccessFeeEnum.REDUCED_BECAUSE_AGENT_INVOLVED:
			case SuccessFeeEnum.REDUCED_BECAUSE_NATIONALITY:
				return 'Reduced fee';
			case SuccessFeeEnum.OTHER:
				return 'Other';
		}
	}
}
