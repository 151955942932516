import { Enrolment } from '@/models/enrolment';
import { EnrolmentStatus } from '@/models/enrolment-status';
import EnrolmentService from '@/services/enrolments-service';
import { TriggerPayload } from '../trigger-payload/trigger-payload';
import { EnrolmentPlacedInInvoicingBatchTriggerMonitor } from './enrolment-placed-in-invoicing-batch-trigger-monitor';
import { TransitionTrigger } from './transition-trigger';

export class EnrolmentPlacedInInvoicingBatchTrigger extends TransitionTrigger {
	public orderingNumber = 60;

	public constructor(excludedEnrolmentStatuses: EnrolmentStatus[]) {
		super('Create an invoice batch', excludedEnrolmentStatuses);
	}

	public async triggerTransitions(enrolments: Readonly<Enrolment[]>, payload: TriggerPayload = new TriggerPayload()): Promise<void> {
		const monitor = new EnrolmentPlacedInInvoicingBatchTriggerMonitor();
		monitor.start();

		try {
			await this.performTransitions(enrolments, payload);
			monitor.commandSucceeded();
		} catch (error) {
			monitor.commandFailed();
		}
	}

	public equals(that: TransitionTrigger): boolean {
		return that instanceof EnrolmentPlacedInInvoicingBatchTrigger;
	}

	protected async performTransitions(enrolments: Readonly<Enrolment[]>, payload: TriggerPayload): Promise<void> {
		await EnrolmentService.createInvoicingBatch(enrolments, payload.organisationIdentity);
	}
}
