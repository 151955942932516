import { computed } from 'vue';
import { DefaultTableSelectorOptions } from '@/models/i-table-selector';
import { Enrolment } from '@/models/enrolment';
import { RouteEnums } from '@/models/route-enums';
import { Month } from '@/models/month';
import TableFilter from '@/presentation/components/generalizations/table-selectors/filters/table-filter';
import TableSelectorOption from '@/presentation/components/generalizations/table-selectors/table-selector-option';
import datePresenter from '@/presenters/date-presenter';
import store from '@/store';

export default class TableFilterIntakePeriod extends TableFilter {
	get defaultOptions(): TableSelectorOption[] {
		return [new TableSelectorOption(DefaultTableSelectorOptions.DEFAULT, 'All intake periods')];
	}

	private onHistoryPage = computed((): boolean => {
		const router = store.getters.router();
		return router.current === RouteEnums.HISTORY;
	});

	public setSelectedOption(option: string | number): void {
		/* For some reason, sometimes the intake filter tries to save the selected value as "01 2023" instead of "January 2023".
		Make sure that this is always corrected, so that filtering against the intakePeriodString value always works. */
		const optionParts = option.toString().split(' ');
		if (optionParts.length !== 2) {
			super.setSelectedOption(option);
			return;
		}

		const monthPart = parseInt(optionParts[0]);
		if (isNaN(monthPart)) {
			super.setSelectedOption(option);
			return;
		}

		super.setSelectedOption(`${Month.forValue(monthPart).name} ${optionParts[1]}`);
	}

	public createOptions(): TableSelectorOption[] {
		const options: TableSelectorOption[] = [];
		datePresenter.years.forEach((year) => {
			datePresenter.months.forEach((month) => {
				const name = `${month.name} ${year}`;
				options.push(new TableSelectorOption(name, name));
			});
		});

		return options;
	}

	public doesEnrolmentMatchFilter(enrolment: Enrolment): boolean {
		const option = this.selectedOptionAsOption.value;
		if (((!option || option.isDefaultOption) && !this.selectedOption.value) || this.onHistoryPage.value) {
			return true;
		}

		if (!enrolment.intakePeriodString) {
			return false;
		}

		return enrolment.intakePeriodString === this.selectedOption.value.toString();
	}
}
