import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = {
  id: "InternalEnrolmentsOverview",
  class: "EnrolmentsOverview"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogOut = _resolveComponent("LogOut")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.CurrentComponent), _mergeProps(_ctx.router.props, { class: "AppContainer" }), null, 16 /* FULL_PROPS */)),
      (_ctx.standAloneLogin)
        ? (_openBlock(), _createBlock(_component_LogOut, { key: 0 }))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}