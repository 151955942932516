import { defineComponent, onMounted, onUnmounted } from 'vue';
import { MultiSelect } from '@studyportals/multiselect';
import OptionalFilterSectionNationalityComponent
	from '@/presentation/components/generalizations/optional-filters/optional-filter-section-nationality/optional-filter-section-nationality-class';

export default defineComponent({
	components: {
		MultiSelect
	},
	setup: () => {
		const component = new OptionalFilterSectionNationalityComponent();

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		return {
			nationalityDropdown: component.nationalityDropdown,
			countriesAsOptions: component.countriesAsOptions
		};
	}
});
