import { GroupSelector } from '@/presentation/components/generalizations/enrolments-table-group-selector/selectors/group-selector';
import { GroupOption } from '@/presentation/components/generalizations/enrolments-table-group-selector/selectors/group-option';
import { InvoiceGroupOption }
	from '@/presentation/components/generalizations/enrolments-table-group-selector/selectors/invoice-group-option';
import { IntakeMonthGroupOption }
	from '@/presentation/components/generalizations/enrolments-table-group-selector/selectors/intake-month-group-option';
import { IntakeYearGroupOption }
	from '@/presentation/components/generalizations/enrolments-table-group-selector/selectors/intake-year-group-option';

export class HistoryInternalGroupSelector extends GroupSelector {
	private invoiceOption: GroupOption;
	private intakeMonthOption: GroupOption;
	private intakeYearOption: GroupOption;

	constructor() {
		super();

		this.invoiceOption = this.addAndGetOption(InvoiceGroupOption);
		this.intakeMonthOption = this.addAndGetOption(IntakeMonthGroupOption);
		this.intakeYearOption = this.addAndGetOption(IntakeYearGroupOption);
	}

	public get defaultOption(): GroupOption {
		return this.invoiceOption;
	}

	public get intakeMonthGroupOption(): GroupOption {
		return this.intakeMonthOption;
	}

	public get intakeYearGroupOption(): GroupOption {
		return this.intakeYearOption;
	}
}
