export class EasterEggClippyJokes {
	public getRandomJoke(): string {
		const jokes = [
			"I'm afraid for the calendar.<br><br>Its days are numbered.",
			'My wife said I should do lunges to stay in shape.<br><br>That would be a big step forward.',
			'Why do fathers take an extra pair of socks when they go golfing?<br><br>In case they get a hole in one!',
			"Singing in the shower is fun until you get soap in your mouth.<br><br>Then it's a soap opera.",
			"What do a tick and the Eiffel Tower have in common?<br><br>They're both Paris sites.",
			'What do you call a fish wearing a bowtie?<br><br>Sofishticated.',
			'How do you follow Will Smith in the snow?<br><br>You follow the fresh prints.',
			'If April showers bring May flowers, what do May flowers bring?<br><br>Pilgrims.',
			'I thought the dryer was shrinking my clothes.<br><br>Turns out it was the refrigerator all along.',
			'What do you call a factory that makes okay products?<br><br>A satisfactory.',
			'Dear Math, grow up and solve your own problems.',
			'What did the janitor say when he jumped out of the closet?<br><br>Supplies!',
			'Have you heard about the chocolate record player?<br><br>It sounds pretty sweet.',
			'What did the ocean say to the beach?<br><br>Nothing, it just waved.',
			"Why do seagulls fly over the ocean?<br><br>Because if they flew over the bay, we'd call them bagels.",
			"I only know 25 letters of the alphabet. I don't know y.",
			'How does the moon cut his hair?<br><br>Eclipse it.',
			"What did one wall say to the other?<br><br>I'll meet you at the corner.",
			'What did the zero say to the eight?<br><br>That belt looks good on you.',
			"A skeleton walks into a bar and says, 'Hey, bartender. I'll have one beer and a mop.'",
			'Where do fruits go on vacation?<br><br>Pear-is!',
			"I asked my dog what's two minus two.<br><br>He said nothing.",
			"What did Baby Corn say to Mama Corn?<br><br>Where's Pop Corn?",
			"What's the best thing about Switzerland?<br><br>I don't know, but the flag is a big plus.",
			'What does a sprinter eat before a race?<br><br>Nothing, they fast!',
			'Where do you learn to make a banana split?<br><br>Sundae school.',
			'What has more letters than the alphabet?<br><br>The post office!',
			'Dad, did you get a haircut?<br><br>No, I got them all cut!',
			'What do you call a poor Santa Claus?<br><br>St. Nickel-less.',
			'I got carded at a liquor store, and my Blockbuster card accidentally fell out. The cashier said never mind.',
			"Where do boats go when they're sick?<br><br>To the boat doc.",
			"I don't trust those trees.<br><br>They seem kind of shady.",
			'My wife is really mad at the fact that I have no sense of direction.<br><br>So I packed up my stuff and right!',
			'How do you get a squirrel to like you?<br><br>Act like a nut.',
			"Why don't eggs tell jokes?<br><br>They'd crack each other up.",
			"I don't trust stairs.<br><br>They're always up to something.",
			'What do you call someone with no body and no nose?<br><br>Nobody knows.',
			"Did you hear the rumor about butter?<br><br>Well, I'm not going to spread it!",
			"Why couldn't the bicycle stand up by itself?<br><br>It was two tired.",
			"What did one hat say to the other?<br><br>Stay here! I'm going on ahead.",
			'Why did Billy get fired from the banana factory?<br><br>He kept throwing away the bent ones.',
			"I used to be addicted to soap, but I'm clean now.",
			'A guy walks into a bar...and he was disqualified from the limbo contest.',
			'You think swimming with sharks is expensive?<br><br>Swimming with sharks cost me an arm and a leg.',
			'When two vegans get in an argument, is it still called a beef?',
			"I ordered a chicken and an egg from Amazon. I'll let you know...",
			"Do you wanna box for your leftovers?<br><br>No, but I'll wrestle you for them.",
			'That car looks nice but the muffler seems exhausted.',
			'Shout out to my fingers.<br><br>I can count on all of them.',
			'If a child refuses to nap, are they guilty of resisting a rest?',
			"What country's capital is growing the fastest?<br><br>Ireland. Every day it's Dublin.",
			'I once had a dream I was floating in an ocean of orange soda.<br><br>It was more of a fanta sea.',
			"Did you know corduroy pillows are in style?<br><br>They're making headlines.",
			"Did you hear about the kidnapping at school?<br><br>It's okay, he woke up.",
			"A cheeseburger walks into a bar. The bartender says, 'Sorry, we don't serve food here.'",
			"I once got fired from a canned juice company.<br><br>Apparently I couldn't concentrate.",
			'I used to play piano by ear.<br><br>Now I use my hands.',
			'Have you ever tried to catch a fog?<br><br>I tried yesterday but I mist.',
			"I'm on a seafood diet.<br><br>I see food and I eat it.",
			'Why did the scarecrow win an award?<br><br>Because he was outstanding in his field.',
			'I made a pencil with two erasers.<br><br>It was pointless.',
			'How do you make a Kleenex dance?<br><br>Put a little boogie in it!',
			"I'm reading a book about anti-gravity.<br><br>It's impossible to put down!",
			"Did you hear about the guy who invented the knock-knock joke?<br><br>He won the 'no-bell' prize."
		];

		return jokes[Math.floor(Math.random() * jokes.length)];
	}
}
