import { computed, Ref, ref } from 'vue';
import store from '@/store';

export default class SearchEnrolmentsBarComponent {
	public searchBar: Ref<HTMLInputElement | null> = ref(null);
	public isRevealed = ref(false);
	public isExpanded = ref(false);
	private hideOnClickListenerActive = ref(false);

	public isActive = computed((): boolean => {
		return this.searchQuery.value.length !== 0;
	});

	private searchQuery = computed((): string => {
		return store.getters.internalEnrolmentsSearchQuery();
	});

	public updateSearchQuery(): void {
		if (!this.searchBar.value) {
			return;
		}

		const query = this.searchBar.value.value.toLowerCase();

		store.mutations.updateInternalEnrolmentsSearchQuery(query);
		this.filterEnrolments();
	}

	public clearSearchQuery(): void {
		if (!this.searchBar.value) {
			return;
		}

		store.mutations.updateInternalEnrolmentsSearchQuery('');
		this.searchBar.value.value = '';
		this.filterEnrolments();
	}

	public async expandSearchBar(): Promise<void> {
		this.isRevealed.value = true;
		await this.wait(20);
		this.isExpanded.value = true;
	}

	public async hideSearchBar(): Promise<void> {
		if (document.activeElement === this.searchBar.value || this.searchQuery.value.length) {
			this.ensureSearchBarIsHiddenWhenNecessary();
			return;
		}
		this.isExpanded.value = false;
		await this.wait(200);
		this.isRevealed.value = false;
	}

	private ensureSearchBarIsHiddenWhenNecessary(): void {
		if (this.hideOnClickListenerActive.value) {
			return;
		}

		const hideSearchBarOnClickMethod = (): void => {
			void this.hideSearchBar();
			document.body.removeEventListener('click', hideSearchBarOnClickMethod);
			this.hideOnClickListenerActive.value = false;
		};

		document.body.addEventListener('click', hideSearchBarOnClickMethod);
		this.hideOnClickListenerActive.value = true;
	}

	private filterEnrolments(): void {
		store.actions.updateFilteredEnrolments();
	}

	private async wait(milliseconds: number): Promise<void> {
		return new Promise((resolve) => setTimeout(() => resolve(), milliseconds));
	}
}
