import { tableSortByEnrolmentReview, tableSortByIdentificationDate, tableSortByLastChangedDate }
	from '@/presentation/components/generalizations/table-comparator';
import HotjarFieldSuppressor from '@/infrastructure/hotjar-field-suppressor';
import { IEnrolmentTableFieldSettings } from '@/models/i-enrolment-table-field-settings';

// NOTE: fits 8px grid
const columnWidthPxXXL = 240;
const columnWidthPxXL = 160;
const columnWidthPxL = 104;
const columnWidthPxS = 48;

const baseEnrolmentTableData: IEnrolmentTableFieldSettings[] = [
	{
		headerName: 'Enrolment review',
		headerTooltip: 'Enrolment review',
		cellRenderer: 'EnrolmentReviewEntry',
		width: 220,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		pinned: 'left',
		lockPosition: true,
		sort: 'desc',
		sortable: true,
		comparator: tableSortByEnrolmentReview,
		unSortIcon: true,
		resizable: false
	},
	{
		field: 'studentName',
		headerName: 'Student name',
		headerTooltip: 'Student name',
		tooltipField: 'studentName',
		width: columnWidthPxXXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		pinned: 'left',
		sortable: true,
		unSortIcon: true,
		resizable: true,
		cellRenderer: HotjarFieldSuppressor.suppressCellContent
	},
	{
		field: 'studentEmail',
		headerName: 'Student email',
		headerTooltip: 'Student email',
		tooltipField: 'studentEmail',
		width: columnWidthPxXXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true,
		cellRenderer: HotjarFieldSuppressor.suppressCellContent
	},
	{
		field: 'programmeLevel.name',
		headerName: 'Degree level',
		headerTooltip: 'Degree level',
		tooltipField: 'programmeLevel.name',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'studentNationality.name',
		headerName: 'Country',
		headerTooltip: 'Country',
		tooltipField: 'studentNationality.name',
		width: columnWidthPxL,
		minWidth: columnWidthPxL,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true,
		cellRenderer: 'CountryIcon'
	},
	{
		field: 'intakePeriodString',
		headerName: 'Intake',
		headerTooltip: 'Intake',
		tooltipField: 'intakePeriodString',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'clientSourceName',
		headerName: 'Source',
		headerTooltip: 'Source',
		tooltipField: 'clientSourceName',
		width: columnWidthPxXXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'successFeeText',
		headerName: 'Success fee category',
		headerTooltip: 'Success fee category',
		tooltipField: 'successFeeText',
		width: columnWidthPxXXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'tuitionFeeString',
		headerName: 'Tuition fee',
		headerTooltip: 'Tuition fee',
		tooltipField: 'tuitionFeeString',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'confirmationNotes',
		headerName: 'Notes',
		headerTooltip: 'Notes',
		tooltipField: 'confirmationNotes',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	}
];

const checkboxColumnData: IEnrolmentTableFieldSettings = {
	checkboxSelection: true,
	headerCheckboxSelection: true,
	width: columnWidthPxS,
	cellClass: 'TableCell',
	headerClass: 'TableHeaderCell',
	pinned: 'left',
	lockPosition: true
};

const reviewStatusIndicationColumnData: IEnrolmentTableFieldSettings = {
	headerName: '',
	headerTooltip: '',
	cellRenderer: 'ReviewStatusIndication',
	tooltipField: 'reviewStatusIndicationText',
	width: columnWidthPxS,
	cellClass: ['TableCell', 'TableActionCell'],
	headerClass: 'TableHeaderCell',
	pinned: 'left',
	lockPosition: true
};

const internalHistoryOnlyTableData: IEnrolmentTableFieldSettings[] = [
	{
		field: 'studentCountryOfResidence.name',
		headerName: 'Country of residence',
		headerTooltip: 'Country of residence',
		tooltipField: 'studentCountryOfResidence.name',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'organisationName',
		headerName: 'University name',
		headerTooltip: 'University name',
		tooltipField: 'organisationName',
		width: columnWidthPxXXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'programmeName',
		headerName: 'Programme name',
		headerTooltip: 'Programme name',
		tooltipField: 'programmeName',
		width: columnWidthPxXXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'studentOrganisationRegistrationNumber',
		headerName: 'Client identification number',
		headerTooltip: 'Client identification number',
		tooltipField: 'studentOrganisationRegistrationNumber',
		width: columnWidthPxXXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'internalSourceName',
		headerName: 'Source',
		headerTooltip: 'Source',
		tooltipField: 'internalSourceName',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'studentRegistrationDateString',
		headerName: 'Registration date',
		headerTooltip: 'Registration date',
		tooltipField: 'studentRegistrationDate',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'studentFirstActiveDateString',
		headerName: 'First active date',
		headerTooltip: 'First active date',
		tooltipField: 'studentFirstActiveDate',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'studentLastActiveDateString',
		headerName: 'Last active date',
		headerTooltip: 'Last active date',
		tooltipField: 'studentLastActiveDate',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'lastEmailActivityDateString',
		headerName: 'Last email active date',
		headerTooltip: 'Last email active date',
		tooltipField: 'lastEmailActivityDateIso',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'insertedInERTDateString',
		headerName: 'Enrolment identification date',
		headerTooltip: 'Enrolment identification date',
		tooltipField: 'insertedInERTDateIso',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		comparator: tableSortByIdentificationDate,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'lastChangedDateString',
		headerName: 'Last changed date',
		headerTooltip: 'Last changed date',
		tooltipField: 'lastChangedDateIso',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		comparator: tableSortByLastChangedDate,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'reason',
		headerName: 'Reason(s)',
		headerTooltip: 'Reason(s)',
		tooltipField: 'reason',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'preDisqualificationReason',
		headerName: 'Pre-Disqualification Reason',
		headerTooltip: 'Pre-Disqualification Reason',
		tooltipField: 'preDisqualificationReason',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'remarks',
		headerName: "Client's remark(s)",
		headerTooltip: "Client's remark(s)",
		tooltipField: 'remarks',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'duplicationNote',
		headerName: 'Duplication note',
		headerTooltip: 'Duplication note',
		tooltipField: 'duplicationNote',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'confirmationChallengeNote',
		headerName: 'Revisit note',
		headerTooltip: 'Revisit note',
		tooltipField: 'remarks',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'invoicingBatchIdentity',
		headerName: 'Invoice Batch ID',
		headerTooltip: 'Invoice Batch ID',
		tooltipField: 'invoicingBatchIdentity',
		width: columnWidthPxL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	}
];

export { baseEnrolmentTableData, checkboxColumnData, reviewStatusIndicationColumnData, internalHistoryOnlyTableData };
