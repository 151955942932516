import { Component, defineComponent, onMounted, onUnmounted } from 'vue';
import OptionalFilterTags
	from '@/presentation/components/generalizations/optional-filters/optional-filter-tags/optional-filter-tags.vue';
import OptionalFiltersButton
	from '@/presentation/components/generalizations/optional-filters/optional-filters-button/optional-filters-button.vue';
import OptionalFiltersPanel
	from '@/presentation/components/generalizations/optional-filters/optional-filters-panel/optional-filters-panel.vue';
import ClearOptionalFiltersButton
	from '@/presentation/components/generalizations/optional-filters/clear-optional-filters-button/clear-optional-filters-button.vue';
import HighlightedFeature
	from '@/presentation/components/client/highlighted-feature/highlighted-feature.vue';
import OptionalFiltersWrapperComponent
	from '@/presentation/components/generalizations/optional-filters/optional-filters-wrapper/optional-filters-wrapper-class';

export default defineComponent({
	components: {
		OptionalFilterTags: OptionalFilterTags as Component,
		OptionalFiltersButton: OptionalFiltersButton as Component,
		ClearOptionalFiltersButton: ClearOptionalFiltersButton as Component,
		OptionalFiltersPanel: OptionalFiltersPanel as Component,
		HighlightedFeature: HighlightedFeature as Component
	},
	setup: () => {
		const component = new OptionalFiltersWrapperComponent();

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		return {
			panelVisible: component.panelVisible,
			togglePanelVisibility: component.togglePanelVisibility.bind(component),
			highlightedFeatureTitle: component.highlightedFeatureTitle,
			highlightedFeatureContent: component.highlightedFeatureContent,
			highlightedFeatureNameFilter: component.highlightedFeatureNameFilter
		};
	}
});
