import { SuccessFee } from '@/models/success-fee';
import { SuccessFeeEnum } from '@/models/success-fee-enum';
import { EnrolmentNotInScopeReason } from '@studyportals/sp-lord-business-interface';

import { NoPayReason } from '../../no-pay-reason';

export class SuccessFeeInformation {
	public noPayReasons: NoPayReason[] = [];

	public constructor(public successFee?: SuccessFee | null | undefined) {}

	public requiresTuitionFee(): boolean {
		if (null === this.successFee || undefined === this.successFee) {
			return false;
		}

		return this.successFee.requiresTuitionFee;
	}

	public requiresNationality(): boolean {
		if (null === this.successFee || undefined === this.successFee) {
			return this.noPayReasons.some((_) => _.type === EnrolmentNotInScopeReason.NATIONALITY);
		}

		return this.successFee.requiresNationality;
	}

	public requiresIntakeInformation(): boolean {
		return this.noPayReasons.some((_) => _.type === EnrolmentNotInScopeReason.INTAKE_DATE);
	}

	public requiresProgrammeInformation(): boolean {
		return this.noPayReasons.some((_) => _.type === EnrolmentNotInScopeReason.PROGRAMME);
	}

	public requiresNote(): boolean {
		return this.successFeeRequiresNote() || this.noPayReasonsRequireNote();
	}

	public includeNote(): boolean {
		return this.successFeeRequiresNote() || null === this.successFee || this.noPayReasonsRequireNote();
	}

	public isNoteOptional(): boolean {
		return null === this.successFee && !this.noPayReasonsRequireNote();
	}

	public noPayReasonsToValues(): EnrolmentNotInScopeReason[] {
		return this.noPayReasons.map((_) => _.type);
	}

	private successFeeRequiresNote(): boolean {
		if (null === this.successFee || undefined === this.successFee) {
			return false;
		}

		return SuccessFeeEnum.OTHER === this.successFee.value;
	}

	private noPayReasonsRequireNote(): boolean {
		return this.noPayReasons.some((_) => _.type === EnrolmentNotInScopeReason.OTHER);
	}
}
