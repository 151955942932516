import { computed, ref } from 'vue';
export default class Monthpicker {
    emit;
    allowPastDates;
    pastYearsRange;
    futureYearsRange;
    defaultMonth;
    defaultYear;
    nativePicker = ref(null);
    largePicker = ref(null);
    selectedDate = ref('');
    debounceTimeout;
    debounceTime = 0;
    clickOutsideHandler = () => { };
    isInvalid = ref(false);
    dateTextInput = ref(null);
    dateFormat = 'mm/yyyy';
    inputText = ref(this.dateFormat);
    showLargePicker = ref(false);
    showNativePicker = computed(() => {
        return this.isMobile.value && !this.showLargePicker.value;
    });
    isMobile = computed(() => {
        const breakpointsMethod = window['Shared']?.['breakpoints'];
        if (!breakpointsMethod) {
            return false;
        }
        return breakpointsMethod(['Small', 'Medium']) ?? false;
    });
    minDate = computed(() => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        if (!this.allowPastDates) {
            return `${year}-${month.toString().padStart(2, '0')}`;
        }
        if (!this.pastYearsRange.value) {
            return '';
        }
        return `${year - this.pastYearsRange.value}-01`;
    });
    maxDate = computed(() => {
        const today = new Date();
        const year = today.getFullYear();
        if (!this.futureYearsRange.value) {
            return '';
        }
        return `${year + this.futureYearsRange.value}-12`;
    });
    errorMessage = computed(() => {
        const genericMessage = `Please enter a valid date in the format ${this.dateFormat}`;
        const futureOnlyMessage = `Please enter a valid future date in the format ${this.dateFormat} `;
        return this.allowPastDates ? genericMessage : futureOnlyMessage;
    });
    constructor(emit, allowPastDates, pastYearsRange, futureYearsRange, defaultMonth, defaultYear) {
        this.emit = emit;
        this.allowPastDates = allowPastDates;
        this.pastYearsRange = pastYearsRange;
        this.futureYearsRange = futureYearsRange;
        this.defaultMonth = defaultMonth;
        this.defaultYear = defaultYear;
    }
    mounted() {
        if (this.defaultMonth && this.defaultYear) {
            this.selectedDate.value = `${this.defaultYear}-${this.defaultMonth}`;
        }
        this.updateInputText();
    }
    monthClicked(event) {
        event.stopPropagation();
        event.preventDefault();
        if (this.showNativePicker.value) {
            // for iOS
            this.nativePicker.value?.focus();
            // for Android
            this.nativePicker.value?.click();
            // for desktop
            this.nativePicker.value?.showPicker();
            return;
        }
        this.addLargePicker();
        this.dateTextInput.value?.focus();
    }
    onDateSelected(date) {
        if (date) {
            this.selectedDate.value = `${date.year}-${date.month}`;
            this.showLargePicker.value = false;
        }
        this.updateInputText();
        this.emitDateChanged();
    }
    onTextInput() {
        this.debounce(() => {
            this.validateDate();
            if (this.isInvalid.value) {
                return;
            }
            this.selectedDate.value = `${this.inputText.value.split('/')[1]}-${this.inputText.value.split('/')[0]}`;
            this.emitDateChanged();
        });
    }
    onEnterClicked() {
        this.validateDate();
        if (this.isInvalid.value) {
            return;
        }
        this.removeBodyClickHandler();
        this.showLargePicker.value = false;
    }
    emitDateChanged() {
        this.validateDate();
        if (this.isInvalid.value) {
            return;
        }
        this.emit('dateChanged', {
            month: this.selectedDate.value.split('-')[1],
            year: this.selectedDate.value.split('-')[0]
        });
    }
    addLargePicker() {
        this.showLargePicker.value = true;
        this.clickOutsideHandler = (event) => { this.removeLargePicker(event); };
        document.body.addEventListener('click', this.clickOutsideHandler);
    }
    removeLargePicker(event) {
        if (!this.largePicker?.value) {
            return;
        }
        const allElemsInTree = document.elementsFromPoint(event.clientX, event.clientY);
        if (!allElemsInTree.includes(this.largePicker.value?.firstChild)) {
            this.showLargePicker.value = false;
            this.removeBodyClickHandler();
        }
    }
    removeBodyClickHandler() {
        document.body.removeEventListener('click', this.clickOutsideHandler);
    }
    validateDate() {
        const regex = new RegExp('^((0[1-9])|(1[0-2]))/([0-9]{4})$');
        const isValid = regex.test(this.inputText.value);
        if (!isValid) {
            this.isInvalid.value = true;
            return;
        }
        if (!this.allowPastDates) {
            const currentMonth = new Date().getMonth();
            const currentYear = new Date().getFullYear();
            const date = new Date(currentYear, currentMonth);
            const selectedDate = new Date(`${this.inputText.value.split('/')[1]}-${this.inputText.value.split('/')[0]}`);
            const isCurrentOrFutureDate = selectedDate >= date;
            if (!isCurrentOrFutureDate) {
                this.isInvalid.value = true;
                return;
            }
        }
        this.isInvalid.value = false;
    }
    updateInputText() {
        if (this.selectedDate.value) {
            this.inputText.value = `${this.selectedDate.value.split('-')[1]}/${this.selectedDate.value.split('-')[0]}`;
            return;
        }
        if (this.defaultMonth && this.defaultYear) {
            this.inputText.value = `${this.defaultMonth}/${this.defaultYear}`;
            return;
        }
        this.inputText.value = this.dateFormat;
    }
    debounce(callback) {
        if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout);
        }
        this.debounceTimeout = setTimeout(() => {
            if (this.debounceTime < Date.now()) {
                callback();
                this.debounceTime = Date.now() + 200;
            }
        }, 200);
    }
}
