import { ClientReviewCsvExport } from '@/presentation/components/generalizations/enrolment-export/client-review-csv-export';
import { BusinessUnitDirectorReviewCsvExport }
	from '@/presentation/components/generalizations/enrolment-export/business-unit-director-review-csv-export';
import { InternalAllColumnsCsvExport } from '@/presentation/components/generalizations/enrolment-export/internal-all-columns-csv-export';
import { TrackingEventEnums } from '@/models/tracking-event-enums';
import { CsvExport } from '@/presentation/components/generalizations/enrolment-export/csv-export';
import { ITrackingOptions } from '@/models/i-tracking-options';

import { BaseSuperAgentRequestFactory } from '@studyportals/mb-platform-http-requests';
const baseSuperAgentRequestFactory = new BaseSuperAgentRequestFactory();

export default class SpAnalyticsTracker {
	public static trackEventExportOption(exportOption: CsvExport): void {
		let action = TrackingEventEnums.UNKNOWN_OPTION_EXPORT;

		if (exportOption instanceof ClientReviewCsvExport) {
			action = TrackingEventEnums.CLIENT_REVIEW_EXPORT;
		} else if (exportOption instanceof BusinessUnitDirectorReviewCsvExport) {
			action = TrackingEventEnums.BUSINESS_UNIT_DIRECTOR_REVIEW_EXPORT;
		} else if (exportOption instanceof InternalAllColumnsCsvExport) {
			action = TrackingEventEnums.INTERNAL_ALL_COLUMNS_EXPORT;
		}

		void SpAnalyticsTracker.trackEvent([action]);
	}

	private static async trackEvent(tags: string[]): Promise<void> {
		const env = webpackDefinitions.VUE_APP_SP_ANALYTICS_ENV;

		const options: ITrackingOptions = {
			category: 'EITTracking',
			action: 'Add',
			label: 'SMTVerification',
			product: 'EIT',
			environment: env,
			team: 'hs',
			url: window.location.href,
			tags: tags ? tags : []
		};

		try {
			await SpAnalyticsTracker.handleTrackingRequest(options);
		} catch (eventError) {
			throw new Error(
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
				`SpAnalyticsTracker.trackEvent: Something went wrong tracking ERT data: ${(eventError as Error).message as string}`
			);
		}
	}

	private static async handleTrackingRequest(options: ITrackingOptions): Promise<any> {
		const url = webpackDefinitions.VUE_APP_SP_ANALYTICS_URL;

		try {
			return await baseSuperAgentRequestFactory.put(`${url}/events`).send(options);
		} catch (requestError) {
			throw new Error(
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
				`SpAnalyticsTracker.handleTrackingRequest: request error; ${(requestError as Error).message as string}.`
			);
		}
	}
}
