import { ValidationResult } from '../../../validation/validation-result';
import { TriggerPayload } from '../trigger-payload';
import { NoteValidation } from './note-validation';
import { TriggerPayloadValidation } from './trigger-payload-validation';

export class EnrolmentConfirmationUnsureTriggerPayloadValidation extends TriggerPayloadValidation {
	public validateInExistingResult(payload: TriggerPayload, result: ValidationResult): ValidationResult {
		new NoteValidation().validateInExistingResult(payload, result);

		return result;
	}
}
