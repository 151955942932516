import { ref } from 'vue';
import { DataStorage } from '@studyportals/data-storage';
import { UTCDateTime } from '@studyportals/mb-platform-date-time';
import { EasterEggClippyJokes } from './easteregg-clippy-jokes';
import { DataStorageLabelsEnum } from '@/models/data-storage-labels-enum';

export default class EasterEggClippyComponent {
	public clippyRight = ref(true);
	public clippyHideFunny = ref(true);
	public jokesClass = new EasterEggClippyJokes();
	public currentJoke = ref(this.jokesClass.getRandomJoke());

	public timeout = 1000;

	public get clippyShouldBeVisible(): boolean {
		const today = UTCDateTime.today();
		const month = today.getMonth();
		const day = today.getDay();

		const withinDateRange = month === 4 && day >= 1 && day <= 7;

		return withinDateRange;
	}

	public notFunny(): void {
		this.clippyRight.value = !this.clippyRight.value;
	}

	public funny(): void {
		this.clippyHideFunny.value = true;
		this.clippyRight.value = true;

		this.setCookie(JSON.stringify(Date.now()));

		setTimeout(() => {
			this.storageChecker();
		}, this.timeout);
	}

	public showClippy(): void {
		this.clippyHideFunny.value = false;

		this.currentJoke.value = this.jokesClass.getRandomJoke();
	}

	public hideClippy(): void {
		this.clippyHideFunny.value = true;
		this.setCookie('hidden');
	}

	public storageChecker(): void {
		const easterEggStorage = DataStorage.retrieve(DataStorageLabelsEnum.EASTER_EGG_CLIPPY);
		if (!easterEggStorage) {
			this.clippyHideFunny.value = false;
			this.showClippy();

			return;
		}

		if (easterEggStorage === 'hidden') {
			return;
		}

		const storedDate = Number.parseInt(easterEggStorage);
		const currentDate = Number.parseInt(JSON.stringify(Date.now()));
		const dateDifference = currentDate - storedDate;

		if (dateDifference >= this.timeout) {
			this.clippyHideFunny.value = false;
			this.showClippy();
		}
	}

	private setCookie(value: string): void {
		DataStorage.store(DataStorageLabelsEnum.EASTER_EGG_CLIPPY, value, 31536000);
	}
}
