import { defineComponent, onMounted } from 'vue';
import { MultiSelect } from '@studyportals/multiselect';
import EnrolmentsTableFilterComponent from '@/presentation/components/client/enrolments-table-filter/enrolments-table-filter-class';

export default defineComponent({
	components: {
		MultiSelect
	},
	setup: () => {
		const component = new EnrolmentsTableFilterComponent();

		onMounted(component.mounted.bind(component));

		return {
			reviewStepsDropdown: component.reviewStepsDropdown,
			reviewStepsCluster: component.reviewStepsCluster,
			selectorClusterHandler: component.selectorClusterHandler
		};
	}
});
