import { Enrolment } from '@/models/enrolment';
import { EnrolmentStatus } from '@/models/enrolment-status';
import EnrolmentService from '@/services/enrolments-service';

import { EnrolmentConfirmedTriggerPayloadConfiguration } from '../trigger-payload/enrolment-confirmed-trigger-payload-configuration';
import { TriggerPayload } from '../trigger-payload/trigger-payload';
import { TransitionTrigger } from './transition-trigger';

export class EnrolmentConfirmedTrigger extends TransitionTrigger {
	public constructor(excludedEnrolmentStatuses: EnrolmentStatus[], displayText = 'Enrolled') {
		super(displayText, excludedEnrolmentStatuses, new EnrolmentConfirmedTriggerPayloadConfiguration());
	}

	public equals(that: TransitionTrigger): boolean {
		return that instanceof EnrolmentConfirmedTrigger;
	}

	protected async performTransitions(enrolments: Readonly<Enrolment[]>, payload: TriggerPayload): Promise<void> {
		await EnrolmentService.triggerEnrolmentConfirmed(
			enrolments,
			payload.enrichmentSpecification.toTransportRepresentation(),
			payload.successFeeInformation.successFee,
			payload.successFeeInformation.noPayReasonsToValues(),
			payload.note
		);
	}
}
