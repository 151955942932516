enum CsvSeparators {
	COMMA = ',',
	SEMICOLON = ';'
}

enum CsvSeparatorNames {
	COMMA = 'comma',
	SEMICOLON = 'semicolon'
}

interface ICsvSeparatorDetails {
	symbol: CsvSeparators;
	name: CsvSeparatorNames;
	title: string;
	learnMoreToggled: boolean;
	description: string;
}

type TAvailableCsvSeparators = {
	[key in CsvSeparatorNames]: ICsvSeparatorDetails;
};

export { CsvSeparators, CsvSeparatorNames, ICsvSeparatorDetails, TAvailableCsvSeparators };
