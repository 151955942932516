import { Enrolment } from '@/models/enrolment';
import { EnrolmentStatus } from '@/models/enrolment-status';
import EnrolmentService from '@/services/enrolments-service';
import { TriggerPayload } from '../trigger-payload/trigger-payload';
import { TransitionTrigger } from './transition-trigger';
import { EnrolmentChallengedTriggerPayloadConfiguration } from '../trigger-payload/enrolment-challenged-trigger-payload-configuration';

export class EnrolmentConfirmationChallengedTrigger extends TransitionTrigger {
	public orderingNumber = 51;

	public constructor(excludedEnrolmentStatuses: EnrolmentStatus[]) {
		super('Challenged', excludedEnrolmentStatuses, new EnrolmentChallengedTriggerPayloadConfiguration());
	}

	public equals(that: TransitionTrigger): boolean {
		return that instanceof EnrolmentConfirmationChallengedTrigger;
	}

	protected async performTransitions(enrolments: Readonly<Enrolment[]>, payload: TriggerPayload): Promise<void> {
		await EnrolmentService.triggerEnrolmentConfirmationChallenged(enrolments, payload.note || '');
	}
}
