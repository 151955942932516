import { Component, defineComponent, onMounted, onUnmounted } from 'vue';

import InvoiceBatchCreatedNotificationComponent
	from '@/presentation/components/internal/invoice-batch-created-notification/invoice-batch-created-notification-class';
import InvoiceBatchCreatedNotificationModal
	from '@/presentation/components/internal/invoice-batch-created-notification-modal/invoice-batch-created-notification-modal.vue';

export default defineComponent({
	components: {
		InvoiceBatchCreatedNotificationModal: InvoiceBatchCreatedNotificationModal as Component
	},
	setup: () => {
		const component = new InvoiceBatchCreatedNotificationComponent();

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		return {
			invoiceBatchCreatedNotificationStatusIsInactive: component.invoiceBatchCreatedNotificationStatusIsInactive,
			modalStatusInvoiceBatchCreatedNotification: component.modalStatusInvoiceBatchCreatedNotification,
			invoiceBatchCreatedPayload: component.invoiceBatchCreatedPayload,
			updateModalStatus: component.updateModalStatus.bind(component)
		};
	}
});
