import EventBus from '@/event-bus/event-bus';
import { EnrolmentEditRequestedEvent } from '@/event-bus/enrolment-edit-requested-event';
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';

export class EditEnrolmentEntry implements ICellRendererComp {
	private outerWrapper: HTMLSpanElement;
	private button: HTMLButtonElement;
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private buttonListener: (event: Event) => void;
	private params: ICellRendererParams | null = null;

	constructor() {
		this.outerWrapper = document.createElement('span');
		this.button = document.createElement('button');
		this.buttonListener = this.buttonClicked.bind(this);
	}

	public init(params: ICellRendererParams): void {
		this.params = params;

		this.button.className = 'EditEnrolmentButton';
		this.button.addEventListener('click', this.buttonListener);
		this.button.innerHTML = '<i class="lnr-pencil"></i>';
		this.outerWrapper.appendChild(this.button);
	}

	public getGui(): HTMLSpanElement {
		return this.outerWrapper;
	}

	public refresh(): boolean {
		return false;
	}

	public destroy(): void {
		this.button.removeEventListener('click', this.buttonListener);
	}

	private buttonClicked(event: Event): void {
		event.stopPropagation();
		if (!this.params) {
			return;
		}

		EventBus.getEvent(EnrolmentEditRequestedEvent).publish(this.params.data);
	}
}
