import { computed } from 'vue';
import tableSelectorRetriever from '@/presentation/components/generalizations/table-selectors/table-selector-retriever';
import store from '@/store';

export default class OptionalFiltersButtonComponent {
	public get isInternal(): boolean {
		return store.getters.isInternal();
	}

	public doesAtLeastOneFilterHaveSelectedOption = computed((): boolean => {
		return this.selectedFiltersCounter.value >= 1;
	});

	public selectedFiltersCounter = computed((): number => {
		const optionalClientFilters = tableSelectorRetriever.optionalClientFilters;
		return optionalClientFilters.filter((filter) => {
			return filter.selectedOption.value !== filter.defaultOption?.value;
		}).length;
	});
}
