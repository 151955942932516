import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "InternalEnrolmentsTableFilter" }
const _hoisted_2 = { class: "FilterContainer" }
const _hoisted_3 = { class: "FilterContainer" }
const _hoisted_4 = { class: "FilterContainer" }
const _hoisted_5 = { class: "FilterContainer ProcessStepFilterContainer" }
const _hoisted_6 = {
  key: 0,
  class: "OptionalFiltersWrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_OptionalFiltersButton = _resolveComponent("OptionalFiltersButton")!
  const _component_OptionalFiltersPanel = _resolveComponent("OptionalFiltersPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MultiSelect, {
        ref: "psmDropdown",
        class: "Filter Medium",
        label: "PSM",
        placeholder: "Nothing selected",
        options: _ctx.psmOptions,
        searchable: true,
        entity: "PSM",
        onUpdated: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectorClusterHandler.markOptionAsSelected(_ctx.psmCluster)))
      }, null, 8 /* PROPS */, ["options"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_MultiSelect, {
        ref: "organisationDropdown",
        class: "Filter Large",
        label: "University name",
        placeholder: "Nothing selected",
        options: _ctx.organisationOptions,
        searchable: true,
        entity: "university",
        onUpdated: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectorClusterHandler.markOptionAsSelected(_ctx.organisationCluster)))
      }, null, 8 /* PROPS */, ["options"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_MultiSelect, {
        ref: "statusDropdown",
        class: "Filter Small",
        label: "Enrolment status",
        placeholder: "Nothing selected",
        options: _ctx.statusOptions,
        searchable: true,
        entity: "enrolment status",
        onUpdated: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectorClusterHandler.markOptionAsSelected(_ctx.statusCluster)))
      }, null, 8 /* PROPS */, ["options"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_MultiSelect, {
        ref: "stepDropdown",
        class: "Filter Small",
        label: "Process step",
        placeholder: "Nothing selected",
        options: _ctx.stepOptions,
        searchable: true,
        entity: "process step",
        onUpdated: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectorClusterHandler.markOptionAsSelected(_ctx.stepCluster)))
      }, null, 8 /* PROPS */, ["options"])
    ]),
    (_ctx.onIdentifiedEnrolmentsPage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_OptionalFiltersButton, {
            panelVisible: _ctx.panelVisible,
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.togglePanelVisibility()))
          }, null, 8 /* PROPS */, ["panelVisible"]),
          (_ctx.panelVisible)
            ? (_openBlock(), _createBlock(_component_OptionalFiltersPanel, {
                key: 0,
                onClosePanel: _cache[5] || (_cache[5] = ($event: any) => (_ctx.togglePanelVisibility()))
              }))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("span", {
      class: "ClearAllFilters",
      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.clearFilters()))
    }, " Clear all filters ")
  ]))
}