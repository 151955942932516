import GrammaticalNumberTextPresenter from '@/presenters/grammatical-number-text-presenter';

export class TriggerPayloadPopupConfiguration {
	private constructor(
		public readonly title: string,
		public readonly label: string,
		public readonly notesPlaceholder: string,
		public readonly notesErrorText: string
	) {}

	public static otherStatus(amount: number): TriggerPayloadPopupConfiguration {
		const textPresenter = new GrammaticalNumberTextPresenter(amount);

		return new TriggerPayloadPopupConfiguration(
			`Reviewing ${textPresenter.amountOfEnrolmentsDisplayText}`,
			`Specify enrolment status of ${textPresenter.amountOfEnrolmentsDisplayText}`,
			'Other enrolment status',
			`Please describe the ${textPresenter.studentsDisplayText} enrolment status`
		);
	}

	public static futureIntakeStatus(amount: number): TriggerPayloadPopupConfiguration {
		const textPresenter = new GrammaticalNumberTextPresenter(amount);

		return new TriggerPayloadPopupConfiguration(
			`${textPresenter.enrolmentsPossessionDisplayText} details`,
			`Provide the intake year and month for ${textPresenter.amountOfEnrolmentsDisplayText}`,
			'',
			''
		);
	}

	public static studentNotEnrolledStatus(amount: number): TriggerPayloadPopupConfiguration {
		const textPresenter = new GrammaticalNumberTextPresenter(amount);

		return new TriggerPayloadPopupConfiguration(
			`Reviewing ${textPresenter.amountOfEnrolmentsDisplayText}`,
			`Provide more details about ${textPresenter.theseStudentsDisplayText}`,
			'Details',
			`Please provide more details about the ${textPresenter.studentsDisplayText} enrolment process`
		);
	}

	public static studentEnrolledStatus(amount: number): TriggerPayloadPopupConfiguration {
		const textPresenter = new GrammaticalNumberTextPresenter(amount);

		return new TriggerPayloadPopupConfiguration(
			`${textPresenter.enrolmentsPossessionDisplayText} details`,
			`Provide the student details for ${textPresenter.amountOfEnrolmentsDisplayText}`,
			'Enter any other reasons',
			`Please enter the reason why ${textPresenter.theseEnrolmentsDisplayText} ${textPresenter.dontDisplayText} qualify for a commission (success fee)`
		);
	}
}
