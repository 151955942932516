import { createApp, h } from 'vue';
const user = webpackDefinitions.USER;
// eslint-disable-next-line
const enrolmentsOverview = require(`@/presentation/views/${user}/${user}-enrolments-overview/${user}-enrolments-overview.vue`).default;
import LogIn from '@/stand-alone/log-in.vue';

import { IBootstrapper } from '@/models/i-bootstrapper';
import { IModalManager } from '@/models/i-modal-manager';
import { IPerformance } from '@/models/i-performance';

import store from '@/store';
import stylesheetLoader from './stylesheet-loader';
import { ModalManager } from '@studyportals/modal';
import LoadingStatusInformer from '@/infrastructure/loading-status-informer';
import RollbarOperator from '@/infrastructure/rollbar-operator';

// eslint-disable-next-line
const bootstrapper: IBootstrapper = require(`./bootstrapper.${user}`).default;
const standAloneLogin = webpackDefinitions.STAND_ALONE_LOGIN === 'true';

declare global {
	interface Window {
		ModalManager: IModalManager;
		hj: (action: string, name: string) => void;
		Rollbar?: {
			error: (message: Error | string) => void;
			warn: (message: Error | string) => void;
		};
		performance?: IPerformance;
		DatalakeEventTrackerLoaded?: boolean;
		Intl: {
			DisplayNames?: {
				new (
					languages: string[],
					payload: any
				): {
					of: (iso: string) => string;
				};
			};
		};
	}
}

const url = webpackDefinitions.VUE_APP_ICONSET_URL;
stylesheetLoader.loadStylesheet(url);

store.actions.subscribeToEventBus();
store.mutations.setInternalFlag(user === 'internal');
store.mutations.setStandAloneLogin(standAloneLogin);
window.ModalManager = new ModalManager();

bootstrapper
	.bootstrap()
	.then(() => {
		const app = createApp({
			render: () => {
				return h(enrolmentsOverview);
			}
		});

		RollbarOperator.handleVueErrors(app);

		app.provide('store', store);
		app.mount('#app');
	})
	.catch((error: Error) => {
		LoadingStatusInformer.announceLoadingFailed();

		if (!standAloneLogin || error.name !== 'NotAuthorizedException') {
			throw error;
		}

		const app = createApp({
			render: () => {
				return h(LogIn);
			}
		});

		app.provide('store', store);
		app.mount('#app');
	});
