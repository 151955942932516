import { LordBusiness } from '@/infrastructure';
import store from '@/store';
import { CCDBContractSegment } from '@studyportals/sp-lord-business-interface/src/CCDB/DataObjects/CCDBContractSegment';
import { Filters } from '@studyportals/sp-lord-business-interface/src/CCDB/DataObjects/Segments/Filters';
import { StudentSpecification } from '@studyportals/sp-lord-business-interface/src/CCDB/DataObjects/Segments/StudentSpecification';
import { SegmentTypeEnum } from '@studyportals/sp-lord-business-interface/src/CCDB/Enums/SegmentTypeEnum';
import { FilterTypeEnum } from '@studyportals/sp-lord-business-interface/src/CCDB/Enums/FilterTypeEnum';
import { RegionEnum } from '@studyportals/sp-lord-business-interface/src/CCDB/Enums/RegionEnum';
import { PriceTypeEnum } from '@studyportals/sp-lord-business-interface/src/CCDB/Enums/PriceTypeEnum';
import { UnitPriceEnum } from '@studyportals/sp-lord-business-interface/src/CCDB/Enums/UnitPriceEnum';
import { ContractSegmentSectionsEnums } from '@/models/contract-segment-sections-enums';
import { ICCDBContractDetails } from '@studyportals/sp-lord-business-interface/src/CCDB/DataObjects/ICCDBContractDetails';

class ContractsPresenter {
	private notAvailableText = 'Not specified';

	public get contractDetails(): ICCDBContractDetails | undefined {
		return store.state.contractDetails;
	}

	public get contractName(): string {
		if (this.contractDetails && this.contractDetails.organisationName) {
			return this.contractDetails.organisationName;
		}

		return this.notAvailableText;
	}

	public get annualFee(): string {
		if (this.contractDetails && this.contractDetails.annualFee && this.contractDetails.annualFee.annualFee) {
			return `${this.contractDetails.annualFee.annualFee} ${this.currency}`;
		}

		return this.notAvailableText;
	}

	public get firstPromotionStartDate(): string {
		if (this.contractDetails && this.contractDetails.promotionStartDate) {
			return this.contractDetails.promotionStartDate.toString();
		}

		if (this.contractDetails && this.contractDetails.term && this.contractDetails.term.contractStartDate) {
			return this.contractDetails.term.contractStartDate;
		}

		return this.notAvailableText;
	}

	public get contractEndDate(): string {
		if (this.contractDetails && this.contractDetails.term && this.contractDetails.term.contractEndDate) {
			return this.contractDetails.term.contractEndDate;
		}

		return this.notAvailableText;
	}

	public get tailPeriodEndDate(): string {
		if (this.contractDetails && this.contractDetails.term && this.contractDetails.term.tailPeriodEndDate) {
			return this.contractDetails.term.tailPeriodEndDate;
		}

		return this.notAvailableText;
	}

	public get segments(): CCDBContractSegment[] {
		if (this.contractDetails) {
			return this.contractDetails.segments;
		}

		return [];
	}

	private get currency(): string {
		if (this.contractDetails && this.contractDetails.paymentOptions && this.contractDetails.paymentOptions.currency) {
			return this.contractDetails.paymentOptions.currency;
		}

		return '';
	}

	private get selectedOrganisation(): string | null {
		return store.state.selectedOrganisation;
	}

	public async retrieveContractDetails(): Promise<void> {
		const selectedOrganisation = this.selectedOrganisation;
		if (!selectedOrganisation) {
			return;
		}

		const contractResult = await LordBusiness.retrieveContractDetailsByOrganisationId(selectedOrganisation);
		this.addContractToStore(contractResult);
	}

	public isSegmentTypeIncluded(segment: CCDBContractSegment): boolean {
		return segment.segmentType === SegmentTypeEnum.INCLUDED;
	}

	public isSegmentSectionIrrelevant(segment: CCDBContractSegment, section: ContractSegmentSectionsEnums): boolean {
		let details = '';

		switch (section) {
			case ContractSegmentSectionsEnums.PROGRAMME:
				details = this.retrieveSegmentProgrammeDetails(segment);
				break;
			case ContractSegmentSectionsEnums.STUDENT:
				details = this.retrieveSegmentStudentDetails(segment);
				break;
			case ContractSegmentSectionsEnums.ATTRIBUTION:
				details = this.retrieveSegmentAttributionDetails(segment);
				break;
			case ContractSegmentSectionsEnums.PRICING:
				details = this.retrieveSegmentPricingDetails(segment);
				break;
			case ContractSegmentSectionsEnums.COMMENTS:
				details = this.retrieveSegmentCommentDetails(segment);
				break;
		}

		return details === '' || details === this.notAvailableText;
	}

	public retrieveSegmentProgrammeDetails(segment: CCDBContractSegment): string {
		if (!segment.programmeSpecification) {
			return this.notAvailableText;
		}

		const filtersBasedDescription = this.determineProgrammeDetailsDescriptionForFiltersCluster(segment.programmeSpecification.filters);
		if (filtersBasedDescription) {
			return filtersBasedDescription;
		}

		if (segment.programmeSpecification.studentExemptions) {
			return segment.programmeSpecification.studentExemptions.join(', ');
		}

		return 'All programmes';
	}

	public retrieveSegmentStudentDetails(segment: CCDBContractSegment): string {
		if (!segment.studentSpecification) {
			return this.notAvailableText;
		}

		const areasBasedDescription = this.determineStudentDetailsDescriptionForAreas(segment.studentSpecification);
		if (areasBasedDescription) {
			return areasBasedDescription;
		}

		if (segment.studentSpecification.studentExemptions) {
			return segment.studentSpecification.studentExemptions.join(', ');
		}

		return this.notAvailableText;
	}

	public retrieveSegmentAttributionDetails(segment: CCDBContractSegment): string {
		if (segment.studentSpecification && segment.studentSpecification.attributionExemptions) {
			return segment.studentSpecification.attributionExemptions.join(', ');
		}

		return this.notAvailableText;
	}

	// TODO: Use right pricing properties & avoid any type for this method when they are available (not private ones with underscore).
	public retrieveSegmentPriceTypeName(pricing: any): string {
		let priceType = PriceTypeEnum.ENROLMENT;

		if (pricing && pricing._priceType) {
			priceType = pricing._priceType;
		}

		return priceType.toLowerCase();
	}

	public retrieveSegmentPricingDetails(segment: CCDBContractSegment): string {
		if (!segment.pricing || !this.currency) {
			return this.notAvailableText;
		}

		let description = this.determineUnitPricingDetailsDescription(segment.pricing);
		description = this.enrichPriceDetailsDescriptionWithTiers(segment.pricing, description);

		return description ? description : this.notAvailableText;
	}

	public retrieveSegmentCommentDetails(segment: CCDBContractSegment): string {
		if (!segment.comments) {
			return this.notAvailableText;
		}

		return segment.comments;
	}

	public calculateNumberOfSegment(segment: CCDBContractSegment): number {
		const segmentsOfSameType = this.segments.filter((segmentFromFullList) => segmentFromFullList.segmentType === segment.segmentType);
		return segmentsOfSameType.findIndex((segmentOfSameType) => segmentOfSameType.id === segment.id) + 1;
	}

	private determineProgrammeDetailsDescriptionForFiltersCluster(filtersCluster: Filters[]): string {
		let description = '';

		if (!filtersCluster) {
			return description;
		}

		filtersCluster.forEach((filters, index) => {
			description += this.determineProgrammeDetailsDescriptionForFilters(filtersCluster, index);
		});

		return description;
	}

	private determineProgrammeDetailsDescriptionForFilters(filtersCluster: Filters[], index: number): string {
		let description = '';
		const filters = filtersCluster[index];

		switch (filters.filterType) {
			case FilterTypeEnum.ALL_ENGLISH_PROGRAMMES:
				description += 'All English programmes';
				break;
			case FilterTypeEnum.PROGRAMME_IDS:
				description += `Programme ids: ${filters.ids.join(', ')}`;
				break;
			case FilterTypeEnum.PROGRAMME_LEVEL:
				description += `${filters.programmeLevels.join(', ')}`;
				break;
			default:
				return '';
		}

		if (index < filtersCluster.length - 1) {
			description += '<br>';
		}

		return description;
	}

	private determineStudentDetailsDescriptionForAreas(studentSpecification: StudentSpecification): string {
		let areas: (string | RegionEnum)[] = [];

		if (studentSpecification.countries) {
			areas = areas.concat(studentSpecification.countries);

			if (window.Intl && window.Intl.DisplayNames) {
				const countryNames = new window.Intl.DisplayNames(['en'], { type: 'region' });
				areas = areas.map((area) => countryNames.of(area));
			}
		}

		if (studentSpecification.regions) {
			areas = areas.concat(studentSpecification.regions);
		}

		return areas.length ? `From: ${areas.join(', ')}` : '';
	}

	// TODO: Use right pricing properties & avoid any type for methods below when they are available (not private ones with underscore).
	private determineUnitPricingDetailsDescription(pricing: any): string {
		let description = '';

		if (!pricing._unitPrice || !pricing._unitPrice._price) {
			return description;
		}

		if (pricing._priceTiers && pricing._priceTiers.length) {
			description += 'From 1st enrolment: ';
		}

		description += this.determinePriceDescriptionWithTypeTakenIntoAccount(pricing._unitPrice);
		return this.enrichPriceDetailsDescriptionWithMinimum(pricing, description);
	}

	private enrichPriceDetailsDescriptionWithTiers(pricing: any, description: string): string {
		if (pricing._priceTiers && pricing._priceTiers.length) {
			if (description) {
				description += '<br>';
			}

			pricing._priceTiers.forEach((tier: any, index: number) => {
				description += this.determinePriceDetailsDescriptionForTier(pricing, index);
			});
		}

		return description;
	}

	private enrichPriceDetailsDescriptionWithMinimum(pricing: any, description: string): string {
		if (!pricing._unitPrice._minimum) {
			return description;
		}

		if (description) {
			description += '<br>';
		}

		description += `Minimum: ${pricing._unitPrice._minimum}`;
		return description;
	}

	private determinePriceDetailsDescriptionForTier(pricing: any, index: number): string {
		let description = '';
		const tier = pricing._priceTiers[index];

		if (tier.pricingFrom === undefined || !tier.unitPrice || !tier.unitPrice.type || !tier.unitPrice._price) {
			return description;
		}

		description += `From ${this.calculateOrdinalNumberBasedOnIndex(tier.pricingFrom)} ${this.retrieveSegmentPriceTypeName(pricing)}: `;
		description += this.determinePriceDescriptionWithTypeTakenIntoAccount(tier.unitPrice);

		if (index < pricing._priceTiers.length - 1) {
			description += '<br>';
		}

		return description;
	}

	private determinePriceDescriptionWithTypeTakenIntoAccount(unitPrice: any): string {
		if (unitPrice.type === UnitPriceEnum.FIXED) {
			return `${unitPrice.type}: ${unitPrice._price} ${this.currency}`;
		}

		return `${unitPrice.type}: ${unitPrice._price}%`;
	}

	private calculateOrdinalNumberBasedOnIndex(index: number): string {
		let ordinalIndicator = 'th';
		const numberAsString = index.toString();

		const lastOneNumber = numberAsString.slice(numberAsString.length - 1);
		const lastTwoNumbers = numberAsString.slice(numberAsString.length - 2);

		switch (true) {
			case lastTwoNumbers === '11':
			case lastTwoNumbers === '12':
			case lastTwoNumbers === '13':
				break;
			case lastOneNumber === '1':
				ordinalIndicator = 'st';
				break;
			case lastOneNumber === '2':
				ordinalIndicator = 'nd';
				break;
			case lastOneNumber === '3':
				ordinalIndicator = 'rd';
				break;
		}

		return `${index}${ordinalIndicator}`;
	}

	private addContractToStore(details: ICCDBContractDetails): void {
		store.mutations.setContractDetails(details);
	}
}

export default new ContractsPresenter();
