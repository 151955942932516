import { EnrolmentReviewState } from './enrolment-review-state';
import { SuccessFeeSelectionInternalReviewState } from '@/models/enrolment-review/state/successfee-selection-internal-review-state';
import { ITransitionableEnrolmentReview } from '@/models/enrolment-review/i-transitionable-enrolment-review';

export class TuitionFeeSkipReviewState extends EnrolmentReviewState {
	public constructor(
		public review: ITransitionableEnrolmentReview,
		public successFeeSelectionInternalReviewState: SuccessFeeSelectionInternalReviewState
	) {
		// The "successFeeSelectionInternalReviewState" (next step) is only needed here, so only pass on the review to the super class.
		super(review);
	}

	public display(): void {
		this.review.popups.showTuitionFeeSkipPopup();
	}

	public requiresAdditionalInput(): boolean {
		return true;
	}

	public next(): void {
		this.review.transitionTo(this.successFeeSelectionInternalReviewState);
	}
}
