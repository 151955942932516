import { EnrolmentEnrichmentSpecification } from '@studyportals/sp-lord-business-interface';
import { Country } from './country';
import { Currency } from './currency';
import { Month } from './month';
import { ProgrammeLevel } from './programme-level';
import store from '@/store';

export class EnrichmentSpecification {
	public constructor(
		public studentName?: string | undefined,
		public studentNationality?: Country | null | undefined,
		public studentCountryOfResidence?: Country | null | undefined,
		public studentFacebook?: string | undefined,
		public studentLinkedIn?: string | undefined,
		public programmeLevel?: ProgrammeLevel | null | undefined,
		public programmeName?: string | undefined,
		public tuitionFee?: number | null | undefined,
		public tuitionFeeCurrency?: Currency | null | undefined,
		public intakeMonth?: Month | null | undefined,
		public intakeYear?: number | null | undefined,
		public skipTuitionFeeStep = false
	) {
		this.preFillCurrencyIfAvailable();
	}

	public toTransportRepresentation(): EnrolmentEnrichmentSpecification {
		const specification = new EnrolmentEnrichmentSpecification();
		const residenceCountryCode = !this.studentCountryOfResidence
			? this.studentCountryOfResidence
			: this.studentCountryOfResidence.isoCode;

		specification.studentName = this.studentName;
		specification.studentNationalityIsoCode = !this.studentNationality ? this.studentNationality : this.studentNationality.isoCode;
		specification.studentResidenceCountryIsoCode = residenceCountryCode;
		specification.enrichmentSourceFacebook = this.studentFacebook;
		specification.enrichmentSourceLinkedIn = this.studentLinkedIn;
		specification.programmeLevel = !this.programmeLevel ? this.programmeLevel : this.programmeLevel.value;
		specification.programmeName = this.programmeName;

		specification.tuitionFee = this.wasNumericalValueRemoved(this.tuitionFee) ? null : this.tuitionFee;
		specification.tuitionFeeCurrencyIso = !this.tuitionFeeCurrency ? this.tuitionFeeCurrency : this.tuitionFeeCurrency.isoCode;

		specification.intakeMonth = null === this.intakeMonth || undefined === this.intakeMonth ? this.intakeMonth : this.intakeMonth.value;
		specification.intakeYear = this.wasNumericalValueRemoved(this.intakeYear) ? null : this.intakeYear;

		return specification;
	}

	private preFillCurrencyIfAvailable(): void {
		// If a currency was explicitly set already, or no currency is available in the store, no prefilling is necessary.
		if (this.tuitionFeeCurrency || !store.state.lastUsedCurrency) {
			return;
		}

		this.tuitionFeeCurrency = store.state.lastUsedCurrency;
	}

	private wasNumericalValueRemoved(numericalValue: number | null | undefined): boolean {
		return typeof numericalValue === 'string' && numericalValue === '';
	}
}
