import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "OptionalFilterSection" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MultiSelect, {
      ref: "tuitionDropdown",
      label: "Tuition fee",
      placeholder: "Nothing selected",
      options: _ctx.tuitionFeeOptions,
      searchable: true,
      entity: "tuition fee"
    }, null, 8 /* PROPS */, ["options"])
  ]))
}