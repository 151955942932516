import { Component, defineComponent } from 'vue';
import PartnershipSelectorComponent from '@/presentation/components/client/partnership-selector/partnership-selector-class';
import PartnershipSelectorOption from '@/presentation/components/client/partnership-selector/partnership-selector-option.vue';

export default defineComponent({
	components: {
		PartnershipSelectorOption: PartnershipSelectorOption as Component
	},
	props: {
		partnership: Object
	},
	setup: () => {
		const component = new PartnershipSelectorComponent();

		return {
			partnerships: component.partnerships,
			isContentVisible: component.isContentVisible,
			toggleContent: component.toggleContent.bind(component),
			hideDropDownContent: component.hideDropDownContent.bind(component)
		};
	}
});
