import { baseEnrolmentTableData, checkboxColumnData, reviewStatusIndicationColumnData, internalHistoryOnlyTableData }
	from '@/presentation/components/generalizations/enrolments-table/enrolments-table-review-fields';
import { IEnrolmentTableFieldSettings } from '@/models/i-enrolment-table-field-settings';

export default class EnrolmentTableFieldsProvider {
	public static getTableFieldsForContext(isInternal: boolean, isHistory: boolean): IEnrolmentTableFieldSettings[] {
		let columnsToBeShown = JSON.parse(JSON.stringify(baseEnrolmentTableData)) as IEnrolmentTableFieldSettings[];

		if (isInternal) {
			// For internal users, the same columns are included as are shown on the "identified enrolments" page.
			columnsToBeShown = columnsToBeShown.concat(internalHistoryOnlyTableData);
		} else {
			// The "review status indication" column is only needed for clients.
			columnsToBeShown.unshift(reviewStatusIndicationColumnData);

			if (!isHistory) {
				// The checkboxes column is only needed for clients on the "review" page.
				columnsToBeShown.unshift(checkboxColumnData);
			}
		}

		return columnsToBeShown;
	}
}
