import { Component, defineComponent } from 'vue';
import MassSelectionOverview from '@/presentation/components/internal/mass-selection-overview/mass-selection-overview.vue';
import TransitionTriggers from '@/presentation/components/internal/transition-triggers/transition-triggers.vue';
import MoveEnrolmentsToOrganisation
	from '@/presentation/components/internal/move-enrolments-to-organisation/move-enrolments-to-organisation.vue';
import MoveEnrolmentsToHistory from '@/presentation/components/internal/move-enrolments-to-history/move-enrolments-to-history.vue';
import EnrolmentsActionsComponent from '@/presentation/components/internal/enrolments-actions/enrolments-actions-class';

export default defineComponent({
	components: {
		MassSelectionOverview: MassSelectionOverview as Component,
		TransitionTriggers: TransitionTriggers as Component,
		MoveEnrolmentsToOrganisation: MoveEnrolmentsToOrganisation as Component,
		MoveEnrolmentsToHistory: MoveEnrolmentsToHistory as Component
	},
	setup: () => {
		const component = new EnrolmentsActionsComponent();

		return {
			massSelectionOverview: component.massSelectionOverview,
			moveEnrolmentsToOrganisation: component.moveEnrolmentsToOrganisation,
			moveEnrolmentsToHistory: component.moveEnrolmentsToHistory,
			selectedEnrolments: component.selectedEnrolments,
			areNoMoveActionsVisible: component.areNoMoveActionsVisible,
			isMoveToHistoryActionShown: component.isMoveToHistoryActionShown,
			isMoveToOrganisationActionShown: component.isMoveToOrganisationActionShown,
			whenSelectionHasSingleStatus: component.whenSelectionHasSingleStatus.bind(component),
			whenSelectionHasMultipleStatuses: component.whenSelectionHasMultipleStatuses.bind(component),
			whenSelectionHasSingleOrganisation: component.whenSelectionHasSingleOrganisation.bind(component),
			whenSelectionHasMultipleOrganisations: component.whenSelectionHasMultipleOrganisations.bind(component),
			whenMoveEnrolmentsToOrganisationExpanded: component.whenMoveEnrolmentsToOrganisationExpanded.bind(component),
			whenMoveEnrolmentsToOrganisationCollapsed: component.whenMoveEnrolmentsToOrganisationCollapsed.bind(component),
			whenMovedEnrolmentsToHistoryExpanded: component.whenMovedEnrolmentsToHistoryExpanded.bind(component),
			whenMovedEnrolmentsToHistoryCollapsed: component.whenMovedEnrolmentsToHistoryCollapsed.bind(component)
		};
	}
});
