import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'is-open': _ctx.isOpen }, "WrapperDrawer"]),
    onMousedown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
  }, [
    _createElementVNode("div", {
      class: "WrapperContent",
      onMousedown: _cache[0] || (_cache[0] = ($event: any) => (_ctx.stopClickEventPropagation($event)))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 32 /* HYDRATE_EVENTS */)
  ], 34 /* CLASS, HYDRATE_EVENTS */))
}