import { computed, Ref, ref } from 'vue';
import { IMultiSelect } from '@studyportals/multiselect/src/interfaces/multiselect.interface';
import { IOption } from '@studyportals/multiselect/src/interfaces/options.interface';
import { ClientFiltersAppliedEvent } from '@/event-bus/client-filters-applied-event';
import tableSelectorRetriever from '@/presentation/components/generalizations/table-selectors/table-selector-retriever';
import EventBus from '@/event-bus/event-bus';
import store from '@/store';

export default class OptionalFilterSectionNationalityComponent {
	public nationalityDropdown: Ref<IMultiSelect | null> = ref(null);
	private nationalityFilter = tableSelectorRetriever.retrieveNationalityFilter();
	private eventSubscriptionToken = ref('');

	public countriesAsOptions = computed((): IOption[] => {
		return store.getters.countriesAsOptions();
	});

	public mounted(): void {
		this.eventSubscriptionToken.value = EventBus.getEvent(ClientFiltersAppliedEvent).subscribe(this.selectNationality.bind(this));
		this.prefillValue();
	}

	public unmounted(): void {
		EventBus.getEvent(ClientFiltersAppliedEvent).unsubscribe(this.eventSubscriptionToken.value);
	}

	public selectNationality(): void {
		if (!this.nationalityDropdown.value?.selectedOption?.value) {
			return;
		}

		const selectedOption = this.nationalityDropdown.value.selectedOption.value as string;
		this.nationalityFilter.setSelectedOption(selectedOption);
		this.filterEnrolments();
	}

	private filterEnrolments(): void {
		store.actions.updateFilteredEnrolments();
	}

	private prefillValue(): void {
		if (
			!this.nationalityFilter.selectedOptionAsOption.value ||
			!this.nationalityDropdown.value ||
			this.nationalityFilter.selectedOptionIsDefault.value
		) {
			return;
		}

		this.nationalityDropdown.value.selectOption(this.nationalityFilter.selectedOptionAsOption.value as IOption);
	}
}
