import { Component, defineComponent } from 'vue';
import SearchBarWrapperComponent from '@/presentation/components/client/search-bar-wrapper/search-bar-wrapper-class';
import SearchEnrolmentsBar from '@/presentation/components/generalizations/search-enrolments-bar/search-enrolments-bar.vue';
import HighlightedFeature from '@/presentation/components/client/highlighted-feature/highlighted-feature.vue';

export default defineComponent({
	components: {
		SearchEnrolmentsBar: SearchEnrolmentsBar as Component,
		HighlightedFeature: HighlightedFeature as Component
	},
	setup: () => {
		const component = new SearchBarWrapperComponent();

		return {
			highlightedFeatureTitle: component.highlightedFeatureTitle,
			highlightedFeatureContent: component.highlightedFeatureContent,
			highlightedFeatureNameSearch: component.highlightedFeatureNameSearch
		};
	}
});
