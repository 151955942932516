import { defineComponent, onMounted } from 'vue';
import EasterEggClippyComponent from './easteregg-clippy-class';

export default defineComponent({
	setup: () => {
		const component = new EasterEggClippyComponent();

		onMounted(component.storageChecker.bind(component));

		return {
			clippyRight: component.clippyRight,
			clippyHideFunny: component.clippyHideFunny,
			currentJoke: component.currentJoke,
			clippyShouldBeVisible: component.clippyShouldBeVisible,
			funny: component.funny.bind(component),
			notFunny: component.notFunny.bind(component),
			hideClippy: component.hideClippy.bind(component)
		};
	}
});
