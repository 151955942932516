import { EnrolmentGroup } from '@/models/enrolment-group';

export class EnrolmentInvoiceGroup extends EnrolmentGroup {
	constructor(
		public invoiceNumber: string,
		displayText: string
	) {
		super(displayText);
	}
}
