import { EnrolmentNotInScopeReason } from '@studyportals/sp-lord-business-interface';

export class NoPayReason {
	public constructor(
		public readonly name: string,
		public readonly type: EnrolmentNotInScopeReason
	) {}

	public isInCollection(noPayReasons: Readonly<NoPayReason[]>): boolean {
		return noPayReasons.some((_) => this.equals(_));
	}

	public equals(that: NoPayReason | null | undefined): boolean {
		return that ? that.type === this.type : false;
	}
}
