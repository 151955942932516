import { computed, ref } from 'vue';
import { Enrolment } from '@/models/enrolment';
import EventBus from '@/event-bus/event-bus';
import { ModalStatusLoadingEnums } from '@/models/modal-status-loading-enum';
import { CollapseMassEditRequestedEvent } from '@/event-bus/collapse-mass-edit-requested-event';
import { EnrolmentStatus } from '@studyportals/sp-lord-business-interface';
import { EnrolmentsMovedToHistoryEvent } from '@/event-bus/enrolments-moved-to-history-event';
import EnrolmentService from '@/services/enrolments-service';
import FulfillmentStepIndication from '@/models/fulfillment-step-indication';
import store from '@/store';

export default class MoveEnrolmentsToHistoryComponent {
	public isMoveEnrolmentAvailable = ref(true);
	public isExpanded = ref(false);
	public isCollapsed = ref(true);

	constructor(private emit: (name: string, value?: string | boolean) => void) {}

	public selectedEnrolments = computed((): Enrolment[] => {
		return store.getters.selectedEnrolments();
	});

	public canAllSelectedEnrolmentsBePlacedInHistory = computed((): boolean => {
		return this.selectedEnrolments.value.find(this.cannotMoveEnrolmentToHistoryGivenStepAndStatus.bind(this)) === undefined;
	});

	public mounted(): void {
		this.onSelectedEnrolmentsChanged();
	}

	public onSelectedEnrolmentsChanged(): void {
		this.isMoveEnrolmentAvailable.value = this.canAllSelectedEnrolmentsBePlacedInHistory.value;
	}

	private cannotMoveEnrolmentToHistoryGivenStepAndStatus(enrolment: Enrolment): boolean {
		return (
			this.enrolmentNotDisqualified(enrolment) &&
			this.enrolmentNotSentToFinance(enrolment) &&
			this.enrolmentNotFinalNonPostponed(enrolment)
		);
	}
	private enrolmentNotDisqualified(enrolment: Enrolment): boolean {
		return !(
			enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.QUALIFICATION_COMPLETED) &&
			enrolment.status.value === EnrolmentStatus.DISQUALIFIED
		);
	}
	private enrolmentNotSentToFinance(enrolment: Enrolment): boolean {
		return !enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.SENT_TO_FINANCE);
	}
	private enrolmentNotFinalNonPostponed(enrolment: Enrolment): boolean {
		return (
			!enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.FINAL) ||
			enrolment.status.value === EnrolmentStatus.POSTPONED
		);
	}

	public expand(): void {
		this.isExpanded.value = true;
		this.isCollapsed.value = false;

		this.emit('expanded');
	}

	public collapse(): void {
		this.isExpanded.value = false;
		this.isCollapsed.value = true;

		this.emit('collapsed');
	}

	public async onMoveConfirmed(): Promise<void> {
		await this.moveEnrolments();
	}

	public onMoveCanceled(): void {
		this.collapse();
	}
	public async moveEnrolments(): Promise<void> {
		this.showLoader();

		try {
			await EnrolmentService.moveEnrolmentsToHistory(this.selectedEnrolments.value);

			EventBus.getEvent(CollapseMassEditRequestedEvent).publish();

			this.showSuccessMessage();
			this.collapse();
		} catch (e) {
			this.showFailureMessage();
		}

		EventBus.getEvent(EnrolmentsMovedToHistoryEvent).publish(this.selectedEnrolments.value);
	}

	public showLoader(): void {
		store.actions.setModalStatusLoading(ModalStatusLoadingEnums.ACTIVE);
	}

	public showSuccessMessage(): void {
		store.actions.setModalStatusLoading(ModalStatusLoadingEnums.SUCCESS);
	}

	public showFailureMessage(): void {
		store.actions.setModalStatusLoading(ModalStatusLoadingEnums.FAILED);
	}
}
