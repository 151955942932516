import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "OptionalFilterBlock OptionalFiltersTag" }
const _hoisted_2 = { class: "OptionalFilterBlockTextWrapper" }
const _hoisted_3 = ["title"]
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
		'OptionalFilterTags',
		_ctx.relevantOptionalFilters.length ? 'OptionalFilterTagsAvailable' : ''
	])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relevantOptionalFilters, (filter) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, [
          _createElementVNode("span", {
            class: "OptionalFilterBlockText",
            title: _ctx.retrieveTitleOfFilter(filter)
          }, _toDisplayString(_ctx.retrieveTitleOfFilter(filter)), 9 /* TEXT, PROPS */, _hoisted_3)
        ]),
        _createElementVNode("i", {
          class: "lnr-cross OptionalFilterBlockIcon",
          onClick: ($event: any) => (_ctx.removeFilter(filter))
        }, null, 8 /* PROPS */, _hoisted_4)
      ]))
    }), 256 /* UNKEYED_FRAGMENT */))
  ], 2 /* CLASS */))
}