import { HighlightedFeatureStatusEnum } from '@/models/highlighted-feature-status-enum';
import { DataStorageLabelsEnum } from '@/models/data-storage-labels-enum';
import { DataStorage } from '@studyportals/data-storage';

class HighlightedFeatureFlowHandler {
	private latestAvailableHighlightedFeature = '2023-07-24_filter';

	public determineHighlightedFeatureFlowStatus(): HighlightedFeatureStatusEnum {
		const flowStatusHighlightedFeature = this.hasUserSeenLatestHighlightedFeature()
			? HighlightedFeatureStatusEnum.INACTIVE
			: HighlightedFeatureStatusEnum.INTRODUCTION;

		DataStorage.store(
			DataStorageLabelsEnum.LATEST_VIEWED_HIGHLIGHTED_FEATURE_LABEL,
			this.latestAvailableHighlightedFeature,
			60 * 60 * 24 * 365 * 5
		);

		return flowStatusHighlightedFeature;
	}

	private hasUserSeenLatestHighlightedFeature(): boolean {
		const latestViewedHighlightedFeature = DataStorage.retrieve(DataStorageLabelsEnum.LATEST_VIEWED_HIGHLIGHTED_FEATURE_LABEL) as
			| string
			| null;

		return latestViewedHighlightedFeature === this.latestAvailableHighlightedFeature;
	}
}

export default new HighlightedFeatureFlowHandler();
