import { defineComponent, onMounted, onUnmounted } from 'vue';
import DrawerComponent from '@/presentation/components/generalizations/drawer/drawer-class';

export default defineComponent({
	setup: () => {
		const component = new DrawerComponent();

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		return {
			isOpen: component.isOpen,
			close: component.close.bind(component),
			stopClickEventPropagation: component.stopClickEventPropagation.bind(component)
		};
	}
});
