import { computed, nextTick, ref, Ref } from 'vue';
import { massEditablePropertiesCluster } from '@/presentation/components/generalizations/mass-edit-modal/mass-editable-properties';
import { MassEditablePropertiesEnum, IMassEditProperty, TMassEditablePropertiesCluster }
	from '@/models/i-mass-editable-properties-cluster';
import { ComplexModal } from '@studyportals/modal';
import { ModalStatusMassEditEnum } from '@/models/modal-status-mass-edit-enum';
import { IMultiSelect } from '@studyportals/multiselect/src/interfaces/multiselect.interface';
import { IOption } from '@studyportals/multiselect/src/interfaces/options.interface';
import { IMultiSelectToggleEvent } from '@/models/i-multiselect';
import { Enrolment } from '@/models/enrolment';
import { ModalStatusLoadingEnums } from '@/models/modal-status-loading-enum';
import { Currency } from '@/models/currency';
import { Month } from '@/models/month';
import { Country } from '@/models/country';
import { ProgrammeLevel } from '@/models/programme-level';
import { ProgrammeLevelEnum } from '@/models/programme-level-enum';
import GrammaticalNumberTextPresenter from '@/presenters/grammatical-number-text-presenter';
import EnrolmentService from '@/services/enrolments-service';
import store from '@/store';

export default class MassEditModalComponent {
	public massEditModifyBlock: Ref<HTMLDivElement | null> = ref(null);
	public modalContent: Ref<HTMLElement | null> = ref(null);
	public currencyDropdown: Ref<IMultiSelect[] | null> = ref(null);
	public monthDropdown: Ref<IMultiSelect[] | null> = ref(null);
	public nationalityDropdown: Ref<IMultiSelect[] | null> = ref(null);
	public residenceDropdown: Ref<IMultiSelect[] | null> = ref(null);
	public programmeLevelDropdown: Ref<IMultiSelect[] | null> = ref(null);
	public tuitionFeeInput: Ref<HTMLInputElement[] | null> = ref(null);
	public intakeYearInput: Ref<HTMLInputElement[] | null> = ref(null);
	public programmeNameInput: Ref<HTMLInputElement[] | null> = ref(null);
	// Copy the object so that the original source is not modified (e.g. for when the popup is re-opened).
	public massEditablePropertiesCluster: Ref<TMassEditablePropertiesCluster> = ref(
		JSON.parse(JSON.stringify(massEditablePropertiesCluster))
	);
	public showMassEditSelectionMessage = ref(false);
	private modal: Ref<ComplexModal | undefined> = ref(undefined);
	private selectedCurrency: Ref<string | undefined> = ref(undefined);
	private selectedIntakeMonth: Ref<number | undefined> = ref(undefined);
	private selectedNationality: Ref<string | undefined> = ref(undefined);
	private selectedResidence: Ref<string | undefined> = ref(undefined);
	private selectedTuitionFee: Ref<number | undefined> = ref(undefined);
	private selectedIntakeYear: Ref<number | undefined> = ref(undefined);
	private selectedProgrammeName: Ref<string | undefined> = ref(undefined);
	private selectedProgrammeLevel: Ref<number | undefined> = ref(undefined);
	private boundModifyBlockScrollListener?: () => void;
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private boundMultiselectToggleListener?: (event: any) => void;

	public get massEditableKeys(): MassEditablePropertiesEnum[] {
		return Object.keys(this.massEditablePropertiesCluster.value) as MassEditablePropertiesEnum[];
	}

	public get massEditableKeyTuitionFeeCurrency(): MassEditablePropertiesEnum {
		return MassEditablePropertiesEnum.TUITION_FEE_CURRENCY;
	}

	public get massEditableKeyTuitionFee(): MassEditablePropertiesEnum {
		return MassEditablePropertiesEnum.TUITION_FEE;
	}

	public get massEditableKeyIntakeYear(): MassEditablePropertiesEnum {
		return MassEditablePropertiesEnum.INTAKE_YEAR;
	}

	public get massEditableKeyIntakeMonth(): MassEditablePropertiesEnum {
		return MassEditablePropertiesEnum.INTAKE_MONTH;
	}

	public get massEditableKeyStudentNationality(): MassEditablePropertiesEnum {
		return MassEditablePropertiesEnum.STUDENT_NATIONALITY;
	}

	public get massEditableKeyCountryOfResidence(): MassEditablePropertiesEnum {
		return MassEditablePropertiesEnum.COUNTRY_OF_RESIDENCE;
	}

	public get massEditableKeyProgrammeName(): MassEditablePropertiesEnum {
		return MassEditablePropertiesEnum.PROGRAMME_NAME;
	}

	public get massEditableKeyProgrammeLevel(): MassEditablePropertiesEnum {
		return MassEditablePropertiesEnum.PROGRAMME_LEVEL;
	}

	public selectedEnrolments = computed((): Enrolment[] => {
		return store.getters.selectedEnrolments();
	});

	public currenciesAsOptions = computed((): IOption[] => {
		return store.getters.currenciesAsOptions();
	});

	public monthsAsOptions = computed((): IOption[] => {
		return store.getters.monthsAsOptions();
	});

	public countriesAsOptions = computed((): IOption[] => {
		return store.getters.countriesAsOptions();
	});

	public programmeLevelsAsOptions = computed((): IOption[] => {
		return store.getters.programmeLevelsAsOptions();
	});

	public modalStatus = computed((): ModalStatusMassEditEnum => {
		return store.getters.modalStatusMassEdit();
	});

	public modalStatusIsPropertySelection = computed((): boolean => {
		return this.modalStatus.value === ModalStatusMassEditEnum.PROPERTY_SELECTION;
	});

	public modalStatusIsPropertyModification = computed((): boolean => {
		return this.modalStatus.value === ModalStatusMassEditEnum.PROPERTY_MODIFICATION;
	});

	public isInternal = computed((): boolean => {
		return store.state.isInternal;
	});

	public isCurrencyDropdownActive = computed((): boolean => {
		if (!this.currencyDropdown.value || !this.currencyDropdown.value.length) {
			return false;
		}

		if (this.selectedCurrency.value) {
			return true;
		}

		return this.currencyDropdown.value[0].isActive.value;
	});

	public isMonthDropdownActive = computed((): boolean => {
		if (!this.monthDropdown.value || !this.monthDropdown.value.length) {
			return false;
		}

		if (this.selectedIntakeMonth.value) {
			return true;
		}

		return this.monthDropdown.value[0].isActive.value;
	});

	public isNationalityDropdownActive = computed((): boolean => {
		if (!this.nationalityDropdown.value || !this.nationalityDropdown.value.length) {
			return false;
		}

		if (this.selectedNationality.value) {
			return true;
		}

		return this.nationalityDropdown.value[0].isActive.value;
	});

	public isResidenceDropdownActive = computed((): boolean => {
		if (!this.residenceDropdown.value || !this.residenceDropdown.value.length) {
			return false;
		}

		if (this.selectedResidence.value) {
			return true;
		}

		return this.residenceDropdown.value[0].isActive.value;
	});

	public isProgrammeLevelDropdownActive = computed((): boolean => {
		if (!this.programmeLevelDropdown.value || !this.programmeLevelDropdown.value.length) {
			return false;
		}

		if (this.selectedProgrammeLevel.value) {
			return true;
		}

		return this.programmeLevelDropdown.value[0].isActive.value;
	});

	public relevantMassEditProperties = computed((): IMassEditProperty[] => {
		return this.massEditableKeys.map((key) => this.massEditablePropertiesCluster.value[key]).filter((property) => property.relevant);
	});

	public grammaticalNumberTextPresenter = computed((): GrammaticalNumberTextPresenter => {
		return new GrammaticalNumberTextPresenter(this.selectedEnrolments.value.length);
	});

	public mounted(): void {
		this.prepareModal();
		this.prepareEventListeners();
		this.preselectTuitionPropertiesForClients();
	}

	public unmounted(): void {
		window.ModalManager.close(this.modal.value);
		this.removeActiveListeners();
	}

	public onTransitionCanceled(): void {
		store.mutations.setModalStatusMassEdit(ModalStatusMassEditEnum.INACTIVE);
	}

	public startModifyingSelectedProperties(): void {
		this.showMassEditSelectionMessage.value = this.relevantMassEditProperties.value.length === 0;
		if (this.showMassEditSelectionMessage.value) {
			return;
		}

		store.mutations.setModalStatusMassEdit(ModalStatusMassEditEnum.PROPERTY_MODIFICATION);

		void this.prepareModifyBlockScrollListener();
	}

	public goBackToPropertySelection(): void {
		this.clearValidation();

		store.mutations.setModalStatusMassEdit(ModalStatusMassEditEnum.PROPERTY_SELECTION);
	}

	public toggleMassEditableProperty(property: MassEditablePropertiesEnum): void {
		this.massEditablePropertiesCluster.value[property].relevant = !this.massEditablePropertiesCluster.value[property].relevant;
	}

	public selectCurrency(option: IOption): void {
		this.selectedCurrency.value = option.value as string;
		this.isModificationValid(MassEditablePropertiesEnum.TUITION_FEE_CURRENCY);
	}

	public selectMonth(option: IOption): void {
		this.selectedIntakeMonth.value = parseInt(option.value);
		this.isModificationValid(MassEditablePropertiesEnum.INTAKE_MONTH);
	}

	public selectNationality(option: IOption): void {
		this.selectedNationality.value = option.value as string;
		this.isModificationValid(MassEditablePropertiesEnum.STUDENT_NATIONALITY);
	}

	public selectResidence(option: IOption): void {
		this.selectedResidence.value = option.value as string;
		this.isModificationValid(MassEditablePropertiesEnum.COUNTRY_OF_RESIDENCE);
	}

	public selectProgrammeLevel(option: IOption): void {
		this.selectedProgrammeLevel.value = parseInt(option.value);
		this.isModificationValid(MassEditablePropertiesEnum.PROGRAMME_LEVEL);
	}

	public selectTuitionFee(): void {
		if (!this.tuitionFeeInput.value || !this.tuitionFeeInput.value.length) {
			return;
		}

		this.selectedTuitionFee.value = parseInt(this.tuitionFeeInput.value[0].value);
		this.isModificationValid(MassEditablePropertiesEnum.TUITION_FEE);
	}

	public selectIntakeYear(): void {
		if (!this.intakeYearInput.value || !this.intakeYearInput.value.length) {
			return;
		}

		this.selectedIntakeYear.value = parseInt(this.intakeYearInput.value[0].value);
		this.isModificationValid(MassEditablePropertiesEnum.INTAKE_YEAR);
	}

	public selectProgrammeName(): void {
		if (!this.programmeNameInput.value || !this.programmeNameInput.value.length) {
			return;
		}

		this.selectedProgrammeName.value = this.programmeNameInput.value[0].value;
		this.isModificationValid(MassEditablePropertiesEnum.PROGRAMME_NAME);
	}

	public shouldPropertyBeAvailableForModification(property: MassEditablePropertiesEnum): boolean {
		return this.massEditablePropertiesCluster.value[property].relevant;
	}

	public async saveSpecifiedValues(): Promise<void> {
		this.closeAllMassEditDropdowns();

		if (!this.areModificationsValid()) {
			return;
		}

		this.showLoader();

		try {
			for (const enrolment of this.selectedEnrolments.value) {
				await this.saveSingleSpecifiedValue(enrolment);
			}

			this.showSuccessMessage();
			this.onTransitionCanceled();
		} catch (e) {
			this.showFailureMessage();
		}
	}

	private async saveSingleSpecifiedValue(enrolment: Enrolment): Promise<void> {
		const enrichmentSpecification = enrolment.createEnrichmentSpecification();

		if (this.selectedCurrency.value !== undefined) {
			enrichmentSpecification.tuitionFeeCurrency = Currency.obtain(this.selectedCurrency.value);
		}
		if (this.selectedTuitionFee.value !== undefined) {
			enrichmentSpecification.tuitionFee = this.selectedTuitionFee.value;
		}
		if (this.selectedIntakeMonth.value !== undefined) {
			enrichmentSpecification.intakeMonth = Month.forValue(this.selectedIntakeMonth.value);
		}
		if (this.selectedIntakeYear.value !== undefined) {
			enrichmentSpecification.intakeYear = this.selectedIntakeYear.value;
		}
		if (this.selectedNationality.value !== undefined) {
			enrichmentSpecification.studentNationality = Country.obtain(this.selectedNationality.value);
		}
		if (this.selectedResidence.value !== undefined) {
			enrichmentSpecification.studentCountryOfResidence = Country.obtain(this.selectedResidence.value);
		}
		if (this.selectedProgrammeName.value !== undefined) {
			enrichmentSpecification.programmeName = this.selectedProgrammeName.value;
		}
		if (this.selectedProgrammeLevel.value !== undefined) {
			const programmeLevel = this.selectedProgrammeLevel.value as ProgrammeLevelEnum;
			enrichmentSpecification.programmeLevel = ProgrammeLevel.forValue(programmeLevel);
		}

		await EnrolmentService.enrich(enrolment, enrichmentSpecification);
	}

	private showLoader(): void {
		store.actions.setModalStatusLoading(ModalStatusLoadingEnums.ACTIVE);
	}

	private showSuccessMessage(): void {
		store.actions.setModalStatusLoading(ModalStatusLoadingEnums.SUCCESS);
	}

	private showFailureMessage(): void {
		store.actions.setModalStatusLoading(ModalStatusLoadingEnums.FAILED);
	}

	private preselectTuitionPropertiesForClients(): void {
		if (this.isInternal.value) {
			return;
		}

		this.toggleMassEditableProperty(MassEditablePropertiesEnum.TUITION_FEE_CURRENCY);
		this.toggleMassEditableProperty(MassEditablePropertiesEnum.TUITION_FEE);
	}

	private clearValidation(): void {
		this.massEditableKeys.forEach((key) => {
			this.massEditablePropertiesCluster.value[key].valid = true;
		});
	}

	private areModificationsValid(): boolean {
		let valid = true;

		this.massEditableKeys.forEach((key) => {
			if (this.isModificationValid(key)) {
				return;
			}

			valid = false;
		});

		return valid;
	}

	private isModificationValid(key: MassEditablePropertiesEnum): boolean {
		const property = this.massEditablePropertiesCluster.value[key];
		if (!property.relevant) {
			return true;
		}

		property.valid = this.doesPropertyHaveModifiedValue(key);

		return property.valid;
	}

	private doesPropertyHaveModifiedValue(key: MassEditablePropertiesEnum): boolean {
		switch (key) {
			case MassEditablePropertiesEnum.TUITION_FEE_CURRENCY:
				return this.selectedCurrency.value !== undefined;
			case MassEditablePropertiesEnum.TUITION_FEE:
				return this.selectedTuitionFee.value !== undefined;
			case MassEditablePropertiesEnum.INTAKE_MONTH:
				return this.selectedIntakeMonth.value !== undefined;
			case MassEditablePropertiesEnum.INTAKE_YEAR:
				return this.selectedIntakeYear.value !== undefined;
			case MassEditablePropertiesEnum.STUDENT_NATIONALITY:
				return this.selectedNationality.value !== undefined;
			case MassEditablePropertiesEnum.COUNTRY_OF_RESIDENCE:
				return this.selectedResidence.value !== undefined;
			case MassEditablePropertiesEnum.PROGRAMME_NAME:
				return this.selectedProgrammeName.value !== undefined;
			case MassEditablePropertiesEnum.PROGRAMME_LEVEL:
				return this.selectedProgrammeLevel.value !== undefined;
			default:
				return true;
		}
	}

	private prepareModal(): void {
		if (!this.modalContent.value) {
			return;
		}

		this.modal.value = new ComplexModal(this.modalContent.value, {
			cssClassName: 'MassEditModalWrapper',
			destroyOnClose: false,
			onClose: this.onTransitionCanceled.bind(this)
		});

		window.ModalManager.open(this.modal.value);
	}

	private prepareEventListeners(): void {
		this.boundModifyBlockScrollListener = this.closeAllMassEditDropdowns.bind(this);
		this.boundMultiselectToggleListener = this.adjustDropdownPlacementOnMultiselectOpen.bind(this);

		document.addEventListener('multiselect_toggle', this.boundMultiselectToggleListener);
	}

	private removeActiveListeners(): void {
		if (this.boundMultiselectToggleListener) {
			document.removeEventListener('multiselect_toggle', this.boundMultiselectToggleListener);
		}

		if (this.massEditModifyBlock.value && this.boundModifyBlockScrollListener) {
			this.massEditModifyBlock.value.removeEventListener('scroll', this.boundModifyBlockScrollListener);
		}
	}

	private async prepareModifyBlockScrollListener(): Promise<void> {
		await nextTick();

		if (!this.massEditModifyBlock.value || !this.boundModifyBlockScrollListener) {
			return;
		}

		this.massEditModifyBlock.value.addEventListener('scroll', this.boundModifyBlockScrollListener);
	}

	private closeAllMassEditDropdowns(): void {
		const allDropdowns = [
			this.currencyDropdown,
			this.monthDropdown,
			this.nationalityDropdown,
			this.residenceDropdown,
			this.programmeLevelDropdown
		];

		// Close all dropdowns that are active (otherwise their positioning might be wrong).
		allDropdowns.forEach((dropdown) => {
			if (!dropdown.value || !dropdown.value.length) {
				return;
			}

			if (!dropdown.value[0].isActive) {
				return;
			}

			dropdown.value[0].toggleMultiSelect();
		});
	}

	private adjustDropdownPlacementOnMultiselectOpen(event: IMultiSelectToggleEvent): void {
		if (!event.detail?.isActive || !event.detail.dropdown || !this.massEditModifyBlock.value) {
			return;
		}

		const dropdown: HTMLElement = event.detail.dropdown;
		const dropdownParent = dropdown.parentNode as HTMLElement;
		if (!dropdownParent) {
			return;
		}

		// Position the dropdown right below the input block of the multiselect (with an offset of 8px).
		dropdown.style.top = `${dropdownParent.offsetTop + dropdownParent.clientHeight + 8 - this.massEditModifyBlock.value.scrollTop}px`;
		dropdown.style.left = `${dropdownParent.offsetLeft}px`;
		dropdown.style.minWidth = `${dropdownParent.clientWidth}px`;
		dropdown.style.width = `${dropdownParent.clientWidth}px`;
	}
}
