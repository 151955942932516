import { TuitionFeeValidation } from '@/models/validation/tuition-fee-validation';

import { ValidationResult } from '../../../validation/validation-result';
import { TriggerPayload } from '../trigger-payload';
import { IntakeInformationValidation } from './intake-information-validation';
import { NoteValidation } from './note-validation';
import { TriggerPayloadValidation } from './trigger-payload-validation';

export class SuccessFeeInformationValidation extends TriggerPayloadValidation {
	public validateInExistingResult(payload: TriggerPayload, result: ValidationResult): ValidationResult {
		if (payload.successFeeInformation.requiresNationality()) {
			if (
				undefined === payload.enrichmentSpecification.studentNationality ||
				null === payload.enrichmentSpecification.studentNationality
			) {
				result.isNationalityValid = false;
			}
		}

		// If the user indicated that tuition fee data can be skipped, don't validate that part.
		if (payload.successFeeInformation.requiresTuitionFee() && !payload.enrichmentSpecification.skipTuitionFeeStep) {
			new TuitionFeeValidation(true).validateInExistingResult(payload.enrichmentSpecification, result);
		}

		if (payload.successFeeInformation.requiresIntakeInformation()) {
			new IntakeInformationValidation().validateInExistingResult(payload, result);
		}

		if (payload.successFeeInformation.requiresProgrammeInformation()) {
			if (undefined === payload.enrichmentSpecification.programmeName || payload.enrichmentSpecification.programmeName.length === 0) {
				result.isProgrammeNameValid = false;
			}

			if (undefined === payload.enrichmentSpecification.programmeLevel || null === payload.enrichmentSpecification.programmeLevel) {
				result.isProgrammeLevelValid = false;
			}
		}

		if (payload.successFeeInformation.requiresNote()) {
			new NoteValidation().validateInExistingResult(payload, result);
		}

		return result;
	}
}
