import { ref } from 'vue';
import { Month } from '@/models/month';
import { ClientFiltersAppliedEvent } from '@/event-bus/client-filters-applied-event';
import tableSelectorRetriever from '@/presentation/components/generalizations/table-selectors/table-selector-retriever';
import EventBus from '@/event-bus/event-bus';
import datePresenter from '@/presenters/date-presenter';
import store from '@/store';

export default class OptionalFilterSectionIntakeComponent {
	public selectedMonth = ref('');
	public selectedYear = ref('');
	public showValidation = ref(false);
	private intakePeriodFilter = tableSelectorRetriever.retrieveIntakePeriodFilter();
	private eventSubscriptionToken = ref('');

	public get numberOfYearsInPast(): number {
		return datePresenter.numberOfYearsInPast;
	}

	public get numberOfYearsInFuture(): number {
		return datePresenter.numberOfYearsInFuture;
	}

	public mounted(): void {
		this.eventSubscriptionToken.value = EventBus.getEvent(ClientFiltersAppliedEvent).subscribe(() => {
			this.showValidation.value = true;
			this.selectIntakePeriod();
		});
	}

	public beforeMounted(): void {
		this.prefillValue();
	}

	public unmounted(): void {
		EventBus.getEvent(ClientFiltersAppliedEvent).unsubscribe(this.eventSubscriptionToken.value);
	}

	public selectIntakePeriod(): void {
		if (!this.selectedMonth.value || !this.selectedYear.value) {
			return;
		}

		this.intakePeriodFilter.setSelectedOption(`${this.selectedMonth.value} ${this.selectedYear.value}`);
		this.filterEnrolments();
	}

	public storeDate(value: { month: string; year: string }): void {
		this.selectedMonth.value = Month.forValue(parseInt(value.month)).name;
		this.selectedYear.value = value.year;
	}

	private filterEnrolments(): void {
		store.actions.updateFilteredEnrolments();
	}

	private prefillValue(): void {
		if (this.intakePeriodFilter.selectedOptionIsDefault.value) {
			return;
		}

		const selectedOptionAsString = this.intakePeriodFilter.selectedOption.value as string;
		const intakePeriodParts = selectedOptionAsString.split(' ');
		const monthAsObject = Month.all().find((month) => month.name === intakePeriodParts[0]);
		if (!monthAsObject) {
			return;
		}

		this.selectedMonth.value = monthAsObject.value.toString().padStart(2, '0');
		this.selectedYear.value = intakePeriodParts[1];
	}
}
