import { GroupOption } from '@/presentation/components/generalizations/enrolments-table-group-selector/selectors/group-option';
import { GroupOptionsEnums } from '@/models/group-options-enums';

export class IntakeMonthGroupOption extends GroupOption {
	public get displayText(): string {
		return 'Intake';
	}
	public equals(option: GroupOption): boolean {
		return option instanceof IntakeMonthGroupOption;
	}
	public getHashKey(): GroupOptionsEnums {
		return GroupOptionsEnums.INTAKE_MONTH_GROUP_OPTION;
	}
}
