import { EnrichmentSpecification } from '@/models/enrichment-specification';
import { TuitionFeeValidation } from '@/models/validation/tuition-fee-validation';
import { ValidationResult } from '@/models/validation/validation-result';

export class EnrichmentSpecificationValidation {
	public validate(enrichmentSpecification: EnrichmentSpecification): ValidationResult {
		const result = new ValidationResult();

		this.validateTuitionFee(enrichmentSpecification, result);

		return result;
	}

	private validateTuitionFee(enrichmentSpecification: EnrichmentSpecification, result: ValidationResult): void {
		new TuitionFeeValidation(false).validateInExistingResult(enrichmentSpecification, result);
	}
}
