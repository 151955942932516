export class Month {
	private static monthJanuary: Month | undefined;
	private static monthFebruary: Month | undefined;
	private static monthMarch: Month | undefined;
	private static monthApril: Month | undefined;
	private static monthMay: Month | undefined;
	private static monthJune: Month | undefined;
	private static monthJuly: Month | undefined;
	private static monthAugust: Month | undefined;
	private static monthSeptember: Month | undefined;
	private static monthOctober: Month | undefined;
	private static monthNovember: Month | undefined;
	private static monthDecember: Month | undefined;

	private constructor(
		public readonly name: string,
		public readonly value: number
	) {}

	public static all(): Readonly<Month[]> {
		return [
			this.january(),
			this.february(),
			this.march(),
			this.april(),
			this.may(),
			this.june(),
			this.july(),
			this.august(),
			this.september(),
			this.october(),
			this.november(),
			this.december()
		];
	}

	public static january(): Month {
		return (this.monthJanuary = this.monthJanuary || new Month('January', 1));
	}

	public static february(): Month {
		return (this.monthFebruary = this.monthFebruary || new Month('February', 2));
	}

	public static march(): Month {
		return (this.monthMarch = this.monthMarch || new Month('March', 3));
	}

	public static april(): Month {
		return (this.monthApril = this.monthApril || new Month('April', 4));
	}

	public static may(): Month {
		return (this.monthMay = this.monthMay || new Month('May', 5));
	}

	public static june(): Month {
		return (this.monthJune = this.monthJune || new Month('June', 6));
	}

	public static july(): Month {
		return (this.monthJuly = this.monthJuly || new Month('July', 7));
	}

	public static august(): Month {
		return (this.monthAugust = this.monthAugust || new Month('August', 8));
	}

	public static september(): Month {
		return (this.monthSeptember = this.monthSeptember || new Month('September', 9));
	}

	public static october(): Month {
		return (this.monthOctober = this.monthOctober || new Month('October', 10));
	}

	public static november(): Month {
		return (this.monthNovember = this.monthNovember || new Month('November', 11));
	}

	public static december(): Month {
		return (this.monthDecember = this.monthDecember || new Month('December', 12));
	}

	public static forValue(value: number): Month {
		switch (value) {
			case 1:
				return this.january();
			case 2:
				return this.february();
			case 3:
				return this.march();
			case 4:
				return this.april();
			case 5:
				return this.may();
			case 6:
				return this.june();
			case 7:
				return this.july();
			case 8:
				return this.august();
			case 9:
				return this.september();
			case 10:
				return this.october();
			case 11:
				return this.november();
			case 12:
				return this.december();
		}

		throw new Error(`Invalid month value ${value}`);
	}
}
