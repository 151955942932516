import { defineComponent, computed, toRef } from 'vue';
import Option from '../Option/Option.vue';
export default defineComponent({
    components: {
        Option
    },
    props: {
        optionData: Array
    },
    setup: (props, context) => {
        const emit = context.emit;
        const options = toRef(props, 'optionData');
        const optionData = computed(() => {
            return (options.value || []);
        });
        return { emit, optionData };
    }
});
