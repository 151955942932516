import { ITableSelector, DefaultTableSelectorOptions } from '@/models/i-table-selector';
import TableSelectorOption from '@/presentation/components/generalizations/table-selectors/table-selector-option';

export default class NullTableSelector implements ITableSelector {
	public defaultOption = undefined;
	public emptyOption = undefined;
	public selectedOption = { value: DefaultTableSelectorOptions.DEFAULT };
	public options = { value: [] };
	public selectedOptionAsOption = { value: undefined };
	public selectedOptionIsDefault = { value: true };

	get defaultOptions(): TableSelectorOption[] {
		return [];
	}

	public createOptions(): TableSelectorOption[] {
		return [];
	}

	public setOptions(): void {
		// Method used by actual table filter implementations.
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public setSelectedOption(option: string | number): void {
		// Method used by actual table filter implementations.
	}

	public resetSelectedOption(): void {
		// Method used by actual table filter implementations.
	}
}
