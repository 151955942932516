import { Enrolment } from '@/models/enrolment';
import { CsvExport } from './csv-export';

export class InternalHistoryCsvExport extends CsvExport {
	public constructor(details: string) {
		super('All enrolments history currently displayed in the table', details);
	}

	protected findExportableEnrolments(enrolments: Enrolment[]): Enrolment[] {
		return enrolments;
	}

	protected determineEmptyExportFileName(): string {
		return 'Empty history export';
	}

	protected determineExportFileNameFromEnrolment(): string {
		return `Enrolment history export for ${this.details ? this.details : ''}`;
	}

	protected createHeaderCells(): string[] {
		return [
			'Review status',
			'Student name',
			'Student email address',
			'Degree level',
			'Nationality',
			'Intake year',
			'Intake month',
			'Source',
			'Success fee category',
			'Tuition fee value',
			'Tuition fee currency',
			'Notes',
			'Country of residence',
			'University name',
			'Programme name',
			'Client identification number',
			'Source',
			'Registration date',
			'First active date',
			'Last active date',
			'Last email active date',
			'Enrolment identification date',
			'Last changed date',
			'Reason(s)',
			'Pre-disqualification reason',
			"Client's remark(s)",
			'Duplication note',
			'Revisit note',
			'Invoice Batch ID'
		];
	}

	protected createEnrolmentLineCells(enrolment: Enrolment): string[] {
		return [
			this.determineReviewStatus(enrolment),
			enrolment.studentName,
			enrolment.studentEmail ? enrolment.studentEmail : '',
			enrolment.programmeLevel ? enrolment.programmeLevel.name : '',
			enrolment.studentNationality ? enrolment.studentNationality.name : '',
			enrolment.intakeYear ? enrolment.intakeYear.toString() : '',
			enrolment.intakeMonth ? enrolment.intakeMonth.name : '',
			enrolment.clientSourceName,
			enrolment.successFeeText,
			enrolment.tuitionFee ? enrolment.tuitionFee.toString() : '',
			enrolment.tuitionFeeCurrency ? enrolment.tuitionFeeCurrency.isoCode : '',
			enrolment.confirmationNotes,
			enrolment.studentCountryOfResidence ? enrolment.studentCountryOfResidence.name : '',
			enrolment.organisationName,
			enrolment.programmeName,
			enrolment.studentOrganisationRegistrationNumber ? enrolment.studentOrganisationRegistrationNumber : '',
			enrolment.internalSourceName,
			enrolment.studentRegistrationDateString,
			enrolment.studentFirstActiveDateString,
			enrolment.studentLastActiveDateString,
			enrolment.lastEmailActivityDateString,
			enrolment.insertedInERTDateString,
			enrolment.lastChangedDateString,
			enrolment.reason,
			enrolment.preDisqualificationReason,
			enrolment.remarks,
			enrolment.duplicationNote,
			enrolment.confirmationChallengeNote,
			enrolment.invoicingBatchIdentity ? enrolment.invoicingBatchIdentity : ''
		];
	}

	private determineReviewStatus(enrolment: Enrolment): string {
		if (enrolment.isAwaitingReview() || enrolment.isReadyToShareWithClient()) {
			return '';
		}

		return enrolment.status.clientFacingName;
	}
}
