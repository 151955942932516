import { computed } from 'vue';
import tableSelectorRetriever from '@/presentation/components/generalizations/table-selectors/table-selector-retriever';

export default class ClearOptionalFiltersButtonComponent {
	public doesAtLeastOneFilterHaveSelectedOption = computed((): boolean => {
		return this.selectedFiltersCounter.value >= 1;
	});

	private selectedFiltersCounter = computed((): number => {
		const optionalClientFilters = tableSelectorRetriever.optionalClientFilters;
		return optionalClientFilters.filter((filter) => {
			return filter.selectedOptionAsOption.value !== undefined && !filter.selectedOptionAsOption.value.isDefaultOption;
		}).length;
	});

	public clearFilters(): void {
		const optionalClientFilters = tableSelectorRetriever.optionalClientFilters;
		optionalClientFilters.forEach((filter) => filter.resetSelectedOption());
	}
}
