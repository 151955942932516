import { ProgrammeLevelEnum } from './programme-level-enum';

export class ProgrammeLevel {
	private static levelBachelor: ProgrammeLevel | undefined;
	private static levelMaster: ProgrammeLevel | undefined;
	private static levelPhd: ProgrammeLevel | undefined;
	private static levelPreparationCourse: ProgrammeLevel | undefined;
	private static levelShortCourse: ProgrammeLevel | undefined;

	private constructor(
		public readonly name: string,
		public readonly value: ProgrammeLevelEnum
	) {}

	public static all(): Readonly<ProgrammeLevel[]> {
		return [
			ProgrammeLevel.bachelor(),
			ProgrammeLevel.master(),
			ProgrammeLevel.phd(),
			ProgrammeLevel.preparationCourse(),
			ProgrammeLevel.shortCourse()
		];
	}

	public static bachelor(): ProgrammeLevel {
		return (this.levelBachelor = this.levelBachelor || new ProgrammeLevel('Bachelor', ProgrammeLevelEnum.BACHELOR));
	}

	public static master(): ProgrammeLevel {
		return (this.levelMaster = this.levelMaster || new ProgrammeLevel('Master', ProgrammeLevelEnum.MASTER));
	}

	public static phd(): ProgrammeLevel {
		return (this.levelPhd = this.levelPhd || new ProgrammeLevel('PhD', ProgrammeLevelEnum.PHD));
	}

	public static preparationCourse(): ProgrammeLevel {
		return (this.levelPreparationCourse =
			this.levelPreparationCourse || new ProgrammeLevel('Preparation course', ProgrammeLevelEnum.PREPARATION));
	}

	public static shortCourse(): ProgrammeLevel {
		return (this.levelShortCourse = this.levelShortCourse || new ProgrammeLevel('Short course', ProgrammeLevelEnum.SHORT));
	}

	public static forValue(value: ProgrammeLevelEnum): ProgrammeLevel {
		switch (value) {
			case ProgrammeLevelEnum.BACHELOR:
				return ProgrammeLevel.bachelor();
			case ProgrammeLevelEnum.MASTER:
				return ProgrammeLevel.master();
			case ProgrammeLevelEnum.PHD:
				return ProgrammeLevel.phd();
			case ProgrammeLevelEnum.PREPARATION:
				return ProgrammeLevel.preparationCourse();
			case ProgrammeLevelEnum.SHORT:
				return ProgrammeLevel.shortCourse();
			default:
				throw new Error('Unknown programme level');
		}
	}
}
