import state from './state';
import getters from '@/store/getters';
import { DataStorage } from '@studyportals/data-storage';
import EventBus from '@/event-bus/event-bus';

import { DataStorageLabelsEnum } from '@/models/data-storage-labels-enum';
import { IMutations } from '@/models/store/i-mutations';
import { EnrolmentSelectionReplacedEventPayload } from '@/event-bus/enrolment-selection-replaced-event-payload';
import { EnrolmentSelectionClearedEvent } from '@/event-bus/enrolment-selection-cleared-event';
import { EnrolmentSelectionReplacedEvent } from '@/event-bus/enrolment-selection-replaced-event';
import { GroupOptionsEnums } from '@/models/group-options-enums';
import { HighlightedFeatureStatusEnum } from '@/models/highlighted-feature-status-enum';
import { EnrolmentInvoiceGroup } from '@/models/enrolment-invoice-group';
import { EnrolmentDateGroup } from '@/models/enrolment-date-group';
import { TEnrolmentGroups } from '@/models/i-enrolment-groups';
import { RouteEnums } from '@/models/route-enums';
import { Country } from '@/models/country';
import { Currency } from '@/models/currency';
import { IOrganisationDetails } from '@/models/i-organisation-details';
import EnrolmentInvoiceGroupsPresenter from '@/presenters/enrolment-invoice-groups-presenter';
import EnrolmentDateGroupsPresenter from '@/presenters/enrolment-date-groups-presenter';
import organisationFilterPresenter from '@/presenters/organisation-filter-presenter';
import EnrolmentBatchSelector from '@/presenters/enrolment-batch-selector';
import EnrolmentPresenter from '@/presenters/enrolment-presenter';

const mutations: IMutations = {
	changeVisibilityAvailableFilterFacets: (show) => {
		state.showAvailableFilterFacets = show;
	},
	changeVisibilityAvailableFilterFacetsBasedOnScope: (scope) => {
		state.showAvailableFilterFacets = scope === '' || scope === state.selectedPSM;
	},
	changeVisibilityClientHistoryNonInvoicableEnrolments: (show) => {
		state.clientHistoryShowNonInvoicableEnrolments = show;
	},
	changeVisibilitySubmitReviewNotification: (show) => {
		state.submitReviewNotificationClosed = !show;
	},
	updateVisibilityIdentifiedEnrolmentsTable: () => {
		const areEmptyFiltersSelected = getters.areEmptyFiltersSelected();
		state.showIdentifiedEnrolmentsTable = !areEmptyFiltersSelected;
	},
	markInitialLoadingSpeedAsTracked: () => {
		state.initialLoadingSpeedTracked = true;
	},
	setInternalFlag: (value) => {
		state.isInternal = value;
	},
	setLoading: (value) => {
		state.loading = value;
	},
	setStandAloneLogin: (value) => {
		state.standAloneLogin = value;
	},
	updateContractDetailsModalShown: (shownStatus) => {
		state.contractDetailsModalShown = shownStatus;
	},
	updateCustomColumnOrderInUseStatus: (value) => {
		state.customColumnOrderInUse = value;
	},
	updateSuspectedDuplicateDetailsModalShown: (shownStatus) => {
		state.suspectedDuplicateDetailsModalShown = shownStatus;
	},
	updateWasReviewSubmitted: (value) => {
		state.wasReviewSubmitted = value;
	},
	updateWasReviewSubmittedNotificationDismissed: (value) => {
		DataStorage.store(
			DataStorageLabelsEnum.WAS_REVIEW_SUBMITTED_NOTIFICATION_DISMISSED_LABEL,
			JSON.stringify(value),
			60 * 60 * 24 * 30
		);
		state.wasReviewSubmittedNotificationDismissed = value;
	},
	setHistoryGroupSelection: (groupOption) => {
		state.historyGroupSelection = groupOption;
		// Reset the selected history group as well, so the grouping always starts with closed groups.
		state.selectedHistoryGroup = '';
	},
	updateSelectedHistoryGroup: (selectedGroup) => {
		state.selectedHistoryGroup = selectedGroup;
	},
	updateLatestOrganisationDetailsClusterId: (id) => {
		state.latestOrganisationDetailsClusterId = id;
	},
	setSelectedOrganisation: (value) => {
		state.selectedOrganisation = value;
	},
	setSelectedPSM: (value) => {
		state.selectedPSM = value;
	},
	updateInternalEnrolmentsSearchQuery: (value) => {
		state.internalEnrolmentsSearchQuery = value;
	},
	changeFilteredEnrolments: (enrolments) => {
		// Slice used not to create copy without reference to the original array, but to trigger the store to update.
		state.filteredEnrolments = enrolments.slice();
	},
	changeSelectedEnrolments: (newSelection) => {
		const previousSelection = [...state.selectedEnrolments];
		// Slice used not to create copy without reference to the original array, but to trigger the store to update.
		state.selectedEnrolments = newSelection.slice();

		EventBus.getEvent(EnrolmentSelectionReplacedEvent).publish(
			new EnrolmentSelectionReplacedEventPayload(state.selectedEnrolments, previousSelection)
		);
	},
	clearSelectedEnrolments: () => {
		state.selectedEnrolments = [];

		EventBus.getEvent(EnrolmentSelectionClearedEvent).publish();
	},
	deselectEnrolment: (value) => {
		const index = state.selectedEnrolments.findIndex((_) => _.equals(value));

		if (-1 !== index) {
			state.selectedEnrolments.splice(index, 1);
		}
	},
	selectEnrolment: (value) => {
		if (!state.selectedEnrolments.some((_) => _.equals(value))) {
			state.selectedEnrolments.push(value);
		}
	},
	setEnrolmentGroups: () => {
		const groups: TEnrolmentGroups = {};
		state.sortedEnrolmentGroups.forEach((group: EnrolmentInvoiceGroup | EnrolmentDateGroup) => {
			if (group instanceof EnrolmentInvoiceGroup) {
				groups[group.displayText] = EnrolmentInvoiceGroupsPresenter.enrolmentsRelevantForSelectedInvoiceNumber(group);
			} else if (group instanceof EnrolmentDateGroup) {
				groups[group.displayText] = EnrolmentDateGroupsPresenter.enrolmentsRelevantForSelectedDate(group);
			}
		});

		state.enrolmentGroups = groups;
	},
	setHistoryGroupsData: () => {
		if (state.historyGroupSelection.getHashKey() === GroupOptionsEnums.INVOICE) {
			state.sortedEnrolmentGroups = EnrolmentInvoiceGroupsPresenter.sortedEnrolmentGroups;
		} else {
			state.sortedEnrolmentGroups = EnrolmentDateGroupsPresenter.sortedEnrolmentGroups;
		}
	},
	updateCountries: (countries) => {
		state.countries = countries as Country[];
	},
	updateCurrencies: (currencies) => {
		state.currencies = currencies as Currency[];
	},
	updateEnrolments: (enrolments) => {
		// Slice used not to create copy without reference to the original array, but to trigger the store to update.
		state.enrolments = enrolments.slice();
	},
	updateOrganisationDetailsCluster: (cluster) => {
		// If the id of this cluster is smaller than the update count, the cluster is outdated, so ignore it.
		if (cluster.id < state.latestOrganisationDetailsClusterId) {
			return;
		}

		state.organisationDetailsCluster = {
			listOfDetails: JSON.parse(JSON.stringify(cluster.listOfDetails)) as IOrganisationDetails[],
			id: cluster.id
		};
	},
	setProjectSuccessManagers: (value) => {
		state.projectSuccessManagers = value;
	},
	setNoWorkOrderOrganisationIds: (value) => {
		state.noWorkOrderOrganisationIds = value;
	},
	updatePartnershipEnrolments: () => {
		const partnershipEnrolments = state.enrolments.filter((_) => state.selectedPartnership.includesEnrolment(_));

		// Slice used not to create copy without reference to the original array, but to trigger the store to update.
		state.partnershipEnrolments = partnershipEnrolments.slice();
	},
	updatePartnerships: (partnerships) => {
		state.partnerships = partnerships;
	},
	updatePartnershipsToAcknowledgeDataProcessingAgreementSignature: () => {
		for (const partnership of state.partnerships) {
			partnership.acknowledgeDataProcessingAgreementSignature();
		}
	},
	considerStudentImpactVideoAsDisplayed: () => {
		for (const partnership of state.partnerships) {
			partnership.considerStudentImpactVideoAsDisplayed();
		}
	},
	updateSuspectedDuplicateEnrolments: (enrolments) => {
		state.suspectedDuplicateEnrolments = enrolments;
	},
	setContractDetails: (details) => {
		state.contractDetails = details;
	},
	setCurrentRoute: (name, props = {}) => {
		state.router.current = name;
		state.router.props = props;

		// Keep the url in the address bar up-to-date.
		const middleRoutePart = state.isInternal ? 'enrolments' : 'client-dashboard/students/enrolment-review';
		history.pushState({}, '', `${window.location.origin}/${middleRoutePart}/#/${name}`);

		if (state.isInternal) {
			// Only reset organisation details when switching between pages.
			if (!state.isInitialPageLoad) {
				organisationFilterPresenter.resetOrganisationDetails();
			}

			if (name === RouteEnums.HISTORY) {
				const batch = EnrolmentBatchSelector.selectEnrolmentsBatch();
				void EnrolmentPresenter.acquireEnrolments(batch);
			} else if (!state.isInitialPageLoad) {
				// Only trigger organisation details retrieval from here when switching between pages.
				void organisationFilterPresenter.retrieveOrganisationDetails();
			}
		}

		state.isInitialPageLoad = false;
	},
	setEnrolmentSubscriptionTokens: (tokens) => {
		state.enrolmentSubscriptionTokens = tokens;
	},
	setModalStatusEnrolmentReview: (status) => {
		state.modalStatusEnrolmentReview = status;
	},
	setModalStatusMassEdit: (status) => {
		state.modalStatusMassEdit = status;
	},
	setModalStatusTransitionTriggers: (status) => {
		state.modalStatusTransitionTriggers = status;
	},
	setUser: (value) => {
		state.user = value;
	},
	updateFlowStatusHighlightedFeature: (value) => {
		state.flowStatusHighlightedFeature = value;
	},
	navigateToOtherStepHighlightedFeaturesFlow: (forward) => {
		const allFlowSteps = Object.values(HighlightedFeatureStatusEnum);
		const indexOfRelevantFlowStep = allFlowSteps.indexOf(state.flowStatusHighlightedFeature);
		const change = forward ? 1 : -1;
		const indexOfNextFlowStep = indexOfRelevantFlowStep + change;
		if (indexOfNextFlowStep < 1 || indexOfNextFlowStep > allFlowSteps.length - 1) {
			return;
		}

		mutations.updateFlowStatusHighlightedFeature(allFlowSteps[indexOfNextFlowStep]);
	},
	updateSelectedCsvSeparator: (value) => {
		state.selectedCsvSeparator = value;
	},
	updateSelectedOptionalFilter: (value) => {
		state.selectedOptionalFilter = value;
	},
	updateFulfilmentProcess: (value) => {
		state.fulfilmentProcess = value;
	},
	updateModalStatusLoading: (status) => {
		state.modalStatusLoading = status;
	},
	updatePartnership: (partnership) => {
		state.selectedPartnership = partnership;
	},
	updateLastUsedCurrency: (value) => {
		state.lastUsedCurrency = value;
	}
};

export default mutations;
