import { defineComponent } from 'vue';
import SingleSignOnSessionManager from '@/stand-alone/single-sign-on-session-manager';

class LogInComponent {
	private singleSignOnSessionManager = new SingleSignOnSessionManager();

	public async signInInternal(): Promise<void> {
		await this.singleSignOnSessionManager.loginInternal();
	}

	public async signInClient(): Promise<void> {
		await this.singleSignOnSessionManager.loginClient();
	}
}

export default defineComponent({
	setup: () => {
		const component = new LogInComponent();

		return {
			signInInternal: component.signInInternal.bind(component),
			signInClient: component.signInClient.bind(component)
		};
	}
});
