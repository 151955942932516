import { nextTick, ref, computed } from 'vue';
export default class MultiSelectComponent {
    emit;
    options;
    placeholder;
    entity;
    multipleOptions;
    disabled;
    groups;
    dropDown = ref(null);
    selectedOption = ref(null);
    selectedOptions = ref([]);
    isActive = ref(false);
    filteredOptions = ref([]);
    filteredGroups = ref([]);
    constructor(emit, options, placeholder, entity, multipleOptions, disabled, groups) {
        this.emit = emit;
        this.options = options;
        this.placeholder = placeholder;
        this.entity = entity;
        this.multipleOptions = multipleOptions;
        this.disabled = disabled;
        this.groups = groups;
    }
    availableOptions = computed(() => {
        return this.filteredOptions.value.filter((option) => {
            return this.selectedOptions.value.indexOf(option) === -1;
        });
    });
    availableGroupedOptions = computed(() => {
        const options = [];
        this.filteredGroups.value.forEach((group) => {
            options.push({
                label: group.label,
                options: group.options.filter((option) => {
                    return this.selectedOptions.value.indexOf(option) === -1;
                })
            });
        });
        return options;
    });
    inputPlaceholder = computed(() => {
        if (this.selectedOption.value !== null && !this.multipleOptions.value) {
            return this.selectedOption.value.label;
        }
        if (this.placeholder.value === "") {
            return "Select an option";
        }
        return this.placeholder.value;
    });
    optionsSelected = computed(() => {
        return this.selectedOption.value !== null || this.selectedOptions.value.length > 0;
    });
    searchPlaceholder = computed(() => {
        if (this.entity.value === "") {
            return "Search";
        }
        return `Search by ${this.entity.value}`;
    });
    mounted() {
        this.updateSearch("");
    }
    selectOption(option) {
        this.isActive.value = false;
        if (this.multipleOptions.value && option) {
            this.selectedOptions.value.push(option);
            this.emit('updated', this.selectedOptions.value);
            return;
        }
        this.selectedOption.value = option;
        this.emit('updated', this.selectedOption.value);
    }
    toggleMultiSelect() {
        if (this.disabled.value) {
            return;
        }
        this.isActive.value = !this.isActive.value;
        // Reset the search query.
        this.updateSearch("");
        // Check if the dropdown overflows the page.
        if (this.isActive.value) {
            void this.checkDropdownOverflow();
        }
    }
    deactivate() {
        this.isActive.value = false;
        // Reset the search query.
        this.updateSearch("");
    }
    deleteOption(deletedOption) {
        this.selectedOptions.value = this.selectedOptions.value.filter((option) => {
            return option !== deletedOption;
        });
        this.emit('updated', this.selectedOptions.value);
    }
    updateSearch(query) {
        if (this.groups.value.length > 0) {
            this.filteredGroups.value = this.filterGroups(query);
            return;
        }
        this.filteredOptions.value = this.filterOptions(this.options.value, query);
    }
    async checkDropdownOverflow() {
        await nextTick();
        const dropdown = this.dropDown.value;
        const bounds = dropdown.getBoundingClientRect();
        const overflow = (bounds.y + bounds.height) - window.innerHeight;
        const remValue = 16;
        if (overflow > 0) {
            dropdown.style.height = bounds.height - (overflow + remValue) + 'px';
        }
    }
    filterGroups(query) {
        let filteredGroups = [];
        this.groups.value.forEach((group) => {
            filteredGroups.push({
                ...group,
                options: this.filterOptions(group.options, query)
            });
        });
        return filteredGroups;
    }
    filterOptions(options, query) {
        if (query === "") {
            return options;
        }
        let filteredOptions = [];
        options.forEach((option) => {
            let lowercaseValue = option.label.toLowerCase();
            if (option.label.indexOf(query) !== -1 || lowercaseValue.indexOf(query) !== -1) {
                filteredOptions.push(option);
            }
        });
        return filteredOptions;
    }
}
