import { computed, ref, Ref, nextTick } from 'vue';
import { ModalStatusEnrolmentReviewEnums } from '@/models/modal-status-enrolment-review-enum';
import { EnrolmentReview } from '@/models/enrolment-review/enrolment-review';
import { InternalEnrolmentReview } from '@/models/enrolment-review/internal-enrolment-review';
import { Country } from '@/models/country';
import { Currency } from '@/models/currency';
import { ProgrammeLevel } from '@/models/programme-level';
import { Month } from '@/models/month';
import GrammaticalNumberTextPresenter from '@/presenters/grammatical-number-text-presenter';
import { TriggerPayloadPopupConfiguration } from '@/models/enrolment-review/trigger-payload-popup-configuration';
import { EnrolmentConfirmationUnsureTriggerPayloadConfiguration }
	from '@/models/transitions/trigger-payload/enrolment-confirmation-unsure-trigger-payload-configuration';
import FulfillmentStepIndication from '@/models/fulfillment-step-indication';
import FormGenerator from '@studyportals/form-generator';
import { TransitionSucceededEvent } from '@/event-bus/transitions-succeeded-event';
import { MassReviewTransitionsFailedEvent } from '@/event-bus/mass-review-transitions-failed-event';
import { EnrolmentReviewCanceledEvent } from '@/event-bus/enrolment-review-canceled-event';
import { EnrolmentReviewDoneEvent } from '@/event-bus/enrolment-review-done-event';
import { TriggerPayloadPopupConfigurationSharedEvent } from '@/event-bus/trigger-payload-popup-configuration-shared-event';
import { ComplexModal } from '@studyportals/modal';
import { IMultiSelect } from '@studyportals/multiselect/src/interfaces/multiselect.interface';
import { IOption } from '@studyportals/multiselect/src/interfaces/options.interface';
import EventBus from '@/event-bus/event-bus';
import store from '@/store';

export default class EnrolmentReviewModalComponent {
	public nationalityDropdown: Ref<IMultiSelect | null> = ref(null);
	public levelDropdown: Ref<IMultiSelect | null> = ref(null);
	public currencyDropdown: Ref<IMultiSelect | null> = ref(null);
	public monthDropdown: Ref<IMultiSelect | null> = ref(null);

	public modalContent: Ref<HTMLElement | null> = ref(null);
	private modal: Ref<ComplexModal | undefined> = ref(undefined);

	public massReviewTransitionsFailed = ref(false);
	public triggerPayloadPopupConfiguration: Ref<TriggerPayloadPopupConfiguration | undefined> = ref();

	private transitionSucceededEventSubscriptionToken = ref('');
	private massReviewTransitionsFailedEventSubscriptionToken = ref('');
	private enrolmentReviewDoneEventSubscriptionToken = ref('');
	private triggerPayLoadPopupConfigSharedSubscriptionToken = ref('');

	constructor(public enrolmentReview: EnrolmentReview) {}

	public get isInternalReview(): boolean {
		return this.enrolmentReview instanceof InternalEnrolmentReview;
	}

	public programmeLevels = computed((): readonly ProgrammeLevel[] => {
		return store.getters.programmeLevels();
	});
	public months = computed((): readonly Month[] => {
		return store.getters.months();
	});
	public countries = computed((): Country[] => {
		return store.getters.countries();
	});
	public currencies = computed((): Currency[] => {
		return store.getters.currencies();
	});
	public programmeLevelsAsOptions = computed((): IOption[] => {
		return store.getters.programmeLevelsAsOptions();
	});
	public monthsAsOptions = computed((): IOption[] => {
		return store.getters.monthsAsOptions();
	});
	public countriesAsOptions = computed((): IOption[] => {
		return store.getters.countriesAsOptions();
	});
	public currenciesAsOptions = computed((): IOption[] => {
		return store.getters.currenciesAsOptions();
	});

	public grammaticalNumberTextPresenter = computed((): GrammaticalNumberTextPresenter => {
		return new GrammaticalNumberTextPresenter(this.enrolmentReview.enrolments.length);
	});

	public modalStatusEnrolmentReview = computed((): ModalStatusEnrolmentReviewEnums => {
		const modalStatus = store.getters.modalStatusEnrolmentReview();
		if (modalStatus === ModalStatusEnrolmentReviewEnums.TRIGGER_PAYLOAD_REVIEW_FORM) {
			void nextTick(this.generateForm.bind(this));
		}

		return store.getters.modalStatusEnrolmentReview();
	});

	public confirmationChallengeNote = computed((): string => {
		return this.enrolmentReview.enrolments[0].confirmationChallengeNote;
	});

	public enrolmentHasNote = computed((): boolean => {
		const firstEnrolment = this.enrolmentReview.enrolments[0];
		return firstEnrolment.confirmationChallengeNote.length > 0;
	});

	public selectedTriggerIsOtherSpecifiedStatus = computed((): boolean => {
		return (
			this.enrolmentReview.selectedTrigger &&
			this.enrolmentReview.selectedTrigger.payloadConfiguration instanceof EnrolmentConfirmationUnsureTriggerPayloadConfiguration
		);
	});

	public areNoEnrolmentsAlreadyReviewed = computed((): boolean => {
		const enrolmentAlreadyReviewed = this.enrolmentReview.enrolments.find(
			(enrolment) =>
				enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.CONFIRMATION_COMPLETED) &&
				!enrolment.isChallengedAndUnconfirmed
		);

		return enrolmentAlreadyReviewed === undefined;
	});

	public massReviewDisclaimerIsActive = computed((): boolean => {
		return this.modalStatusEnrolmentReview.value === ModalStatusEnrolmentReviewEnums.MASS_REVIEW_DISCLAIMER;
	});

	public tuitionFeeSkipReviewIsActive = computed((): boolean => {
		return this.modalStatusEnrolmentReview.value === ModalStatusEnrolmentReviewEnums.TUITION_FEE_SKIP_REVIEW;
	});

	public triggerSelectionReviewIsActive = computed((): boolean => {
		return this.modalStatusEnrolmentReview.value === ModalStatusEnrolmentReviewEnums.TRIGGER_SELECTION_REVIEW;
	});

	public successFeeSelectionReviewIsActive = computed((): boolean => {
		return this.modalStatusEnrolmentReview.value === ModalStatusEnrolmentReviewEnums.SUCCESS_FEE_SELECTION_REVIEW;
	});

	public additionalNoPayReasonSelectionReviewIsActive = computed((): boolean => {
		return this.modalStatusEnrolmentReview.value === ModalStatusEnrolmentReviewEnums.ADDITIONAL_NO_PAY_REASON_SELECTION_REVIEW;
	});

	public triggerPayloadReviewFormIsActive = computed((): boolean => {
		return this.modalStatusEnrolmentReview.value === ModalStatusEnrolmentReviewEnums.TRIGGER_PAYLOAD_REVIEW_FORM;
	});

	public massReviewConfirmationIsActive = computed((): boolean => {
		return this.modalStatusEnrolmentReview.value === ModalStatusEnrolmentReviewEnums.MASS_REVIEW_CONFIRMATION;
	});

	public mounted(): void {
		this.prepareModal();

		this.transitionSucceededEventSubscriptionToken.value = EventBus.getEvent(TransitionSucceededEvent).subscribe(() => {
			this.massReviewTransitionsFailed.value = false;
			this.onTransitionSucceeded();
		});
		this.massReviewTransitionsFailedEventSubscriptionToken.value = EventBus.getEvent(MassReviewTransitionsFailedEvent).subscribe(
			() => (this.massReviewTransitionsFailed.value = true)
		);
		this.enrolmentReviewDoneEventSubscriptionToken.value = EventBus.getEvent(EnrolmentReviewDoneEvent).subscribe(
			() => (this.massReviewTransitionsFailed.value = false)
		);
		this.triggerPayLoadPopupConfigSharedSubscriptionToken.value = EventBus.getEvent(
			TriggerPayloadPopupConfigurationSharedEvent
		).subscribe((configuration) => {
			this.triggerPayloadPopupConfiguration.value = configuration;
		});
	}

	public unmounted(): void {
		EventBus.getEvent(TransitionSucceededEvent).unsubscribe(this.transitionSucceededEventSubscriptionToken.value);
		EventBus.getEvent(MassReviewTransitionsFailedEvent).unsubscribe(this.massReviewTransitionsFailedEventSubscriptionToken.value);
		EventBus.getEvent(EnrolmentReviewDoneEvent).unsubscribe(this.enrolmentReviewDoneEventSubscriptionToken.value);
		EventBus.getEvent(TriggerPayloadPopupConfigurationSharedEvent).unsubscribe(
			this.triggerPayLoadPopupConfigSharedSubscriptionToken.value
		);

		window.ModalManager.close(this.modal.value);
	}

	public storeLastUsedCurrency(): void {
		const currency = this.enrolmentReview.selectedTriggerPayload.enrichmentSpecification.tuitionFeeCurrency;
		if (!currency) {
			return;
		}

		store.mutations.updateLastUsedCurrency(currency);
	}

	public async prefillDropdownsForLatestFlowStep(): Promise<void> {
		await nextTick();
		if (this.triggerPayloadReviewFormIsActive.value) {
			if (this.enrolmentReview.requiresTuitionFee()) {
				this.prefillTuitionFeeCurrency();
			}
			if (this.enrolmentReview.requiresNationality()) {
				this.prefillStudentNationalityDropdown();
			}
			if (this.enrolmentReview.requiresIntakeInformation()) {
				this.prefillIntakeMonth();
			}
			if (this.enrolmentReview.requiresProgrammeInformation()) {
				this.prefillProgrammeLevel();
			}
		}
	}

	public setIntakeMonth(selectedValue: IOption): void {
		const selectedMonth = this.months.value.find((month) => month.value === selectedValue.value);
		const enrichmentSpecification = this.enrolmentReview.selectedTriggerPayload.enrichmentSpecification;
		if (!selectedMonth) {
			return;
		}

		enrichmentSpecification.intakeMonth = selectedMonth;
		this.enrolmentReview.isValid();
	}

	public setProgrammeLevel(selectedValue: IOption): void {
		const selectedLevel = this.programmeLevels.value.find((level) => level.value === selectedValue.value);
		const enrichmentSpecification = this.enrolmentReview.selectedTriggerPayload.enrichmentSpecification;
		if (!selectedLevel) {
			return;
		}

		enrichmentSpecification.programmeLevel = selectedLevel;
		this.enrolmentReview.isValid();
	}

	public setStudentNationality(selectedValue: IOption): void {
		const selectedCountry = this.countries.value.find((country) => country.isoCode === selectedValue.value);
		const enrichmentSpecification = this.enrolmentReview.selectedTriggerPayload.enrichmentSpecification;
		if (!selectedCountry) {
			return;
		}

		enrichmentSpecification.studentNationality = selectedCountry;
		this.enrolmentReview.isValid();
	}

	public setTuitionFeeCurrency(selectedValue: IOption): void {
		const selectedCurrency = this.currencies.value.find((currency) => currency.isoCode === selectedValue.value);
		const enrichmentSpecification = this.enrolmentReview.selectedTriggerPayload.enrichmentSpecification;
		if (!selectedCurrency) {
			return;
		}

		enrichmentSpecification.tuitionFeeCurrency = selectedCurrency;
		this.enrolmentReview.isValid();
	}

	private prefillStudentNationalityDropdown(): void {
		const enrichmentSpecification = this.enrolmentReview.selectedTriggerPayload.enrichmentSpecification;
		if (!this.nationalityDropdown.value || !enrichmentSpecification.studentNationality) {
			return;
		}

		const selectedCountry = this.countriesAsOptions.value.find(
			(country) => country.value === enrichmentSpecification.studentNationality?.isoCode
		);
		if (!selectedCountry) {
			return;
		}

		this.nationalityDropdown.value.selectedOption = selectedCountry;
	}

	private prefillProgrammeLevel(): void {
		const enrichmentSpecification = this.enrolmentReview.selectedTriggerPayload.enrichmentSpecification;
		if (!this.levelDropdown.value || !enrichmentSpecification.programmeLevel) {
			return;
		}

		const selectedLevel = this.programmeLevelsAsOptions.value.find(
			(level) => level.value === enrichmentSpecification.programmeLevel?.value
		);
		if (!selectedLevel) {
			return;
		}

		this.levelDropdown.value.selectedOption = selectedLevel;
	}

	private prefillTuitionFeeCurrency(): void {
		const enrichmentSpecification = this.enrolmentReview.selectedTriggerPayload.enrichmentSpecification;
		if (!this.currencyDropdown.value || !enrichmentSpecification.tuitionFeeCurrency) {
			return;
		}

		const selectedCurrency = this.currenciesAsOptions.value.find(
			(currency) => currency.value === enrichmentSpecification.tuitionFeeCurrency?.isoCode
		);
		if (!selectedCurrency) {
			return;
		}

		this.currencyDropdown.value.selectedOption = selectedCurrency;
	}

	private prefillIntakeMonth(): void {
		const enrichmentSpecification = this.enrolmentReview.selectedTriggerPayload.enrichmentSpecification;
		if (!this.monthDropdown.value || !enrichmentSpecification.intakeMonth) {
			return;
		}

		const selectedMonth = this.monthsAsOptions.value.find((month) => month.value === enrichmentSpecification.intakeMonth?.value);
		if (!selectedMonth) {
			return;
		}

		this.monthDropdown.value.selectedOption = selectedMonth;
	}

	private onReviewCanceled(): void {
		this.massReviewTransitionsFailed.value = false;
		EventBus.getEvent(EnrolmentReviewCanceledEvent).publish();
		store.mutations.setModalStatusEnrolmentReview(ModalStatusEnrolmentReviewEnums.INACTIVE);
	}

	private onTransitionSucceeded(): void {
		store.mutations.setModalStatusEnrolmentReview(ModalStatusEnrolmentReviewEnums.INACTIVE);
	}

	private generateForm(): void {
		const form = document.getElementById('IntakeForm');
		if (!form) {
			return;
		}

		const fgForm = new FormGenerator(form as HTMLFormElement);
		fgForm.generate();
	}

	private prepareModal(): void {
		if (!this.modalContent.value) {
			return;
		}

		this.modal.value = new ComplexModal(this.modalContent.value, {
			cssClassName: 'EnrolmentReviewModalWrapper',
			destroyOnClose: false,
			onClose: this.onReviewCanceled.bind(this)
		});

		window.ModalManager.open(this.modal.value);
	}
}
