import { TransitionTrigger } from './transition-trigger';

export class NullTrigger extends TransitionTrigger {
	public constructor() {
		super('', []);
	}

	public equals(): boolean {
		return true;
	}

	// eslint-disable-next-line @typescript-eslint/require-await
	protected async performTransitions(): Promise<void> {
		throw new Error('Trigger not available');
	}
}
