import { computed, ref, Ref } from 'vue';
import { ModalStatusInvoiceBatchCreatedNotificationEnums } from '@/models/modal-status-invoice-batch-created-notification-enum';
import { InvoiceBatchCreatedEventPayload } from '@/event-bus/invoice-batch-created-event-payload';
import { InvoiceBatchCreatedEvent } from '@/event-bus/invoice-batch-created-event';
import { InvoiceBatchCreationFailedEvent } from '@/event-bus/invoice-batch-creation-failed-event';
import EventBus from '@/event-bus/event-bus';

export default class InvoiceBatchCreatedNotificationComponent {
	public modalStatusInvoiceBatchCreatedNotification = ref(ModalStatusInvoiceBatchCreatedNotificationEnums.INACTIVE);

	public invoiceBatchCreatedPayload: Ref<InvoiceBatchCreatedEventPayload | undefined> = ref();

	private invoiceBatchCreatedEventSubscriptionToken = ref('');
	private invoiceBatchCreationFailedEventSubscriptionToken = ref('');

	public invoiceBatchCreatedNotificationStatusIsInactive = computed((): boolean => {
		return this.modalStatusInvoiceBatchCreatedNotification.value === ModalStatusInvoiceBatchCreatedNotificationEnums.INACTIVE;
	});

	public mounted(): void {
		this.invoiceBatchCreatedEventSubscriptionToken.value = EventBus.getEvent(InvoiceBatchCreatedEvent).subscribe((payload) => {
			this.invoiceBatchCreatedPayload.value = payload;
			this.modalStatusInvoiceBatchCreatedNotification.value = ModalStatusInvoiceBatchCreatedNotificationEnums.ACTIVE;
		});

		this.invoiceBatchCreationFailedEventSubscriptionToken.value = EventBus.getEvent(InvoiceBatchCreationFailedEvent).subscribe(() => {
			this.modalStatusInvoiceBatchCreatedNotification.value = ModalStatusInvoiceBatchCreatedNotificationEnums.FAILED;
		});
	}

	public unmounted(): void {
		EventBus.getEvent(InvoiceBatchCreatedEvent).unsubscribe(this.invoiceBatchCreatedEventSubscriptionToken.value);
		EventBus.getEvent(InvoiceBatchCreationFailedEvent).unsubscribe(this.invoiceBatchCreationFailedEventSubscriptionToken.value);
	}

	public updateModalStatus(status: ModalStatusInvoiceBatchCreatedNotificationEnums): void {
		this.modalStatusInvoiceBatchCreatedNotification.value = status;
	}
}
