import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';
import { computed } from 'vue';
import { Enrolment } from '@/models/enrolment';

export class ReviewStatusIndication implements ICellRendererComp {
	private outerWrapper: HTMLSpanElement;
	private params: ICellRendererParams | null = null;

	private enrolment = computed((): Enrolment | undefined => {
		if (!this.params?.data) {
			return;
		}

		return this.params.data as Enrolment;
	});

	constructor() {
		this.outerWrapper = document.createElement('span');
		this.outerWrapper.className = 'ReviewStatusIcon';
	}

	public init(params: ICellRendererParams): void {
		this.params = params;

		const element = document.createElement('i');
		if (this.enrolment.value?.isChallengedAndUnconfirmed && this.enrolment.value?.isSharedWithClient()) {
			element.className = 'lnr-question-circle CheckIcon';
		} else if (this.enrolment.value?.isConfirmationCompleted()) {
			element.className = 'lnr-check ReviewedIcon';
		} else if (this.enrolmentIsApproved()) {
			element.className = 'lnr-arrow-right-circle approvedIcon';
		} else if (this.enrolmentIsFinal()) {
			element.className = 'lnr-prohibited isFinalIcon';
		} else if (this.confirmationHasBeenSubmitted()) {
			element.className = 'lnr-arrow-up SubmittedIcon';
		}

		this.outerWrapper.appendChild(element);
	}

	public getGui(): HTMLSpanElement {
		return this.outerWrapper;
	}

	public refresh(): boolean {
		return false;
	}

	private confirmationHasBeenSubmitted(): boolean {
		return (
			this.enrolment.value !== undefined &&
			!this.enrolment.value.isAwaitingReview() &&
			!this.enrolment.value.isReadyForReviewSubmission() &&
			!this.enrolment.value.isApproved() &&
			!this.enrolment.value.isFinal()
		);
	}
	private enrolmentIsApproved(): boolean {
		return this.enrolment.value !== undefined && this.enrolment.value.isApproved();
	}
	private enrolmentIsFinal(): boolean {
		return this.enrolment.value !== undefined && this.enrolment.value.isFinal();
	}
}
