export default class VideoStudentImpactComponent {
	public mounted(): void {
		this.addAsyncScript('https://fast.wistia.com/embed/medias/2kf74ltl9z.jsonp');
		this.addAsyncScript('https://fast.wistia.com/assets/external/E-v1.js');
	}

	private addAsyncScript(url: string): void {
		const script = document.createElement('script');
		script.setAttribute('src', url);
		script.setAttribute('async', '');
		document.head.appendChild(script);
	}
}
