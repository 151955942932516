import { Component, defineComponent, onMounted, onUnmounted, watch } from 'vue';
import EnrolmentReviewForm from '@/presentation/components/generalizations/enrolment-review-form/enrolment-review-form.vue';
import InvoiceSummary from '@/presentation/components/internal/invoice-summary/invoice-summary.vue';
import DuplicateAlertReasonModal from '@/presentation/components/internal/duplicate-alert-reason-modal/duplicate-alert-reason-modal.vue';
import TransitionTriggersModal from '@/presentation/components/internal/transition-triggers-modal/transition-triggers-modal.vue';
import TransitionTriggersComponent from '@/presentation/components/internal/transition-triggers/transition-triggers-class';

export default defineComponent({
	components: {
		InvoiceSummary: InvoiceSummary as Component,
		EnrolmentReviewForm: EnrolmentReviewForm as Component,
		DuplicateAlertReasonModal: DuplicateAlertReasonModal as Component,
		TransitionTriggersModal: TransitionTriggersModal as Component
	},
	setup: (props, context) => {
		const component = new TransitionTriggersComponent(context.emit);

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		watch(component.duplicateAlertReason, component.updateDuplicateAlertReason.bind(component));
		watch(component.selectedEnrolments, component.onSelectedEnrolmentsChanged.bind(component));

		return {
			triggers: component.triggers,
			isDuplicateAlertReasonModalShown: component.isDuplicateAlertReasonModalShown,
			isTriggerDuplicated: component.isTriggerDuplicated,
			duplicateAlertReason: component.duplicateAlertReason,
			transitionTriggersStatusIsInactive: component.transitionTriggersStatusIsInactive,
			selectedTransitionTrigger: component.selectedTransitionTrigger,
			triggerPayload: component.triggerPayload,
			selectionHasSameStatus: component.selectionHasSameStatus,
			requestTransition: component.requestTransition.bind(component),
			hideDuplicateAlertReasonModal: component.hideDuplicateAlertReasonModal.bind(component),
			updateDuplicateAlertReasonFromChild: component.updateDuplicateAlertReasonFromChild.bind(component),
			resetTrigger: component.resetTrigger.bind(component)
		};
	}
});
