import { computed, ref } from 'vue';
import { Enrolment } from '@/models/enrolment';
import store from '@/store';

export default class EnrolmentMenuComponent {
	public buttonsEnabled = ref(false);

	public filteredEnrolments = computed((): Enrolment[] => {
		return store.getters.filteredEnrolments();
	});

	public checkExportPossibility(): void {
		this.buttonsEnabled.value = this.enrolmentsBelongToSameOrganisation();
	}

	private enrolmentsBelongToSameOrganisation(): boolean {
		const organisationIdentities = this.filteredEnrolments.value.map((_) => _.organisationIdentity);
		const organisationIdentitiesSet = new Set<string>(organisationIdentities);

		return organisationIdentitiesSet.size === 1;
	}
}
