import { computed, nextTick, ref, Ref } from 'vue';
import { DateTime } from '@studyportals/mb-platform-date-time';
import { EnrolmentEditRequestedEvent } from '@/event-bus/enrolment-edit-requested-event';
import { EnrolmentEnrichedEvent } from '@/event-bus/enrolment-enriched-event';
import { DrawerOpenEvent } from '@/event-bus/drawer-open-event';
import { DrawerCloseEvent } from '@/event-bus/drawer-close-event';
import EventBus from '@/event-bus/event-bus';
import { Country } from '@/models/country';
import { Currency } from '@/models/currency';
import { EnrichmentSpecification } from '@/models/enrichment-specification';
import { Enrolment } from '@/models/enrolment';
import { Month } from '@/models/month';
import { ProgrammeLevel } from '@/models/programme-level';
import { ValidationResult } from '@/models/validation/validation-result';
import EnrolmentService from '@/services/enrolments-service';
import FormGenerator from '@studyportals/form-generator';
import { EnrichmentSpecificationValidation } from './validation/enrichment-specification-validation';
import FulfillmentStepIndication from '@/models/fulfillment-step-indication';
import { ModalStatusLoadingEnums } from '@/models/modal-status-loading-enum';
import { IMultiSelect } from '@studyportals/multiselect/src/interfaces/multiselect.interface';
import { IOption } from '@studyportals/multiselect/src/interfaces/options.interface';
import store from '@/store';

export default class EditEnrolmentComponent {
	public nationalityDropdown: Ref<IMultiSelect | null> = ref(null);
	public residenceDropdown: Ref<IMultiSelect | null> = ref(null);
	public levelDropdown: Ref<IMultiSelect | null> = ref(null);
	public currencyDropdown: Ref<IMultiSelect | null> = ref(null);
	public monthDropdown: Ref<IMultiSelect | null> = ref(null);
	public enrolment: Ref<Enrolment | null> = ref(null);
	public enrichmentSpecification: Ref<EnrichmentSpecification | null> = ref(null);
	public validationResult: Ref<ValidationResult | undefined> = ref();

	public programmeLevels = computed((): readonly ProgrammeLevel[] => {
		return store.getters.programmeLevels();
	});
	public months = computed((): readonly Month[] => {
		return store.getters.months();
	});
	public countries = computed((): Country[] => {
		return store.getters.countries();
	});
	public currencies = computed((): Currency[] => {
		return store.getters.currencies();
	});
	public programmeLevelsAsOptions = computed((): IOption[] => {
		return store.getters.programmeLevelsAsOptions();
	});
	public monthsAsOptions = computed((): IOption[] => {
		return store.getters.monthsAsOptions();
	});
	public countriesAsOptions = computed((): IOption[] => {
		return store.getters.countriesAsOptions();
	});
	public currenciesAsOptions = computed((): IOption[] => {
		return store.getters.currenciesAsOptions();
	});

	public suspectedDuplicateDetailsModalShown = computed((): boolean => {
		return store.getters.suspectedDuplicateDetailsModalShown();
	});

	public stepIsDuplicateSuspected = computed((): boolean => {
		return (
			this.enrolment.value !== null &&
			this.enrolment.value.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.DUPLICATION_SUSPECTED)
		);
	});

	public isTuitionFeeValid = computed((): boolean => {
		return (
			!this.validationResult.value ||
			this.validationResult.value.isTuitionFeeValid ||
			!this.enrichmentSpecification.value ||
			!this.enrichmentSpecification.value.tuitionFeeCurrency ||
			!!this.enrichmentSpecification.value.tuitionFee
		);
	});

	public isTuitionFeeCurrencyValid = computed((): boolean => {
		return (
			!this.validationResult.value ||
			this.validationResult.value.isTuitionFeeCurrencyValid ||
			!this.enrichmentSpecification.value ||
			!!this.enrichmentSpecification.value.tuitionFeeCurrency
		);
	});

	public mounted(): void {
		EventBus.getEvent(EnrolmentEditRequestedEvent).subscribe((_) => this.startEditingEnrolment(_));
	}

	public cancel(): void {
		this.transitionToFreshState();
	}

	public async save(): Promise<void> {
		if (this.enrolment.value === null || this.enrichmentSpecification.value === null) {
			return;
		}

		if (!this.performValidation()) {
			return;
		}

		this.showLoader();

		try {
			await EnrolmentService.enrich(this.enrolment.value, this.enrichmentSpecification.value);

			this.publishEnrolmentEnrichedEvent(this.enrolment.value);
			this.showSuccessMessage();
			this.transitionToFreshState();
		} catch (e) {
			this.showFailureMessage();
		}
	}

	public formatDate(value: DateTime | undefined): string {
		return value ? value.toShortDateString() : '';
	}

	public showLoader(): void {
		store.actions.setModalStatusLoading(ModalStatusLoadingEnums.ACTIVE);
	}

	public showSuccessMessage(): void {
		store.actions.setModalStatusLoading(ModalStatusLoadingEnums.SUCCESS);
	}

	public showFailureMessage(): void {
		store.actions.setModalStatusLoading(ModalStatusLoadingEnums.FAILED);
	}

	public showSuspectedDuplicates(): void {
		store.mutations.updateSuspectedDuplicateDetailsModalShown(true);
	}

	public isLinkedInLinkAvailable(): boolean {
		return this.isLinkAvailable('studentLinkedIn');
	}

	public isFacebookLinkAvailable(): boolean {
		return this.isLinkAvailable('studentFacebook');
	}

	public setIntakeMonth(selectedValue: IOption): void {
		const selectedMonth = this.months.value.find((month) => month.value === selectedValue.value);
		if (!selectedMonth || !this.enrichmentSpecification.value) {
			return;
		}

		this.enrichmentSpecification.value.intakeMonth = selectedMonth;
	}

	public setProgrammeLevel(selectedValue: IOption): void {
		const selectedLevel = this.programmeLevels.value.find((level) => level.value === selectedValue.value);
		if (!selectedLevel || !this.enrichmentSpecification.value) {
			return;
		}

		this.enrichmentSpecification.value.programmeLevel = selectedLevel;
	}

	public setStudentNationality(selectedValue: IOption): void {
		const selectedCountry = this.countries.value.find((country) => country.isoCode === selectedValue.value);
		if (!selectedCountry || !this.enrichmentSpecification.value) {
			return;
		}

		this.enrichmentSpecification.value.studentNationality = selectedCountry;
	}

	public setStudentCountryOfResidence(selectedValue: IOption): void {
		const selectedCountry = this.countries.value.find((country) => country.isoCode === selectedValue.value);
		if (!selectedCountry || !this.enrichmentSpecification.value) {
			return;
		}

		this.enrichmentSpecification.value.studentCountryOfResidence = selectedCountry;
	}

	public setTuitionFeeCurrency(selectedValue: IOption): void {
		const selectedCurrency = this.currencies.value.find((currency) => currency.isoCode === selectedValue.value);
		if (!selectedCurrency || !this.enrichmentSpecification.value) {
			return;
		}

		this.enrichmentSpecification.value.tuitionFeeCurrency = selectedCurrency;
	}

	private openDrawer(): void {
		EventBus.getEvent(DrawerOpenEvent).publish();
		void nextTick(this.prefillAllDropdowns.bind(this));
	}

	private closeDrawer(): void {
		EventBus.getEvent(DrawerCloseEvent).publish();
	}

	private prefillAllDropdowns(): void {
		this.prefillStudentNationalityDropdown();
		this.prefillStudentCountryOfResidenceDropdown();
		this.prefillProgrammeLevel();
		this.prefillTuitionFeeCurrency();
		this.prefillIntakeMonth();
	}

	private prefillStudentNationalityDropdown(): void {
		if (!this.nationalityDropdown.value || !this.enrichmentSpecification.value?.studentNationality) {
			return;
		}

		const selectedCountry = this.countriesAsOptions.value.find(
			(country) => country.value === this.enrichmentSpecification.value?.studentNationality?.isoCode
		);
		if (!selectedCountry) {
			return;
		}

		this.nationalityDropdown.value.selectedOption = selectedCountry;
	}

	private prefillStudentCountryOfResidenceDropdown(): void {
		if (!this.residenceDropdown.value || !this.enrichmentSpecification.value?.studentCountryOfResidence) {
			return;
		}

		const selectedCountry = this.countriesAsOptions.value.find(
			(country) => country.value === this.enrichmentSpecification.value?.studentCountryOfResidence?.isoCode
		);
		if (!selectedCountry) {
			return;
		}

		this.residenceDropdown.value.selectedOption = selectedCountry;
	}

	private prefillProgrammeLevel(): void {
		if (!this.levelDropdown.value || !this.enrichmentSpecification.value?.programmeLevel) {
			return;
		}

		const selectedLevel = this.programmeLevelsAsOptions.value.find(
			(level) => level.value === this.enrichmentSpecification.value?.programmeLevel?.value
		);
		if (!selectedLevel) {
			return;
		}

		this.levelDropdown.value.selectedOption = selectedLevel;
	}

	private prefillTuitionFeeCurrency(): void {
		if (!this.currencyDropdown.value || !this.enrichmentSpecification.value?.tuitionFeeCurrency) {
			return;
		}

		const selectedCurrency = this.currenciesAsOptions.value.find(
			(currency) => currency.value === this.enrichmentSpecification.value?.tuitionFeeCurrency?.isoCode
		);
		if (!selectedCurrency) {
			return;
		}

		this.currencyDropdown.value.selectedOption = selectedCurrency;
	}

	private prefillIntakeMonth(): void {
		if (!this.monthDropdown.value || !this.enrichmentSpecification.value?.intakeMonth) {
			return;
		}

		const selectedMonth = this.monthsAsOptions.value.find(
			(month) => month.value === this.enrichmentSpecification.value?.intakeMonth?.value
		);
		if (!selectedMonth) {
			return;
		}

		this.monthDropdown.value.selectedOption = selectedMonth;
	}

	private performValidation(): boolean {
		if (this.enrichmentSpecification.value === null) {
			throw Error('The enrichment specification is not defined');
		}

		this.validationResult.value = new EnrichmentSpecificationValidation().validate(this.enrichmentSpecification.value);
		return this.validationResult.value.isValid();
	}

	private startEditingEnrolment(enrolment: Enrolment): void {
		this.enrolment.value = enrolment;
		this.enrichmentSpecification.value = this.enrolment.value.createEnrichmentSpecification();

		this.generateForm();
		this.openDrawer();

		this.retrieveSuspectedDuplicatesOnDuplicateAlert();
	}

	private transitionToFreshState(): void {
		this.enrolment.value = null;
		this.enrichmentSpecification.value = null;

		this.closeDrawer();
	}

	private generateForm(): void {
		const form = document.getElementById('EditForm');
		const fgForm = new FormGenerator(form as HTMLFormElement);
		fgForm.generate();
	}

	private publishEnrolmentEnrichedEvent(enrolment: Enrolment): void {
		EventBus.getEvent(EnrolmentEnrichedEvent).publish(enrolment);
	}

	private retrieveSuspectedDuplicatesOnDuplicateAlert(): void {
		// Only retrieve suspected duplicates when the selected enrolment is of "duplication suspected" step.
		if (this.enrolment.value === null || !this.stepIsDuplicateSuspected.value) {
			return;
		}

		void EnrolmentService.retrieveSuspectedDuplicates(this.enrolment.value);
	}

	private isLinkAvailable(property: 'studentLinkedIn' | 'studentFacebook'): boolean {
		return (
			(this.enrichmentSpecification.value &&
				this.enrichmentSpecification.value[property] &&
				this.isValidExternalLink(this.enrichmentSpecification.value[property])) === true
		);
	}

	private isValidExternalLink(text: string | undefined): boolean {
		if (!text) {
			return false;
		}

		return /(((https|http):\/\/)|\/\/)[a-zA-Z0-9].*\.[a-zA-Z0-9].*/g.exec(text) !== null;
	}
}
