import { defineComponent } from 'vue';
import OptionalFilterTagsComponent
	from '@/presentation/components/generalizations/optional-filters/optional-filter-tags/optional-filter-tags-class';

export default defineComponent({
	setup: () => {
		const component = new OptionalFilterTagsComponent();

		return {
			relevantOptionalFilters: component.relevantOptionalFilters,
			retrieveTitleOfFilter: component.retrieveTitleOfFilter.bind(component),
			removeFilter: component.removeFilter.bind(component)
		};
	}
});
