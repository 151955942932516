import { Enrolment } from '@/models/enrolment';
import { EnrolmentStatus } from '@/models/enrolment-status';
import EnrolmentService from '@/services/enrolments-service';

import { TransitionTrigger } from './transition-trigger';

export class EnrolmentNotTrustedTrigger extends TransitionTrigger {
	public orderingNumber = 103;

	public constructor(excludedEnrolmentStatuses: EnrolmentStatus[]) {
		super('Not trusted', excludedEnrolmentStatuses);
	}

	public equals(that: TransitionTrigger): boolean {
		return that instanceof EnrolmentNotTrustedTrigger;
	}

	protected async performTransitions(enrolments: Readonly<Enrolment[]>): Promise<void> {
		await EnrolmentService.triggerEnrolmentNotTrusted(enrolments);
	}
}
