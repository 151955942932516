import { EnrolmentReviewState } from './enrolment-review-state';
import { TriggerPayloadPopupConfiguration } from '../trigger-payload-popup-configuration';
import { EnrolmentConfirmationUnsureTrigger } from '@/models/transitions/triggers/enrolment-confirmation-unsure-trigger';
import { EnrolmentNotRecognizedTrigger } from '@/models/transitions/triggers/enrolment-not-recognized-trigger';
import { EnrolmentDeferredTrigger } from '@/models/transitions/triggers/enrolment-deferred-trigger';
import { Currency } from '@/models/currency';
import { Month } from '@/models/month';

export class TriggerPayloadReviewState extends EnrolmentReviewState {
	public display(): void {
		this.review.popups.showTriggerPayloadPopup(this.createPopupConfiguration());
	}

	public requiresAdditionalInput(): boolean {
		// When mass-reviewing, a confirmation screen still needs to be shown.
		return this.review.enrolments.length !== 1;
	}

	public canPerformNext(): boolean {
		return this.tuitionFeeInputAvailableIfApplicable() && this.intakeInputAvailableIfApplicable();
	}

	public next(): void {
		this.review.transitionTo(this.review.createTriggerMassReviewConfirmationState());
	}

	private createPopupConfiguration(): TriggerPayloadPopupConfiguration {
		if (this.review.selectedTrigger instanceof EnrolmentNotRecognizedTrigger) {
			return TriggerPayloadPopupConfiguration.studentNotEnrolledStatus(this.review.enrolments.length);
		}

		if (this.review.selectedTrigger instanceof EnrolmentConfirmationUnsureTrigger) {
			return TriggerPayloadPopupConfiguration.otherStatus(this.review.enrolments.length);
		}

		if (this.review.selectedTrigger instanceof EnrolmentDeferredTrigger) {
			return TriggerPayloadPopupConfiguration.futureIntakeStatus(this.review.enrolments.length);
		}

		return TriggerPayloadPopupConfiguration.studentEnrolledStatus(this.review.enrolments.length);
	}

	private tuitionFeeInputAvailableIfApplicable(): boolean {
		return (
			!this.review.requiresTuitionFee() ||
			(typeof this.review.selectedTriggerPayload.enrichmentSpecification.tuitionFee === 'number' &&
				this.review.selectedTriggerPayload.enrichmentSpecification.tuitionFeeCurrency instanceof Currency)
		);
	}

	private intakeInputAvailableIfApplicable(): boolean {
		return (
			!this.review.requiresIntakeInformation() ||
			(this.review.selectedTriggerPayload.enrichmentSpecification.intakeMonth instanceof Month &&
				typeof this.review.selectedTriggerPayload.enrichmentSpecification.intakeYear === 'number')
		);
	}
}
