import { Enrolment } from '@/models/enrolment';
import { CsvExport } from './csv-export';

export class ClientHistoryCsvExport extends CsvExport {
	public constructor(details: string) {
		super('All enrolments history currently displayed in the table', details);
	}

	protected findExportableEnrolments(enrolments: Enrolment[]): Enrolment[] {
		return enrolments;
	}

	protected determineEmptyExportFileName(): string {
		return 'Empty client history export';
	}

	protected determineExportFileNameFromEnrolment(enrolment: Enrolment): string {
		return `Enrolment history export for ${enrolment.organisationName} ${this.details ? this.details : ''}`;
	}

	protected createHeaderCells(): string[] {
		return [
			'Review status',
			'Student name',
			'Student email address',
			'Degree level',
			'Nationality',
			'Intake year',
			'Intake month',
			'Source',
			'Success fee category',
			'Tuition fee value',
			'Tuition fee currency',
			'Notes'
		];
	}

	protected createEnrolmentLineCells(enrolment: Enrolment): string[] {
		return [
			this.determineReviewStatus(enrolment),
			enrolment.studentName,
			enrolment.studentEmail ? enrolment.studentEmail : '',
			enrolment.programmeLevel ? enrolment.programmeLevel.name : '',
			enrolment.studentNationality ? enrolment.studentNationality.name : '',
			enrolment.intakeYear ? enrolment.intakeYear.toString() : '',
			enrolment.intakeMonth ? enrolment.intakeMonth.name : '',
			enrolment.clientSourceName,
			enrolment.successFeeText,
			enrolment.tuitionFee ? enrolment.tuitionFee.toString() : '',
			enrolment.tuitionFeeCurrency ? enrolment.tuitionFeeCurrency.isoCode : '',
			enrolment.confirmationNotes
		];
	}

	private determineReviewStatus(enrolment: Enrolment): string {
		if (enrolment.isAwaitingReview() || enrolment.isReadyToShareWithClient()) {
			return '';
		}

		return enrolment.status.clientFacingName;
	}
}
