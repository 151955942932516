import { renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("li", {
        onClick: [
            _cache[0] || (_cache[0] = ($event) => (_ctx.emit('customClick'))),
            _cache[1] || (_cache[1] = _withModifiers(() => { }, ["stop"]))
        ],
        "aria-label": "Select option",
        class: _normalizeClass([{ 'is-disabled': _ctx.disabled }, "MultiSelectOption"])
    }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2 /* CLASS */));
}
