import { EnrichmentSpecification } from '../enrichment-specification';
import { ValidationResult } from './validation-result';

export class TuitionFeeValidation {
	constructor(private tuitionFeeRequired: boolean) {}

	public validateInExistingResult(enrichmentSpecification: EnrichmentSpecification, result: ValidationResult): ValidationResult {
		const hasFee = typeof enrichmentSpecification.tuitionFee === 'number';
		const hasCurrency = enrichmentSpecification.tuitionFeeCurrency !== null && enrichmentSpecification.tuitionFeeCurrency !== undefined;

		if (!hasFee && (hasCurrency || this.tuitionFeeRequired)) {
			result.isTuitionFeeValid = false;
		}

		if (!hasCurrency && (hasFee || this.tuitionFeeRequired)) {
			result.isTuitionFeeCurrencyValid = false;
		}

		return result;
	}
}
