import CurrenciesData from './currencies';
import EventBus from '@/event-bus/event-bus';
import { Currency } from '@/models/currency';
import { ICurrency, TCurrencyList } from '@/models/i-currency';
import { CurrenciesAcquiredEvent } from '@/event-bus/currencies-acquired-event';

class CurrencyPresenter {
	public acquireCurrencies(): void {
		const currenciesData = this.retrieveCurrenciesData();
		const currencies = this.instantiateCurrencies(currenciesData);

		EventBus.getEvent(CurrenciesAcquiredEvent).publish(currencies);
	}

	private retrieveCurrenciesData(): TCurrencyList {
		return CurrenciesData;
	}

	private instantiateCurrencies(currenciesData: TCurrencyList): Currency[] {
		return Object.values(currenciesData).map((currency: ICurrency) => Currency.create(currency.code, currency.name));
	}
}

export default new CurrencyPresenter();
