import { computed } from 'vue';
import { DefaultTableSelectorOptions } from '@/models/i-table-selector';
import { Enrolment } from '@/models/enrolment';
import { IOption } from '@studyportals/multiselect/src/interfaces/options.interface';
import { RouteEnums } from '@/models/route-enums';
import TableFilter from '@/presentation/components/generalizations/table-selectors/filters/table-filter';
import TableSelectorOption from '@/presentation/components/generalizations/table-selectors/table-selector-option';
import store from '@/store';

export default class TableFilterNationality extends TableFilter {
	get defaultOptions(): TableSelectorOption[] {
		return [new TableSelectorOption(DefaultTableSelectorOptions.DEFAULT, 'All countries')];
	}

	public countriesAsOptions = computed((): IOption[] => {
		return store.getters.countriesAsOptions();
	});

	private onHistoryPage = computed((): boolean => {
		const router = store.getters.router();
		return router.current === RouteEnums.HISTORY;
	});

	public createOptions(): TableSelectorOption[] {
		return this.countriesAsOptions.value.map((option) => new TableSelectorOption(option.value, option.label));
	}

	public doesEnrolmentMatchFilter(enrolment: Enrolment): boolean {
		const option = this.selectedOptionAsOption.value;
		if (((!option || option.isDefaultOption) && !this.selectedOption.value) || this.onHistoryPage.value) {
			return true;
		}

		if (!enrolment.studentNationality) {
			return false;
		}

		return enrolment.studentNationality.isoCode === this.selectedOption.value.toString();
	}
}
