import { Component, defineComponent, onMounted, onUnmounted, watch } from 'vue';
import VideoStudentImpact from '@/presentation/components/client/video-student-impact/video-student-impact.vue';
import ClientIntroductionModalComponent from '@/presentation/components/client/client-introduction-modal/client-introduction-modal-class';

export default defineComponent({
	components: {
		VideoStudentImpact: VideoStudentImpact as Component
	},
	setup: () => {
		const component = new ClientIntroductionModalComponent();

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		watch(component.dataProcessingAgreementConfirmed, component.onDataProcessingAgreementConfirmedChanged.bind(component));

		return {
			modalContent: component.modalContent,
			studentImpactVideoDisplayed: component.studentImpactVideoDisplayed,
			dataProcessingAgreementConfirmed: component.dataProcessingAgreementConfirmed,
			acceptDataProcessingAgreement: component.acceptDataProcessingAgreement.bind(component),
			acceptAgreementAndConsiderVideoAsDisplayed: component.acceptAgreementAndConsiderVideoAsDisplayed.bind(component)
		};
	}
});
