import { DataStorageLabelsEnum } from '@/models/data-storage-labels-enum';
import { DataStorage } from '@studyportals/data-storage';
import { ColumnApi, ColumnState } from 'ag-grid-community';
import store from '@/store';

export default class SavedColumnsHandler {
	protected checkedForSavedColumns = false;
	private dataStorageLabel = '';

	constructor(
		private columnApi: ColumnApi | null | undefined,
		private isInternal: boolean,
		private isHistory: boolean
	) {
		this.dataStorageLabel = this.isInternal
			? DataStorageLabelsEnum.INTERNAL_REVIEW_TABLE_COLUMNS
			: DataStorageLabelsEnum.CLIENT_REVIEW_TABLE_COLUMNS;
	}

	public saveColumns(isReset = false): void {
		if (this.isHistory || !this.columnApi || !this.checkedForSavedColumns) return;

		store.mutations.updateCustomColumnOrderInUseStatus(!isReset);

		if (isReset) {
			this.columnApi.resetColumnState();
			DataStorage.remove(this.dataStorageLabel);
			return;
		}

		const oneYearInSeconds = 60 * 60 * 24 * 365;

		DataStorage.store(this.dataStorageLabel, this.columnApi.getColumnState() as unknown as string, oneYearInSeconds);
	}

	public applySavedColumnsIfAvailable(): void {
		if (this.isHistory || !this.columnApi) {
			return;
		}

		const savedState = DataStorage.retrieve(this.dataStorageLabel) as string | null;
		const currentState = this.columnApi.getColumnState();
		if (savedState) {
			// Ensure that saved columns are available with the same column count (so that newly added or removed columns aren't overwritten).
			if (savedState.length === currentState.length) {
				this.columnApi.applyColumnState({
					state: savedState as unknown as ColumnState[],
					applyOrder: true
				});
				store.mutations.updateCustomColumnOrderInUseStatus(true);
			} else {
				DataStorage.remove(this.dataStorageLabel);
			}
		}

		this.checkedForSavedColumns = true;
	}
}
