import { LordBusiness } from '@/infrastructure';
import { DataStorage } from '@studyportals/data-storage';
import { AbstractAndReportExceptionAsync } from '@studyportals/mb-platform-exceptions-aop';
import { DataStorageLabelsEnum } from '@/models/data-storage-labels-enum';

class PartnershipService {
	private hundredYearsInSeconds = 3153600000;

	@AbstractAndReportExceptionAsync()
	public async submitReview(organisationId: string): Promise<void> {
		await LordBusiness.batchedSubmitPartnershipBatch(organisationId);
	}

	public signAgreement(): void {
		DataStorage.store(DataStorageLabelsEnum.DATA_PROCESSING_AGREEMENT_SIGNED_LABEL, 'true', this.hundredYearsInSeconds);
	}

	public considerStudentImpactVideoAsDisplayed(): void {
		DataStorage.store(DataStorageLabelsEnum.STUDENT_IMPACT_VIDEO_DISPLAYED_LABEL, 'true', this.hundredYearsInSeconds);
	}
}

export default new PartnershipService();
