import { ref } from 'vue';
import EventBus from '@/event-bus/event-bus';
import { DrawerOpenEvent } from '@/event-bus/drawer-open-event';
import { DrawerCloseEvent } from '@/event-bus/drawer-close-event';

export default class DrawerComponent {
	public isOpen = ref(false);
	private drawerOpenEventSubscriptionToken = ref('');
	private drawerCloseEventSubscriptionToken = ref('');

	public mounted(): void {
		this.drawerOpenEventSubscriptionToken.value = EventBus.getEvent(DrawerOpenEvent).subscribe(() => this.open());
		this.drawerCloseEventSubscriptionToken.value = EventBus.getEvent(DrawerCloseEvent).subscribe(() => this.close());
	}

	public unmounted(): void {
		EventBus.getEvent(DrawerOpenEvent).unsubscribe(this.drawerOpenEventSubscriptionToken.value);
		EventBus.getEvent(DrawerCloseEvent).unsubscribe(this.drawerCloseEventSubscriptionToken.value);
	}

	public close(): void {
		this.isOpen.value = false;
	}

	public open(): void {
		this.isOpen.value = true;
	}

	public stopClickEventPropagation(event: MouseEvent): void {
		event.stopPropagation();
	}
}
