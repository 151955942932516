import { TriggerPayloadConfiguration } from './trigger-payload-configuration';
import { EnrolmentConfirmationUnsureTriggerPayloadValidation } from './validation/enrolment-confirmation-unsure-trigger-payload-validation';
import { TriggerPayloadValidation } from './validation/trigger-payload-validation';

export class EnrolmentConfirmationUnsureTriggerPayloadConfiguration extends TriggerPayloadConfiguration {
	public constructor() {
		super(false, true, false, false);
	}

	public createValidation(): TriggerPayloadValidation {
		return new EnrolmentConfirmationUnsureTriggerPayloadValidation();
	}
}
