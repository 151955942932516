import { ref, Ref } from 'vue';
import store from '@/store';
import { ComplexModal } from '@studyportals/modal';
import { ContractSegmentSectionsEnums } from '@/models/contract-segment-sections-enums';
import { CCDBContractSegment } from '@studyportals/sp-lord-business-interface/src/CCDB/DataObjects/CCDBContractSegment';
import ContractsPresenter from '@/presenters/contracts-presenter';

export default class ContractDetailsModalComponent {
	public modalContent: Ref<HTMLElement | null> = ref(null);
	private modal: Ref<ComplexModal | undefined> = ref(undefined);

	public get contractName(): string {
		return ContractsPresenter.contractName;
	}

	public get annualFee(): string {
		return ContractsPresenter.annualFee;
	}

	public get firstPromotionStartDate(): string {
		return ContractsPresenter.firstPromotionStartDate;
	}

	public get contractEndDate(): string {
		return ContractsPresenter.contractEndDate;
	}

	public get tailPeriodEndDate(): string {
		return ContractsPresenter.tailPeriodEndDate;
	}

	public get segments(): CCDBContractSegment[] {
		return ContractsPresenter.segments;
	}

	public mounted(): void {
		this.prepareModal();
	}

	private prepareModal(): void {
		if (!this.modalContent.value) {
			return;
		}

		this.modal.value = new ComplexModal(this.modalContent.value, {
			cssClassName: 'ContractDetailsModalWrapper',
			destroyOnClose: true,
			onClose: (): void => {
				store.mutations.updateContractDetailsModalShown(false);
			}
		});

		window.ModalManager.open(this.modal.value);
	}

	public isSegmentTypeIncluded(segment: CCDBContractSegment): boolean {
		return ContractsPresenter.isSegmentTypeIncluded(segment);
	}

	public isSegmentProgrammeSectionIrrelevant(segment: CCDBContractSegment): boolean {
		return ContractsPresenter.isSegmentSectionIrrelevant(segment, ContractSegmentSectionsEnums.PROGRAMME);
	}

	public isSegmentStudentSectionIrrelevant(segment: CCDBContractSegment): boolean {
		return ContractsPresenter.isSegmentSectionIrrelevant(segment, ContractSegmentSectionsEnums.STUDENT);
	}

	public isSegmentAttributionSectionIrrelevant(segment: CCDBContractSegment): boolean {
		return ContractsPresenter.isSegmentSectionIrrelevant(segment, ContractSegmentSectionsEnums.ATTRIBUTION);
	}

	public isSegmentPricingSectionIrrelevant(segment: CCDBContractSegment): boolean {
		return ContractsPresenter.isSegmentSectionIrrelevant(segment, ContractSegmentSectionsEnums.PRICING);
	}

	public isSegmentCommentsSectionIrrelevant(segment: CCDBContractSegment): boolean {
		return ContractsPresenter.isSegmentSectionIrrelevant(segment, ContractSegmentSectionsEnums.COMMENTS);
	}

	public retrieveSegmentProgrammeDetails(segment: CCDBContractSegment): string {
		return ContractsPresenter.retrieveSegmentProgrammeDetails(segment);
	}

	public retrieveSegmentStudentDetails(segment: CCDBContractSegment): string {
		return ContractsPresenter.retrieveSegmentStudentDetails(segment);
	}

	public retrieveSegmentAttributionDetails(segment: CCDBContractSegment): string {
		return ContractsPresenter.retrieveSegmentAttributionDetails(segment);
	}

	public retrieveSegmentPriceTypeName(segment: CCDBContractSegment): string {
		return ContractsPresenter.retrieveSegmentPriceTypeName(segment.pricing);
	}

	public retrieveSegmentPricingDetails(segment: CCDBContractSegment): string {
		return ContractsPresenter.retrieveSegmentPricingDetails(segment);
	}

	public retrieveSegmentCommentDetails(segment: CCDBContractSegment): string {
		return ContractsPresenter.retrieveSegmentCommentDetails(segment);
	}

	public calculateNumberOfSegment(segment: CCDBContractSegment): number {
		return ContractsPresenter.calculateNumberOfSegment(segment);
	}
}
