import { defineComponent, onMounted, onUnmounted, watch } from 'vue';
import MoveEnrolmentsToOrganisationComponent
	from '@/presentation/components/internal/move-enrolments-to-organisation/move-enrolments-to-organisation-class';

export default defineComponent({
	setup: (props, context) => {
		const component = new MoveEnrolmentsToOrganisationComponent(context.emit);

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		watch(component.selectedEnrolments, component.onSelectedEnrolmentsChanged.bind(component), { deep: true });
		watch(component.searchBoxInput, component.onSearchBoxInputChanged.bind(component));

		return {
			isCollapsed: component.isCollapsed,
			isChangeOrganisationAvailable: component.isChangeOrganisationAvailable,
			isExpanded: component.isExpanded,
			isConfirmationPromptVisible: component.isConfirmationPromptVisible,
			searchBoxInput: component.searchBoxInput,
			organisation: component.organisation,
			expand: component.expand.bind(component),
			collapse: component.collapse.bind(component),
			resetOrganisation: component.resetOrganisation.bind(component),
			showConfirmationPrompt: component.showConfirmationPrompt.bind(component),
			confirmOrganisationChange: component.confirmOrganisationChange.bind(component),
			cancelOrganisationChange: component.cancelOrganisationChange.bind(component)
		};
	}
});
