import { NoPayReason } from '@/models/no-pay-reason';
import { TriggerPayload } from '@/models/transitions/trigger-payload/trigger-payload';
import { SuccessFee } from '@/models/success-fee';

export class SuccessFeeOption {
	private optionDisabled = false;

	public constructor(
		public readonly name: string,
		public readonly successFee: SuccessFee | null | undefined,
		public readonly noPayReasons: Readonly<NoPayReason[]> = [],
		public readonly requiresAdditionalInput = false,
		public readonly requiresNoPayReasonsInput = false
	) {}

	public get disabled(): boolean {
		return this.optionDisabled;
	}

	public apply(triggerPayload: TriggerPayload): void {
		triggerPayload.successFeeInformation.successFee = this.successFee;
		triggerPayload.successFeeInformation.noPayReasons = [...this.noPayReasons];
	}

	public equals(that: SuccessFeeOption): boolean {
		return this.name === that.name;
	}

	public disable(): void {
		this.optionDisabled = true;
	}
}
