import EventBus from '@/event-bus/event-bus';
import { InvoiceBatchExportAcquiredEvent } from '@/event-bus/invoice-batch-export-acquired-event';
import { LordBusiness } from '@/infrastructure';
import { AbstractAndReportExceptionAsync } from '@studyportals/mb-platform-exceptions-aop';

class InvoiceBatchExportPresenter {
	@AbstractAndReportExceptionAsync()
	public async retrieveInvoiceBatchExport(invoiceBatchIdentity: string): Promise<void> {
		const invoiceBatchExport = await LordBusiness.exportInvoicingBatch(invoiceBatchIdentity);

		EventBus.getEvent(InvoiceBatchExportAcquiredEvent).publish(invoiceBatchExport);
	}
}

export default new InvoiceBatchExportPresenter();
