import { reactive } from 'vue';
import { DataStorage } from '@studyportals/data-storage';

import { IState } from '@/models/store/i-store';
import { ModalStatusLoadingEnums } from '@/models/modal-status-loading-enum';
import { DataStorageLabelsEnum } from '@/models/data-storage-labels-enum';
import { RouteEnums } from '@/models/route-enums';
import { ModalStatusTransitionTriggersEnums } from '@/models/modal-status-transition-triggers-enum';
import { ModalStatusEnrolmentReviewEnums } from '@/models/modal-status-enrolment-review-enum';
import { ModalStatusMassEditEnum } from '@/models/modal-status-mass-edit-enum';
import { CsvSeparatorNames } from '@/models/i-csv-separators';
import { OptionalFilters } from '@/models/optional-filters';
import { FulfilmentProcess } from '@/models/fulfilment-process-model';
import { IntakeMonthGroupOption } from '@/presentation/components/generalizations/enrolments-table-group-selector/selectors/intake-month-group-option';
import { NullPartership } from '@/models/null-partnership';

import highlightedFeatureFlowHandler from '@/services/highlighted-feature-flow-handler';
import RedshiftTracker from '@/infrastructure/redshift-tracker';

const notificationDismissed = DataStorage.retrieve(DataStorageLabelsEnum.WAS_REVIEW_SUBMITTED_NOTIFICATION_DISMISSED_LABEL) as
	| string
	| null;

/* State should match the IState interface, but it's typed as any,
because otherwise the reactive wrapper around it screws up the typing.
Further below the object is returned as an IState instance, so any
usages inside other files should be forced to follow the interface. */
const state: any = reactive({
	clientHistoryShowNonInvoicableEnrolments: false,
	contractDetailsModalShown: false,
	customColumnOrderInUse: false,
	initialLoadingSpeedTracked: false,
	isInitialPageLoad: true,
	isInternal: true,
	loading: false,
	showAvailableFilterFacets: false,
	showIdentifiedEnrolmentsTable: false,
	standAloneLogin: false,
	submitReviewNotificationClosed: false,
	suspectedDuplicateDetailsModalShown: false,
	wasReviewSubmitted: false,
	wasReviewSubmittedNotificationDismissed:
		notificationDismissed !== null && notificationDismissed !== '' && JSON.parse(notificationDismissed) === true,
	internalEnrolmentsSearchQuery: '',
	selectedHistoryGroup: '',
	selectedOrganisation: null,
	selectedPSM: '0',
	latestOrganisationDetailsClusterId: 0,
	countries: [],
	currencies: [],
	enrolments: [],
	filteredEnrolments: [],
	organisationDetailsCluster: {
		listOfDetails: [],
		id: 0
	},
	projectSuccessManagers: [],
	noWorkOrderOrganisationIds: [],
	partnershipEnrolments: [],
	partnerships: [],
	selectedEnrolments: [],
	sortedEnrolmentGroups: [],
	suspectedDuplicateEnrolments: [],
	enrolmentGroups: {},
	flowStatusHighlightedFeature: highlightedFeatureFlowHandler.determineHighlightedFeatureFlowStatus(),
	fulfilmentProcess: new FulfilmentProcess([]),
	historyGroupSelection: new IntakeMonthGroupOption(),
	modalStatusEnrolmentReview: ModalStatusEnrolmentReviewEnums.INACTIVE,
	modalStatusLoading: ModalStatusLoadingEnums.INACTIVE,
	modalStatusMassEdit: ModalStatusMassEditEnum.INACTIVE,
	modalStatusTransitionTriggers: ModalStatusTransitionTriggersEnums.INACTIVE,
	redshiftTracker: new RedshiftTracker(),
	selectedCsvSeparator: CsvSeparatorNames.COMMA,
	selectedOptionalFilter: OptionalFilters.TUITION_FEE,
	selectedPartnership: new NullPartership(),
	router: {
		current: RouteEnums.REVIEW_OVERVIEW,
		props: []
	}
});

export default state as IState;
