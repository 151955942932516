import { computed, ref, Ref } from 'vue';
import { ComplexModal } from '@studyportals/modal';
import { HighlightedFeatureStatusEnum } from '@/models/highlighted-feature-status-enum';
import store from '@/store';

export default class HighlightedFeaturesIntroductionModalComponent {
	public modalContent: Ref<HTMLElement | null> = ref(null);
	private modal: Ref<ComplexModal | undefined> = ref(undefined);
	private relevantFlowStatus = HighlightedFeatureStatusEnum.INTRODUCTION;

	public currentHighlightedFeatureFlowStatus = computed(() => {
		return store.getters.flowStatusHighlightedFeature();
	});

	public mounted(): void {
		this.prepareModal();
		this.updateModalDisplayStatus(this.currentHighlightedFeatureFlowStatus.value, null);
	}

	public updateModalDisplayStatus(newValue: HighlightedFeatureStatusEnum, oldValue: HighlightedFeatureStatusEnum | null): void {
		if (newValue === this.relevantFlowStatus) {
			window.ModalManager.open(this.modal.value);
		} else if (oldValue === this.relevantFlowStatus) {
			window.ModalManager.close(this.modal.value);
		}
	}

	public navigateToFirstHighlightedFeature(): void {
		store.mutations.navigateToOtherStepHighlightedFeaturesFlow(true);
	}

	private prepareModal(): void {
		if (!this.modalContent.value) {
			return;
		}

		this.modal.value = new ComplexModal(this.modalContent.value, {
			cssClassName: 'HighlightedFeaturesIntroductionModalWrapper',
			destroyOnClose: true,
			onClose: this.closeModal.bind(this)
		});
	}

	private closeModal(): void {
		// Don't set the status as inactive if the modal is closed because of the user navigating to the first highlighted feature.
		if (this.currentHighlightedFeatureFlowStatus.value !== this.relevantFlowStatus) {
			return;
		}

		store.mutations.updateFlowStatusHighlightedFeature(HighlightedFeatureStatusEnum.INACTIVE);
	}
}
