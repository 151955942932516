import { computed, ref } from 'vue';
import EventBus from '@/event-bus/event-bus';
import { Enrolment } from '@/models/enrolment';
import { ClientEnrolmentReviewStartedEvent } from '@/event-bus/client-enrolment-review-started-event';
import { EnrolmentReviewStartedEventPayload } from '@/event-bus/enrolment-review-started-event-payload';
import FulfillmentStepIndication from '@/models/fulfillment-step-indication';
import { EnrolmentReviewDoneEvent } from '@/event-bus/enrolment-review-done-event';
import { EnrolmentReviewCanceledEvent } from '@/event-bus/enrolment-review-canceled-event';
import { TransitionFailedEvent } from '@/event-bus/transition-failed-event';
import GrammaticalNumberTextPresenter from '@/presenters/grammatical-number-text-presenter';
import store from '@/store';

export default class MassReviewIncentiveComponent {
	private reviewActive = ref(false);
	private enrolmentReviewStartedSubscriptionToken = ref('');
	private enrolmentReviewDoneEventSubscriptionToken = ref('');
	private enrolmentReviewCanceledEventSubscriptionToken = ref('');
	private enrolmentReviewFailedEventSubscriptionToken = ref('');

	public selectedEnrolments = computed((): Enrolment[] => {
		return store.getters.selectedEnrolments();
	});

	public grammaticalNumberTextPresenter = computed((): GrammaticalNumberTextPresenter => {
		return new GrammaticalNumberTextPresenter(this.selectedEnrolments.value.length);
	});

	public areAllEnrolmentsReviewable = computed((): boolean => {
		// All enrolments that are not under the "Reviewed and submitted" tab can be reviewed.
		const enrolmentNotAwaitingReview = this.selectedEnrolments.value.find(
			(enrolment) =>
				!(
					enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.AWAITING_CONFIRMATION) ||
					enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.PENDING_CONFIRMATION_REVIEW) ||
					(enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.CONFIRMATION_COMPLETED) &&
						!enrolment.isChallengedAndUnconfirmed)
				)
		);
		return enrolmentNotAwaitingReview === undefined;
	});

	public showIncentive = computed((): boolean => {
		return !this.reviewActive.value && this.selectedEnrolments.value.length > 0 && this.areAllEnrolmentsReviewable.value;
	});

	public mounted(): void {
		this.enrolmentReviewStartedSubscriptionToken.value = EventBus.getEvent(ClientEnrolmentReviewStartedEvent).subscribe(
			() => (this.reviewActive.value = true)
		);
		this.enrolmentReviewDoneEventSubscriptionToken.value = EventBus.getEvent(EnrolmentReviewDoneEvent).subscribe(
			() => (this.reviewActive.value = false)
		);
		this.enrolmentReviewCanceledEventSubscriptionToken.value = EventBus.getEvent(EnrolmentReviewCanceledEvent).subscribe(
			() => (this.reviewActive.value = false)
		);
		this.enrolmentReviewFailedEventSubscriptionToken.value = EventBus.getEvent(TransitionFailedEvent).subscribe(
			() => (this.reviewActive.value = false)
		);
	}

	public unmounted(): void {
		EventBus.getEvent(ClientEnrolmentReviewStartedEvent).unsubscribe(this.enrolmentReviewStartedSubscriptionToken.value);
		EventBus.getEvent(EnrolmentReviewDoneEvent).unsubscribe(this.enrolmentReviewDoneEventSubscriptionToken.value);
		EventBus.getEvent(EnrolmentReviewCanceledEvent).unsubscribe(this.enrolmentReviewCanceledEventSubscriptionToken.value);
		EventBus.getEvent(TransitionFailedEvent).unsubscribe(this.enrolmentReviewFailedEventSubscriptionToken.value);
	}

	public startReview(): void {
		EventBus.getEvent(ClientEnrolmentReviewStartedEvent).publish(
			new EnrolmentReviewStartedEventPayload(store.getters.selectedPartnership(), this.selectedEnrolments.value)
		);
	}
}
