import { Enrolment } from '@/models/enrolment';

export class InvoiceBatchCreatedEventPayload {
	public constructor(
		public readonly invoiceBatchIdentity: string,
		public readonly enrolmentsNotPlacedInInvoiceBatch: Enrolment[] = []
	) {}
	public get isPartial(): boolean {
		return this.enrolmentsNotPlacedInInvoiceBatch.length > 0;
	}
}
