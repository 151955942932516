import { Month } from '@/models/month';

class DatePresenter {
	public get months(): readonly Month[] {
		return Month.all();
	}

	public get years(): number[] {
		const newestYear = this.newestIntakeYear;
		const oldestYear = 2016;
		const years: number[] = [];

		for (let year = oldestYear; year <= newestYear; year++) {
			years.push(year);
		}

		return years;
	}

	public get numberOfYearsInPast(): number {
		const date = new Date();
		const year = date.getFullYear();
		return this.years.indexOf(year);
	}

	public get numberOfYearsInFuture(): number {
		const date = new Date();
		const year = date.getFullYear();
		const indexOfCurrentYear = this.years.indexOf(year);
		const indexOfNewestYear = this.years.indexOf(this.newestIntakeYear);
		return indexOfNewestYear - indexOfCurrentYear;
	}

	private get newestIntakeYear(): number {
		const date = new Date();
		const year = date.getFullYear();

		// From September onwards, the next year is included.
		return date.getMonth() > 7 ? year + 1 : year;
	}
}

export default new DatePresenter();
