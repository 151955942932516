export class Currency {
	private static currenciesByIsoCode = new Map<string, Currency>();

	private currencyName: string;

	private constructor(
		public readonly isoCode: string,
		name = ''
	) {
		this.currencyName = name;
	}

	public static create(isoCode: string, name: string): Currency {
		const currency = this.obtain(isoCode);
		currency.changeName(name);

		return currency;
	}

	public static obtain(isoCode: string): Currency {
		if (!this.currenciesByIsoCode.has(isoCode.toLowerCase())) {
			this.currenciesByIsoCode.set(isoCode.toLowerCase(), new Currency(isoCode));
		}

		return this.currenciesByIsoCode.get(isoCode.toLowerCase()) as Currency;
	}

	public get name(): string {
		return this.currencyName;
	}

	public get nameWithIsoCode(): string {
		return `${this.currencyName} (${this.isoCode})`;
	}

	private changeName(name: string): void {
		this.currencyName = name;
	}
}
