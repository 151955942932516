import { Component } from 'vue';
import { EnrolmentReview } from '@/models/enrolment-review/enrolment-review';
import { MultiSelect } from '@studyportals/multiselect';
import EnrolmentReviewPopupButtons
	from '@/presentation/components/generalizations/enrolment-review-form/enrolment-review-popup-buttons.vue';

import { defineComponent, onMounted, onUnmounted, watch } from 'vue';
import EnrolmentReviewModalComponent from '@/presentation/components/generalizations/enrolment-review-modal/enrolment-review-modal-class';

export default defineComponent({
	props: {
		enrolmentReview: Object
	},
	components: {
		EnrolmentReviewPopupButtons: EnrolmentReviewPopupButtons as Component,
		MultiSelect
	},
	setup: (props) => {
		const component = new EnrolmentReviewModalComponent(props.enrolmentReview as EnrolmentReview);

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		watch(component.modalStatusEnrolmentReview, component.prefillDropdownsForLatestFlowStep.bind(component));

		return {
			modalContent: component.modalContent,
			nationalityDropdown: component.nationalityDropdown,
			levelDropdown: component.levelDropdown,
			currencyDropdown: component.currencyDropdown,
			monthDropdown: component.monthDropdown,
			monthsAsOptions: component.monthsAsOptions,
			programmeLevelsAsOptions: component.programmeLevelsAsOptions,
			countriesAsOptions: component.countriesAsOptions,
			currenciesAsOptions: component.currenciesAsOptions,
			massReviewDisclaimerIsActive: component.massReviewDisclaimerIsActive,
			tuitionFeeSkipReviewIsActive: component.tuitionFeeSkipReviewIsActive,
			triggerSelectionReviewIsActive: component.triggerSelectionReviewIsActive,
			enrolmentHasNote: component.enrolmentHasNote,
			grammaticalNumberTextPresenter: component.grammaticalNumberTextPresenter,
			confirmationChallengeNote: component.confirmationChallengeNote,
			successFeeSelectionReviewIsActive: component.successFeeSelectionReviewIsActive,
			additionalNoPayReasonSelectionReviewIsActive: component.additionalNoPayReasonSelectionReviewIsActive,
			triggerPayloadReviewFormIsActive: component.triggerPayloadReviewFormIsActive,
			triggerPayloadPopupConfiguration: component.triggerPayloadPopupConfiguration,
			massReviewConfirmationIsActive: component.massReviewConfirmationIsActive,
			selectedTriggerIsOtherSpecifiedStatus: component.selectedTriggerIsOtherSpecifiedStatus,
			areNoEnrolmentsAlreadyReviewed: component.areNoEnrolmentsAlreadyReviewed,
			massReviewTransitionsFailed: component.massReviewTransitionsFailed,
			isInternalReview: component.isInternalReview,
			storeLastUsedCurrency: component.storeLastUsedCurrency.bind(component),
			setStudentNationality: component.setStudentNationality.bind(component),
			setProgrammeLevel: component.setProgrammeLevel.bind(component),
			setTuitionFeeCurrency: component.setTuitionFeeCurrency.bind(component),
			setIntakeMonth: component.setIntakeMonth.bind(component)
		};
	}
});
