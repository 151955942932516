import { Enrolment } from '@/models/enrolment';
import { ISuspectedDuplicateTableColumn } from '@/models/i-suspected-duplicate-table-column';
import FulfillmentStepIndication from '@/models/fulfillment-step-indication';
import { TRowData } from '@/models/i-table';
import { ColDef } from 'ag-grid-community';

export default class SuspectedDuplicateTableFieldsAssembler {
	private static get rowHeadings(): string[] {
		return [
			'Location',
			'Status',
			'Reason',
			'Invoice batch ID',
			'Identification source',
			'Email',
			'Student name',
			'University name',
			'Programme name',
			'Tuition fee',
			'Intake year',
			'Intake month',
			'Duplication note'
		];
	}

	private static get rowPropertyRetrievalMethods(): ((enrolment: Enrolment) => string)[] {
		const defaultContent = '–';

		return [
			(enrolment): string =>
				enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.HISTORY) ? 'History' : 'Identified enrolments',
			(enrolment): string => (enrolment.status && enrolment.status.name ? enrolment.status.name : defaultContent),
			(enrolment): string => (enrolment.reason ? enrolment.reason : defaultContent),
			(enrolment): string => (enrolment.invoicingBatchIdentity ? enrolment.invoicingBatchIdentity : defaultContent),
			(enrolment): string => (enrolment.internalSourceName ? enrolment.internalSourceName : defaultContent),
			(enrolment): string => (enrolment.studentEmail ? enrolment.studentEmail : defaultContent),
			(enrolment): string => (enrolment.studentName ? enrolment.studentName : defaultContent),
			(enrolment): string => (enrolment.organisationName ? enrolment.organisationName : defaultContent),
			(enrolment): string => (enrolment.programmeName ? enrolment.programmeName : defaultContent),
			(enrolment): string =>
				enrolment.tuitionFee && enrolment.tuitionFeeCurrency
					? `${enrolment.tuitionFee} ${enrolment.tuitionFeeCurrency.isoCode}`
					: defaultContent,
			(enrolment): string => (enrolment.intakeYear ? enrolment.intakeYear.toString() : defaultContent),
			(enrolment): string => (enrolment.intakeMonth ? enrolment.intakeMonth.name : defaultContent),
			(enrolment): string => (enrolment.duplicationNote ? enrolment.duplicationNote : defaultContent)
		];
	}

	public static getColumnData(enrolments: Enrolment[]): ColDef[] {
		// Column number used as "field" property to link the column to the right row properties.
		let columnNumber = (0).toString();
		// Heading shown at the top of the column.
		let columnHeading = SuspectedDuplicateTableFieldsAssembler.getRowHeaderForFirstColumn(0);
		// Array used to store data about each column.
		const columns: ISuspectedDuplicateTableColumn[] = [];

		// Start with the first column (meant for headings for each row).
		columns.push({
			headerName: columnHeading ? columnHeading : '',
			field: columnNumber,
			tooltipField: columnNumber,
			width: 160,
			lockPosition: true,
			pinned: 'left'
		});

		// Add a column for each enrolment.
		enrolments.forEach((enrolment, index) => {
			// Since the first column is reserved for row headings, start counting from 1 instead of 0.
			columnNumber = (index + 1).toString();
			columnHeading = SuspectedDuplicateTableFieldsAssembler.getPropertyForEnrolmentInRow(0, enrolment);

			const newColumn: ISuspectedDuplicateTableColumn = {
				headerName: columnHeading ? columnHeading : '',
				field: columnNumber,
				tooltipField: columnNumber,
				width: 256
			};

			// The first enrolment in the list is always the enrolment that was selected to get to this overview.
			if (index === 0) {
				newColumn.headerName += ' (selected)';
				newColumn.lockPosition = true;
				newColumn.pinned = 'left';
			}

			columns.push(newColumn);
		});

		return columns as ColDef[];
	}

	public static getRowData(enrolments: Enrolment[]): TRowData[] {
		const rows: TRowData[] = [];

		// Iterate over the rows, and start with an index of 1, since the first row is already filled with column headings.
		for (let rowNumber = 1; rowNumber < SuspectedDuplicateTableFieldsAssembler.rowHeadings.length; rowNumber++) {
			const newRowItem: TRowData = {};

			// Column number used to link the row items to the right column by using the same value as the "field" property of column data.
			let columnNumber = (0).toString();
			// Start with the first column property for this row (meant for row headings).
			newRowItem[columnNumber] = SuspectedDuplicateTableFieldsAssembler.getRowHeaderForFirstColumn(rowNumber);

			enrolments.forEach((enrolment, index) => {
				// Since the first column is reserved for row headings, add 1 to the enrolment index.
				columnNumber = (index + 1).toString();
				newRowItem[columnNumber] = SuspectedDuplicateTableFieldsAssembler.getPropertyForEnrolmentInRow(rowNumber, enrolment);
			});

			rows.push(newRowItem);
		}

		return rows;
	}

	private static getRowHeaderForFirstColumn(rowNumber: number): string | undefined {
		// Stay within the boundaries of row heading data.
		if (rowNumber < 0 || rowNumber >= SuspectedDuplicateTableFieldsAssembler.rowHeadings.length) {
			return;
		}

		return this.rowHeadings[rowNumber];
	}

	private static getPropertyForEnrolmentInRow(rowNumber: number, enrolment: Enrolment): string | undefined {
		// Stay within the boundaries of row property retrieval data.
		if (rowNumber < 0 || rowNumber >= this.rowPropertyRetrievalMethods.length) {
			return;
		}

		return SuspectedDuplicateTableFieldsAssembler.rowPropertyRetrievalMethods[rowNumber](enrolment);
	}
}
