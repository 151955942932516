import RedshiftTracker from '@/infrastructure/redshift-tracker';
import store from '@/store';

export default class ClientQuickGuideComponent {
	private get redshiftTracker(): RedshiftTracker {
		return store.state.redshiftTracker;
	}

	public mounted(): void {
		this.redshiftTracker.trackInitialLoadingSpeed(false);
	}
}
