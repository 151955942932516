import { computed } from 'vue';
import { Partnership } from '@/models/partnership';
import { PartnershipSelectedEvent } from '@/event-bus/partnership-selected-event';
import EventBus from '@/event-bus/event-bus';
import store from '@/store';

export default class PartnershipSelectorOptionComponent {
	constructor(
		private emit: (name: string) => void,
		public partnership: Partnership
	) {}

	public isCurrentPartnership = computed((): boolean => {
		return this.partnership.equals(store.getters.selectedPartnership());
	});

	public changePartnership(): void {
		EventBus.getEvent(PartnershipSelectedEvent).publish(this.partnership);

		this.emit('partnershipSelected');
		this.filterEnrolments();
	}

	private filterEnrolments(): void {
		store.actions.updateFilteredEnrolments();
	}
}
