import { defineComponent } from 'vue';
import TabMenuComponent from '@/presentation/components/client/tab-menu/tab-menu-class';

export default defineComponent({
	setup: () => {
		const component = new TabMenuComponent();

		return {
			reviewingPageSelected: component.reviewingPageSelected,
			historyPageSelected: component.historyPageSelected,
			quickGuidePageSelected: component.quickGuidePageSelected,
			navigateToReviewingPage: component.navigateToReviewingPage.bind(component),
			navigateToHistoryPage: component.navigateToHistoryPage.bind(component),
			navigateToQuickGuidePage: component.navigateToQuickGuidePage.bind(component)
		};
	}
});
