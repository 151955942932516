import { GroupSelector } from '@/presentation/components/generalizations/enrolments-table-group-selector/selectors/group-selector';
import { GroupOption } from '@/presentation/components/generalizations/enrolments-table-group-selector/selectors/group-option';
import { IntakeMonthGroupOption }
	from '@/presentation/components/generalizations/enrolments-table-group-selector/selectors/intake-month-group-option';
import { ReviewDateGroupOption }
	from '@/presentation/components/generalizations/enrolments-table-group-selector/selectors/review-date-group-option';

export class HistoryClientGroupSelector extends GroupSelector {
	private intakeMonthOption: GroupOption;
	private reviewDateOption: GroupOption;

	constructor() {
		super();

		this.intakeMonthOption = this.addAndGetOption(IntakeMonthGroupOption);
		this.reviewDateOption = this.addAndGetOption(ReviewDateGroupOption);
	}

	public get defaultOption(): GroupOption {
		return this.intakeMonthOption;
	}

	public get reviewDateGroupOption(): GroupOption {
		return this.reviewDateOption;
	}
}
