import EventBus from '@/event-bus/event-bus';
import { FulfilmentProcessAcquiredEvent } from '@/event-bus/fulfilment-process-acquired-event';
import { LordBusiness } from '@/infrastructure';
import { FulfillmentStep } from '@/models/fulfillment-step';
import { FulfilmentProcess } from '@/models/fulfilment-process-model';
import FulfillmentStepFlyweightFactory from '@/models/presentation-fulfillment-step-flyweight-factory';
import TriggerFactory from '@/models/transitions/triggers/trigger-factory';
import { AbstractAndReportExceptionAsync } from '@studyportals/mb-platform-exceptions-aop';

class FulfilmentProcessPresenter {
	@AbstractAndReportExceptionAsync()
	public async acquireFulfilmentProcessForCurrentUser(): Promise<void> {
		const description = await LordBusiness.describeFulfillmentProcess();
		const steps = new Set<FulfillmentStep>();

		for (const transitionDescription of description) {
			const source = FulfillmentStepFlyweightFactory.obtain(transitionDescription.source);
			const target = FulfillmentStepFlyweightFactory.obtain(transitionDescription.target);

			const trigger = TriggerFactory.create(transitionDescription);
			source.registerTrigger(trigger);

			steps.add(source);
			steps.add(target);
		}

		EventBus.getEvent(FulfilmentProcessAcquiredEvent).publish(new FulfilmentProcess(Array.from(steps)));
	}
}

export default new FulfilmentProcessPresenter();
