import { ValidationResult } from '../../../validation/validation-result';
import { TriggerPayload } from '../trigger-payload';
import { IntakeInformationValidation } from './intake-information-validation';
import { TriggerPayloadValidation } from './trigger-payload-validation';

export class EnrolmentDeferredTriggerPayloadValidation extends TriggerPayloadValidation {
	public validateInExistingResult(payload: TriggerPayload, result: ValidationResult): ValidationResult {
		new IntakeInformationValidation().validateInExistingResult(payload, result);

		return result;
	}
}
