import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';
import { computed } from 'vue';
import { Enrolment } from '@/models/enrolment';

export class CountryIcon implements ICellRendererComp {
	private outerWrapper: HTMLSpanElement;
	private errorListener: () => void;
	private image: HTMLImageElement | undefined;
	private params: ICellRendererParams | null = null;

	private enrolment = computed((): Enrolment | undefined => {
		if (!this.params?.data) {
			return;
		}

		return this.params.data as Enrolment;
	});

	constructor() {
		this.outerWrapper = document.createElement('span');
		this.errorListener = this.addTextElement.bind(this);
	}

	public init(params: ICellRendererParams): void {
		this.params = params;

		const isoCode = this.enrolment.value?.studentNationality?.isoCode;
		if (isoCode) {
			const url = `https://monet-prtl-co.imgix.net/Shared/CountryTest/flags/${isoCode.toLowerCase()}.svg`;
			this.addImageElement(url);
		} else {
			this.addTextElement();
		}
	}

	public getGui(): HTMLSpanElement {
		return this.outerWrapper;
	}

	public refresh(): boolean {
		return false;
	}

	public destroy(): void {
		if (!this.image) {
			return;
		}

		this.image.removeEventListener('error', this.errorListener);
	}

	private addImageElement(url: string): void {
		this.image = document.createElement('img');
		this.image.className = 'CountryIcon';
		this.image.src = url;
		this.image.addEventListener('error', this.errorListener);
		this.outerWrapper.innerHTML = '';
		this.outerWrapper.appendChild(this.image);
	}

	private addTextElement(): void {
		const paragraph = document.createElement('p');
		paragraph.className = 'CountryText';
		paragraph.innerHTML = this.enrolment.value?.studentNationality?.name || '';
		this.outerWrapper.innerHTML = '';
		this.outerWrapper.appendChild(paragraph);
	}
}
