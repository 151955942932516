import { defineComponent, onMounted, onUnmounted } from 'vue';
import { MultiSelect } from '@studyportals/multiselect';
import MassEditModalComponent from '@/presentation/components/generalizations/mass-edit-modal/mass-edit-modal-class';

export default defineComponent({
	components: {
		MultiSelect
	},
	setup: () => {
		const component = new MassEditModalComponent();

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		return {
			modalContent: component.modalContent,
			currencyDropdown: component.currencyDropdown,
			monthDropdown: component.monthDropdown,
			nationalityDropdown: component.nationalityDropdown,
			residenceDropdown: component.residenceDropdown,
			programmeLevelDropdown: component.programmeLevelDropdown,
			tuitionFeeInput: component.tuitionFeeInput,
			intakeYearInput: component.intakeYearInput,
			programmeNameInput: component.programmeNameInput,
			massEditModifyBlock: component.massEditModifyBlock,
			isCurrencyDropdownActive: component.isCurrencyDropdownActive,
			isMonthDropdownActive: component.isMonthDropdownActive,
			isNationalityDropdownActive: component.isNationalityDropdownActive,
			isResidenceDropdownActive: component.isResidenceDropdownActive,
			isProgrammeLevelDropdownActive: component.isProgrammeLevelDropdownActive,
			modalStatusIsPropertySelection: component.modalStatusIsPropertySelection,
			modalStatusIsPropertyModification: component.modalStatusIsPropertyModification,
			currenciesAsOptions: component.currenciesAsOptions,
			monthsAsOptions: component.monthsAsOptions,
			countriesAsOptions: component.countriesAsOptions,
			programmeLevelsAsOptions: component.programmeLevelsAsOptions,
			selectedEnrolments: component.selectedEnrolments,
			massEditablePropertiesCluster: component.massEditablePropertiesCluster,
			showMassEditSelectionMessage: component.showMassEditSelectionMessage,
			relevantMassEditProperties: component.relevantMassEditProperties,
			isInternal: component.isInternal,
			grammaticalNumberTextPresenter: component.grammaticalNumberTextPresenter,
			massEditableKeys: component.massEditableKeys,
			massEditableKeyTuitionFeeCurrency: component.massEditableKeyTuitionFeeCurrency,
			massEditableKeyTuitionFee: component.massEditableKeyTuitionFee,
			massEditableKeyIntakeYear: component.massEditableKeyIntakeYear,
			massEditableKeyIntakeMonth: component.massEditableKeyIntakeMonth,
			massEditableKeyStudentNationality: component.massEditableKeyStudentNationality,
			massEditableKeyCountryOfResidence: component.massEditableKeyCountryOfResidence,
			massEditableKeyProgrammeName: component.massEditableKeyProgrammeName,
			massEditableKeyProgrammeLevel: component.massEditableKeyProgrammeLevel,
			toggleMassEditableProperty: component.toggleMassEditableProperty.bind(component),
			startModifyingSelectedProperties: component.startModifyingSelectedProperties.bind(component),
			goBackToPropertySelection: component.goBackToPropertySelection.bind(component),
			shouldPropertyBeAvailableForModification: component.shouldPropertyBeAvailableForModification.bind(component),
			selectCurrency: component.selectCurrency.bind(component),
			selectMonth: component.selectMonth.bind(component),
			selectNationality: component.selectNationality.bind(component),
			selectResidence: component.selectResidence.bind(component),
			selectTuitionFee: component.selectTuitionFee.bind(component),
			selectIntakeYear: component.selectIntakeYear.bind(component),
			selectProgrammeName: component.selectProgrammeName.bind(component),
			selectProgrammeLevel: component.selectProgrammeLevel.bind(component),
			saveSpecifiedValues: component.saveSpecifiedValues.bind(component)
		};
	}
});
