import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "InternalEnrolmentsExport" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EnrolmentExport = _resolveComponent("EnrolmentExport")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_EnrolmentExport, {
      enrolments: _ctx.filteredEnrolments,
      exportOptions: _ctx.csvExportOptions,
      canExport: _ctx.canExport
    }, null, 8 /* PROPS */, ["enrolments", "exportOptions", "canExport"])
  ]))
}