import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["src", "width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "QuickGuidePreview",
    style: _normalizeStyle(`max-height: ${_ctx.previewHeight}px`)
  }, [
    (_ctx.previewUrl)
      ? (_openBlock(), _createElementBlock("iframe", {
          key: 0,
          src: _ctx.previewUrl,
          width: `${_ctx.previewWidth}px`,
          height: `${_ctx.previewHeight}px`
        }, null, 8 /* PROPS */, _hoisted_1))
      : _createCommentVNode("v-if", true)
  ], 4 /* STYLE */))
}