export default class GrammaticalNumberTextPresenter {
	constructor(private amount: number) {}

	public get amountOfEnrolmentsDisplayText(): string {
		return `${this.amount} ${this.enrolmentsDisplayText}`;
	}

	public get amountOfSelectedEnrolmentsDisplayText(): string {
		return `${this.amount} selected ${this.enrolmentsDisplayText}`;
	}

	public get theseEnrolmentsDisplayText(): string {
		return `${this.theseDisplayText} ${this.enrolmentsDisplayText}`;
	}

	public get theseAmountOfEnrolmentsDisplayText(): string {
		return `${this.theseDisplayText} ${this.amount} ${this.enrolmentsDisplayText}`;
	}

	public get theseStudentsDisplayText(): string {
		return `${this.theseDisplayText} ${this.studentsDisplayText}`;
	}

	public get enrolmentsPossessionDisplayText(): string {
		return `Enrolment${this.amountIsPlural ? "s'" : ''}`;
	}

	public get enrolmentsDisplayText(): string {
		return `enrolment${this.amountIsPlural ? 's' : ''}`;
	}

	public get studentsDisplayText(): string {
		return `student${this.amountIsPlural ? 's' : ''}`;
	}

	public get dontDisplayText(): string {
		return this.amountIsPlural ? "doesn't" : "don't";
	}

	private get theseDisplayText(): string {
		return this.amountIsPlural ? 'these' : 'this';
	}

	private get amountIsPlural(): boolean {
		return this.amount !== 1;
	}
}
