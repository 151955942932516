import { Enrolment } from '@/models/enrolment';
import { EnrolmentStatus } from '@/models/enrolment-status';
import EnrolmentService from '@/services/enrolments-service';

import { TransitionTrigger } from './transition-trigger';

export class EnrolmentNotForInvoicingTrigger extends TransitionTrigger {
	public orderingNumber = 102;

	public constructor(excludedEnrolmentStatuses: EnrolmentStatus[]) {
		super('Not for invoicing', excludedEnrolmentStatuses);
	}

	public equals(that: TransitionTrigger): boolean {
		return that instanceof EnrolmentNotForInvoicingTrigger;
	}

	protected async performTransitions(enrolments: Readonly<Enrolment[]>): Promise<void> {
		await EnrolmentService.triggerEnrolmentNotForInvoicing(enrolments);
	}
}
