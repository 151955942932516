import { Enrolment } from '@/models/enrolment';
import { EnrolmentStatus } from '@/models/enrolment-status';
import EnrolmentService from '@/services/enrolments-service';

import { TriggerPayload } from '../trigger-payload/trigger-payload';
import { TransitionTrigger } from './transition-trigger';

export class EnrolmentDuplicationConfirmedTrigger extends TransitionTrigger {
	public orderingNumber = 21;

	public constructor(excludedEnrolmentStatuses: EnrolmentStatus[]) {
		super('Duplicated', excludedEnrolmentStatuses);
	}

	public equals(that: TransitionTrigger): boolean {
		return that instanceof EnrolmentDuplicationConfirmedTrigger;
	}

	protected async performTransitions(enrolments: Readonly<Enrolment[]>, payload: TriggerPayload): Promise<void> {
		const note = payload.note ? payload.note : '';
		await EnrolmentService.triggerEnrolmentDuplicationConfirmed(enrolments, note);
	}
}
