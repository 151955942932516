import { EnrolmentConfirmedTrigger } from '../transitions/triggers/enrolment-confirmed-trigger';
import { IEnrolmentReviewFlowPrefill } from './i-enrolment-review-flow-prefill';
import { IPrefillableReview } from './i-prefillable-review';
import { SuccessFeeOption } from './success-fee-options/success-fee-option';
import { TransitionTrigger } from '@/models/transitions/triggers/transition-trigger';

export class DefaultReviewFlowPrefill implements IEnrolmentReviewFlowPrefill {
	public constructor(public readonly target: IPrefillableReview) {}

	public prefill(): void {
		this.target.changeTrigger(this.findEnrolmentConfirmedTrigger());
		this.target.changeSelectedSuccessFeeOption(this.findRegularSuccessFeeOption());
	}

	protected findRegularSuccessFeeOption(): SuccessFeeOption {
		if (this.target.availableSuccessFeeOptions.length === 0) {
			throw new Error('No success fee options available');
		}

		const regularSuccessFee = this.target.availableSuccessFeeOptions.find((_) => !!_.successFee && _.successFee.isRegularFee());

		if (undefined === regularSuccessFee) {
			throw new Error('The regular success fee option is not available');
		}

		return regularSuccessFee;
	}

	private findEnrolmentConfirmedTrigger(): TransitionTrigger {
		const enrolmentConfirmedTrigger = this.target.availableTriggers.find((_) => _ instanceof EnrolmentConfirmedTrigger);

		if (enrolmentConfirmedTrigger === undefined) {
			throw new Error('The enrolment confirmed trigger is not available');
		}

		return enrolmentConfirmedTrigger;
	}
}
