enum DataStorageLabelsEnum {
	DATA_PROCESSING_AGREEMENT_SIGNED_LABEL = 'AgreementSigned',
	STUDENT_IMPACT_VIDEO_DISPLAYED_LABEL = 'StudentImpactVideoDisplayed',
	WAS_REVIEW_SUBMITTED_NOTIFICATION_DISMISSED_LABEL = 'ReviewSubmittedNotificationDismissed',
	NO_MASS_REVIEW_DISCLAIMER_STORAGE_LABEL = 'NoMassReviewDisclaimer',
	INTERNAL_REVIEW_TABLE_COLUMNS = 'ERT/internalReviewTableColumns',
	CLIENT_REVIEW_TABLE_COLUMNS = 'ERT/clientReviewTableColumns',
	LATEST_VIEWED_HIGHLIGHTED_FEATURE_LABEL = 'ERT/LatestViewedHighlightedFeature',
	EASTER_EGG_CLIPPY = 'easteregg-clippy'
}

export { DataStorageLabelsEnum };
