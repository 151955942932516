import { DefaultTableSelectorOptions } from '@/models/i-table-selector';

export default class TableSelectorOption {
	public get label(): string {
		let displayedText = this.title;
		if (typeof this.facet === 'number') {
			displayedText += ` (${this.facet})`;
		}

		return displayedText;
	}

	public get isDefaultOption(): boolean {
		return this.value === DefaultTableSelectorOptions.DEFAULT;
	}

	public get isEmptyOption(): boolean {
		return this.value === DefaultTableSelectorOptions.EMPTY;
	}

	public get isRemainingOption(): boolean {
		return this.value === DefaultTableSelectorOptions.REMAINING;
	}

	public get isOptionWithCorrespondingEnrolments(): boolean {
		return this.facet !== 0;
	}

	public get isOptionWithDisplayableName(): boolean {
		return this.title.length !== 0;
	}

	constructor(
		public value: string | number,
		public title: string,
		public facet?: number
	) {}
}
