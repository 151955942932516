import { HighlightedFeatureStatusEnum } from '@/models/highlighted-feature-status-enum';

export default class SearchBarWrapperComponent {
	public highlightedFeatureTitle = 'Finding enrolments with search';
	public highlightedFeatureContent = `Swiftly identify and review enrolments by typing in the
		corresponding student name or email address. Type the desired name or email here for a faster,
		more seamless enrolment review process.`;

	public get highlightedFeatureNameSearch(): string {
		return HighlightedFeatureStatusEnum.SEARCH;
	}
}
