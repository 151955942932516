import { computed, watch } from 'vue';
import { Enrolment } from '@/models/enrolment';
import TableSelectorDynamicOptions
	from '@/presentation/components/generalizations/table-selectors/selectors/table-selector-dynamic-options';
import tableSelectorRetriever from '@/presentation/components/generalizations/table-selectors/table-selector-retriever';
import enrolmentFilter from '@/presentation/components/generalizations/table-selectors/filters/enrolment-filter';
import store from '@/store';

export default abstract class TableFilter extends TableSelectorDynamicOptions {
	protected enrolmentsFilteredByOtherFilters = computed((): Enrolment[] => {
		const enrolments = enrolmentFilter.filteredEnrolments.value;
		const allOtherFilters = tableSelectorRetriever.allFilters.filter((filter) => filter !== this);

		// Look at enrolments that are only filtered by the status filter (the selected step filter shouldn't influence the options).
		return enrolments.filter((enrolment) => {
			return allOtherFilters.find((filter) => !filter.doesEnrolmentMatchFilter(enrolment)) === undefined;
		});
	});

	constructor(optionsWithoutEnrolmentsCanBeShown = false) {
		super(optionsWithoutEnrolmentsCanBeShown);

		watch(this.selectedOption, this.filterEnrolments.bind(this));
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public doesEnrolmentMatchFilter(enrolment: Enrolment): boolean {
		return true;
	}

	private filterEnrolments(): void {
		store.actions.updateFilteredEnrolments();
	}
}
