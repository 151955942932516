import { RouteEnums } from '@/models/route-enums';
import store from '@/store';

class InitialRoutePresenter {
	private get isInternal(): boolean {
		return store.state.isInternal;
	}

	private get defaultRoute(): RouteEnums {
		return this.isInternal ? RouteEnums.IDENTIFIED_ENROLMENTS : RouteEnums.REVIEW_OVERVIEW;
	}

	private get routeToShow(): RouteEnums {
		const initialRouteParts = window.location.href.split('#');
		if (initialRouteParts.length < 2) {
			// Path is empty; probably we're on the home route already, so bail out.
			return this.defaultRoute;
		}

		const initialRoute = initialRouteParts[1].replace('/', '');
		const allRoutes: string[] = Object.values(RouteEnums);
		if (!allRoutes.includes(initialRoute)) {
			// Invalid route name, so bail out.
			return this.defaultRoute;
		}

		return initialRoute as RouteEnums;
	}

	public prepareForRoutingActions(): void {
		// Ensure that initially, the right component is shown.
		this.showComponentInLineWithCurrentRoute();
		// Ensure that, when navigation is triggered outside of the microservice, the right component is shown.
		this.respondToExternalRouteChanges();
	}

	private showComponentInLineWithCurrentRoute(): void {
		store.mutations.setCurrentRoute(this.routeToShow);
	}

	private respondToExternalRouteChanges(): void {
		addEventListener('hashchange', this.showComponentInLineWithCurrentRoute.bind(this));
	}
}

export default new InitialRoutePresenter();
