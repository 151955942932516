import { EnrolmentStatus } from '@/models/enrolment-status';
import FulfillmentStepIndication from '@/models/fulfillment-step-indication';
import { FulfillmentTransitionTrigger, IFulfillmentTransitionDescription } from '@studyportals/sp-lord-business-interface';

import { EnrolmentAlreadyProcessedTrigger } from './enrolment-already-processed-trigger';
import { EnrolmentConfirmationAcceptedTrigger } from './enrolment-confirmation-accepted-trigger';
import { EnrolmentConfirmationChallengedTrigger } from './enrolment-confirmation-challenged-trigger';
import { EnrolmentConfirmationRequestedTrigger } from './enrolment-confirmation-requested-trigger';
import { EnrolmentConfirmationUnsureTrigger } from './enrolment-confirmation-unsure-trigger';
import { EnrolmentConfirmedTrigger } from './enrolment-confirmed-trigger';
import { EnrolmentDeferredTrigger } from './enrolment-deferred-trigger';
import { EnrolmentDisqualifiedTrigger } from './enrolment-disqualified-trigger';
import { EnrolmentDuplicationConfirmedTrigger } from './enrolment-duplication-confirmed-trigger';
import { EnrolmentInProcessingRoundScopeTrigger } from './enrolment-in-processing-round-scope-trigger';
import { EnrolmentInformationMissingTrigger } from './enrolment-information-missing-trigger';
import { EnrolmentNotDuplicatedTrigger } from './enrolment-not-duplicated-trigger';
import { EnrolmentNotForInvoicingTrigger } from './enrolment-not-for-invoicing-trigger';
import { EnrolmentNotRecognizedTrigger } from './enrolment-not-recognized-trigger';
import { EnrolmentNotTrustedTrigger } from './enrolment-not-trusted-trigger';
import { EnrolmentNotYetInvoiceableTrigger } from './enrolment-not-yet-invoiceable-trigger';
import { EnrolmentPlacedInInvoicingBatchTrigger } from './enrolment-placed-in-invoicing-batch-trigger';
import { EnrolmentPreconfirmedTrigger } from './enrolment-preconfirmed-trigger';
import { EnrolmentQualifiedTrigger } from './enrolment-qualified-trigger';
import { EnrolmentReadyForConfirmationTrigger } from './enrolment-ready-for-confirmation-trigger';
import { EnrolmentRevisedTrigger } from '@/models/transitions/triggers/enrolment-revised-trigger';
import { NullTrigger } from './null-trigger';
import { StudentNotRecognizedTrigger } from './student-not-recognized-trigger';
import { TransitionTrigger } from './transition-trigger';

class TriggerFactory {
	public create(transitionDescription: IFulfillmentTransitionDescription): TransitionTrigger {
		const excludedEnrolmentStatuses = EnrolmentStatus.obtainForValues(transitionDescription.excludedEnrolmentStatuses);
		const permittedEnrolmentStatuses = EnrolmentStatus.obtainForValues(transitionDescription.permittedEnrolmentStatuses);

		if (
			transitionDescription.source === FulfillmentStepIndication.AWAITING_CONFIRMATION_REQUEST &&
			transitionDescription.target === FulfillmentStepIndication.AWAITING_CONFIRMATION_REQUEST &&
			FulfillmentTransitionTrigger.ENROLMENT_CONFIRMED === transitionDescription.trigger
		) {
			return new EnrolmentPreconfirmedTrigger(excludedEnrolmentStatuses);
		}

		switch (transitionDescription.trigger) {
			case FulfillmentTransitionTrigger.ENROLMENT_ALREADY_PROCESSED:
				return new EnrolmentAlreadyProcessedTrigger(excludedEnrolmentStatuses);

			case FulfillmentTransitionTrigger.ENROLMENT_DISQUALIFIED:
				return new EnrolmentDisqualifiedTrigger(excludedEnrolmentStatuses);

			case FulfillmentTransitionTrigger.ENROLMENT_INFORMATION_MISSING:
				return new EnrolmentInformationMissingTrigger(excludedEnrolmentStatuses);

			case FulfillmentTransitionTrigger.ENROLMENT_IN_PROCESSING_ROUND_SCOPE:
				return new EnrolmentInProcessingRoundScopeTrigger(excludedEnrolmentStatuses, permittedEnrolmentStatuses);

			case FulfillmentTransitionTrigger.ENROLMENT_NOT_FOR_INVOICING:
				return new EnrolmentNotForInvoicingTrigger(excludedEnrolmentStatuses);

			case FulfillmentTransitionTrigger.ENROLMENT_NOT_TRUSTED:
				return new EnrolmentNotTrustedTrigger(excludedEnrolmentStatuses);

			case FulfillmentTransitionTrigger.ENROLMENT_NOT_YET_INVOICEABLE:
				return new EnrolmentNotYetInvoiceableTrigger(excludedEnrolmentStatuses);

			case FulfillmentTransitionTrigger.ENROLMENT_QUALIFIED:
				return new EnrolmentQualifiedTrigger(excludedEnrolmentStatuses);

			case FulfillmentTransitionTrigger.ENROLMENT_READY_FOR_CONFIRMATION:
				return new EnrolmentReadyForConfirmationTrigger(excludedEnrolmentStatuses);

			case FulfillmentTransitionTrigger.ENROLMENT_CONFIRMATION_REQUESTED:
				return new EnrolmentConfirmationRequestedTrigger(excludedEnrolmentStatuses);

			case FulfillmentTransitionTrigger.ENROLMENT_CONFIRMED:
				return new EnrolmentConfirmedTrigger(excludedEnrolmentStatuses);

			case FulfillmentTransitionTrigger.ENROLMENT_DEFERRED:
				return new EnrolmentDeferredTrigger(excludedEnrolmentStatuses);

			case FulfillmentTransitionTrigger.ENROLMENT_NOT_RECOGNIZED:
				return new EnrolmentNotRecognizedTrigger(excludedEnrolmentStatuses);

			case FulfillmentTransitionTrigger.ENROLMENT_STUDENT_NOT_RECOGNIZED:
				return new StudentNotRecognizedTrigger(excludedEnrolmentStatuses);

			case FulfillmentTransitionTrigger.ENROLMENT_NOT_DUPLICATED:
				return new EnrolmentNotDuplicatedTrigger(excludedEnrolmentStatuses);

			case FulfillmentTransitionTrigger.ENROLMENT_DUPLICATION_CONFIRMED:
				return new EnrolmentDuplicationConfirmedTrigger(excludedEnrolmentStatuses);

			case FulfillmentTransitionTrigger.ENROLMENT_CONFIRMATION_UNSURE:
				return new EnrolmentConfirmationUnsureTrigger(excludedEnrolmentStatuses);

			case FulfillmentTransitionTrigger.ENROLMENT_CONFIRMATION_CHALLENGED:
				return new EnrolmentConfirmationChallengedTrigger(excludedEnrolmentStatuses);

			case FulfillmentTransitionTrigger.ENROLMENT_CONFIRMATION_ACCEPTED:
				return new EnrolmentConfirmationAcceptedTrigger(excludedEnrolmentStatuses);

			case FulfillmentTransitionTrigger.ENROLMENT_PLACED_IN_INVOICING_BATCH:
				return new EnrolmentPlacedInInvoicingBatchTrigger(excludedEnrolmentStatuses);

			case FulfillmentTransitionTrigger.ENROLMENT_REVISED:
				return new EnrolmentRevisedTrigger(excludedEnrolmentStatuses);
		}

		return new NullTrigger();
	}
}

export default new TriggerFactory();
