import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "InternalHistoryOverview",
  class: "HistoryOverview"
}
const _hoisted_2 = { class: "EnrolmentOverviewActionsContainer" }
const _hoisted_3 = { class: "ActionsContainerRow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EnrolmentsTableFilter = _resolveComponent("EnrolmentsTableFilter")!
  const _component_SearchBarWrapper = _resolveComponent("SearchBarWrapper")!
  const _component_EnrolmentsTableGroupSelector = _resolveComponent("EnrolmentsTableGroupSelector")!
  const _component_EnrolmentsHistorySection = _resolveComponent("EnrolmentsHistorySection")!
  const _component_EasterEggClippy = _resolveComponent("EasterEggClippy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_EnrolmentsTableFilter),
        _createVNode(_component_SearchBarWrapper)
      ]),
      _createVNode(_component_EnrolmentsTableGroupSelector)
    ]),
    _createVNode(_component_EnrolmentsHistorySection),
    _createVNode(_component_EasterEggClippy)
  ]))
}