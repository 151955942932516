import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0e59a503"), n = n(), _popScopeId(), n);
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { class: "MultiSelectGroupLabel" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Option = _resolveComponent("Option");
    return (_openBlock(), _createElementBlock("div", null, [
        (_ctx.groupData.options.length > 0)
            ? (_openBlock(), _createElementBlock("ol", _hoisted_1, [
                _createElementVNode("li", _hoisted_2, _toDisplayString(_ctx.groupData.label), 1 /* TEXT */),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupData.options, (option, index) => {
                    return (_openBlock(), _createBlock(_component_Option, {
                        onCustomClick: ($event) => (_ctx.emit('optionSelected', option)),
                        key: index
                    }, {
                        default: _withCtx(() => [
                            (option.icon)
                                ? (_openBlock(), _createElementBlock("i", {
                                    key: 0,
                                    class: _normalizeClass([option.icon, "MultiSelectOptionIcon"])
                                }, null, 2 /* CLASS */))
                                : _createCommentVNode("v-if", true),
                            _createTextVNode(" " + _toDisplayString(option.label), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onCustomClick"]));
                }), 128 /* KEYED_FRAGMENT */))
            ]))
            : _createCommentVNode("v-if", true)
    ]));
}
