import { computed, watch } from 'vue';
import { DefaultTableSelectorOptions } from '@/models/i-table-selector';
import { Enrolment } from '@/models/enrolment';
import { RouteEnums } from '@/models/route-enums';
import TableSelectorOption from '@/presentation/components/generalizations/table-selectors/table-selector-option';
import TableFilter from '@/presentation/components/generalizations/table-selectors/filters/table-filter';
import store from '@/store';
import { IProjectSuccessManager } from '@/models/i-project-success-manager';

export default class TableFilterPsmHistory extends TableFilter {
	get defaultOptions(): TableSelectorOption[] {
		return [new TableSelectorOption(DefaultTableSelectorOptions.DEFAULT, 'All PSMs')];
	}

	private selectedIdentityPsm = computed((): string => {
		return store.getters.selectedIdentityPsm();
	});

	private onIdentifiedEnrolmentsPage = computed((): boolean => {
		return this.currentRoute.value === RouteEnums.IDENTIFIED_ENROLMENTS;
	});

	private projectSuccessManagers = computed((): IProjectSuccessManager[] => {
		return store.getters.projectSuccessManagers();
	});

	private currentRoute = computed((): RouteEnums => {
		const router = store.getters.router();
		return router.current;
	});

	constructor() {
		super();

		watch(this.selectedIdentityPsm, this.setSelectedPSMAsSelectedOption.bind(this));
		watch(this.currentRoute, this.setSelectedPSMAsSelectedOption.bind(this));
	}

	public createOptions(): TableSelectorOption[] {
		return this.projectSuccessManagers.value.map((psm) => new TableSelectorOption(psm.id, psm.name));
	}

	public setSelectedOption(option: string | number): void {
		super.setSelectedOption(option);

		// Update the selected PSM in the store as well, so the selection is used for prefilling when switching pages.
		store.mutations.setSelectedPSM(option.toString());
	}

	public doesEnrolmentMatchFilter(enrolment: Enrolment): boolean {
		const option = this.selectedOptionAsOption.value;
		if (!option || option.isDefaultOption || this.onIdentifiedEnrolmentsPage.value) {
			return true;
		}

		const psm = this.projectSuccessManagers.value.find((user) => user.name === enrolment.psmOwner);
		if (!psm) {
			return false;
		}

		const selected = this.selectedOption.value.toString();
		return psm.id === selected;
	}

	private setSelectedPSMAsSelectedOption(): void {
		this.selectedOption.value = this.selectedIdentityPsm.value;
	}
}
