import { EnrolmentReviewState } from './enrolment-review-state';

export class SuccessFeeSelectionReviewState extends EnrolmentReviewState {
	public display(): void {
		this.review.popups.showSuccessFeeSelectionPopup();
	}

	public requiresAdditionalInput(): boolean {
		return this.review.enrolments.length > 1 || this.review.selectedSuccessFeeOption.requiresAdditionalInput;
	}

	public next(): void {
		if (this.review.selectedSuccessFeeOption.requiresNoPayReasonsInput) {
			this.review.transitionTo(this.review.createNoPayReasonsSelectionState());
			return;
		}

		if (!this.review.selectedSuccessFeeOption.requiresAdditionalInput) {
			this.review.transitionTo(this.review.createTriggerMassReviewConfirmationState());
			return;
		}

		this.review.transitionTo(this.review.createTriggerPayloadReviewState());
	}
}
