import { defineComponent, Component } from 'vue';
import ContractDetailsModal from '@/presentation/components/internal/contract-details/contract-details-modal/contract-details-modal.vue';
import ContractDetailsButtonComponent
	from '@/presentation/components/internal/contract-details/contract-details-button/contract-details-button-class';

export default defineComponent({
	props: {
		canOpen: Boolean
	},
	components: {
		ContractDetailsModal: ContractDetailsModal as Component
	},
	setup: (props) => {
		const component = new ContractDetailsButtonComponent();

		return {
			canOpen: props.canOpen,
			contractDetailsModalShown: component.contractDetailsModalShown,
			showContractDetails: component.showContractDetails.bind(component)
		};
	}
});
