import { computed, ref, Ref, watch } from 'vue';
import { DefaultTableSelectorOptions } from '@/models/i-table-selector';
import { ICountDetailsOfEnrolment, IOrganisationDetails } from '@/models/i-organisation-details';
import TableSelectorDynamicOptions
	from '@/presentation/components/generalizations/table-selectors/selectors/table-selector-dynamic-options';
import TableSelectorOption from '@/presentation/components/generalizations/table-selectors/table-selector-option';
import tableSelectorRetriever from '@/presentation/components/generalizations/table-selectors/table-selector-retriever';
import store from '@/store';
import { IProjectSuccessManager } from '@/models/i-project-success-manager';
import TableFilterTuitionFee from '@/presentation/components/generalizations/table-selectors/filters/table-filter-tuition-fee';
import TableFilterNationality from '@/presentation/components/generalizations/table-selectors/filters/table-filter-nationality';
import TableFilterIntakePeriod from '@/presentation/components/generalizations/table-selectors/filters/table-filter-intake-period';
import TableFilterSource from '@/presentation/components/generalizations/table-selectors/filters/table-filter-source';

export default class TableSelectorOrganisation extends TableSelectorDynamicOptions {
	public selectedOption: Ref<string | number> = ref(DefaultTableSelectorOptions.EMPTY);

	get defaultOptions(): TableSelectorOption[] {
		const options = [
			new TableSelectorOption(DefaultTableSelectorOptions.EMPTY, 'Nothing selected'),
			new TableSelectorOption(DefaultTableSelectorOptions.DEFAULT, 'All universities', this.calculateAllOrganisationsFacet())
		];

		// Only include the "no-work-order universities" option when no PSM is selected.
		if (this.selectedIdentityPsm.value === '') {
			options.push(
				new TableSelectorOption(
					DefaultTableSelectorOptions.REMAINING,
					'No-work-order universities',
					this.calculateNoWorkOrderOrganisationsFacet()
				)
			);
		}

		return options;
	}

	private organisationDetails = computed((): IOrganisationDetails[] => {
		const cluster = store.getters.organisationDetailsCluster();
		return cluster.listOfDetails;
	});

	private selectedIdentityPsm = computed((): string => {
		return store.getters.selectedIdentityPsm();
	});

	private noWorkOrderOrganisationDetails = computed((): IOrganisationDetails | undefined => {
		return store.getters.noWorkOrderOrganisationDetails();
	});

	private projectSuccessManagers = computed((): IProjectSuccessManager[] => {
		return store.getters.projectSuccessManagers();
	});

	constructor() {
		super(true);

		watch(this.organisationDetails, this.setOptions.bind(this));
		watch(this.selectedIdentityPsm, this.setOptions.bind(this));
		watch(this.projectSuccessManagers, this.setOptions.bind(this));
	}

	public createOptions(): TableSelectorOption[] {
		const idSelectedPSM = store.state.selectedPSM;
		const selectedPSM = this.projectSuccessManagers.value.find((psm) => psm.id === idSelectedPSM);

		return this.organisationDetails.value
			.filter((details) => !selectedPSM || selectedPSM.name === details.psmOwner)
			.map(
				(details) =>
					new TableSelectorOption(
						details.organisationIdentity,
						details.organisationName,
						details.enrolments ? this.getNumberOfEnrolmentsMatchingOnFilters(details.enrolments) : undefined
					)
			);
	}

	private calculateAllOrganisationsFacet(): number | undefined {
		const enrolmentCountData = this.getListOfOrganisationDetailsForSelectedPSM()
			.filter((details) => details.enrolments !== undefined)
			.map((details) => this.getNumberOfEnrolmentsMatchingOnFilters(details.enrolments as ICountDetailsOfEnrolment[]));

		if (enrolmentCountData.length === 0) {
			return;
		}

		// When the organisation filter options are ready, make sure that options without enrolments are filtered out.
		this.optionsWithoutEnrolmentsCanBeShown = false;

		return enrolmentCountData.reduce(
			(overallCount, numberOfEnrolmentsForOrganisation) => (overallCount += numberOfEnrolmentsForOrganisation)
		);
	}

	private calculateNoWorkOrderOrganisationsFacet(): number | undefined {
		if (!this.noWorkOrderOrganisationDetails.value || this.noWorkOrderOrganisationDetails.value.enrolments === undefined) {
			return;
		}

		return this.getNumberOfEnrolmentsMatchingOnFilters(this.noWorkOrderOrganisationDetails.value.enrolments);
	}

	private getNumberOfEnrolmentsMatchingOnFilters(enrolments: ICountDetailsOfEnrolment[]): number {
		return enrolments.filter((enrolment) => {
			return (
				this.enrolmentMatchesStepFilter(enrolment) &&
				this.enrolmentMatchesStatusFilter(enrolment) &&
				this.enrolmentMatchesOptionalFilters(enrolment)
			);
		}).length;
	}

	private enrolmentMatchesStepFilter(enrolment: ICountDetailsOfEnrolment): boolean {
		const stepFilter = tableSelectorRetriever.retrieveStepFilter();
		return (
			stepFilter.selectedOption.value === '' ||
			stepFilter.selectedOption.value.toString() === enrolment.fulfillmentStep.fulfillmentStepIndication.toString()
		);
	}

	private enrolmentMatchesStatusFilter(enrolment: ICountDetailsOfEnrolment): boolean {
		const statusFilter = tableSelectorRetriever.retrieveStatusFilter();
		return (
			statusFilter.selectedOption.value === '' || statusFilter.selectedOption.value.toString() === enrolment.status.value.toString()
		);
	}

	private enrolmentMatchesOptionalFilters(enrolment: ICountDetailsOfEnrolment): boolean {
		const allOtherFilters = tableSelectorRetriever.optionalInternalFilters;
		return (
			allOtherFilters.find((filter) => {
				if (filter instanceof TableFilterTuitionFee) {
					return filter.selectedOption.value !== '' && filter.selectedOption.value !== enrolment.tuitionFee;
				} else if (filter instanceof TableFilterNationality) {
					return filter.selectedOption.value !== '' && filter.selectedOption.value !== enrolment.studentNationality;
				} else if (filter instanceof TableFilterIntakePeriod) {
					return filter.selectedOption.value !== '' && filter.selectedOption.value !== enrolment.intakePeriod;
				} else if (filter instanceof TableFilterSource) {
					return filter.selectedOption.value !== '' && filter.selectedOption.value !== enrolment.source;
				} else {
					return false;
				}
			}) === undefined
		);
	}

	private getListOfOrganisationDetailsForSelectedPSM(): IOrganisationDetails[] {
		const idSelectedPSM = store.state.selectedPSM;
		const selectedPSM = this.projectSuccessManagers.value.find((psm) => psm.id === idSelectedPSM);

		return this.organisationDetails.value.filter((details) => !selectedPSM || selectedPSM.name === details.psmOwner);
	}
}
