import { Enrolment } from '@/models/enrolment';
import { EnrolmentStatus } from '@/models/enrolment-status';
import EnrolmentService from '@/services/enrolments-service';

import { EnrolmentDeferredTriggerPayloadConfiguration } from '../trigger-payload/enrolment-deferred-trigger-payload-configuration';
import { TriggerPayload } from '../trigger-payload/trigger-payload';
import { TransitionTrigger } from './transition-trigger';

export class EnrolmentDeferredTrigger extends TransitionTrigger {
	public constructor(excludedEnrolmentStatuses: EnrolmentStatus[]) {
		super('Future intake', excludedEnrolmentStatuses, new EnrolmentDeferredTriggerPayloadConfiguration());
	}

	public equals(that: TransitionTrigger): boolean {
		return that instanceof EnrolmentDeferredTrigger;
	}

	protected async performTransitions(enrolments: Readonly<Enrolment[]>, payload: TriggerPayload): Promise<void> {
		await EnrolmentService.triggerEnrolmentDeferred(enrolments, payload.enrichmentSpecification.toTransportRepresentation());
	}
}
