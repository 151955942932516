import { DisqualificationReason as DisqualificationReasonEnum } from '@studyportals/sp-lord-business-interface';

export class DisqualificationReason {
	private static reasonIntakeOutOfAgreement: DisqualificationReason | undefined;
	private static reasonNationalityOutOfAgreement: DisqualificationReason | undefined;
	private static reasonLevelOutOfAgreement: DisqualificationReason | undefined;
	private static reasonProgrammeOutOfAgreement: DisqualificationReason | undefined;
	private static reasonStudentActivityOutOfAgreement: DisqualificationReason | undefined;
	private static reasonOther: DisqualificationReason | undefined;

	public constructor(
		public readonly value: number,
		public readonly name: string,
		public readonly requiresAdditionalExplanation = false
	) {}
	public static all(): Readonly<DisqualificationReason[]> {
		return [
			this.intakeOutOfAgreement(),
			this.nationalityOutOfAgreement(),
			this.levelOutOfAgreement(),
			this.programmeOutOfAgreement(),
			this.studentActivityOutOfAgreement(),
			this.other()
		];
	}

	public static intakeOutOfAgreement(): DisqualificationReason {
		return (this.reasonIntakeOutOfAgreement =
			this.reasonIntakeOutOfAgreement ||
			new DisqualificationReason(DisqualificationReasonEnum.INTAKE_OUT_OF_AGREEMENT, 'Intake out of agreement'));
	}

	public static nationalityOutOfAgreement(): DisqualificationReason {
		return (this.reasonNationalityOutOfAgreement =
			this.reasonNationalityOutOfAgreement ||
			new DisqualificationReason(DisqualificationReasonEnum.NATIONALITY_OUT_OF_AGREEMENT, 'Nationality out of agreement'));
	}

	public static levelOutOfAgreement(): DisqualificationReason {
		return (this.reasonLevelOutOfAgreement =
			this.reasonLevelOutOfAgreement ||
			new DisqualificationReason(DisqualificationReasonEnum.LEVEL_OUT_OF_AGREEMENT, 'Degree level out of agreement'));
	}

	public static programmeOutOfAgreement(): DisqualificationReason {
		return (this.reasonProgrammeOutOfAgreement =
			this.reasonProgrammeOutOfAgreement ||
			new DisqualificationReason(DisqualificationReasonEnum.PROGRAMME_OUT_OF_AGREEMENT, 'Programme out of agreement'));
	}

	public static studentActivityOutOfAgreement(): DisqualificationReason {
		return (this.reasonStudentActivityOutOfAgreement =
			this.reasonStudentActivityOutOfAgreement ||
			new DisqualificationReason(DisqualificationReasonEnum.STUDENT_ACTIVITY_OUT_OF_AGREEMENT, 'Student activity out of agreement'));
	}

	public static other(): DisqualificationReason {
		return (this.reasonOther =
			this.reasonOther || new DisqualificationReason(DisqualificationReasonEnum.OTHER, 'Other (please specify in the notes)', true));
	}

	public equals(that: DisqualificationReason): boolean {
		return this.value === that.value;
	}
}
