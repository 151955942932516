import RedshiftTracker from '@/infrastructure/redshift-tracker';
import store from '@/store';

export default class InternalHistoryOverviewComponent {
	private get redshiftTracker(): RedshiftTracker {
		return store.state.redshiftTracker;
	}

	public mounted(): void {
		this.redshiftTracker.trackInitialLoadingSpeed(true);
	}
}
