import { Component, defineComponent, onMounted, onUnmounted } from 'vue';
import { MultiSelect } from '@studyportals/multiselect';
import OptionalFiltersButton
	from '@/presentation/components/generalizations/optional-filters/optional-filters-button/optional-filters-button.vue';
import OptionalFiltersPanel
	from '@/presentation/components/generalizations/optional-filters/optional-filters-panel/optional-filters-panel.vue';
import EnrolmentsTableFilterComponent from '@/presentation/components/internal/enrolments-table-filter/enrolments-table-filter-class';

export default defineComponent({
	components: {
		MultiSelect,
		OptionalFiltersButton: OptionalFiltersButton as Component,
		OptionalFiltersPanel: OptionalFiltersPanel as Component
	},
	setup: () => {
		const component = new EnrolmentsTableFilterComponent();

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		return {
			psmDropdown: component.psmDropdown,
			organisationDropdown: component.organisationDropdown,
			statusDropdown: component.statusDropdown,
			stepDropdown: component.stepDropdown,
			psmOptions: component.psmOptions,
			organisationOptions: component.organisationOptions,
			statusOptions: component.statusOptions,
			stepOptions: component.stepOptions,
			psmCluster: component.psmCluster,
			organisationCluster: component.organisationCluster,
			statusCluster: component.statusCluster,
			stepCluster: component.stepCluster,
			selectorClusterHandler: component.selectorClusterHandler,
			panelVisible: component.panelVisible,
			onIdentifiedEnrolmentsPage: component.onIdentifiedEnrolmentsPage,
			togglePanelVisibility: component.togglePanelVisibility.bind(component),
			clearFilters: component.clearFilters.bind(component)
		};
	}
});
