import cookieHandler from '@/infrastructure/cookie-handler';
import userCampaignManagementAPIClient from '@/infrastructure/user-campaign-management-api-client';
import store from '@/store';
import { AbstractAndReportExceptionAsync } from '@studyportals/mb-platform-exceptions-aop';

class PSMPresenter {
	@AbstractAndReportExceptionAsync()
	public async retrievePSMs(): Promise<void> {
		const jwt = cookieHandler.retrieveTokenIdCookie();
		const psms = await userCampaignManagementAPIClient.getAllUsersWithEbpPsm(jwt);

		const mappedPsms = psms.map((psm) => {
			return {
				id: psm.id.toString(),
				name: psm.name
			};
		});

		store.mutations.setProjectSuccessManagers(mappedPsms);
	}
}

export default new PSMPresenter();
