import { EnrolmentConfirmedTrigger } from '../transitions/triggers/enrolment-confirmed-trigger';
import { TransitionTrigger } from '../transitions/triggers/transition-trigger';
import { EnrolmentReview } from './enrolment-review';
import { EnrolmentReviewState } from './state/enrolment-review-state';
import { SuccessFeeSelectionInternalReviewState } from './state/successfee-selection-internal-review-state';
import { SuccessFeeOption } from './success-fee-options/success-fee-option';
import { TuitionFeeSkipReviewState } from '@/models/enrolment-review/state/tuition-fee-skip-review-state';

export class InternalEnrolmentReview extends EnrolmentReview {
	private get tuitionFilledInForAllEnrolments(): boolean {
		// Establish that no selected enrolment is missing any tuition fee data (amount or currency).
		return this.enrolments.find((enrolment) => !enrolment.tuitionFee || !enrolment.tuitionFeeCurrency) === undefined;
	}
	public createSuccessFeeSelectionState(): EnrolmentReviewState {
		return new SuccessFeeSelectionInternalReviewState(this);
	}

	public createTuitionFeeSkipReviewState(): EnrolmentReviewState {
		// For the state creation, pass on the EnrolmentReview data, but also the state for the screen after the tuition fee skip screen.
		return new TuitionFeeSkipReviewState(this, this.createSuccessFeeSelectionState());
	}

	public requiresNationality(): boolean {
		return false;
	}

	public nationalitySuccessFeeOptionIsDisabled(): boolean {
		return this.availableSuccessFeeOptions.some(
			(_) => _.disabled && _.successFee !== undefined && _.successFee !== null && _.successFee.isReducedBecauseNationalityFee()
		);
	}

	protected createInitialState(): EnrolmentReviewState {
		// If multiple enrolments are selected & tuition fee was filled in for all of them, ask the user if tuition fee can be skipped.
		if (this.enrolments.length > 1 && this.tuitionFilledInForAllEnrolments) {
			return this.createTuitionFeeSkipReviewState();
		}

		return this.createSuccessFeeSelectionState();
	}

	protected determineAvailableTriggers(): TransitionTrigger[] {
		return super.determineAvailableTriggers().filter((_) => _ instanceof EnrolmentConfirmedTrigger);
	}

	protected determineAvailableSuccessFeeOptions(): SuccessFeeOption[] {
		return super.determineAvailableSuccessFeeOptions().filter((_) => this.isCompatibleSuccessFeeOption(_));
	}

	private isCompatibleSuccessFeeOption(option: SuccessFeeOption): boolean {
		if (undefined === option.successFee || null === option.successFee) {
			return false;
		}

		if (option.successFee.isReducedBecauseNationalityFee() && !this.isNationalitySpecified()) {
			option.disable();
		}

		return true;
	}

	private isNationalitySpecified(): boolean {
		return !this.enrolments.some((_) => undefined === _.studentNationality);
	}
}
