import { DateTime } from '@studyportals/mb-platform-date-time';
import { Country } from './country';
import { Currency } from './currency';
import { DisqualificationReason } from './disqualification-reason';
import { EnrichmentSpecification } from './enrichment-specification';
import { EnrolmentStatus } from './enrolment-status';
import { EnrolmentStatusEnum } from './enrolment-status-enum';
import { FulfillmentStep } from './fulfillment-step';
import FulfillmentStepIndication from './fulfillment-step-indication';
import { Month } from './month';
import { NoPayReason } from './no-pay-reason';
import { ProgrammeLevel } from './programme-level';
import { SuccessFee } from './success-fee';
import { TransitionTrigger } from './transitions/triggers/transition-trigger';
import { PreDisqualificationReason } from '@studyportals/sp-lord-business-interface/src/PreDisqualificationReason';

export class Enrolment {
	public psmOwner: string;
	public readonly successFeeText: string;
	public readonly disqualificationReasonText: string;
	public readonly reason: string;
	public readonly remarks: string;

	private enrolmentIsDirty = false;

	public constructor(
		public readonly identity: string,
		public readonly internalSourceName: string,
		public readonly clientSourceName: string,
		public readonly fulfillmentStep: FulfillmentStep,
		public readonly status: EnrolmentStatus,
		public readonly preDisqualificationReason: PreDisqualificationReason,
		public readonly organisationIdentity: string,
		public readonly organisationName: string,
		public readonly studentIdentity: string | undefined,
		public readonly studentEmail: string | undefined,
		public readonly studentRegistrationDate: DateTime | undefined,
		public readonly studentFirstActiveDate: DateTime | undefined,
		public readonly studentLastActiveDate: DateTime | undefined,
		rawPsmOwner: string,
		public readonly lastQualificationStatus: EnrolmentStatus,
		public readonly studentNationality: Country | undefined,
		public readonly studentCountryOfResidence: Country | undefined,
		public readonly studentName: string,
		public readonly studentLinkedIn: string,
		public readonly studentFacebook: string,
		public readonly programmeLevel: ProgrammeLevel | undefined,
		public readonly programmeName: string,
		public readonly intakeMonth: Month | undefined,
		public readonly intakeYear: number | undefined,
		public readonly tuitionFee: number | undefined,
		public readonly tuitionFeeCurrency: Currency | undefined,
		public readonly successFee: SuccessFee | undefined,
		public readonly noPayReasons: Readonly<NoPayReason[]>,
		public readonly notRecognizedNote: string,
		public readonly confirmationUnsureNote: string,
		public readonly noPayReasonNote: string,
		public readonly confirmedNote: string,
		public readonly studentOrganisationRegistrationNumber: string | undefined,
		public readonly disqualificationReason: Readonly<DisqualificationReason[]>,
		public readonly disqualificationNote: string,
		public readonly invoicingBatchIdentity: string | undefined,
		public readonly isChallengedAndUnconfirmed: boolean,
		public readonly isSharedForClientProcessing: boolean,
		public readonly confirmationChallengeNote: string,
		public readonly reviewSubmittedDate: DateTime | undefined,
		public readonly duplicationNote: string,
		public readonly insertedInERTDateIso: DateTime | undefined,
		public readonly lastChangedDateIso: DateTime | undefined,
		public readonly crmInvoiceIdentity: string,
		public readonly firstEmailActivityDateIso: DateTime | undefined,
		public readonly lastEmailActivityDateIso: DateTime | undefined,
		public readonly showLastEmailActivityDate: boolean
	) {
		// Replace any diacritics (e.g. é becomes e), so that string comparison with psm-name-based variables works as intended.
		this.psmOwner = rawPsmOwner.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
		this.successFeeText = this.determineSuccessFeeText();
		this.disqualificationReasonText = this.determineDisqualificationReasonText();
		this.reason = this.determineReason();
		this.remarks = this.determineRemarks();
	}

	public static findEquivalentEnrolments(needleStack: Readonly<Enrolment[]>, hayStack: Readonly<Enrolment[]>): Enrolment[] {
		const equivalentEnrolments: Enrolment[] = [];

		for (const enrolment of needleStack) {
			const equivalentEnrolment = hayStack.find((_) => enrolment.equals(_));

			if (undefined !== equivalentEnrolment) {
				equivalentEnrolments.push(equivalentEnrolment);
			}
		}

		return equivalentEnrolments;
	}
	public get isDirty(): boolean {
		return this.enrolmentIsDirty;
	}

	public get confirmationNotes(): string {
		return this.notRecognizedNote || this.confirmationUnsureNote || this.noPayReasonNote || this.confirmedNote;
	}

	public get tuitionFeeString(): string {
		return this.tuitionFee && this.tuitionFeeCurrency ? `${this.tuitionFee} ${this.tuitionFeeCurrency.isoCode}` : '';
	}

	public get intakePeriodString(): string {
		return this.intakeMonth && typeof this.intakeYear === 'number' ? `${this.intakeMonth.name} ${this.intakeYear}` : '';
	}

	public get studentRegistrationDateString(): string {
		return this.convertActivityDateToString(this.studentRegistrationDate);
	}

	public get studentFirstActiveDateString(): string {
		return this.convertActivityDateToString(this.studentFirstActiveDate);
	}

	public get studentLastActiveDateString(): string {
		return this.convertActivityDateToString(this.studentLastActiveDate);
	}

	public get insertedInERTDateString(): string {
		return this.convertActivityDateToString(this.insertedInERTDateIso);
	}

	public get lastChangedDateString(): string {
		return this.convertActivityDateToString(this.lastChangedDateIso);
	}

	public get reviewSubmittedDateString(): string {
		return this.convertActivityDateToString(this.reviewSubmittedDate);
	}

	public get firstEmailActivityDateString(): string {
		return this.convertActivityDateToString(this.firstEmailActivityDateIso);
	}

	public get lastEmailActivityDateString(): string {
		if(this.showLastEmailActivityDate){
			return this.convertActivityDateToString(this.lastEmailActivityDateIso);
		}
		return 'Not Applicable';
	}

	public get reviewStatusIndicationText(): string {
		if (this.isChallengedAndUnconfirmed) {
			return 'Double check needed';
		}
		if (this.isReadyForReviewSubmission()) {
			return 'Reviewed';
		}
		if (!this.isAwaitingReview() && !this.isReadyForReviewSubmission()) {
			return 'Submitted';
		}
		return '';
	}

	public availableTriggers(): Readonly<TransitionTrigger[]> {
		return this.fulfillmentStep.availableTriggersForEnrolment(this);
	}

	public getSuccessFee(): SuccessFee {
		if (undefined === this.successFee) {
			throw new Error('Success fee not defined');
		}

		return this.successFee;
	}

	public equals(that: Enrolment): boolean {
		return this.identity === that.identity;
	}

	public isAssignedToPartnershipSuccessManager(): boolean {
		return '-' !== this.psmOwner;
	}

	public createEnrichmentSpecification(): EnrichmentSpecification {
		return new EnrichmentSpecification(
			this.studentName,
			this.studentNationality,
			this.studentCountryOfResidence,
			this.studentFacebook,
			this.studentLinkedIn,
			this.programmeLevel,
			this.programmeName,
			this.tuitionFee,
			this.tuitionFeeCurrency,
			this.intakeMonth,
			this.intakeYear
		);
	}

	public isReadyToShareWithClient(): boolean {
		return this.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.AWAITING_CONFIRMATION_REQUEST);
	}

	public isSharedWithClient(): boolean {
		return (
			this.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.AWAITING_CONFIRMATION) ||
			this.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.CONFIRMATION_COMPLETED) ||
			this.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.PENDING_CONFIRMATION_REVIEW) ||
			this.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.CONFIRMATION_SUBMITTED)
		);
	}

	public hasNoFurtherActions(): boolean {
		return (
			this.isPostponed() ||
			this.isNotTrusted() ||
			this.isNotForInvoicing() ||
			this.isDuplicated() ||
			this.isNotRecognized() ||
			this.isStudentNotRecognized() ||
			this.isConfirmationUnsure()
		);
	}

	public isAwaitingReview(): boolean {
		return this.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.AWAITING_CONFIRMATION);
	}

	public isConfirmationCompleted(): boolean {
		return this.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.CONFIRMATION_COMPLETED);
	}

	public isPendingConfirmationReview(): boolean {
		return this.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.PENDING_CONFIRMATION_REVIEW);
	}

	public isConfirmed(): boolean {
		return EnrolmentStatusEnum.CONFIRMED === this.status.value;
	}

	public isConfirmationUnsure(): boolean {
		return EnrolmentStatusEnum.CONFIRMATION_UNSURE === this.status.value;
	}

	public isPostponed(): boolean {
		return EnrolmentStatusEnum.POSTPONED === this.status.value;
	}

	public isNotRecognized(): boolean {
		return EnrolmentStatusEnum.ENROLMENT_NOT_RECOGNIZED === this.status.value;
	}

	public isStudentNotRecognized(): boolean {
		return EnrolmentStatusEnum.STUDENT_NOT_RECOGNIZED === this.status.value;
	}

	public isReadyForReviewSubmission(): boolean {
		return (
			this.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.CONFIRMATION_COMPLETED) ||
			this.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.PENDING_CONFIRMATION_REVIEW)
		);
	}

	public isReviewed(): boolean {
		return (
			this.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.CONFIRMATION_COMPLETED) ||
			this.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.PENDING_CONFIRMATION_REVIEW) ||
			this.isConfirmed()
		);
	}

	public isApproved(): boolean {
		return (
			this.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.SENT_TO_FINANCE) ||
			this.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.AWAITING_INVOICING)
		);
	}

	public isFinal(): boolean {
		return this.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.FINAL);
	}

	public isNotTrusted(): boolean {
		return EnrolmentStatusEnum.NOT_TRUSTED === this.status.value;
	}

	public isNotForInvoicing(): boolean {
		return EnrolmentStatusEnum.NOT_FOR_INVOICING === this.status.value;
	}

	public isDuplicated(): boolean {
		return EnrolmentStatusEnum.DUPLICATED === this.status.value;
	}

	public markDirty(): void {
		this.enrolmentIsDirty = true;
	}

	private determineSuccessFeeText(): string {
		if (this.isConfirmed()) {
			return this.successFee
				? this.successFee.name
				: `Not in scope of our agreement (${this.noPayReasons.map((_) => _.name).join(', ')})`;
		}

		return '';
	}

	private convertActivityDateToString(activityDate: DateTime | undefined): string {
		return undefined !== activityDate ? activityDate.toShortDateString() : '';
	}

	private determineDisqualificationReasonText(): string {
		return this.disqualificationReason ? this.disqualificationReason.map((_) => _.name).join(', ') : '';
	}

	private determineReason(): string {
		return this.determineDisqualificationReasonText() || this.determineSuccessFeeText();
	}

	private determineRemarks(): string {
		return this.confirmationNotes || this.disqualificationNote;
	}
}
