import { ValidationResult } from '../../../validation/validation-result';
import { TriggerPayload } from '../trigger-payload';
import { TriggerPayloadValidation } from './trigger-payload-validation';

export class NoteValidation extends TriggerPayloadValidation {
	public validateInExistingResult(payload: TriggerPayload, result: ValidationResult): ValidationResult {
		if (payload.note === undefined || payload.note.trim().length === 0) {
			result.isNoteValid = false;
		}

		return result;
	}
}
