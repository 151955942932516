enum MassEditablePropertiesEnum {
	COUNTRY_OF_RESIDENCE = 'countryOfResidence',
	INTAKE_MONTH = 'intakeMonth',
	INTAKE_YEAR = 'intakeYear',
	PROGRAMME_LEVEL = 'programmeLevel',
	PROGRAMME_NAME = 'programmeName',
	STUDENT_NATIONALITY = 'studentNationality',
	TUITION_FEE_CURRENCY = 'tuitionFeeCurrency',
	TUITION_FEE = 'tuitionFee'
}

interface IMassEditProperty {
	key: MassEditablePropertiesEnum;
	label: string;
	relevant: boolean;
	valid: boolean;
	invalidMessage: string;
}

type TMassEditablePropertiesCluster = {
	[key in MassEditablePropertiesEnum]: IMassEditProperty;
};

export { TMassEditablePropertiesCluster, IMassEditProperty, MassEditablePropertiesEnum };
