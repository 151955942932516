import { defineComponent, Component, onBeforeMount } from 'vue';
import InternalEnrolmentsOverviewComponent
	from '@/presentation/views/internal/internal-enrolments-overview/internal-enrolments-overview-class';
import LogOut from '@/stand-alone/log-out.vue';
import InitialRoutePresenter from '@/presenters/initial-route-presenter';

export default defineComponent({
	components: {
		LogOut: LogOut as Component
	},
	setup: () => {
		const component = new InternalEnrolmentsOverviewComponent();

		onBeforeMount(() => InitialRoutePresenter.prepareForRoutingActions());

		return {
			standAloneLogin: component.standAloneLogin,
			router: component.router,
			CurrentComponent: component.CurrentComponent
		};
	}
});
