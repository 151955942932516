import { LoadingStatusEnums } from '@/models/loading-status-enums';

class LoadingStatusInformer {
	public static announceLoadingFailed(): void {
		// Dispatch an event that announces the outcome of loading the ERT microservice, so UniAdmin can respond to it.
		document.dispatchEvent(new CustomEvent(LoadingStatusEnums.ERT_LOAD_FAILED));
	}
}

export default LoadingStatusInformer;
