import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "IdentifiedEnrolmentsSection" }
const _hoisted_2 = { class: "EnrolmentsOverviewHeader" }
const _hoisted_3 = { class: "Title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EnrolmentMenu = _resolveComponent("EnrolmentMenu")!
  const _component_OptionalFilterTags = _resolveComponent("OptionalFilterTags")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_EnrolmentsTable = _resolveComponent("EnrolmentsTable")!
  const _component_EmptyFiltersUnselectedMessage = _resolveComponent("EmptyFiltersUnselectedMessage")!
  const _component_EditEnrolment = _resolveComponent("EditEnrolment")!
  const _component_EnrolmentsActions = _resolveComponent("EnrolmentsActions")!
  const _component_LoadingModal = _resolveComponent("LoadingModal")!
  const _component_EasterEggClippy = _resolveComponent("EasterEggClippy")!
  const _component_InvoiceBatchCreatedNotification = _resolveComponent("InvoiceBatchCreatedNotification")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, [
        _createTextVNode(" Showing "),
        _createElementVNode("b", null, _toDisplayString(_ctx.filteredEnrolments.length), 1 /* TEXT */),
        _createTextVNode(" of "),
        _createElementVNode("b", null, _toDisplayString(_ctx.nonHistoryEnrolments.length), 1 /* TEXT */),
        _createTextVNode(" identified enrolments ")
      ])
    ]),
    _createVNode(_component_EnrolmentMenu),
    _createVNode(_component_OptionalFilterTags),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : (_ctx.showIdentifiedEnrolmentsTable)
        ? (_openBlock(), _createBlock(_component_EnrolmentsTable, { key: 1 }))
        : (_openBlock(), _createBlock(_component_EmptyFiltersUnselectedMessage, { key: 2 })),
    _createVNode(_component_EditEnrolment),
    _createVNode(_component_EnrolmentsActions),
    (!_ctx.modalStatusLoadingIsInactive)
      ? (_openBlock(), _createBlock(_component_LoadingModal, { key: 3 }))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_EasterEggClippy),
    _createVNode(_component_InvoiceBatchCreatedNotification)
  ]))
}