import { SuccessFeeSelectionReviewState } from './successfee-selection-review-state';

export class SuccessFeeSelectionInternalReviewState extends SuccessFeeSelectionReviewState {
	public requiresAdditionalInput(): boolean {
		const selectedSuccessFee = this.review.selectedSuccessFeeOption.successFee;

		if (undefined === selectedSuccessFee || null === selectedSuccessFee) {
			return false;
		}

		// Additional tuition fee input is only required when the user hasn't indicated that this part can be skipped.
		return (
			(selectedSuccessFee.requiresTuitionFee && !this.review.selectedTriggerPayload.enrichmentSpecification.skipTuitionFeeStep) ||
			selectedSuccessFee.isOtherSuccessFee()
		);
	}
}
