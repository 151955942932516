import { LordBusiness } from '@/infrastructure';
import { FulfillmentStep, PresentationEnrolment, TransitPresentationEnrolment } from '@studyportals/sp-lord-business-interface';
import EventBus from '@/event-bus/event-bus';
import { Event } from '@/event-bus/event';
import { EnrolmentsAcquiredEvent } from '@/event-bus/enrolments-acquired-event';
import EnrolmentFactory from '@/models/enrolment-factory';
import { SubscriptionTokenEnums } from '@/models/enrolment-subscriptions-enums';
import { EnrolmentSubscriptionTokens } from '@/models/enrolment-subscriptions';
import EnrolmentSubscriptionTokensEvents, { IEnrolmentSubscriptionEvent } from '@/models/enrolment-subscriptions-events';
import { EnrolmentBatchesEnums } from '@/models/enrolment-batches-enums';
import { Enrolment } from '@/models/enrolment';
import LoadingPresenter from '@/presenters/loading-presenter';
import datePresenter from '@/presenters/date-presenter';
import store from '@/store';
import { ungzip } from 'pako';
import { Buffer } from 'buffer';
class EnrolmentPresenter {
	private static get isInternal(): boolean {
		return store.getters.isInternal();
	}

	private static get nonHistorySteps(): number[] {
		const allSteps = Object.values(FulfillmentStep).filter((step) => !isNaN(step as number)) as FulfillmentStep[];
		return allSteps.filter((step) => step !== FulfillmentStep.HISTORY);
	}

	public static async acquireEnrolments(batch: EnrolmentBatchesEnums): Promise<void> {
		EnrolmentPresenter.unsubscribeFromPreviousEnrolmentRelatedEvents();
		EnrolmentPresenter.subscribeToEnrolmentRelatedEvents(batch);

		await EnrolmentPresenter.handleEnrolmentsAcquiringAndLoading(batch);
	}

	public static async acquireEnrolmentsOfRelevantBatch(batch: EnrolmentBatchesEnums): Promise<Enrolment[]> {
		let enrolmentsInput: (PresentationEnrolment | TransitPresentationEnrolment)[] = [];
		const selectedIdentityPsm = store.getters.selectedIdentityPsm();
		const selectedIdentityOrganisation = store.getters.selectedIdentityOrganisation();
		let result;

		switch (batch) {
			case EnrolmentBatchesEnums.PSM_AND_ORGANISATION:
				result = await LordBusiness.retrieveEnrolmentsForPsmWithOrganisation(
					selectedIdentityPsm,
					selectedIdentityOrganisation
				);

				enrolmentsInput = this.parseGzipEnrolments(result.body);
				break;
			case EnrolmentBatchesEnums.PSM:
				result = await LordBusiness.retrieveEnrolmentsForPsm(
					selectedIdentityPsm
				);

				enrolmentsInput = this.parseGzipEnrolments(result.body);
				break;
			case EnrolmentBatchesEnums.ORGANISATION:
				result = await LordBusiness.retrieveEnrolmentsForOrganisation(
					selectedIdentityOrganisation
				);

				enrolmentsInput = this.parseGzipEnrolments(result.body);
				break;
			case EnrolmentBatchesEnums.ALL:
				enrolmentsInput = await this.retrieveAllEnrolmentsInBatches();
				break;
			case EnrolmentBatchesEnums.HISTORY:
				enrolmentsInput = await this.retrieveInternalHistoryEnrolmentsInBatches();
				break;
			case EnrolmentBatchesEnums.NO_VALID_WORK_ORDER:
				return await this.retrieveAllNoWorkOrderEnrolments();
			default:
				break;
		}

		return EnrolmentFactory.reconstituteEnrolments(PresentationEnrolment.fromTransitRepresentationMultiple(enrolmentsInput));
	}

	private static async retrieveAllNoWorkOrderEnrolments(): Promise<Enrolment[]> {
		let enrolmentsInput: (PresentationEnrolment | TransitPresentationEnrolment)[] = [];
		const noWorkOrderOrganisationIds = store.getters.noWorkOrderOrganisationIds();
		const retrieveRelevantEnrolmentsByBrowsingAllEnrolments = noWorkOrderOrganisationIds.length === 0;
		if (retrieveRelevantEnrolmentsByBrowsingAllEnrolments) {
			enrolmentsInput = await this.retrieveAllEnrolmentsInBatches();
		} else {
			enrolmentsInput = await this.retrieveNoWorkOrderEnrolmentsInBatches();
		}
		const cluster = store.getters.organisationDetailsCluster();

		const enrolments = EnrolmentFactory.reconstituteEnrolments(
			PresentationEnrolment.fromTransitRepresentationMultiple(enrolmentsInput)
		);

		// If enrolments were retrieved with specific no-work-order-organisation ids, no further filtering is necessary.
		if (!retrieveRelevantEnrolmentsByBrowsingAllEnrolments) {
			return enrolments;
		}

		return enrolments.filter(
			(enrolment) =>
				cluster.listOfDetails.find((organisation) => organisation.organisationIdentity === enrolment.organisationIdentity) ===
				undefined
		);
	}

	private static async retrieveAllEnrolmentsInBatches(): Promise<(PresentationEnrolment | TransitPresentationEnrolment)[]> {
		// On the client-side, no batching is needed, so just fetch all enrolments.
		if (!this.isInternal) {
			const result = await LordBusiness.retrieveAllEnrolments();

			return this.parseGzipEnrolments(result.body);
		}

		const relevantSteps = this.nonHistorySteps;
		let enrolments: (PresentationEnrolment | TransitPresentationEnrolment)[] = [];
		// Retrieve enrolments in batches that are grouped by step, to avoid API Gateway timeouts.
		for (const step of relevantSteps) {
			const latestBatch = await LordBusiness.retrieveEnrolmentsByFulfillmentStepId(step);
			const temporaryEnrolments = this.parseGzipEnrolments(latestBatch.body);
			enrolments = enrolments.concat(temporaryEnrolments);
		}

		return enrolments;
	}

	private static async retrieveNoWorkOrderEnrolmentsInBatches(): Promise<(PresentationEnrolment | TransitPresentationEnrolment)[]> {
		const noWorkOrderOrganisationIds = store.getters.noWorkOrderOrganisationIds();
		let enrolments: (PresentationEnrolment | TransitPresentationEnrolment)[] = [];
		// Retrieve enrolments in batches of no-work-order universities.
		for (const id of noWorkOrderOrganisationIds) {
			const result = await LordBusiness.retrieveEnrolmentsForOrganisation(id);
			enrolments = this.parseGzipEnrolments(result.body);
		}

		return enrolments;
	}

	private static async retrieveInternalHistoryEnrolmentsInBatches(): Promise<(PresentationEnrolment | TransitPresentationEnrolment)[]> {
		let enrolments: (PresentationEnrolment | TransitPresentationEnrolment)[] = [];
		// Retrieve enrolments in batches of no-work-order universities.
		for (const year of datePresenter.years) {
			const newEnrolments = await this.retrieveHistoryEnrolments(year, 5000, undefined);

			enrolments = enrolments.concat(newEnrolments);
		}

		return enrolments;
	}

	private static async retrieveHistoryEnrolments(year: number, limit: number, lastEnrolmentIdentity: string | undefined): Promise<TransitPresentationEnrolment[]> {
		const historyEnrolmentGzipResult = await LordBusiness.retrieveAllInternalHistoryEnrolmentsByYear(year, limit, lastEnrolmentIdentity);
	
		const historyEnrolmentsResult = this.parseGzipHistoryEnrolments(historyEnrolmentGzipResult.body);

		let enrolments: TransitPresentationEnrolment[] = historyEnrolmentsResult.enrolments;

		const nextBatchLastEnrolmentIdentity = historyEnrolmentsResult.lastEnrolmentIdentity;
		if(nextBatchLastEnrolmentIdentity) {
			const nextBatchEnrolments = await this.retrieveHistoryEnrolments(year, limit, nextBatchLastEnrolmentIdentity);

			enrolments = enrolments.concat(nextBatchEnrolments);
		}

		return enrolments;
	}

	private static async handleEnrolmentsAcquiringAndLoading(batch: EnrolmentBatchesEnums): Promise<void> {
		LoadingPresenter.startLoading();

		const enrolments = await EnrolmentPresenter.acquireEnrolmentsOfRelevantBatch(batch);

		LoadingPresenter.finishLoading();

		EventBus.getEvent(EnrolmentsAcquiredEvent).publish(enrolments);
	}

	private static unsubscribeFromPreviousEnrolmentRelatedEvents(): void {
		const tokens = store.state.enrolmentSubscriptionTokens;
		if (!tokens) {
			return;
		}

		EnrolmentPresenter.performActionForEachSubscriptionToken((tokenProperty, event) => {
			event.unsubscribe(tokens[tokenProperty]);
		});
	}

	private static subscribeToEnrolmentRelatedEvents(batch: EnrolmentBatchesEnums): void {
		const tokens: EnrolmentSubscriptionTokens = {} as EnrolmentSubscriptionTokens;

		EnrolmentPresenter.performActionForEachSubscriptionToken((tokenProperty, event) => {
			tokens[tokenProperty] = event.subscribe(() => void EnrolmentPresenter.acquireEnrolments(batch));
		});

		store.mutations.setEnrolmentSubscriptionTokens(tokens);
	}

	private static performActionForEachSubscriptionToken(
		callback: (usableTokenProperty: SubscriptionTokenEnums, event: Event) => void
	): void {
		const allTokenProperties = Object.keys(new EnrolmentSubscriptionTokens());
		allTokenProperties.forEach((tokenProperty: string) => {
			const usableTokenProperty = EnrolmentPresenter.getUsableTokenProperty(tokenProperty);
			if (!usableTokenProperty) {
				return;
			}
			const event = EnrolmentPresenter.getEventForSubscriptionToken(usableTokenProperty);
			if (!event) {
				return;
			}

			callback(usableTokenProperty, event);
		});
	}

	private static getEventForSubscriptionToken<TEvent extends Event>(tokenProperty: SubscriptionTokenEnums): Event | null {
		const usableTokenProperty = EnrolmentPresenter.getUsableTokenProperty(tokenProperty);
		if (!usableTokenProperty) {
			return null;
		}

		const event: IEnrolmentSubscriptionEvent = EnrolmentSubscriptionTokensEvents[usableTokenProperty];
		return EventBus.getEvent(event) as TEvent;
	}

	private static getUsableTokenProperty(property: string): SubscriptionTokenEnums | undefined {
		switch (property) {
			case SubscriptionTokenEnums.REVIEW_SUBMITTED:
				return property;
			default:
				return undefined;
		}
	}

	private static parseGzipEnrolments(rawData: any): (PresentationEnrolment | TransitPresentationEnrolment)[] {
		const buffer = Buffer.from(rawData, 'base64');
		const rawEnrolments = ungzip(buffer, { to: 'string' });
		return JSON.parse(rawEnrolments) as (PresentationEnrolment | TransitPresentationEnrolment)[];
	}

	private static parseGzipHistoryEnrolments(rawData: any): HistoryEnrolmentResult {
		const buffer = Buffer.from(rawData, 'base64');
		const rawEnrolments = ungzip(buffer, { to: 'string' });
		return JSON.parse(rawEnrolments) as HistoryEnrolmentResult;
	}

}

type HistoryEnrolmentResult = {
	enrolments: TransitPresentationEnrolment[],
	lastEnrolmentIdentity: string | undefined
}

export default EnrolmentPresenter;
