import {
	tableSortByEnrolmentStatus,
	tableSortByIdentificationDate,
	tableSortByLastChangedDate
} from '@/presentation/components/generalizations/table-comparator';
import HotjarFieldSuppressor from '@/infrastructure/hotjar-field-suppressor';
import { IEnrolmentTableFieldSettings } from '@/models/i-enrolment-table-field-settings';

// NOTE: fits 8px grid
const columnWidthPxXXL = 240;
const columnWidthPxXL = 160;
const columnWidthPxL = 104;
const columnWidthPxM = 64;
const columnWidthPxS = 48;

const firstInternalEnrolmentsTableFields: IEnrolmentTableFieldSettings[] = [
	{
		checkboxSelection: true,
		headerCheckboxSelection: true,
		width: columnWidthPxS,
		cellClass: ['TableCell'],
		headerClass: 'TableHeaderCell',
		pinned: 'left',
		lockPosition: true
	},
	{
		headerName: 'Edit',
		headerTooltip: 'Edit',
		cellRenderer: 'EditEnrolmentEntry',
		width: columnWidthPxM,
		cellClass: ['TableCell'],
		headerClass: 'TableHeaderCell',
		pinned: 'left',
		lockPosition: true
	},
	{
		field: 'studentEmail',
		headerName: 'Email',
		headerTooltip: 'Email',
		tooltipField: 'studentEmail',
		width: columnWidthPxXXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		pinned: 'left',
		sortable: true,
		unSortIcon: true,
		resizable: true,
		cellRenderer: HotjarFieldSuppressor.suppressCellContent
	},
	{
		field: 'studentName',
		headerName: 'Student name',
		headerTooltip: 'Student name',
		tooltipField: 'studentName',
		width: columnWidthPxXXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true,
		cellRenderer: HotjarFieldSuppressor.suppressCellContent
	},
	{
		field: 'studentOrganisationRegistrationNumber',
		headerName: 'Client identification number',
		headerTooltip: 'Client identification number',
		tooltipField: 'studentOrganisationRegistrationNumber',
		width: columnWidthPxXXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'studentNationality.name',
		headerName: 'Student nationality',
		headerTooltip: 'Student nationality (will be shared with client)',
		tooltipField: 'studentNationality.name',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'studentCountryOfResidence.name',
		headerName: 'Country of residence',
		headerTooltip: 'Country of residence',
		tooltipField: 'studentCountryOfResidence.name',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'organisationName',
		headerName: 'University name',
		headerTooltip: 'University name',
		tooltipField: 'organisationName',
		width: columnWidthPxXXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'programmeLevel.name',
		headerName: 'Degree level',
		headerTooltip: 'Degree level',
		tooltipField: 'programmeLevel.name',
		width: columnWidthPxL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'programmeName',
		headerName: 'Programme name',
		headerTooltip: 'Programme name',
		tooltipField: 'programmeName',
		width: columnWidthPxXXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'tuitionFeeString',
		headerName: 'Tuition fee',
		headerTooltip: 'Tuition fee',
		tooltipField: 'tuitionFeeString',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'intakeYear',
		headerName: 'Intake year',
		headerTooltip: 'Intake year',
		tooltipField: 'intakeYear',
		width: columnWidthPxL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'intakeMonth.name',
		headerName: 'Intake month',
		headerTooltip: 'Intake month',
		tooltipField: 'intakeMonth.name',
		width: columnWidthPxL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'internalSourceName',
		headerName: 'Source',
		headerTooltip: 'Source',
		tooltipField: 'internalSourceName',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'studentRegistrationDateString',
		headerName: 'Registration date',
		headerTooltip: 'Registration date',
		tooltipField: 'studentRegistrationDate',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'studentFirstActiveDateString',
		headerName: 'First active date',
		headerTooltip: 'First active date',
		tooltipField: 'studentFirstActiveDate',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'studentLastActiveDateString',
		headerName: 'Last active date',
		headerTooltip: 'Last active date',
		tooltipField: 'studentLastActiveDate',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	}
];

const lastInternalEnrolmentsTableFields: IEnrolmentTableFieldSettings[] = [
	{
		field: 'insertedInERTDateString',
		headerName: 'Enrolment identification date',
		headerTooltip: 'Enrolment identification date',
		tooltipField: 'insertedInERTDateIso',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		comparator: tableSortByIdentificationDate,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'lastChangedDateString',
		headerName: 'Last changed date',
		headerTooltip: 'Last changed date',
		tooltipField: 'lastChangedDateIso',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		comparator: tableSortByLastChangedDate,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'fulfillmentStep.name',
		headerName: 'Process step',
		headerTooltip: 'Process step',
		tooltipField: 'fulfillmentStep.name',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		headerName: 'Status',
		cellRenderer: 'EnrolmentConfirmationStatus',
		headerTooltip: 'Status',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		comparator: tableSortByEnrolmentStatus,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'reason',
		headerName: 'Reason(s)',
		headerTooltip: 'Reason(s)',
		tooltipField: 'reason',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'preDisqualificationReason',
		headerName: 'Pre-Disqualification Reason',
		headerTooltip: 'Pre-Disqualification Reason',
		tooltipField: 'preDisqualificationReason',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'remarks',
		headerName: "Client's remark(s)",
		headerTooltip: "Client's remark(s)",
		tooltipField: 'remarks',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'duplicationNote',
		headerName: 'Duplication note',
		headerTooltip: 'Duplication note',
		tooltipField: 'duplicationNote',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'confirmationChallengeNote',
		headerName: 'Revisit note',
		headerTooltip: 'Revisit note',
		tooltipField: 'remarks',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	},
	{
		field: 'invoicingBatchIdentity',
		headerName: 'Invoice Batch ID',
		headerTooltip: 'Invoice Batch ID',
		tooltipField: 'invoicingBatchIdentity',
		width: columnWidthPxL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableContentCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	}
];

const emailDataRelatedEnrolmentsTableFields: IEnrolmentTableFieldSettings[] = [
	{
		field: 'lastEmailActivityDateString',
		headerName: 'Last email active date',
		headerTooltip: 'Last email active date',
		tooltipField: 'lastEmailActivityDateIso',
		width: columnWidthPxXL,
		minWidth: columnWidthPxS,
		cellClass: ['TableCell', 'TableActionCell'],
		headerClass: 'TableHeaderCell',
		sortable: true,
		unSortIcon: true,
		resizable: true
	}
];

const internalEnrolmentsTableFields: IEnrolmentTableFieldSettings[] = [
	...firstInternalEnrolmentsTableFields,
	...lastInternalEnrolmentsTableFields
];

const internalEnrolmentsTableFieldsWithEmailData: IEnrolmentTableFieldSettings[] = [
	...firstInternalEnrolmentsTableFields,
	...emailDataRelatedEnrolmentsTableFields,
	...lastInternalEnrolmentsTableFields
];

export { internalEnrolmentsTableFields, internalEnrolmentsTableFieldsWithEmailData };