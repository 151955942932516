import { defineComponent, onMounted } from 'vue';
import store from '@/store';
import EnrolmentsTableGroupSelectorComponent
	from '@/presentation/components/generalizations/enrolments-table-group-selector/enrolments-table-group-selector-class';

export default defineComponent({
	setup: () => {
		const component = new EnrolmentsTableGroupSelectorComponent();

		onMounted(component.mounted.bind(component));

		return {
			isInternal: store.state.isInternal,
			selectedGroup: component.selectedGroup,
			groupSelector: component.groupSelector,
			changeGroupOption: component.changeGroupOption.bind(component)
		};
	}
});
