import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "ClientHistoryOverview",
  class: "HistoryOverview"
}
const _hoisted_2 = { class: "EnrolmentsOverviewHeader ActionsContainerRow" }
const _hoisted_3 = { class: "Title" }
const _hoisted_4 = { class: "ActionsContainerRow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnershipSelector = _resolveComponent("PartnershipSelector")!
  const _component_SearchBarWrapper = _resolveComponent("SearchBarWrapper")!
  const _component_EnrolmentsTableGroupSelector = _resolveComponent("EnrolmentsTableGroupSelector")!
  const _component_HistoryTableFilter = _resolveComponent("HistoryTableFilter")!
  const _component_EnrolmentsHistorySection = _resolveComponent("EnrolmentsHistorySection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.organisationName), 1 /* TEXT */),
      _createVNode(_component_PartnershipSelector),
      _createVNode(_component_SearchBarWrapper)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_EnrolmentsTableGroupSelector),
      _createVNode(_component_HistoryTableFilter)
    ]),
    _createVNode(_component_EnrolmentsHistorySection)
  ]))
}