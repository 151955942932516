import { EnrolmentReviewState } from './enrolment-review-state';

export class MassReviewConfirmationReviewState extends EnrolmentReviewState {
	public display(): void {
		this.review.popups.showMassReviewConfirmationPopup();
	}

	public requiresAdditionalInput(): boolean {
		return false;
	}

	public next(): void {
		// Abstract parent function should be implemented, but no functionality required.
	}
}
