import { IMultiSelect } from '@studyportals/multiselect/src/interfaces/multiselect.interface';
import { IOption } from '@studyportals/multiselect/src/interfaces/options.interface';
import { DefaultTableSelectorOptions } from '@/models/i-table-selector';

export default class NullMultiselect implements IMultiSelect {
	public isActive = { value: false };
	public selectedOption = { label: '', value: DefaultTableSelectorOptions.DEFAULT };

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public selectOption(option: IOption | null): void {
		// Method used in actual multiselect instance.
	}

	public toggleMultiSelect(): void {
		// Method used in actual multiselect instance.
	}
}
