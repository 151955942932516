import { defineComponent, onMounted, onUnmounted } from 'vue';
import { ModalStatusSubmitReviewEnums } from '@/models/modal-status-submit-review-enum';
import SubmitReviewModalComponent from '@/presentation/components/client/submit-review-modal/submit-review-modal-class';

export default defineComponent({
	props: {
		modalStatus: String
	},
	setup: (props, context) => {
		const component = new SubmitReviewModalComponent(context.emit, props.modalStatus as ModalStatusSubmitReviewEnums);

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		return {
			modalContent: component.modalContent,
			modalStatusIsConfirmation: component.modalStatusIsConfirmation,
			modalStatusIsLater: component.modalStatusIsLater,
			triggerSubmitReview: component.triggerSubmitReview.bind(component),
			showSubmitReviewLaterModal: component.showSubmitReviewLaterModal.bind(component),
			hideModal: component.hideModal.bind(component)
		};
	}
});
