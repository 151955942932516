import { EnrolmentInvoiceGroup } from '@/models/enrolment-invoice-group';
import { Enrolment } from '@/models/enrolment';
import store from '@/store';

const TO_BE_INVOICED_GROUP_LABEL = 'To be invoiced';

export default class EnrolmentInvoiceGroupsPresenter {
	public static get sortedEnrolmentGroups(): EnrolmentInvoiceGroup[] {
		let toBeInvoicedGroup!: EnrolmentInvoiceGroup;

		const groups: EnrolmentInvoiceGroup[] = EnrolmentInvoiceGroupsPresenter.uniqueEnrolmentGroups.filter((group) => {
			// If it is encountered, exclude the "to be invoiced" group, but keep it for later.
			if (group.displayText === TO_BE_INVOICED_GROUP_LABEL) {
				toBeInvoicedGroup = group;
				return false;
			}

			return true;
		});

		// Sort by invoice number.
		groups.sort((a, b) => a.invoiceNumber.localeCompare(b.invoiceNumber));

		// Only after sorting has completed, put the "to be invoiced" group in front.
		if (toBeInvoicedGroup) {
			groups.unshift(toBeInvoicedGroup);
		}

		return groups;
	}

	private static get enrolmentInvoiceData(): EnrolmentInvoiceGroup[] {
		// Take only the invoice-related properties from the filtered enrolments.
		return store.getters
			.filteredEnrolments()
			.map(
				(enrolment: Enrolment) =>
					new EnrolmentInvoiceGroup(
						enrolment.crmInvoiceIdentity,
						enrolment.crmInvoiceIdentity ? enrolment.crmInvoiceIdentity : TO_BE_INVOICED_GROUP_LABEL
					)
			);
	}

	private static get uniqueEnrolmentGroups(): EnrolmentInvoiceGroup[] {
		const uniqueGroups: EnrolmentInvoiceGroup[] = [];
		EnrolmentInvoiceGroupsPresenter.enrolmentInvoiceData.forEach((newInvoiceInfo) => {
			// Whenever an invoice number is already in the list, stop here, because we don't want duplicates.
			if (
				uniqueGroups.filter(
					(uniqueInvoiceInfo: EnrolmentInvoiceGroup) => uniqueInvoiceInfo.displayText === newInvoiceInfo.displayText
				).length
			) {
				return;
			}

			uniqueGroups.push(newInvoiceInfo);
		});

		return uniqueGroups;
	}

	public static enrolmentsRelevantForSelectedInvoiceNumber(invoiceInfo: EnrolmentInvoiceGroup): Enrolment[] {
		const filteredEnrolments = store.getters.filteredEnrolments();
		return filteredEnrolments.filter((enrolment: Enrolment) => enrolment.crmInvoiceIdentity === invoiceInfo.invoiceNumber);
	}
}
