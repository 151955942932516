import { Component, defineComponent, onMounted, onUnmounted, watch } from 'vue';
import SubmitReviewModal from '@/presentation/components/client/submit-review-modal/submit-review-modal.vue';
import SubmitReviewComponent from '@/presentation/components/client/submit-review/submit-review-class';

export default defineComponent({
	components: {
		SubmitReviewModal: SubmitReviewModal as Component
	},
	setup: () => {
		const component = new SubmitReviewComponent();

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		watch(component.enrolments, component.determineWhetherReviewCanBeSubmitted.bind(component));

		return {
			wasReviewSubmitted: component.wasReviewSubmitted,
			canSubmitReview: component.canSubmitReview,
			notificationClosed: component.notificationClosed,
			modalStatusSubmitReview: component.modalStatusSubmitReview,
			submitReviewModalStatusIsInactive: component.submitReviewModalStatusIsInactive,
			requestSubmitReviewConfirmation: component.requestSubmitReviewConfirmation.bind(component),
			hideModal: component.hideModal.bind(component),
			closeNotification: component.closeNotification.bind(component)
		};
	}
});
