import { computed, Ref, ref } from 'vue';
import EventBus from '@/event-bus/event-bus';
import { SubmitReviewTriggeredEvent } from '@/event-bus/submit-review-triggered-event';
import { PartnershipSelectedEvent } from '@/event-bus/partnership-selected-event';
import { ReviewSubmittedEvent } from '@/event-bus/review-submitted-event';
import { ModalStatusLoadingEnums } from '@/models/modal-status-loading-enum';
import { ModalStatusSubmitReviewEnums } from '@/models/modal-status-submit-review-enum';
import { Enrolment } from '@/models/enrolment';
import { Partnership } from '@/models/partnership';
import PartnershipService from '@/services/partnership-service';
import store from '@/store';

export default class SubmitReviewComponent {
	public modalStatusSubmitReview: Ref<ModalStatusSubmitReviewEnums> = ref(ModalStatusSubmitReviewEnums.INACTIVE);

	public canSubmitReview: Ref<boolean | null> = ref(null);
	public wasPartnershipChangedSinceLastCheck = ref(false);

	private submitReviewTriggeredEventSubscriptionToken = ref('');
	private partnershipSelectedEventSubscriptionToken = ref('');

	public enrolments = computed((): Enrolment[] => {
		return store.getters.partnershipEnrolments();
	});

	public partnership = computed((): Partnership => {
		return store.getters.selectedPartnership();
	});

	public wasReviewSubmitted = computed((): boolean => {
		return store.getters.wasReviewSubmitted();
	});

	public notificationClosed = computed((): boolean => {
		return store.getters.submitReviewNotificationClosed();
	});

	public submitReviewModalStatusIsInactive = computed((): boolean => {
		return this.modalStatusSubmitReview.value === ModalStatusSubmitReviewEnums.INACTIVE;
	});

	public mounted(): void {
		this.determineWhetherReviewCanBeSubmitted();

		this.submitReviewTriggeredEventSubscriptionToken.value = EventBus.getEvent(SubmitReviewTriggeredEvent).subscribe(
			() => void this.submitReview()
		);

		this.partnershipSelectedEventSubscriptionToken.value = EventBus.getEvent(PartnershipSelectedEvent).subscribe(
			() => (this.wasPartnershipChangedSinceLastCheck.value = true)
		);
	}

	public unmounted(): void {
		EventBus.getEvent(SubmitReviewTriggeredEvent).unsubscribe(this.submitReviewTriggeredEventSubscriptionToken.value);
		EventBus.getEvent(PartnershipSelectedEvent).unsubscribe(this.partnershipSelectedEventSubscriptionToken.value);
	}

	public determineWhetherReviewCanBeSubmitted(): void {
		const couldSubmitReview = this.canSubmitReview.value;
		const canSubmitReview =
			this.enrolments.value.length > 0 &&
			!this.enrolments.value.some((enrolment) => enrolment.isAwaitingReview() || enrolment.isPendingConfirmationReview());

		this.canSubmitReview.value = canSubmitReview;

		if (canSubmitReview && couldSubmitReview === false && !this.wasPartnershipChangedSinceLastCheck.value) {
			this.whenReviewCompleted();
		}

		this.wasPartnershipChangedSinceLastCheck.value = false;
	}

	public requestSubmitReviewConfirmation(): void {
		this.modalStatusSubmitReview.value = ModalStatusSubmitReviewEnums.CONFIRMATION;
	}

	public async submitReview(): Promise<void> {
		this.modalStatusSubmitReview.value = ModalStatusSubmitReviewEnums.INACTIVE;
		this.showLoader();

		try {
			await PartnershipService.submitReview(this.partnership.value.organisationId);

			EventBus.getEvent(ReviewSubmittedEvent).publish();
			this.showHotjarSurvey();
			this.showSuccessMessage();
		} catch (e) {
			this.showFailureMessage();
		}
	}

	public showLoader(): void {
		store.actions.setModalStatusLoading(ModalStatusLoadingEnums.ACTIVE);
	}

	public showSuccessMessage(): void {
		store.actions.setModalStatusLoading(ModalStatusLoadingEnums.SUCCESS);
	}

	public showFailureMessage(): void {
		store.actions.setModalStatusLoading(ModalStatusLoadingEnums.FAILED);
	}

	public hideModal(): void {
		this.modalStatusSubmitReview.value = ModalStatusSubmitReviewEnums.INACTIVE;
	}

	public closeNotification(): void {
		store.mutations.changeVisibilitySubmitReviewNotification(false);
	}

	private whenReviewCompleted(): void {
		store.mutations.changeVisibilitySubmitReviewNotification(true);
		setTimeout(() => this.requestSubmitReviewConfirmation(), 2000);
	}

	private showHotjarSurvey(): void {
		// Make sure that hotjar is available on the window before continuing.
		if (typeof window.hj !== 'function') {
			return;
		}

		window.hj('trigger', 'ert_client_survey');
	}
}
