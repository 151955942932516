import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InvoiceBatchCreatedNotificationModal = _resolveComponent("InvoiceBatchCreatedNotificationModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.invoiceBatchCreatedNotificationStatusIsInactive)
      ? (_openBlock(), _createBlock(_component_InvoiceBatchCreatedNotificationModal, {
          key: 0,
          modalStatus: _ctx.modalStatusInvoiceBatchCreatedNotification,
          payload: _ctx.invoiceBatchCreatedPayload,
          onUpdateModalStatus: _ctx.updateModalStatus
        }, null, 8 /* PROPS */, ["modalStatus", "payload", "onUpdateModalStatus"]))
      : _createCommentVNode("v-if", true)
  ]))
}