import { Enrolment } from '@/models/enrolment';
import { CsvExportResult } from './csv-export-result';
import { CsvSeparators, CsvSeparatorNames } from '@/models/i-csv-separators';
import store from '@/store';

export abstract class CsvExport {
	public get headerString(): string {
		return this.createHeaderCells().join(', ');
	}

	protected constructor(
		public readonly label: string,
		public readonly details?: string
	) {}

	public generate(enrolments: Enrolment[]): CsvExportResult {
		const exportableEnrolments = this.findExportableEnrolments(enrolments);
		const exportRows = this.createExportRows(exportableEnrolments);

		const exportContent = this.writeToString(exportRows);

		return new CsvExportResult(
			this.determineExportFileName(enrolments),
			new Blob([exportContent], {
				type: 'text/csv;charset=UTF-8'
			}),
			1 === exportRows.length
		);
	}

	private writeToString(rows: string[][]): string {
		const separator = this.retrieveRelevantSeparator();
		// Separate cells with semicolons, but also remove new-line characters that were left.
		return `${rows
			.map((rawRow) => {
				return rawRow
					.map((rawCellValue) => `"${rawCellValue}"`)
					.join(separator)
					.split('\n')
					.join(' ');
			})
			.join('\n')}`;
	}

	private determineExportFileName(enrolments: Enrolment[]): string {
		if (0 === enrolments.length) {
			return `${this.determineEmptyExportFileName()}.csv`;
		}

		const firstEnrolment = enrolments[0];

		return `${this.determineExportFileNameFromEnrolment(firstEnrolment)}.csv`;
	}

	private createExportRows(enrolments: Enrolment[]): string[][] {
		const header = this.createHeaderCells();
		const lines = enrolments.map((_) => this.createEnrolmentLineCells(_));

		return [header, ...lines];
	}

	private retrieveRelevantSeparator(): CsvSeparators {
		const separatorName = store.getters.selectedCsvSeparator();

		switch (separatorName) {
			case CsvSeparatorNames.COMMA:
				return CsvSeparators.COMMA;
			case CsvSeparatorNames.SEMICOLON:
				return CsvSeparators.SEMICOLON;
		}
	}

	protected abstract findExportableEnrolments(enrolments: Enrolment[]): Enrolment[];
	protected abstract createHeaderCells(): string[];
	protected abstract createEnrolmentLineCells(enrolment: Enrolment): string[];
	protected abstract determineEmptyExportFileName(): string;
	protected abstract determineExportFileNameFromEnrolment(enrolment: Enrolment): string;
}
