import { TMassEditablePropertiesCluster, MassEditablePropertiesEnum } from '@/models/i-mass-editable-properties-cluster';

const massEditablePropertiesCluster: TMassEditablePropertiesCluster = {
	[MassEditablePropertiesEnum.COUNTRY_OF_RESIDENCE]: {
		key: MassEditablePropertiesEnum.COUNTRY_OF_RESIDENCE,
		label: 'Country of residence',
		relevant: false,
		valid: true,
		invalidMessage: "Please select the students' country of residence"
	},
	[MassEditablePropertiesEnum.PROGRAMME_LEVEL]: {
		key: MassEditablePropertiesEnum.PROGRAMME_LEVEL,
		label: 'Degree',
		relevant: false,
		valid: true,
		invalidMessage: 'Please select a valid programme level'
	},
	[MassEditablePropertiesEnum.INTAKE_MONTH]: {
		key: MassEditablePropertiesEnum.INTAKE_MONTH,
		label: 'Intake month',
		relevant: false,
		valid: true,
		invalidMessage: 'Please select a valid intake month'
	},
	[MassEditablePropertiesEnum.INTAKE_YEAR]: {
		key: MassEditablePropertiesEnum.INTAKE_YEAR,
		label: 'Intake year',
		relevant: false,
		valid: true,
		invalidMessage: 'Please select a valid intake year'
	},
	[MassEditablePropertiesEnum.PROGRAMME_NAME]: {
		key: MassEditablePropertiesEnum.PROGRAMME_NAME,
		label: 'Programme name',
		relevant: false,
		valid: true,
		invalidMessage: 'Please provide a name'
	},
	[MassEditablePropertiesEnum.STUDENT_NATIONALITY]: {
		key: MassEditablePropertiesEnum.STUDENT_NATIONALITY,
		label: 'Student nationality',
		relevant: false,
		valid: true,
		invalidMessage: "Please specify the students' nationality"
	},
	[MassEditablePropertiesEnum.TUITION_FEE_CURRENCY]: {
		key: MassEditablePropertiesEnum.TUITION_FEE_CURRENCY,
		label: 'Tuition fee currency',
		relevant: false,
		valid: true,
		invalidMessage: 'Please select a valid currency'
	},
	[MassEditablePropertiesEnum.TUITION_FEE]: {
		key: MassEditablePropertiesEnum.TUITION_FEE,
		label: 'Tuition fee',
		relevant: false,
		valid: true,
		invalidMessage: 'Please provide a valid tuition fee'
	}
};

export { massEditablePropertiesCluster };
