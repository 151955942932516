import { Component, defineComponent, watch } from 'vue';

import EnrolmentsTableFilter from '@/presentation/components/internal/enrolments-table-filter/enrolments-table-filter.vue';
import SearchEnrolmentsBar from '@/presentation/components/generalizations/search-enrolments-bar/search-enrolments-bar.vue';
import InternalEnrolmentsExport from '@/presentation/components/internal/internal-enrolments-export/internal-enrolments-export.vue';
import ContractDetailsButton
	from '@/presentation/components/internal/contract-details/contract-details-button/contract-details-button.vue';
import ColumnSavingButtons from '@/presentation/components/internal/column-saving-buttons/column-saving-buttons.vue';
import EnrolmentMenuComponent from '@/presentation/components/internal/enrolment-menu/enrolment-menu-class';

export default defineComponent({
	components: {
		EnrolmentsTableFilter: EnrolmentsTableFilter as Component,
		SearchEnrolmentsBar: SearchEnrolmentsBar as Component,
		ContractDetailsButton: ContractDetailsButton as Component,
		InternalEnrolmentsExport: InternalEnrolmentsExport as Component,
		ColumnSavingButtons: ColumnSavingButtons as Component
	},
	setup: () => {
		const component = new EnrolmentMenuComponent();

		watch(component.filteredEnrolments, component.checkExportPossibility.bind(component));

		return {
			buttonsEnabled: component.buttonsEnabled
		};
	}
});
