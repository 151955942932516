import { TransitionTrigger } from '@/models/transitions/triggers/transition-trigger';

import { Enrolment } from './enrolment';
import FulfillmentStepIndication from './fulfillment-step-indication';
import { NullTrigger } from './transitions/triggers/null-trigger';

export class FulfillmentStep {
	private triggers: TransitionTrigger[] = [];

	public constructor(
		public readonly fulfillmentStepIndication: FulfillmentStepIndication,
		public readonly name: string
	) {}

	public registerTrigger(trigger: TransitionTrigger): void {
		if (this.containsTrigger(trigger) || trigger instanceof NullTrigger) {
			return;
		}

		this.triggers.push(trigger);
	}

	public containsTrigger(trigger: TransitionTrigger): boolean {
		return this.triggers.some((_) => _.equals(trigger));
	}

	public availableTriggersForEnrolment(enrolment: Enrolment): Readonly<TransitionTrigger[]> {
		return this.triggers.filter((_) => _.isAvailableForEnrolment(enrolment));
	}

	public equals(that: FulfillmentStep): boolean {
		return this.isIndicatedBy(that.fulfillmentStepIndication);
	}

	public isIndicatedBy(indication: FulfillmentStepIndication): boolean {
		return this.fulfillmentStepIndication === indication;
	}
}
