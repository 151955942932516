import { Component, defineComponent, onMounted, onUnmounted, onBeforeMount } from 'vue';
import { Monthpicker } from '@studyportals/monthpicker';
import OptionalFilterSectionIntakeComponent
	from '@/presentation/components/generalizations/optional-filters/optional-filter-section-intake/optional-filter-section-intake-class';

export default defineComponent({
	components: {
		Monthpicker: Monthpicker as Component
	},
	setup: () => {
		const component = new OptionalFilterSectionIntakeComponent();

		onBeforeMount(component.beforeMounted.bind(component));
		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		return {
			selectedMonth: component.selectedMonth,
			selectedYear: component.selectedYear,
			showValidation: component.showValidation,
			numberOfYearsInPast: component.numberOfYearsInPast,
			numberOfYearsInFuture: component.numberOfYearsInFuture,
			storeDate: component.storeDate.bind(component)
		};
	}
});
