import { Component, defineComponent, onMounted } from 'vue';
import EnrolmentsReviewSection from '@/presentation/components/client/enrolments-review-section/enrolments-review-section.vue';
import EnrolmentsTableFilter from '@/presentation/components/client/enrolments-table-filter/enrolments-table-filter.vue';
import OptionalFiltersWrapper
	from '@/presentation/components/generalizations/optional-filters/optional-filters-wrapper/optional-filters-wrapper.vue';
import SubmitReview from '@/presentation/components/client/submit-review/submit-review.vue';
import ClientEnrolmentsExport from '@/presentation/components/client/client-enrolments-export/client-enrolments-export.vue';
import MassReviewIncentive from '@/presentation/components/client/mass-review-incentive/mass-review-incentive.vue';
import LoadingModal from '@/presentation/components/generalizations/loading-modal/loading-modal.vue';
import ClientReviewOverviewComponent from '@/presentation/views/client/client-review-overview/client-review-overview-class';
import PartnershipSelector from '@/presentation/components/client/partnership-selector/partnership-selector.vue';
import SearchBarWrapper from '@/presentation/components/client/search-bar-wrapper/search-bar-wrapper.vue';
import MassEdit from '@/presentation/components/client/mass-edit/mass-edit.vue';
import HighlightedFeaturesIntroductionModal
	from '@/presentation/components/client/highlighted-features-introduction-modal/highlighted-features-introduction-modal.vue';

export default defineComponent({
	components: {
		EnrolmentsTableFilter: EnrolmentsTableFilter as Component,
		OptionalFiltersWrapper: OptionalFiltersWrapper as Component,
		SubmitReview: SubmitReview as Component,
		EnrolmentsReviewSection: EnrolmentsReviewSection as Component,
		ClientEnrolmentsExport: ClientEnrolmentsExport as Component,
		MassReviewIncentive: MassReviewIncentive as Component,
		PartnershipSelector: PartnershipSelector as Component,
		SearchBarWrapper: SearchBarWrapper as Component,
		MassEdit: MassEdit as Component,
		HighlightedFeaturesIntroductionModal: HighlightedFeaturesIntroductionModal as Component,
		LoadingModal: LoadingModal as Component
	},
	setup: () => {
		const component = new ClientReviewOverviewComponent();

		onMounted(component.mounted.bind(component));

		return {
			modalStatusLoadingIsInactive: component.modalStatusLoadingIsInactive,
			organisationName: component.organisationName
		};
	}
});
