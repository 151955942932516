import { defineComponent, onMounted, watch } from 'vue';
import MoveEnrolmentsToHistoryComponent
	from '@/presentation/components/internal/move-enrolments-to-history/move-enrolments-to-history-class';

export default defineComponent({
	setup: (props, context) => {
		const component = new MoveEnrolmentsToHistoryComponent(context.emit);

		onMounted(component.mounted.bind(component));

		watch(component.selectedEnrolments, component.onSelectedEnrolmentsChanged.bind(component), { deep: true });

		return {
			isCollapsed: component.isCollapsed,
			isMoveEnrolmentAvailable: component.isMoveEnrolmentAvailable,
			isExpanded: component.isExpanded,
			expand: component.expand.bind(component),
			collapse: component.collapse.bind(component),
			onMoveConfirmed: component.onMoveConfirmed.bind(component),
			onMoveCanceled: component.onMoveCanceled.bind(component)
		};
	}
});
