import { SubscriptionTokenEnums } from '@/models/enrolment-subscriptions-enums';

interface IEnrolmentSubscriptionTokens {
	[SubscriptionTokenEnums.REVIEW_SUBMITTED]: string;
}

class EnrolmentSubscriptionTokens implements IEnrolmentSubscriptionTokens {
	public [SubscriptionTokenEnums.REVIEW_SUBMITTED] = '';
}

export { EnrolmentSubscriptionTokens };
