import { computed } from 'vue';
import store from '@/store';
import ContractsPresenter from '@/presenters/contracts-presenter';

export default class ContractDetailsButtonComponent {
	public contractDetailsModalShown = computed((): boolean => {
		return store.getters.contractDetailsModalShown();
	});

	public async showContractDetails(): Promise<void> {
		await ContractsPresenter.retrieveContractDetails();
		store.mutations.updateContractDetailsModalShown(true);
	}
}
