import { LordBusiness } from '@/infrastructure';
import { ITableSelector } from '@/models/i-table-selector';
import { IOrganisationChange } from '@/models/i-organisation-details';
import { DisqualificationReasonSelection } from '@/models/disqualification-reason-selection';
import { EnrichmentSpecification } from '@/models/enrichment-specification';
import { Enrolment } from '@/models/enrolment';
import { Organisation } from '@/models/organisation';
import { SuccessFee } from '@/models/success-fee';
import EventBus from '@/event-bus/event-bus';
import { EnrolmentsAcquiredEvent } from '@/event-bus/enrolments-acquired-event';
import EnrolmentFactory from '@/models/enrolment-factory';
import LoadingPresenter from '@/presenters/loading-presenter';
import { EnrolmentEnrichmentSpecification, EnrolmentNotInScopeReason, PresentationEnrolment, TransitPresentationEnrolment }
from '@studyportals/sp-lord-business-interface';
import { AbstractAndReportExceptionAsync } from '@studyportals/mb-platform-exceptions-aop';
import OrganisationDetailsTransitionHandler from '@/presenters/organisation-details-transition-handler';
import tableSelectorRetriever from '@/presentation/components/generalizations/table-selectors/table-selector-retriever';
import store from '@/store';

class EnrolmentsService {
	private get isInternal(): boolean {
		return store.getters.isInternal();
	}

	private async updateStoredEnrolments(
		lordBusinessRequest: () => Promise<(PresentationEnrolment | TransitPresentationEnrolment)[]>
	): Promise<void> {
		// By creating a new loadingPresenter instance, loading starts and keeps going until it is wrapped up further below.
		LoadingPresenter.startLoading();
		const enrolmentsInput = await lordBusinessRequest();

		const enrolments = EnrolmentFactory.reconstituteEnrolments(
			PresentationEnrolment.fromTransitRepresentationMultiple(enrolmentsInput)
		);

		const updatedEnrolments = this.getUpdatedEnrolments(enrolments);

		LoadingPresenter.finishLoading();

		EventBus.getEvent(EnrolmentsAcquiredEvent).publish(updatedEnrolments);
	}

	private getUpdatedEnrolments(enrolments: Enrolment[]): Enrolment[] {
		const storedEnrolments = store.getters.enrolments();
		const organisationChanges: IOrganisationChange[] = [];

		// Find each of the changed enrolments in the total list of available enrolments and update them there.
		enrolments.forEach((enrolment) => {
			const index = storedEnrolments.findIndex((storedEnrolment) => storedEnrolment.identity === enrolment.identity);

			// If the enrolment has changed organisation, remember it as it is relevant for updating the list of organisation details.
			if (storedEnrolments[index].organisationIdentity !== enrolment.organisationIdentity) {
				organisationChanges.push({
					enrolmentIdentity: enrolment.identity,
					organisationIdentityPrevious: storedEnrolments[index].organisationIdentity,
					organisationIdentityCurrent: enrolment.organisationIdentity
				});
			}

			storedEnrolments[index] = enrolment;
		});

		// Organisation details are only relevant when ERT is used by internal users.
		if (!this.isInternal) {
			return storedEnrolments;
		}

		OrganisationDetailsTransitionHandler.updateOrganisationDetailsAfterTransition(enrolments, organisationChanges);

		return this.excludeIrrelevantEnrolments(storedEnrolments, organisationChanges.length !== 0);
	}

	private excludeIrrelevantEnrolments(enrolments: Enrolment[], organisationChangesDetected: boolean): Enrolment[] {
		// If no organisation changes were detected, all enrolments are still relevant.
		if (!organisationChangesDetected) {
			return enrolments;
		}

		const psmSelector = tableSelectorRetriever.retrievePsmSelector();
		const organisationSelector = tableSelectorRetriever.retrieveOrganisationSelector();
		// If all PSMs and all organisations are selected, all enrolments are still relevant.
		if (organisationSelector.selectedOptionIsDefault.value && psmSelector.selectedOptionIsDefault.value) {
			return enrolments;
		}

		const noWorkOrderOrganisationIds = store.getters.noWorkOrderOrganisationIds();
		const cluster = store.getters.organisationDetailsCluster();
		const organisationsRelevantForPSM = cluster.listOfDetails
			.filter((organisation) => organisation.psmOwner === psmSelector.selectedOption.value)
			.map((organisation) => organisation.organisationIdentity);

		return enrolments.filter((enrolment) => {
			return this.isEnrolmentStillRelevant(enrolment, organisationSelector, noWorkOrderOrganisationIds, organisationsRelevantForPSM);
		});
	}

	private isEnrolmentStillRelevant(
		enrolment: Enrolment,
		organisationSelector: ITableSelector,
		noWorkOrderOrganisationIds: string[],
		organisationsRelevantForPSM: string[]
	): boolean {
		// The organisation of the enrolment matches the selected organisation, so it is relevant.
		if (enrolment.organisationIdentity === organisationSelector.selectedOption.value) {
			return true;
		}

		// The enrolment has no work order and the user is looking at the no-work-order enrolments, so it is relevant.
		if (
			organisationSelector.selectedOptionAsOption.value &&
			organisationSelector.selectedOptionAsOption.value.isRemainingOption &&
			noWorkOrderOrganisationIds.includes(enrolment.organisationIdentity)
		) {
			return true;
		}

		// If a specific organisation was selected, the enrolment should have already matched up with the selected option, so exclude it.
		if (!organisationSelector.selectedOptionIsDefault.value) {
			return false;
		}

		// If all organisations are selected for a specific PSM and the enrolment matches one of the PSM's organisations, it is relevant.
		return organisationsRelevantForPSM.includes(enrolment.organisationIdentity);
	}

	@AbstractAndReportExceptionAsync()
	public async triggerEnrolmentInCurrentProcessingRound(enrolments: Readonly<Enrolment[]>): Promise<void> {
		await this.updateStoredEnrolments(() =>
			LordBusiness.batchedTriggerEnrolmentInCurrentProcessingRound(enrolments.map((_) => _.identity))
		);
	}

	@AbstractAndReportExceptionAsync()
	public async triggerEnrolmentNotForInvoicing(enrolments: Readonly<Enrolment[]>): Promise<void> {
		await this.updateStoredEnrolments(() => LordBusiness.batchedTriggerEnrolmentNotForInvoicing(enrolments.map((_) => _.identity)));
	}

	@AbstractAndReportExceptionAsync()
	public async triggerEnrolmentNotTrusted(enrolments: Readonly<Enrolment[]>): Promise<void> {
		await this.updateStoredEnrolments(() => LordBusiness.batchedTriggerEnrolmentNotTrusted(enrolments.map((_) => _.identity)));
	}

	@AbstractAndReportExceptionAsync()
	public async triggerEnrolmentAlreadyProcessed(enrolments: Readonly<Enrolment[]>): Promise<void> {
		await this.updateStoredEnrolments(() => LordBusiness.batchedTriggerEnrolmentAlreadyProcessed(enrolments.map((_) => _.identity)));
	}

	@AbstractAndReportExceptionAsync()
	public async triggerEnrolmentNotYetInvoiceable(enrolments: Readonly<Enrolment[]>): Promise<void> {
		await this.updateStoredEnrolments(() => LordBusiness.batchedTriggerEnrolmentNotYetInvoiceable(enrolments.map((_) => _.identity)));
	}

	@AbstractAndReportExceptionAsync()
	public async triggerEnrolmentQualified(enrolments: Readonly<Enrolment[]>): Promise<void> {
		await this.updateStoredEnrolments(() => LordBusiness.batchedTriggerEnrolmentQualified(enrolments.map((_) => _.identity)));
	}

	@AbstractAndReportExceptionAsync()
	public async triggerEnrolmentInformationMissing(enrolments: Readonly<Enrolment[]>): Promise<void> {
		await this.updateStoredEnrolments(() => LordBusiness.batchedTriggerEnrolmentInformationMissing(enrolments.map((_) => _.identity)));
	}

	@AbstractAndReportExceptionAsync()
	public async triggerEnrolmentDisqualified(
		enrolments: Readonly<Enrolment[]>,
		reasons: DisqualificationReasonSelection,
		note?: string
	): Promise<void> {
		await this.updateStoredEnrolments(() =>
			LordBusiness.batchedTriggerEnrolmentDisqualified(
				enrolments.map((_) => _.identity),
				reasons.toValues(),
				note
			)
		);
	}

	@AbstractAndReportExceptionAsync()
	public async triggerEnrolmentReadyForConfirmation(enrolments: Readonly<Enrolment[]>): Promise<void> {
		await this.updateStoredEnrolments(() =>
			LordBusiness.batchedTriggerEnrolmentReadyForConfirmation(enrolments.map((_) => _.identity))
		);
	}

	@AbstractAndReportExceptionAsync()
	public async triggerEnrolmentConfirmationRequested(enrolments: Readonly<Enrolment[]>): Promise<void> {
		await this.updateStoredEnrolments(() =>
			LordBusiness.batchedTriggerEnrolmentConfirmationRequested(enrolments.map((_) => _.identity))
		);
	}

	@AbstractAndReportExceptionAsync()
	public async triggerEnrolmentConfirmed(
		enrolments: Readonly<Enrolment[]>,
		enrolmentEnrichmentSpecification: EnrolmentEnrichmentSpecification,
		successFee: SuccessFee | null | undefined,
		enrolmentNotInScopeReasons: EnrolmentNotInScopeReason[] | undefined,
		note?: string
	): Promise<void> {
		await this.updateStoredEnrolments(() =>
			LordBusiness.batchedTriggerEnrolmentConfirmed(
				enrolments.map((_) => _.identity),
				enrolmentEnrichmentSpecification,
				successFee ? successFee.value : undefined,
				enrolmentNotInScopeReasons,
				note
			)
		);
	}

	@AbstractAndReportExceptionAsync()
	public async triggerEnrolmentDeferred(
		enrolments: Readonly<Enrolment[]>,
		enrolmentEnrichmentSpecification: EnrolmentEnrichmentSpecification
	): Promise<void> {
		await this.updateStoredEnrolments(() =>
			LordBusiness.batchedTriggerEnrolmentDeferred(
				enrolments.map((_) => _.identity),
				enrolmentEnrichmentSpecification
			)
		);
	}

	@AbstractAndReportExceptionAsync()
	public async triggerEnrolmentNotRecognized(enrolments: Readonly<Enrolment[]>, note: string): Promise<void> {
		await this.updateStoredEnrolments(() =>
			LordBusiness.batchedTriggerEnrolmentNotRecognized(
				enrolments.map((_) => _.identity),
				note
			)
		);
	}

	@AbstractAndReportExceptionAsync()
	public async triggerStudentNotRecognized(enrolments: Readonly<Enrolment[]>): Promise<void> {
		await this.updateStoredEnrolments(() => LordBusiness.batchedTriggerStudentNotRecognized(enrolments.map((_) => _.identity)));
	}

	@AbstractAndReportExceptionAsync()
	public async triggerEnrolmentNotDuplicated(enrolments: Readonly<Enrolment[]>, note: string): Promise<void> {
		await this.updateStoredEnrolments(() =>
			LordBusiness.batchedTriggerEnrolmentNotDuplicated(
				enrolments.map((_) => _.identity),
				note
			)
		);
	}

	@AbstractAndReportExceptionAsync()
	public async triggerEnrolmentDuplicationConfirmed(enrolments: Readonly<Enrolment[]>, note: string): Promise<void> {
		await this.updateStoredEnrolments(() =>
			LordBusiness.batchedTriggerEnrolmentDuplicationConfirmed(
				enrolments.map((_) => _.identity),
				note
			)
		);
	}

	@AbstractAndReportExceptionAsync()
	public async triggerEnrolmentConfirmationUnsure(enrolments: Readonly<Enrolment[]>, note: string): Promise<void> {
		await this.updateStoredEnrolments(() =>
			LordBusiness.batchedTriggerEnrolmentConfirmationUnsure(
				enrolments.map((_) => _.identity),
				note
			)
		);
	}

	@AbstractAndReportExceptionAsync()
	public async triggerEnrolmentConfirmationChallenged(enrolments: Readonly<Enrolment[]>, note: string): Promise<void> {
		await this.updateStoredEnrolments(() =>
			LordBusiness.batchedTriggerEnrolmentConfirmationChallenged(
				enrolments.map((_) => _.identity),
				note
			)
		);
	}

	@AbstractAndReportExceptionAsync()
	public async triggerEnrolmentConfirmationAccepted(enrolments: Readonly<Enrolment[]>): Promise<void> {
		await this.updateStoredEnrolments(() =>
			LordBusiness.batchedTriggerEnrolmentConfirmationAccepted(enrolments.map((_) => _.identity))
		);
	}
	@AbstractAndReportExceptionAsync()
	public async triggerEnrolmentRevised(enrolments: Readonly<Enrolment[]>): Promise<void> {
		await this.updateStoredEnrolments(() => LordBusiness.batchedTriggerEnrolmentRevised(enrolments.map((_) => _.identity)));
	}

	@AbstractAndReportExceptionAsync()
	public async createInvoicingBatch(enrolments: Readonly<Enrolment[]>, organisationIdentity: string): Promise<void> {
		await this.updateStoredEnrolments(() =>
			LordBusiness.batchedCreateInvoicingBatch(
				enrolments.map((_) => _.identity),
				organisationIdentity
			)
		);
	}

	@AbstractAndReportExceptionAsync()
	public async enrich(enrolment: Enrolment, enrichmentSpecification: EnrichmentSpecification): Promise<void> {
		await this.updateStoredEnrolments(() =>
			LordBusiness.enrich(enrolment.identity, enrichmentSpecification.toTransportRepresentation())
		);
	}

	@AbstractAndReportExceptionAsync()
	public async moveEnrolmentsToOrganisation(enrolments: Readonly<Enrolment[]>, organisation: Organisation): Promise<void> {
		await this.updateStoredEnrolments(() =>
			LordBusiness.batchedChangeEnrolmentOrganisation(
				enrolments.map((_) => _.identity),
				organisation.identity
			)
		);
	}

	@AbstractAndReportExceptionAsync()
	public async moveEnrolmentsToHistory(enrolments: Readonly<Enrolment[]>): Promise<void> {
		await this.updateStoredEnrolments(() => LordBusiness.batchedTriggerEnrolmentPlacedInHistory(enrolments.map((_) => _.identity)));
	}

	@AbstractAndReportExceptionAsync()
	public async retrieveSuspectedDuplicates(enrolment: Enrolment): Promise<void> {
		const email = enrolment.studentEmail;
		const enrolments = await LordBusiness.retrieveDuplicateEnrolments(email ? email : '');
		const reconstitutedEnrolments = EnrolmentFactory.reconstituteEnrolments(enrolments);

		// Iterate over the enrolments until the right enrolment is found.
		for (let i = 0; i < reconstitutedEnrolments.length; i++) {
			// The enrolment with the same identity as the one we used initially to retrieve suspected duplicates should be shown first.
			if (reconstitutedEnrolments[i].identity === enrolment.identity) {
				// Remove this enrolment and put it at the beginning of the list.
				const selectedEnrolment = reconstitutedEnrolments.splice(i, 1)[0];
				reconstitutedEnrolments.unshift(selectedEnrolment);
				break;
			}
		}

		store.mutations.updateSuspectedDuplicateEnrolments(reconstitutedEnrolments);
	}
}

export default new EnrolmentsService();
