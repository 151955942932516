import { computed } from 'vue';
import { Enrolment } from '@/models/enrolment';
import { SuccessFee } from '@/models/success-fee';
import store from '@/store';

export default class InvoiceSummaryComponent {
	public selectedEnrolments = computed((): Enrolment[] => {
		return store.getters.selectedEnrolments();
	});

	public organisationIdentity = computed((): string => {
		return this.selectedEnrolments.value[0].organisationIdentity;
	});

	public organisationName = computed((): string => {
		return this.selectedEnrolments.value[0].organisationName;
	});

	public successFees = computed((): SuccessFee[] => {
		return this.selectedEnrolments.value && this.selectedEnrolments.value.length > 0
			? this.selectedEnrolments.value.map((_) => _.getSuccessFee())
			: [];
	});

	public getNumberOfRegularSuccessFee(): number {
		return this.successFees.value.filter((_) => _.isRegularFee()).length;
	}

	public getNumberOfReducedBecauseAgentInvolvedSuccessFee(): number {
		return this.successFees.value.filter((_) => _.isReducedBecauseAgentInvolvedFee()).length;
	}

	public getNumberOfReducedBecauseNationalitySuccessFee(): number {
		return this.successFees.value.filter((_) => _.isReducedBecauseNationalityFee()).length;
	}

	public getNumberOfOtherSuccessFee(): number {
		return this.successFees.value.filter((_) => _.isOtherSuccessFee()).length;
	}
}
