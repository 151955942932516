import store from '@/store';

class LoadingPresenter {
	private timeLoadInitiated: number = Date.now();

	public startLoading(): void {
		this.timeLoadInitiated = Date.now();
		store.mutations.setLoading(true);
	}

	public finishLoading(): void {
		// The animation should be shown at least 1.1 seconds (1 full loop); if it was shown for less time, wait for enough time to elapse.
		const remainingTimeToShowLoader = this.timeLoadInitiated + 1100 - Date.now();

		if (remainingTimeToShowLoader > 0) {
			setTimeout(() => store.mutations.setLoading(false), remainingTimeToShowLoader);
		} else {
			store.mutations.setLoading(false);
		}
	}
}

export default new LoadingPresenter();
