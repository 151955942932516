import { computed, ref, Ref } from 'vue';
import EventBus from '@/event-bus/event-bus';
import { ComplexModal } from '@studyportals/modal';
import { ModalStatusSubmitReviewEnums } from '@/models/modal-status-submit-review-enum';
import { SubmitReviewTriggeredEvent } from '@/event-bus/submit-review-triggered-event';

export default class SubmitReviewModalComponent {
	public modalContent: Ref<HTMLElement | null> = ref(null);
	private modal: Ref<ComplexModal | undefined> = ref(undefined);
	private modalStatus = ref(ModalStatusSubmitReviewEnums.INACTIVE);

	constructor(
		private emit: (name: string, payload: ModalStatusSubmitReviewEnums) => void,
		modalStatus: ModalStatusSubmitReviewEnums
	) {
		this.modalStatus.value = modalStatus;
	}

	public modalStatusIsConfirmation = computed((): boolean => {
		return this.modalStatus.value === ModalStatusSubmitReviewEnums.CONFIRMATION;
	});

	public modalStatusIsLater = computed((): boolean => {
		return this.modalStatus.value === ModalStatusSubmitReviewEnums.LATER;
	});

	public mounted(): void {
		this.prepareModal();
	}

	public unmounted(): void {
		window.ModalManager.close(this.modal.value);
	}

	public triggerSubmitReview(): void {
		EventBus.getEvent(SubmitReviewTriggeredEvent).publish();
	}

	public showSubmitReviewLaterModal(): void {
		this.modalStatus.value = ModalStatusSubmitReviewEnums.LATER;
	}

	public hideModal(): void {
		this.emit('hideModal', ModalStatusSubmitReviewEnums.INACTIVE);
	}

	private prepareModal(): void {
		if (!this.modalContent.value) {
			return;
		}

		this.modal.value = new ComplexModal(this.modalContent.value, {
			cssClassName: 'SubmitReviewModalWrapper',
			destroyOnClose: false,
			onClose: this.hideModal.bind(this)
		});

		window.ModalManager.open(this.modal.value);
	}
}
