import { defineComponent } from 'vue';
import { EnrolmentReview } from '@/models/enrolment-review/enrolment-review';
import EnrolmentReviewPopupButtonsComponent
	from '@/presentation/components/generalizations/enrolment-review-form/enrolment-review-popup-buttons-class';

export default defineComponent({
	props: {
		enrolmentReview: Object
	},
	setup: (props) => {
		const component = new EnrolmentReviewPopupButtonsComponent(props.enrolmentReview as EnrolmentReview);

		return {
			enrolmentReview: component.enrolmentReview,
			setTuitionFeeStepSkippingAs: component.setTuitionFeeStepSkippingAs.bind(component),
			previous: component.previous.bind(component),
			submit: component.submit.bind(component),
			next: component.next.bind(component)
		};
	}
});
