import { defineComponent, onMounted, onUnmounted, watch } from 'vue';
import { Enrolment } from '@/models/enrolment';
import { CsvExport } from '@/presentation/components/generalizations/enrolment-export/csv-export';
import EnrolmentExportModalComponent from '@/presentation/components/generalizations/enrolment-export-modal/enrolment-export-modal-class';

export default defineComponent({
	props: {
		isShown: Boolean,
		exportOptions: Array,
		enrolments: Array
	},
	setup: (props, context) => {
		const component = new EnrolmentExportModalComponent(
			context.emit,
			props.enrolments as Enrolment[],
			props.exportOptions as CsvExport[]
		);

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		watch(component.selectedExportOption, component.onSelectedExportOptionChanged.bind(component));
		watch(component.selectedCsvSeparator, component.onSelectedExportOptionChanged.bind(component));

		return {
			availableCsvSeparators: component.availableCsvSeparators,
			modalContent: component.modalContent,
			selectedExportOption: component.selectedExportOption,
			csvExportEmpty: component.csvExportEmpty,
			textPresenter: component.textPresenter,
			onExportDownloaded: component.onExportDownloaded.bind(component),
			onExportCanceled: component.onExportCanceled.bind(component),
			updateSelectedCsvSeparator: component.updateSelectedCsvSeparator.bind(component),
			isSeparatorSelected: component.isSeparatorSelected.bind(component),
			toggleLearnMore: component.toggleLearnMore.bind(component),
			isLearnMoreToggled: component.isLearnMoreToggled.bind(component)
		};
	}
});
