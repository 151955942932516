import { Component, defineComponent } from 'vue';
import OptionalFilterSectionTuitionFee
	from '@/presentation/components/generalizations/optional-filters/optional-filter-section-tuition-fee/optional-filter-section-tuition-fee.vue';
import OptionalFilterSectionStatus
	from '@/presentation/components/generalizations/optional-filters/optional-filter-section-status/optional-filter-section-status.vue';
import OptionalFilterSectionNationality
	from '@/presentation/components/generalizations/optional-filters/optional-filter-section-nationality/optional-filter-section-nationality.vue';
import OptionalFilterSectionIntakePeriod
	from '@/presentation/components/generalizations/optional-filters/optional-filter-section-intake/optional-filter-section-intake.vue';
import OptionalFilterSectionSource
	from '@/presentation/components/generalizations/optional-filters/optional-filter-section-source/optional-filter-section-source.vue';
import OptionalFiltersPanelComponent
	from '@/presentation/components/generalizations/optional-filters/optional-filters-panel/optional-filters-panel-class';

export default defineComponent({
	components: {
		OptionalFilterSectionTuitionFee: OptionalFilterSectionTuitionFee as Component,
		OptionalFilterSectionStatus: OptionalFilterSectionStatus as Component,
		OptionalFilterSectionIntakePeriod: OptionalFilterSectionIntakePeriod as Component,
		OptionalFilterSectionNationality: OptionalFilterSectionNationality as Component,
		OptionalFilterSectionSource: OptionalFilterSectionSource as Component
	},
	setup: (props, context) => {
		const component = new OptionalFiltersPanelComponent(context.emit);

		return {
			optionalFilterKeyTuitionFee: component.optionalFilterKeyTuitionFee,
			optionalFilterKeyEnrolmentStatus: component.optionalFilterKeyEnrolmentStatus,
			optionalFilterKeyIntakePeriod: component.optionalFilterKeyIntakePeriod,
			optionalFilterKeyStudentNationality: component.optionalFilterKeyStudentNationality,
			optionalFilterKeySource: component.optionalFilterKeySource,
			availableFilters: component.availableFilters,
			selectedOptionalFilter: component.selectedOptionalFilter,
			isFilterSelected: component.isFilterSelected.bind(component),
			isFilterRelevantForUser: component.isFilterRelevantForUser.bind(component),
			selectOptionalFilter: component.selectOptionalFilter.bind(component),
			doesFilterHaveSelectedOption: component.doesFilterHaveSelectedOption.bind(component),
			retrieveDisplayRelatedClassName: component.retrieveDisplayRelatedClassName.bind(component),
			applyFilters: component.applyFilters.bind(component),
			closePanel: component.closePanel.bind(component)
		};
	}
});
