import { DisqualificationReason as DisqualificationReasonEnum } from '@studyportals/sp-lord-business-interface';

import { DisqualificationReason } from './disqualification-reason';

export class DisqualificationReasonSelection {
	public constructor(public readonly reasons: DisqualificationReason[] = []) {}

	public isEmpty(): boolean {
		return 0 === this.reasons.length;
	}

	public requiresAdditionalExplanation(): boolean {
		return this.reasons.some((_) => _.requiresAdditionalExplanation);
	}

	public toValues(): Readonly<DisqualificationReasonEnum[]> {
		return this.reasons.map((_) => _.value);
	}

	public toggle(reason: DisqualificationReason): void {
		if (this.isSelected(reason)) {
			this.deselect(reason);
		} else {
			this.reasons.push(reason);
		}
	}

	public deselect(reason: DisqualificationReason): void {
		const index = this.reasons.findIndex((_) => _.equals(reason));

		if (-1 !== index) {
			this.reasons.splice(index, 1);
		}
	}

	public isSelected(reason: DisqualificationReason): boolean {
		return this.reasons.some((_) => _.equals(reason));
	}
}
