import { computed, Component } from 'vue';
import { RouteEnums } from '@/models/route-enums';
import { TRoutes, IRouter } from '@/models/i-router';
import store from '@/store';

import InternalIdentifiedEnrolmentsOverview
	from '@/presentation/views/internal/internal-identified-enrolments-overview/internal-identified-enrolments-overview.vue';
import InternalHistoryOverview from '@/presentation/views/internal/internal-history-overview/internal-history-overview.vue';

export default class InternalEnrolmentsOverviewComponent {
	private routes: TRoutes = {
		[RouteEnums.IDENTIFIED_ENROLMENTS]: InternalIdentifiedEnrolmentsOverview as Component,
		[RouteEnums.REVIEW_OVERVIEW]: InternalIdentifiedEnrolmentsOverview as Component,
		[RouteEnums.HISTORY]: InternalHistoryOverview as Component,
		[RouteEnums.QUICK_GUIDE]: InternalIdentifiedEnrolmentsOverview as Component
	};

	public standAloneLogin = computed((): boolean => {
		return store.state.standAloneLogin;
	});

	public router = computed((): IRouter => {
		return store.getters.router();
	});

	// eslint-disable-next-line @typescript-eslint/naming-convention
	public CurrentComponent = computed((): Component => {
		return this.routes[this.router.value.current];
	});
}
