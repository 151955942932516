import { computed, watch } from 'vue';
import { DefaultTableSelectorOptions } from '@/models/i-table-selector';
import TableSelectorOption from '@/presentation/components/generalizations/table-selectors/table-selector-option';
import { RouteEnums } from '@/models/route-enums';
import store from '@/store';
import { IProjectSuccessManager } from '@/models/i-project-success-manager';
import TableSelector from '@/presentation/components/generalizations/table-selectors/table-selector';

export default class TableSelectorPsm extends TableSelector {
	get defaultOptions(): TableSelectorOption[] {
		return [new TableSelectorOption(DefaultTableSelectorOptions.DEFAULT, 'All PSMs')];
	}

	private selectedIdentityPsm = computed((): string => {
		return store.getters.selectedIdentityPsm();
	});

	private currentRoute = computed((): RouteEnums => {
		const router = store.getters.router();
		return router.current;
	});

	private projectSuccessManagers = computed((): IProjectSuccessManager[] => {
		return store.getters.projectSuccessManagers();
	});

	constructor() {
		super();

		watch(this.selectedIdentityPsm, this.setSelectedPSMAsSelectedOption.bind(this));
		watch(this.currentRoute, this.setSelectedPSMAsSelectedOption.bind(this));
		watch(this.projectSuccessManagers, this.setOptions.bind(this));
	}

	public createOptions(): TableSelectorOption[] {
		return this.projectSuccessManagers.value.map((psm) => new TableSelectorOption(psm.id, psm.name));
	}

	private setSelectedPSMAsSelectedOption(): void {
		this.selectedOption.value = this.selectedIdentityPsm.value;
	}
}
