import EventBus from '@/event-bus/event-bus';
import { computed } from 'vue';
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';
import { Enrolment } from '@/models/enrolment';
import { ClearEnrolmentSelectionRequestedEvent } from '@/event-bus/clear-enrolment-selection-requested-event';
import { ClientEnrolmentReviewStartedEvent } from '@/event-bus/client-enrolment-review-started-event';
import { EnrolmentReviewStartedEventPayload } from '@/event-bus/enrolment-review-started-event-payload';
import { EnrolmentConfirmationStatus } from '@/presentation/components/generalizations/custom-table-components/enrolment-confirmation-status/enrolment-confirmation-status';
import store from '@/store';

export class EnrolmentReviewEntry implements ICellRendererComp {
	private outerWrapper: HTMLSpanElement;
	private params: ICellRendererParams | null = null;
	private reviewButton: HTMLButtonElement | null = null;
	private buttonListener: () => void;

	private enrolment = computed((): Enrolment | undefined => {
		if (!this.params?.data) {
			return;
		}

		return this.params.data as Enrolment;
	});

	private enrolmentChallengedTooltipText = computed((): string => {
		const note = this.enrolment.value ? this.enrolment.value.confirmationChallengeNote : '';
		return `Why to revisit this enrolment: "${note}"`;
	});

	constructor() {
		this.outerWrapper = document.createElement('span');
		this.outerWrapper.className = 'EnrolmentReviewEntry';
		this.buttonListener = this.startReview.bind(this);
	}

	public init(params: ICellRendererParams): void {
		this.params = params;

		if (this.enrolment.value?.isAwaitingReview()) {
			this.addReviewIncentive();
		} else {
			this.addEditReviewIncentive();
		}
	}

	public getGui(): HTMLSpanElement {
		return this.outerWrapper;
	}

	public refresh(): boolean {
		return false;
	}

	public destroy(): void {
		if (!this.reviewButton) {
			return;
		}

		this.reviewButton.removeEventListener('click', this.buttonListener);
	}

	private addReviewIncentive(): void {
		const element = document.createElement('div');
		element.className = 'Review';
		element.id = `enrolment-${this.enrolment.value?.identity || ''}`;

		this.reviewButton = document.createElement('button');
		this.reviewButton.className = 'DriverButton Green ReviewEnrolment';
		this.reviewButton.addEventListener('click', this.buttonListener);
		this.reviewButton.innerHTML = 'Review';

		element.appendChild(this.reviewButton);
		this.outerWrapper.appendChild(element);
	}

	private addEditReviewIncentive(): void {
		const outerElement = document.createElement('div');
		outerElement.className = 'EditReview';
		outerElement.id = `enrolment-${this.enrolment.value?.identity || ''}`;

		const innerElement = document.createElement('div');
		innerElement.className = 'EditReviewActions';

		const confirmationStatus = new EnrolmentConfirmationStatus(this.enrolment.value, outerElement);
		confirmationStatus.constructElement();

		this.addChallengedBackButtonIfRelevant(innerElement);

		outerElement.appendChild(innerElement);
		this.outerWrapper.appendChild(outerElement);
	}

	private addChallengedBackButtonIfRelevant(parent: HTMLDivElement): void {
		if (this.enrolment.value?.confirmationChallengeNote === '' || !this.enrolment.value?.isSharedWithClient()) {
			return;
		}

		const button = document.createElement('button');
		button.className = 'ExitButton NoteChallengedBack';

		const icon = document.createElement('i');
		icon.className = 'lnr-bubble';
		icon.title = this.enrolmentChallengedTooltipText.value;

		button.appendChild(icon);
		parent.appendChild(button);
	}

	private startReview(): void {
		if (!this.enrolment.value) {
			return;
		}

		EventBus.getEvent(ClearEnrolmentSelectionRequestedEvent).publish();
		store.mutations.selectEnrolment(this.enrolment.value);

		EventBus.getEvent(ClientEnrolmentReviewStartedEvent).publish(
			new EnrolmentReviewStartedEventPayload(store.state.selectedPartnership, [this.enrolment.value])
		);
	}
}
