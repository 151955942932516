import { computed } from 'vue';
import { ClientFiltersAppliedEvent } from '@/event-bus/client-filters-applied-event';
import { OptionalFilters, TAvailableOptionalFilters } from '@/models/optional-filters';
import { ITableFilter } from '@/models/i-table-filter';
import tableSelectorRetriever from '@/presentation/components/generalizations/table-selectors/table-selector-retriever';
import EventBus from '@/event-bus/event-bus';
import store from '@/store';
import NullTableFilter from '@/presentation/components/generalizations/table-selectors/filters/null-table-filter';

export default class OptionalFiltersPanelComponent {
	public availableFilters: TAvailableOptionalFilters = {
		[OptionalFilters.TUITION_FEE]: {
			label: 'Tuition fee',
			icon: 'lnr-bag-coins',
			description: 'Filter by tuition fee to find students based on their tuition fee.'
		},
		[OptionalFilters.ENROLMENT_STATUS]: {
			label: 'Enrolment status',
			icon: 'lnr-file-stats',
			description: 'Filter by enrolment status to find students based on their enrolment.'
		},
		[OptionalFilters.INTAKE_PERIOD]: {
			label: 'Intake period',
			icon: 'lnr-calendar-full',
			description: 'Filter by intake period to find students available during specific periods.'
		},
		[OptionalFilters.STUDENT_NATIONALITY]: {
			label: 'Country',
			icon: 'lnr-earth',
			description: 'Filter by country to find students from specific countries.'
		},
		[OptionalFilters.SOURCE]: {
			label: 'Source',
			icon: 'lnr-magnifier',
			description: 'Filter by source to find students from different channels or referrals.'
		}
	};
	public optionalFilterKeyTuitionFee = OptionalFilters.TUITION_FEE;
	public optionalFilterKeyEnrolmentStatus = OptionalFilters.ENROLMENT_STATUS;
	public optionalFilterKeyIntakePeriod = OptionalFilters.INTAKE_PERIOD;
	public optionalFilterKeyStudentNationality = OptionalFilters.STUDENT_NATIONALITY;
	public optionalFilterKeySource = OptionalFilters.SOURCE;
	private tuitionFilter = tableSelectorRetriever.retrieveTuitionFeeFilter();
	private statusFilter = tableSelectorRetriever.retrieveClientStatusFilter();
	private intakeFilter = tableSelectorRetriever.retrieveIntakePeriodFilter();
	private nationalityFilter = tableSelectorRetriever.retrieveNationalityFilter();
	private sourceFilter = tableSelectorRetriever.retrieveSourceFilter();

	private get isInternal(): boolean {
		return store.getters.isInternal();
	}

	public selectedOptionalFilter = computed((): OptionalFilters => {
		return store.getters.selectedOptionalFilter();
	});

	constructor(private emit: (name: string, value?: string | boolean) => void) {}

	public isFilterSelected(filter: OptionalFilters): boolean {
		return this.selectedOptionalFilter.value === filter;
	}

	public doesFilterHaveSelectedOption(filter: OptionalFilters): boolean {
		let relevantFilter: ITableFilter = new NullTableFilter();
		switch (filter) {
			case OptionalFilters.TUITION_FEE:
				relevantFilter = this.tuitionFilter;
				break;
			case OptionalFilters.ENROLMENT_STATUS:
				relevantFilter = this.statusFilter;
				break;
			case OptionalFilters.INTAKE_PERIOD:
				relevantFilter = this.intakeFilter;
				break;
			case OptionalFilters.STUDENT_NATIONALITY:
				relevantFilter = this.nationalityFilter;
				break;
			case OptionalFilters.SOURCE:
				relevantFilter = this.sourceFilter;
				break;
		}

		return relevantFilter.selectedOption.value !== relevantFilter.defaultOption?.value;
	}

	public isFilterRelevantForUser(filter: OptionalFilters): boolean {
		if (!this.isInternal) {
			return true;
		}

		return filter !== OptionalFilters.ENROLMENT_STATUS;
	}

	public retrieveDisplayRelatedClassName(filter: OptionalFilters): string {
		return this.isFilterSelected(filter) ? '' : 'HiddenOptionalFilter';
	}

	public selectOptionalFilter(filter: OptionalFilters): void {
		store.mutations.updateSelectedOptionalFilter(filter);
	}

	public applyFilters(): void {
		EventBus.getEvent(ClientFiltersAppliedEvent).publish();
	}

	public closePanel(): void {
		this.emit('closePanel');
	}
}
