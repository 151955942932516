import { computed, reactive, Ref, ref, nextTick, watch } from 'vue';
import { EnrolmentEditRequestedEvent } from '@/event-bus/enrolment-edit-requested-event';
import { EnrolmentSelectionClearedEvent } from '@/event-bus/enrolment-selection-cleared-event';
import { ColumnSavingTriggeredEvent } from '@/event-bus/column-saving-triggered-event';
import { ColumnResetTriggeredEvent } from '@/event-bus/column-reset-triggered-event';
import EventBus from '@/event-bus/event-bus';
import { Enrolment } from '@/models/enrolment';
import { EnrolmentConfirmationStatus }
	from '@/presentation/components/generalizations/custom-table-components/enrolment-confirmation-status/enrolment-confirmation-status';
import { internalEnrolmentsTableFields, internalEnrolmentsTableFieldsWithEmailData } from '@/presentation/components/internal/internal-enrolments-table/internal-enrolments-table-fields';
import SavedColumnsHandler from '@/presentation/components/generalizations/enrolments-table/saved-columns-handler';
import { EditEnrolmentEntry }
	from '@/presentation/components/generalizations/custom-table-components/edit-enrolment-entry/edit-enrolment-entry';
import { Grid, GridOptions, ColumnApi, GridApi, RowSelectedEvent, ColDef } from 'ag-grid-community';
import store from '@/store';

export default class InternalEnrolmentsTableComponent {
	public grid: Ref<HTMLElement | null> = ref(null);
	public gridObject: { gridOptions?: GridOptions } = reactive({});
	public customCellComponents = { EditEnrolmentEntry, EnrolmentConfirmationStatus };

	private savedColumnsHandler: Ref<SavedColumnsHandler | undefined> = ref();

	private enrolmentSelectionClearedEventSubscriptionToken = ref('');
	private columnSavingTriggeredEventSubscriptionToken = ref('');
	private columnResetTriggeredEventSubscriptionToken = ref('');

	public filteredEnrolments = computed((): Enrolment[] => {
		return store.getters.filteredEnrolments();
	});

	public selectedEnrolments = computed((): Enrolment[] => {
		return store.getters.selectedEnrolments();
	});

	public gridApi = computed((): GridApi | null | undefined => {
		return this.gridObject?.gridOptions?.api;
	});

	public columnApi = computed((): ColumnApi | null | undefined => {
		return this.gridObject?.gridOptions?.columnApi;
	});

	public mounted(): void {
		if (!this.grid.value) {
			return;
		}

		const isEmailDataActive = this.filteredEnrolments.value.some(item => item.showLastEmailActivityDate === true);
		
		const cols = isEmailDataActive ? internalEnrolmentsTableFieldsWithEmailData : internalEnrolmentsTableFields;
		
		this.gridObject = new Grid(this.grid.value, {
			columnDefs: cols as ColDef[],
			rowData: this.filteredEnrolments.value,
			components: this.customCellComponents,
			headerHeight: 40,
			tooltipShowDelay: 0,
			rowSelection: 'multiple',
			rowMultiSelectWithClick: true,
			onRowDataChanged: this.rebuildSelection.bind(this),
			onFirstDataRendered: this.rebuildSelection.bind(this),
			onRowSelected: this.onRowSelected.bind(this)
		}) as unknown as { gridOptions: GridOptions };


		this.enrolmentSelectionClearedEventSubscriptionToken.value = EventBus.getEvent(EnrolmentSelectionClearedEvent).subscribe(() =>
			this.clearSelection()
		);
		this.columnSavingTriggeredEventSubscriptionToken.value = EventBus.getEvent(ColumnSavingTriggeredEvent).subscribe(
			this.saveColumns.bind(this)
		);
		this.columnResetTriggeredEventSubscriptionToken.value = EventBus.getEvent(ColumnResetTriggeredEvent).subscribe(
			this.resetColumns.bind(this)
		);

		this.savedColumnsHandler.value = new SavedColumnsHandler(this.columnApi.value, true, false);
		/* When applying of saved columns is finished,the saving of columns for this session is enabled. So, only apply the saved columns
		after the default column structure has been rendered. Otherwise, the default columns might be saved immediately every time
		this component is mounted, making it impossible for saved columns from a previous session to ever be applied. */
		void nextTick(this.applySavedColumnsIfAvailable.bind(this));
	}

	public unmounted(): void {
		EventBus.getEvent(EnrolmentSelectionClearedEvent).unsubscribe(this.enrolmentSelectionClearedEventSubscriptionToken.value);
		EventBus.getEvent(ColumnSavingTriggeredEvent).unsubscribe(this.columnSavingTriggeredEventSubscriptionToken.value);
		EventBus.getEvent(ColumnResetTriggeredEvent).unsubscribe(this.columnResetTriggeredEventSubscriptionToken.value);
	}

	public editEnrolment(enrolment: Enrolment): void {
		EventBus.getEvent(EnrolmentEditRequestedEvent).publish(enrolment);
	}

	public onRowSelected(row: RowSelectedEvent): void {
		if (row.node.isSelected()) {
			store.mutations.selectEnrolment(row.data);
		} else {
			store.mutations.deselectEnrolment(row.data);
		}
	}

	public clearSelection(): void {
		if (!this.gridApi.value) {
			return;
		}

		this.gridApi.value.deselectAll();
	}

	public rebuildSelection(): void {
		const selectedEnrolmentIdentitiesSet = new Set<string>();

		for (const enrolment of this.selectedEnrolments.value) {
			selectedEnrolmentIdentitiesSet.add(enrolment.identity);
		}

		if (!this.gridApi.value) {
			return;
		}

		this.gridApi.value.setRowData(this.filteredEnrolments.value);
		this.gridApi.value.forEachNode((node: { data: { identity: string }; setSelected: (isSelected: boolean) => void }) => {
			const isEnrolmentSelected = selectedEnrolmentIdentitiesSet.has(node.data.identity);

			node.setSelected(isEnrolmentSelected);
		});
	}

	public saveColumns(): void {
		if (!this.savedColumnsHandler.value) {
			return;
		}

		this.savedColumnsHandler.value.saveColumns();
	}

	public resetColumns(): void {
		if (!this.savedColumnsHandler.value) {
			return;
		}

		this.savedColumnsHandler.value.saveColumns(true);
	}

	private applySavedColumnsIfAvailable(): void {
		if (!this.savedColumnsHandler.value) {
			return;
		}

		this.savedColumnsHandler.value.applySavedColumnsIfAvailable();
	}
}
