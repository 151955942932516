import { defineComponent, onMounted, onUnmounted } from 'vue';
import LoadingModalComponent from '@/presentation/components/generalizations/loading-modal/loading-modal-class';

export default defineComponent({
	setup: () => {
		const component = new LoadingModalComponent();

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		return {
			modalContent: component.modalContent,
			modalStatusLoadingIsFailed: component.modalStatusLoadingIsFailed,
			modalStatusLoadingIsSuccess: component.modalStatusLoadingIsSuccess,
			modalStatusLoadingIsActive: component.modalStatusLoadingIsActive
		};
	}
});
