import { computed, nextTick, watch } from 'vue';
import { DefaultTableSelectorOptions } from '@/models/i-table-selector';
import { RouteEnums } from '@/models/route-enums';
import { IOrganisationDetails } from '@/models/i-organisation-details';
import { Enrolment } from '@/models/enrolment';
import { EnrolmentBatchesEnums } from '@/models/enrolment-batches-enums';
import FulfillmentStepIndication from '@/models/fulfillment-step-indication';
import EnrolmentPresenter from '@/presenters/enrolment-presenter';
import { LordBusiness } from '@/infrastructure';
import store from '@/store';

class OrganisationFilterPresenter {
	private loadingComplete = false;
	private batchSizeForOrganisationDetails = 250;

	private selectedPsm = computed((): string => {
		return store.getters.selectedPsm();
	});

	private noWorkOrderOrganisationDetails = computed((): IOrganisationDetails | undefined => {
		return store.getters.noWorkOrderOrganisationDetails();
	});

	constructor() {
		void this.initialiseSelectedPsmWatcher();
	}

	private get listOfOrganisationDetailsWithEmptyEnrolmentLists(): IOrganisationDetails[] {
		const cluster = store.getters.organisationDetailsCluster();
		cluster.listOfDetails.forEach((details) => (details.enrolments = []));
		return cluster.listOfDetails;
	}

	public incrementOrganisationDetailsClusterId(): number {
		const latestOrganisationDetailsClusterId = store.getters.latestOrganisationDetailsClusterId();
		const updatedId = latestOrganisationDetailsClusterId + 1;
		store.mutations.updateLatestOrganisationDetailsClusterId(updatedId);
		return updatedId;
	}

	public resetOrganisationDetails(): void {
		const router = store.getters.router();
		const onHistoryPage = router.current === RouteEnums.HISTORY;
		store.mutations.changeVisibilityAvailableFilterFacets(onHistoryPage);

		this.updateListOfOrganisationDetails(
			this.listOfOrganisationDetailsWithEmptyEnrolmentLists,
			this.incrementOrganisationDetailsClusterId()
		);

		// Also reset the 'loadingComplete' flag, so that the organisation-details-loading process can be triggered again.
		this.loadingComplete = false;
	}

	public async retrieveOrganisationDetails(): Promise<void> {
		// If all possible organisation details have been loaded, there's no point in starting the process from scratch.
		if (this.loadingComplete) {
			return;
		}

		const updateId = this.incrementOrganisationDetailsClusterId();
		const organisationList = await LordBusiness.retrieveOrganisationList();

		const listOfOrganisationDetails: IOrganisationDetails[] = organisationList.map((organisation) => ({
			organisationIdentity: organisation.identity,
			organisationName: organisation.name,
			psmOwner: organisation.partnershipSuccessManager.name
		}));

		listOfOrganisationDetails.push({
			organisationIdentity: DefaultTableSelectorOptions.REMAINING,
			organisationName: '',
			psmOwner: ''
		});

		this.updateListOfOrganisationDetails(listOfOrganisationDetails, updateId);

		await this.prepareFacetNumbers();
	}

	private async initialiseSelectedPsmWatcher(): Promise<void> {
		// Only set up the watcher on next tick, because the store may not immediately be ready yet.
		await nextTick();
		watch(this.selectedPsm, this.retrieveOrganisationDetails.bind(this));
	}

	private async prepareFacetNumbers(): Promise<void> {
		const selectedPsm = this.selectedPsm.value;
		// If a PSM is selected, prepare facet numbers for corresponding enrolments / organisations first.
		if (selectedPsm && selectedPsm.length) {
			const updateId = await this.prepareFacetNumbersForScope(EnrolmentBatchesEnums.PSM);
			// If other updates have been triggered in the meantime, don't continue with the next step.
			if (store.getters.latestOrganisationDetailsClusterId() > updateId) {
				return;
			}
		}

		// Prepare facet numbers for all enrolments / organisations.
		await this.prepareFacetNumbersForScope(EnrolmentBatchesEnums.ALL);

		// The most detailed organisation details have been loaded, so update the 'loadingComplete' flag.
		this.loadingComplete = true;
	}

	private async prepareFacetNumbersForScope(batch: EnrolmentBatchesEnums): Promise<number> {
		const updateId = this.incrementOrganisationDetailsClusterId();
		const enrolments = await EnrolmentPresenter.acquireEnrolmentsOfRelevantBatch(batch);
		store.mutations.changeVisibilityAvailableFilterFacetsBasedOnScope(
			batch === EnrolmentBatchesEnums.PSM ? store.getters.selectedIdentityPsm() : ''
		);
		/* When loading facet data for all enrolments, we stagger the process, meaning we build up the list of organisations gradually
		while allowing the browser breaks in between to keep rendering and responding to user input. Only when the list of organisations
		is complete do we update the FE store and force the tool to re-render. */
		const shouldProcessBeStaggered = batch === EnrolmentBatchesEnums.ALL && enrolments.length > this.batchSizeForOrganisationDetails;
		this.enrichOrganisationDetailsWithEnrolmentAmounts(
			enrolments,
			updateId,
			0,
			shouldProcessBeStaggered ? this.batchSizeForOrganisationDetails : enrolments.length
		);

		return updateId;
	}

	private enrichOrganisationDetailsWithEnrolmentAmounts(
		enrolments: Enrolment[],
		updateId: number,
		indexStart = 0,
		indexEnd = enrolments.length,
		existingList: IOrganisationDetails[] | null = null
	): void {
		if (!this.noWorkOrderOrganisationDetails.value) {
			return;
		}

		const listOfOrganisationDetails = existingList ? existingList : this.listOfOrganisationDetailsWithEmptyEnrolmentLists;
		const noWorkOrderOrganisationIds: string[] = [];

		// Count the amount of enrolments for each university by looping over all enrolments; this info can be used to display facets.
		for (let i = indexStart; i < indexEnd && i < enrolments.length; i++) {
			const enrolment = enrolments[i];
			let relevantDetails = listOfOrganisationDetails.find(
				(organisation) => organisation.organisationIdentity === enrolment.organisationIdentity
			);
			// If the organisation was not found, the enrolment is part of an organisation without a valid work order.
			if (!relevantDetails) {
				// Keep track of the ids of the organisations without work orders.
				if (!noWorkOrderOrganisationIds.includes(enrolment.organisationIdentity)) {
					noWorkOrderOrganisationIds.push(enrolment.organisationIdentity);
				}

				relevantDetails = this.noWorkOrderOrganisationDetails.value;
			}
			if (relevantDetails.enrolments === undefined || !this.isNonHistoryEnrolment(enrolment)) {
				continue;
			}

			relevantDetails.enrolments.push({
				identity: enrolment.identity,
				fulfillmentStep: enrolment.fulfillmentStep,
				status: enrolment.status,
				intakePeriod: enrolment.intakePeriodString,
				studentNationality: enrolment.studentNationality?.isoCode || '',
				source: enrolment.clientSourceName,
				tuitionFee: enrolment.tuitionFeeString
			});
		}

		if (indexEnd === enrolments.length) {
			this.updateListOfOrganisationDetails(listOfOrganisationDetails, updateId);
			store.mutations.setNoWorkOrderOrganisationIds(noWorkOrderOrganisationIds);
			return;
		}

		// Trigger next step of staggered process.
		setTimeout(() => {
			const nextEndIndex = indexEnd + this.batchSizeForOrganisationDetails;
			this.enrichOrganisationDetailsWithEnrolmentAmounts(
				enrolments,
				updateId,
				indexEnd,
				nextEndIndex > enrolments.length ? enrolments.length : nextEndIndex,
				listOfOrganisationDetails
			);
		}, 20);
	}

	private isNonHistoryEnrolment(enrolment: Enrolment): boolean {
		const router = store.getters.router();
		return router.current !== RouteEnums.HISTORY && !enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.HISTORY);
	}

	private updateListOfOrganisationDetails(listOfOrganisationDetails: IOrganisationDetails[], id: number): void {
		store.mutations.updateOrganisationDetailsCluster({
			listOfDetails: listOfOrganisationDetails,
			id
		});
	}
}

export default new OrganisationFilterPresenter();
