import { computed } from 'vue';
import RedshiftTracker from '@/infrastructure/redshift-tracker';
import store from '@/store';

export default class ClientHistoryOverviewComponent {
	public organisationName = computed((): string => {
		const selectedPartnership = store.getters.selectedPartnership();
		return selectedPartnership.organisationName;
	});

	private get redshiftTracker(): RedshiftTracker {
		return store.state.redshiftTracker;
	}

	public mounted(): void {
		this.redshiftTracker.trackInitialLoadingSpeed(true);
	}
}
