import { computed, nextTick } from 'vue';
import { RouteEnums } from '@/models/route-enums';
import { ITableFilter } from '@/models/i-table-filter';
import { ITableSelector } from '@/models/i-table-selector';
import NullTableSelector from '@/presentation/components/generalizations/table-selectors/selectors/null-table-selector';
import NullTableFilter from '@/presentation/components/generalizations/table-selectors/filters/null-table-filter';
import TableSelectorPsm from '@/presentation/components/generalizations/table-selectors/selectors/table-selector-psm';
import TableFilterPsmHistory from '@/presentation/components/generalizations/table-selectors/filters/table-filter-psm-history';
import TableSelectorOrganisation from '@/presentation/components/generalizations/table-selectors/selectors/table-selector-organisation';
import TableFilterOrganisationHistory
	from '@/presentation/components/generalizations/table-selectors/filters/table-filter-organisation-history';
import TableFilterStatus from '@/presentation/components/generalizations/table-selectors/filters/table-filter-status';
import TableFilterStep from '@/presentation/components/generalizations/table-selectors/filters/table-filter-step';
import TableFilterReviewSteps from '@/presentation/components/generalizations/table-selectors/filters/table-filter-review-steps';
import TableFilterNationality from '@/presentation/components/generalizations/table-selectors/filters/table-filter-nationality';
import TableFilterTuitionFee from '@/presentation/components/generalizations/table-selectors/filters/table-filter-tuition-fee';
import TableFilterIntakePeriod from '@/presentation/components/generalizations/table-selectors/filters/table-filter-intake-period';
import TableFilterClientStatus from '@/presentation/components/generalizations/table-selectors/filters/table-filter-client-status';
import TableFilterSource from '@/presentation/components/generalizations/table-selectors/filters/table-filter-source';
import store from '@/store';

class TableSelectorRetriever {
	private readonly selectorDefault?: NullTableSelector;
	private readonly filterDefault?: NullTableFilter;

	private psmSelector?: TableSelectorPsm;
	private psmFilterHistory?: TableFilterPsmHistory;
	private organisationSelector?: TableSelectorOrganisation;
	private organisationFilterHistory?: TableFilterOrganisationHistory;
	private statusFiler?: TableFilterStatus;
	private stepFilter?: TableFilterStep;
	private reviewStepsFilter?: TableFilterReviewSteps;
	private nationalityFilter?: TableFilterNationality;
	private tuitionFeeFilter?: TableFilterTuitionFee;
	private intakePeriodFilter?: TableFilterIntakePeriod;
	private clientStatusFilter?: TableFilterClientStatus;
	private sourceFilter?: TableFilterSource;

	public get allFilters(): ITableFilter[] {
		if (this.isInternal) {
			return [
				this.retrievePsmFilterHistory(),
				this.retrieveOrganisationFilter(),
				this.retrieveStatusFilter(),
				this.retrieveStepFilter(),
				this.retrieveNationalityFilter(),
				this.retrieveTuitionFeeFilter(),
				this.retrieveIntakePeriodFilter(),
				this.retrieveSourceFilter()
			];
		} else {
			return [
				this.retrieveReviewStepsFilter(),
				this.retrieveNationalityFilter(),
				this.retrieveTuitionFeeFilter(),
				this.retrieveIntakePeriodFilter(),
				this.retrieveClientStatusFilter(),
				this.retrieveSourceFilter()
			];
		}
	}

	public get optionalInternalFilters(): ITableFilter[] {
		return [
			this.retrieveNationalityFilter(),
			this.retrieveTuitionFeeFilter(),
			this.retrieveIntakePeriodFilter(),
			this.retrieveSourceFilter()
		];
	}

	public get optionalClientFilters(): ITableFilter[] {
		return [
			this.retrieveNationalityFilter(),
			this.retrieveTuitionFeeFilter(),
			this.retrieveIntakePeriodFilter(),
			this.retrieveClientStatusFilter(),
			this.retrieveSourceFilter()
		];
	}

	private get isInternal(): boolean {
		return store.getters.isInternal();
	}

	private isOnIdentifiedEnrolmentsPage = computed((): boolean => {
		const router = store.getters.router();
		return router.current === RouteEnums.IDENTIFIED_ENROLMENTS;
	});

	constructor() {
		this.selectorDefault = new NullTableSelector();
		this.filterDefault = new NullTableFilter();

		// Create table selector class instances with a small delay so that circular-imports issues are avoided.
		void nextTick(() => {
			this.psmSelector = new TableSelectorPsm();
			this.psmFilterHistory = new TableFilterPsmHistory();
			this.organisationSelector = new TableSelectorOrganisation();
			this.organisationFilterHistory = new TableFilterOrganisationHistory();
			this.statusFiler = new TableFilterStatus();
			this.stepFilter = new TableFilterStep();
			this.reviewStepsFilter = new TableFilterReviewSteps();
			this.nationalityFilter = new TableFilterNationality();
			this.tuitionFeeFilter = new TableFilterTuitionFee();
			this.intakePeriodFilter = new TableFilterIntakePeriod();
			this.clientStatusFilter = new TableFilterClientStatus();
			this.sourceFilter = new TableFilterSource();
		});
	}

	public retrievePsmSelectorOrFilter(): ITableSelector | ITableFilter {
		if (!this.psmSelector || !this.psmFilterHistory) {
			return this.filterDefault as NullTableFilter;
		}

		if (this.isOnIdentifiedEnrolmentsPage.value) {
			return this.psmSelector;
		} else {
			return this.psmFilterHistory;
		}
	}

	public retrievePsmSelector(): ITableSelector {
		if (!this.psmSelector) {
			return this.selectorDefault as NullTableSelector;
		}

		return this.psmSelector;
	}

	public retrievePsmFilterHistory(): ITableFilter {
		if (!this.psmFilterHistory) {
			return this.filterDefault as NullTableFilter;
		}

		return this.psmFilterHistory;
	}

	public retrieveOrganisationSelectorOrFilter(): ITableSelector | ITableFilter {
		if (!this.organisationSelector || !this.organisationFilterHistory) {
			return this.filterDefault as NullTableFilter;
		}

		if (this.isOnIdentifiedEnrolmentsPage.value) {
			return this.organisationSelector;
		} else {
			return this.organisationFilterHistory;
		}
	}

	public retrieveOrganisationSelector(): ITableSelector {
		if (!this.organisationSelector) {
			return this.selectorDefault as NullTableSelector;
		}

		return this.organisationSelector;
	}

	public retrieveOrganisationFilter(): ITableFilter {
		if (!this.organisationFilterHistory) {
			return this.filterDefault as NullTableFilter;
		}

		return this.organisationFilterHistory;
	}

	public retrieveStatusFilter(): ITableFilter {
		if (!this.statusFiler) {
			return this.filterDefault as NullTableFilter;
		}

		return this.statusFiler;
	}

	public retrieveStepFilter(): ITableFilter {
		if (!this.stepFilter) {
			return this.filterDefault as NullTableFilter;
		}

		return this.stepFilter;
	}

	public retrieveReviewStepsFilter(): ITableFilter {
		if (!this.reviewStepsFilter) {
			return this.filterDefault as NullTableFilter;
		}

		return this.reviewStepsFilter;
	}

	public retrieveNationalityFilter(): ITableFilter {
		if (!this.nationalityFilter) {
			return this.filterDefault as NullTableFilter;
		}

		return this.nationalityFilter;
	}

	public retrieveTuitionFeeFilter(): ITableFilter {
		if (!this.tuitionFeeFilter) {
			return this.filterDefault as NullTableFilter;
		}

		return this.tuitionFeeFilter;
	}

	public retrieveIntakePeriodFilter(): ITableFilter {
		if (!this.intakePeriodFilter) {
			return this.filterDefault as NullTableFilter;
		}

		return this.intakePeriodFilter;
	}

	public retrieveClientStatusFilter(): ITableFilter {
		if (!this.clientStatusFilter) {
			return this.filterDefault as NullTableFilter;
		}

		return this.clientStatusFilter;
	}

	public retrieveSourceFilter(): ITableFilter {
		if (!this.sourceFilter) {
			return this.filterDefault as NullTableFilter;
		}

		return this.sourceFilter;
	}
}

export default new TableSelectorRetriever();
