import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "PartnershipSelector"
}
const _hoisted_2 = { class: "DropDownContainer" }
const _hoisted_3 = { class: "DropDownToggle" }
const _hoisted_4 = { class: "PartnershipOptionsContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnershipSelectorOption = _resolveComponent("PartnershipSelectorOption")!

  return (_ctx.partnerships.length > 1)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              class: "PartnershipSelectorLabel HelperButton",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleContent()))
            }, " Switch organisation ")
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["DropDownContent", { Hidden: !_ctx.isContentVisible }])
          }, [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partnerships, (partnership) => {
                return (_openBlock(), _createBlock(_component_PartnershipSelectorOption, {
                  key: partnership.organisationId,
                  partnership: partnership,
                  onPartnershipSelected: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hideDropDownContent()))
                }, null, 8 /* PROPS */, ["partnership"]))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ], 2 /* CLASS */)
        ])
      ]))
    : _createCommentVNode("v-if", true)
}