import { defineComponent, onMounted, watch } from 'vue';
import EnrolmentsTableComponent from '@/presentation/components/generalizations/enrolments-table/enrolments-table-class';

export default defineComponent({
	setup: () => {
		const component = new EnrolmentsTableComponent();

		onMounted(component.mounted.bind(component));

		watch(component.displayedEnrolments, component.rebuildSelection.bind(component));

		return {
			grid: component.grid,
			isHistoryTable: component.isHistoryTable,
			displayedEnrolments: component.displayedEnrolments
		};
	}
});
