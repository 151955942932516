import { computed, reactive, ref, Ref } from 'vue';
import { ComplexModal } from '@studyportals/modal';
import { Enrolment } from '@/models/enrolment';
import { TRowData } from '@/models/i-table';
import { Grid, GridOptions, ColDef } from 'ag-grid-community';
import SuspectedDuplicateTableFieldsAssembler
	from '@/presentation/components/internal/suspected-duplicate-details-modal/suspected-duplicate-table-fields-assembler';
import store from '@/store';

export default class SuspectedDuplicateDetailsModalComponent {
	public grid: Ref<HTMLElement | null> = ref(null);
	public gridObject: { gridOptions?: GridOptions } = reactive({});
	public columnDefs: Ref<ColDef[]> = ref([]);
	public rowData: Ref<TRowData[]> = ref([]);
	public modalContent: Ref<HTMLElement | null> = ref(null);
	private modal: Ref<ComplexModal | undefined> = ref(undefined);
	private loaded: Ref<boolean> = ref(false);

	public suspectedDuplicateEnrolments = computed((): Enrolment[] => {
		return store.getters.suspectedDuplicateEnrolments();
	});

	public mounted(): void {
		this.constructTableAndModal();
	}

	public constructTableAndModal(): void {
		// Only show the table and modal when there are enrolments to show.
		if (!this.grid.value || !this.suspectedDuplicateEnrolments.value.length || this.loaded.value) {
			return;
		}

		this.prepareModal();

		this.columnDefs.value = SuspectedDuplicateTableFieldsAssembler.getColumnData(this.suspectedDuplicateEnrolments.value);
		this.rowData.value = SuspectedDuplicateTableFieldsAssembler.getRowData(this.suspectedDuplicateEnrolments.value);

		this.gridObject = new Grid(this.grid.value, {
			columnDefs: this.columnDefs.value,
			rowData: this.rowData.value,
			domLayout: 'autoHeight'
		}) as unknown as { gridOptions: GridOptions };

		this.loaded.value = true;
	}

	private prepareModal(): void {
		if (!this.modalContent.value) {
			return;
		}

		this.modal.value = new ComplexModal(this.modalContent.value, {
			cssClassName: 'SuspectedDuplicateDetailsModalWrapper',
			destroyOnClose: true,
			onClose: (): void => {
				store.mutations.updateSuspectedDuplicateDetailsModalShown(false);
			}
		});

		window.ModalManager.open(this.modal.value);
	}
}
