import { defineComponent, onMounted, onUnmounted } from 'vue';
import { MultiSelect } from '@studyportals/multiselect';
import OptionalFilterSectionStatusComponent
	from '@/presentation/components/generalizations/optional-filters/optional-filter-section-status/optional-filter-section-status-class';

export default defineComponent({
	components: {
		MultiSelect
	},
	setup: () => {
		const component = new OptionalFilterSectionStatusComponent();

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		return {
			statusDropdown: component.statusDropdown,
			statusOptions: component.statusOptions
		};
	}
});
