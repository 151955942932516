import { computed } from 'vue';
import { Enrolment } from '@/models/enrolment';
import { ModalStatusLoadingEnums } from '@/models/modal-status-loading-enum';
import FulfillmentStepIndication from '@/models/fulfillment-step-indication';
import RedshiftTracker from '@/infrastructure/redshift-tracker';
import store from '@/store';

export default class InternalIdentifiedEnrolmentsOverviewComponent {
	public enrolments = computed((): Enrolment[] => {
		return store.getters.enrolments();
	});

	public nonHistoryEnrolments = computed((): Enrolment[] => {
		// TODO: Use 'enrolments' without any filter once the 'active' enrolment batch is in use.
		return this.enrolments.value.filter((enrolment) => !enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.HISTORY));
	});

	public filteredEnrolments = computed((): Enrolment[] => {
		return store.getters.filteredEnrolments();
	});

	public loading = computed((): boolean => {
		return store.getters.loading();
	});

	public showIdentifiedEnrolmentsTable = computed((): boolean => {
		return store.getters.showIdentifiedEnrolmentsTable();
	});

	public modalStatusLoading = computed((): ModalStatusLoadingEnums => {
		return store.getters.modalStatusLoading();
	});

	public modalStatusLoadingIsInactive = computed((): boolean => {
		return this.modalStatusLoading.value === ModalStatusLoadingEnums.INACTIVE;
	});

	private get redshiftTracker(): RedshiftTracker {
		return store.state.redshiftTracker;
	}

	public mounted(): void {
		this.redshiftTracker.trackInitialLoadingSpeed(false);
	}
}
