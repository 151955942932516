import { defineComponent } from 'vue';
import ReviewSubmittedNotificationComponent
	from '@/presentation/components/client/review-submitted-notification/review-submitted-notification-class';

export default defineComponent({
	setup: () => {
		const component = new ReviewSubmittedNotificationComponent();

		return {
			dismissReviewSubmittedNotification: component.dismissReviewSubmittedNotification.bind(component)
		};
	}
});
