import { Enrolment } from '@/models/enrolment';
import { EnrolmentStatus } from '@/models/enrolment-status';
import EnrolmentService from '@/services/enrolments-service';

import { TriggerPayload } from '../trigger-payload/trigger-payload';
import { TransitionTrigger } from './transition-trigger';

export class EnrolmentNotDuplicatedTrigger extends TransitionTrigger {
	public orderingNumber = 20;

	public constructor(excludedEnrolmentStatuses: EnrolmentStatus[]) {
		super('Not duplicated', excludedEnrolmentStatuses);
	}

	public equals(that: TransitionTrigger): boolean {
		return that instanceof EnrolmentNotDuplicatedTrigger;
	}

	protected async performTransitions(enrolments: Readonly<Enrolment[]>, payload: TriggerPayload): Promise<void> {
		const note = payload.note ? payload.note : '';
		await EnrolmentService.triggerEnrolmentNotDuplicated(enrolments, note);
	}
}
