import { DisqualificationReason as DisqualificationReasonEnum } from '@studyportals/sp-lord-business-interface';

import { DisqualificationReason } from './disqualification-reason';

class DisqualificationReasonFactory {
	private static disqualificationReasonsByValue = new Map<DisqualificationReasonEnum, DisqualificationReason>();

	public obtainForValues(values: Readonly<DisqualificationReasonEnum[]>): Readonly<DisqualificationReason[]> {
		return values.map((_) => this.obtain(_));
	}

	public obtain(value: DisqualificationReasonEnum): DisqualificationReason {
		if (!DisqualificationReasonFactory.disqualificationReasonsByValue.has(value)) {
			DisqualificationReasonFactory.disqualificationReasonsByValue.set(value, this.createForValue(value));
		}

		return DisqualificationReasonFactory.disqualificationReasonsByValue.get(value) as DisqualificationReason;
	}

	private createForValue(value: DisqualificationReasonEnum): DisqualificationReason {
		switch (value) {
			case DisqualificationReasonEnum.INTAKE_OUT_OF_AGREEMENT:
				return DisqualificationReason.intakeOutOfAgreement();
			case DisqualificationReasonEnum.NATIONALITY_OUT_OF_AGREEMENT:
				return DisqualificationReason.nationalityOutOfAgreement();
			case DisqualificationReasonEnum.LEVEL_OUT_OF_AGREEMENT:
				return DisqualificationReason.levelOutOfAgreement();
			case DisqualificationReasonEnum.PROGRAMME_OUT_OF_AGREEMENT:
				return DisqualificationReason.programmeOutOfAgreement();
			case DisqualificationReasonEnum.STUDENT_ACTIVITY_OUT_OF_AGREEMENT:
				return DisqualificationReason.studentActivityOutOfAgreement();
			case DisqualificationReasonEnum.OTHER:
				return DisqualificationReason.other();
		}

		throw new Error('Unknown disqualification reason');
	}
}

const disqualificationReasonFactory = new DisqualificationReasonFactory();
export default disqualificationReasonFactory;
