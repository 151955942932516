import { TriggerPayloadConfiguration } from './trigger-payload-configuration';
import { EnrolmentDeferredTriggerPayloadValidation } from './validation/enrolment-deferred-trigger-payload-validation';
import { TriggerPayloadValidation } from './validation/trigger-payload-validation';

export class EnrolmentDeferredTriggerPayloadConfiguration extends TriggerPayloadConfiguration {
	public constructor() {
		super(false, false, false, true);
	}

	public createValidation(): TriggerPayloadValidation {
		return new EnrolmentDeferredTriggerPayloadValidation();
	}
}
