import { Partnership } from './partnership';

export class NullPartership extends Partnership {
	public constructor() {
		super('', '', [], [], false, false);
	}

	public includesEnrolment(): boolean {
		return false;
	}
}
