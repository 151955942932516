import { Organisation } from '@/models/organisation';
import { AbstractAndReportExceptionSync } from '@studyportals/mb-platform-exceptions-aop';

export class OrganisationSearchResult {
	private resultOrganisation: Organisation | undefined;

	public constructor(organisation?: Organisation) {
		this.resultOrganisation = organisation;
	}

	public get organisation(): Organisation {
		if (undefined === this.resultOrganisation) {
			throw new Error('The organisation could not be found');
		}

		return this.resultOrganisation;
	}

	@AbstractAndReportExceptionSync()
	public hasOrganisation(): boolean {
		return undefined !== this.resultOrganisation;
	}
}
