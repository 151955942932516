import { computed } from 'vue';
import { DefaultTableSelectorOptions } from '@/models/i-table-selector';
import { Enrolment } from '@/models/enrolment';
import { RouteEnums } from '@/models/route-enums';
import { IEnrolmentClientStatusCounter } from '@/models/i-enrolment-counters';
import TableFilter from '@/presentation/components/generalizations/table-selectors/filters/table-filter';
import TableSelectorOption from '@/presentation/components/generalizations/table-selectors/table-selector-option';
import enrolmentStatusPresenter from '@/presenters/enrolment-status-presenter';
import store from '@/store';

export default class TableFilterClientStatus extends TableFilter {
	get defaultOptions(): TableSelectorOption[] {
		return [new TableSelectorOption(DefaultTableSelectorOptions.DEFAULT, 'All statuses')];
	}

	private onHistoryPage = computed((): boolean => {
		const router = store.getters.router();
		return router.current === RouteEnums.HISTORY;
	});

	public createOptions(): TableSelectorOption[] {
		const statusCounters: IEnrolmentClientStatusCounter[] = [];
		this.enrolmentsFilteredByOtherFilters.value.forEach((enrolment) => {
			const statusLabel = enrolmentStatusPresenter.getClientStatusLabelOfEnrolment(enrolment);
			if (!statusLabel) {
				return;
			}

			const existingCounter = statusCounters.find((counter) => counter.status === statusLabel);
			if (existingCounter) {
				existingCounter.facet++;
				return;
			}

			statusCounters.push({
				label: statusLabel,
				status: statusLabel,
				facet: 1
			});
		});

		return statusCounters.map((counter) => new TableSelectorOption(counter.status, counter.label, counter.facet));
	}

	public doesEnrolmentMatchFilter(enrolment: Enrolment): boolean {
		const option = this.selectedOptionAsOption.value;
		if (((!option || option.isDefaultOption) && !this.selectedOption.value) || this.onHistoryPage.value) {
			return true;
		}

		const statusLabel = enrolmentStatusPresenter.getClientStatusLabelOfEnrolment(enrolment);
		if (!statusLabel) {
			return false;
		}

		return statusLabel === this.selectedOption.value.toString();
	}
}
