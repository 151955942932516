class FormGenerator {

    private form: HTMLFormElement;

    /**
     * @constructor
     * @param {HTMLElement} form
     */
    constructor(form: HTMLFormElement) {
        this.form = form;
    }

    private static handleElementBlur(element: HTMLInputElement) {
        FormGenerator.addOrRemoveElementHasValueClass(element);
        element.classList.remove("HasFocus");
    }

    private static handleElementFocus(element: HTMLInputElement) {
        element.classList.add("HasFocus");
    }

    private static addOrRemoveElementHasValueClass(element: HTMLInputElement) {
        const elementHasValue = (element.value !== "");

        if (elementHasValue) {
            element.classList.add("HasValue");
        } else {
            element.classList.remove("HasValue");
        }
    }

    public generate() {
        this.addEventListeners();
        this.repositionElementLabels();
    }

    private addEventListeners() {
        // form element listeners
        this.form.addEventListener("blur", (event) => {
            if (event.target) {
                FormGenerator.handleElementBlur((event.target) as HTMLInputElement);
            }
        }, true);

        this.form.addEventListener("focus", (event) => {
            if (event.target) {
                FormGenerator.handleElementFocus((event.target) as HTMLInputElement);
            }
        }, true);
    }

    private repositionElementLabels() {
        this.getInputElements().forEach((element) => {
            FormGenerator.addOrRemoveElementHasValueClass(element as HTMLInputElement);
        });
    }

    private getInputElements(): Array<HTMLInputElement> {
        return Array.from(this.form.elements)
            .filter(element => element instanceof HTMLInputElement)
            .map(element => element as HTMLInputElement);
    }
}

export { FormGenerator as default };