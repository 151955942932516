import { defineComponent, onMounted } from 'vue';
import ContractDetailsModalComponent
	from '@/presentation/components/internal/contract-details/contract-details-modal/contract-details-modal-class';

export default defineComponent({
	setup: () => {
		const component = new ContractDetailsModalComponent();

		onMounted(component.mounted.bind(component));

		return {
			modalContent: component.modalContent,
			contractId: component.contractId,
			contractName: component.contractName,
			annualFee: component.annualFee,
			firstPromotionStartDate: component.firstPromotionStartDate,
			contractEndDate: component.contractEndDate,
			tailPeriodEndDate: component.tailPeriodEndDate,
			segments: component.segments,
			isSegmentTypeIncluded: component.isSegmentTypeIncluded.bind(component),
			isSegmentProgrammeSectionIrrelevant: component.isSegmentProgrammeSectionIrrelevant.bind(component),
			retrieveSegmentProgrammeDetails: component.retrieveSegmentProgrammeDetails.bind(component),
			isSegmentStudentSectionIrrelevant: component.isSegmentStudentSectionIrrelevant.bind(component),
			retrieveSegmentStudentDetails: component.retrieveSegmentStudentDetails.bind(component),
			isSegmentAttributionSectionIrrelevant: component.isSegmentAttributionSectionIrrelevant.bind(component),
			retrieveSegmentAttributionDetails: component.retrieveSegmentAttributionDetails.bind(component),
			isSegmentPricingSectionIrrelevant: component.isSegmentPricingSectionIrrelevant.bind(component),
			retrieveSegmentPriceTypeName: component.retrieveSegmentPriceTypeName.bind(component),
			retrieveSegmentPricingDetails: component.retrieveSegmentPricingDetails.bind(component),
			isSegmentCommentsSectionIrrelevant: component.isSegmentCommentsSectionIrrelevant.bind(component),
			retrieveSegmentCommentDetails: component.retrieveSegmentCommentDetails.bind(component),
			calculateNumberOfSegment: component.calculateNumberOfSegment.bind(component)
		};
	}
});
