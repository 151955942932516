import { Component, defineComponent } from 'vue';
import EmptyHistoryMessage from '@/presentation/components/generalizations/empty-history-message/empty-history-message.vue';
import EnrolmentsTable from '@/presentation/components/generalizations/enrolments-table/enrolments-table.vue';
import HistoryExport from '@/presentation/components/generalizations/history-export/history-export.vue';
import Loading from '@/presentation/components/generalizations/loading/loading.vue';
import EnrolmentsHistorySectionComponent
	from '@/presentation/components/generalizations/enrolments-history-section/enrolments-history-section-class';
import store from '@/store';

export default defineComponent({
	components: {
		EmptyHistoryMessage: EmptyHistoryMessage as Component,
		EnrolmentsTable: EnrolmentsTable as Component,
		HistoryExport: HistoryExport as Component,
		Loading: Loading as Component
	},
	setup: () => {
		const component = new EnrolmentsHistorySectionComponent();

		return {
			isInternal: store.state.isInternal,
			loading: component.loading,
			sortedEnrolmentGroups: component.sortedEnrolmentGroups,
			selectedHistoryGroup: component.selectedHistoryGroup,
			enrolmentsInGroup: component.enrolmentsInGroup,
			setSelectedGroup: component.setSelectedGroup.bind(component)
		};
	}
});
