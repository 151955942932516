import state from './state';
import { DefaultTableSelectorOptions } from '@/models/i-table-selector';
import { IGetters } from '@/models/store/i-getters';
import { Month } from '@/models/month';
import { ProgrammeLevel } from '@/models/programme-level';
import { RouteEnums } from '@/models/route-enums';
import tableSelectorRetriever from '@/presentation/components/generalizations/table-selectors/table-selector-retriever';

const getters: IGetters = {
	areEmptyFiltersSelected: () => {
		if (state.router.current === RouteEnums.HISTORY) {
			return false;
		}

		const organisationSelector = tableSelectorRetriever.retrieveOrganisationSelector();
		const selectedOption = organisationSelector.selectedOptionAsOption.value;
		if (!selectedOption) {
			return true;
		}

		return selectedOption.isEmptyOption;
	},
	clientHistoryShowNonInvoicableEnrolments: () => {
		return state.clientHistoryShowNonInvoicableEnrolments;
	},
	contractDetailsModalShown: () => {
		return state.contractDetailsModalShown;
	},
	customColumnOrderInUse: () => {
		return state.customColumnOrderInUse;
	},
	isInternal: () => {
		return state.isInternal;
	},
	loading: () => {
		return state.loading;
	},
	shouldReviewSubmittedNotificationBeShown: () => {
		return (
			state.wasReviewSubmitted && !state.wasReviewSubmittedNotificationDismissed && state.router.current !== RouteEnums.QUICK_GUIDE
		);
	},
	showAvailableFilterFacets: () => {
		return state.showAvailableFilterFacets;
	},
	showIdentifiedEnrolmentsTable: () => {
		return state.showIdentifiedEnrolmentsTable;
	},
	submitReviewNotificationClosed: () => {
		return state.submitReviewNotificationClosed;
	},
	suspectedDuplicateDetailsModalShown: () => {
		return state.suspectedDuplicateDetailsModalShown;
	},
	wasReviewSubmitted: () => {
		return state.wasReviewSubmitted;
	},
	wasReviewSubmittedNotificationDismissed: () => {
		return state.wasReviewSubmittedNotificationDismissed;
	},
	internalEnrolmentsSearchQuery: () => {
		return state.internalEnrolmentsSearchQuery;
	},
	selectedHistoryGroup: () => {
		return state.selectedHistoryGroup;
	},
	selectedIdentityOrganisation: () => {
		return state.selectedOrganisation ? state.selectedOrganisation : '';
	},
	selectedOrganisation: () => {
		return state.selectedOrganisation;
	},
	selectedPsm: () => {
		return state.selectedPSM;
	},
	latestOrganisationDetailsClusterId: () => {
		return state.latestOrganisationDetailsClusterId;
	},
	selectedIdentityPsm: () => {
		// Make sure that the user is a PSM and that a PSM is also marked as selected.
		if (!state.user || !state.isInternal || !state.selectedPSM) {
			return '';
		}

		return state.selectedPSM;
	},
	countries: () => {
		return state.countries;
	},
	countriesAsOptions: () => {
		return state.countries.map((country) => ({
			value: country.isoCode,
			label: country.name
		}));
	},
	currencies: () => {
		return state.currencies;
	},
	currenciesAsOptions: () => {
		return state.currencies.map((currency) => ({
			value: currency.isoCode,
			label: currency.name
		}));
	},
	enrolments: () => {
		return state.enrolments;
	},
	filteredEnrolments: () => {
		return state.filteredEnrolments;
	},
	organisationDetailsCluster: () => {
		return state.organisationDetailsCluster;
	},
	projectSuccessManagers: () => {
		return state.projectSuccessManagers;
	},
	noWorkOrderOrganisationIds: () => {
		return state.noWorkOrderOrganisationIds;
	},
	noWorkOrderOrganisationDetails: () => {
		return state.organisationDetailsCluster.listOfDetails.find(
			(organisation) => organisation.organisationIdentity === DefaultTableSelectorOptions.REMAINING
		);
	},
	partnershipEnrolments: () => {
		return state.partnershipEnrolments;
	},
	months: () => {
		return Month.all();
	},
	monthsAsOptions: () => {
		const months = getters.months();
		return months.map((month) => ({
			value: month.value,
			label: month.name
		}));
	},
	partnerships: () => {
		return state.partnerships;
	},
	programmeLevels: () => {
		return ProgrammeLevel.all();
	},
	programmeLevelsAsOptions: () => {
		const levels = getters.programmeLevels();
		return levels.map((level) => ({
			value: level.value,
			label: level.name
		}));
	},
	selectedEnrolments: () => {
		return state.selectedEnrolments;
	},
	suspectedDuplicateEnrolments: () => {
		return state.suspectedDuplicateEnrolments;
	},
	sortedEnrolmentGroups: () => {
		return state.sortedEnrolmentGroups;
	},
	enrolmentGroups: () => {
		return state.enrolmentGroups;
	},
	historyGroupSelection: () => {
		return state.historyGroupSelection;
	},
	flowStatusHighlightedFeature: () => {
		return state.flowStatusHighlightedFeature;
	},
	modalStatusEnrolmentReview: () => {
		return state.modalStatusEnrolmentReview;
	},
	modalStatusLoading: () => {
		return state.modalStatusLoading;
	},
	modalStatusMassEdit: () => {
		return state.modalStatusMassEdit;
	},
	modalStatusTransitionTriggers: () => {
		return state.modalStatusTransitionTriggers;
	},
	router: () => state.router,
	selectedCsvSeparator: () => {
		return state.selectedCsvSeparator;
	},
	selectedOptionalFilter: () => {
		return state.selectedOptionalFilter;
	},
	selectedPartnership: () => {
		return state.selectedPartnership;
	}
};

export default getters;
