import { NoPayReason } from '@/models/no-pay-reason';
import { EnrolmentNotInScopeReason } from '@studyportals/sp-lord-business-interface';
import { Partnership } from '@/models/partnership';

class SelectableNoPayReasons {
	public obtainForPartnership(partnership: Partnership): NoPayReason[] {
		const noPayReasonTypesForInput = [
			EnrolmentNotInScopeReason.INTAKE_DATE,
			EnrolmentNotInScopeReason.PROGRAMME,
			EnrolmentNotInScopeReason.OTHER
		];

		return partnership.noPayReasons.filter((reason) => noPayReasonTypesForInput.some((reasonType) => reason.type === reasonType));
	}
}

export default new SelectableNoPayReasons();
