import { computed, watch } from 'vue';
import { ITableSelector } from '@/models/i-table-selector';
import { Enrolment } from '@/models/enrolment';
import TableSelector from '@/presentation/components/generalizations/table-selectors/table-selector';
import store from '@/store';

export default abstract class TableSelectorDynamicOptions extends TableSelector implements ITableSelector {
	private filteredEnrolments = computed((): Enrolment[] => {
		return store.getters.filteredEnrolments();
	});

	protected constructor(optionsWithoutEnrolmentsCanBeShown = false) {
		super(optionsWithoutEnrolmentsCanBeShown);

		watch(this.filteredEnrolments, this.setOptions.bind(this));
	}
}
