export class ValidationResult {
	public isIntakeYearValid = true;
	public isIntakeMonthValid = true;
	public isNoteValid = true;
	public isTuitionFeeValid = true;
	public isTuitionFeeCurrencyValid = true;
	public isProgrammeNameValid = true;
	public isProgrammeLevelValid = true;
	public isNationalityValid = true;

	public isValid(): boolean {
		return (
			this.isIntakeYearValid &&
			this.isIntakeMonthValid &&
			this.isNoteValid &&
			this.isTuitionFeeValid &&
			this.isTuitionFeeCurrencyValid &&
			this.isProgrammeNameValid &&
			this.isProgrammeLevelValid &&
			this.isNationalityValid
		);
	}
}
