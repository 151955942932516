import { EnrolmentDateGroup } from '@/models/enrolment-date-group';
import { GroupOptionsEnums } from '@/models/group-options-enums';
import { Enrolment } from '@/models/enrolment';
import { Month } from '@/models/month';
import store from '@/store';

const UNSPECIFIED_GROUP_LABEL = 'Unspecified';

export default class EnrolmentDateGroupsPresenter {
	public static get sortedEnrolmentGroups(): EnrolmentDateGroup[] {
		const dates = EnrolmentDateGroupsPresenter.uniqueEnrolmentGroups;

		// Sort the enrolments based on date (the latest first).
		return dates.sort((a: EnrolmentDateGroup, b: EnrolmentDateGroup) => {
			// If enrolment 'a' has incomplete date data, it should be at the bottom of the list.
			if (!a.year) return 1;

			// If enrolment 'b' has incomplete date data, it should be at the bottom of the list.
			if (!b.year) return -1;

			// For date comparison the used year is multiplied with 13 so it has a bigger influence than any month could have.
			let aTime = a.year * 13;
			let bTime = b.year * 13;

			// Only include month info in the equation when it's available.
			aTime = a.month && a.month.value ? aTime + a.month.value : aTime;
			bTime = b.month && b.month.value ? bTime + b.month.value : bTime;

			// Compare the two numbers (representing date info for both enrolments).
			return aTime < bTime ? 1 : -1;
		});
	}

	private static get enrolmentIntakeDateInfo(): EnrolmentDateGroup[] {
		// Take only the intake-date-related properties from the filtered enrolments.
		return store.getters
			.filteredEnrolments()
			.map((enrolment: Enrolment) => new EnrolmentDateGroup(enrolment.intakeYear, enrolment.intakeMonth));
	}

	private static get enrolmentReviewSubmissionDateInfo(): EnrolmentDateGroup[] {
		// Take only the review-submission-date-related properties from the filtered enrolments.
		return store.getters
			.filteredEnrolments()
			.map(
				(enrolment: Enrolment) =>
					new EnrolmentDateGroup(
						enrolment.reviewSubmittedDate !== undefined ? enrolment.reviewSubmittedDate.getFullYear() : undefined,
						enrolment.reviewSubmittedDate !== undefined ? Month.forValue(enrolment.reviewSubmittedDate.getMonth()) : undefined
					)
			);
	}

	private static get enrolmentDateInfoBasedOnSelectedGroupOption(): EnrolmentDateGroup[] {
		const historyGroupSelection = store.getters.historyGroupSelection();
		const groupOption = historyGroupSelection.getHashKey();
		switch (groupOption) {
			case GroupOptionsEnums.INTAKE_MONTH_GROUP_OPTION:
			// intentional fallthrough; enrolment dates can be retrieved the same way.
			case GroupOptionsEnums.INTAKE_YEAR_GROUP_OPTION:
				return EnrolmentDateGroupsPresenter.enrolmentIntakeDateInfo;
			case GroupOptionsEnums.REVIEW_DATE_GROUP_OPTION:
				return EnrolmentDateGroupsPresenter.enrolmentReviewSubmissionDateInfo;
			default:
				return [];
		}
	}

	private static get enrolmentGroups(): EnrolmentDateGroup[] {
		return EnrolmentDateGroupsPresenter.enrolmentDateInfoBasedOnSelectedGroupOption.map((group: EnrolmentDateGroup) => {
			if (group.year === undefined) {
				return EnrolmentDateGroupsPresenter.markAsUnspecifiedGroup(group);
			}

			const historyGroupSelection = store.getters.historyGroupSelection();
			if (historyGroupSelection.getHashKey() !== GroupOptionsEnums.INTAKE_YEAR_GROUP_OPTION) {
				if (group.month === undefined) {
					return EnrolmentDateGroupsPresenter.markAsUnspecifiedGroup(group);
				}

				// Only add month info when it's available and leave a space for the year info that will be added.
				group.displayText = `${group.month.name} `;
			}

			// At this point year info is available.
			group.displayText += group.year;

			return group;
		});
	}

	private static get uniqueEnrolmentGroups(): EnrolmentDateGroup[] {
		const uniqueDates: EnrolmentDateGroup[] = [];
		EnrolmentDateGroupsPresenter.enrolmentGroups.forEach((newDate: EnrolmentDateGroup) => {
			// Whenever the date of an enrolment is already put in the list, stop here, because we don't want duplicates.
			if (uniqueDates.filter((uniqueDate: EnrolmentDateGroup) => uniqueDate.displayText === newDate.displayText).length) {
				return;
			}

			uniqueDates.push(newDate);
		});

		return uniqueDates;
	}

	public static enrolmentsRelevantForSelectedDate(dateInfo: EnrolmentDateGroup): Enrolment[] {
		let enrolments: Enrolment[] = [];
		const historyGroupSelection = store.getters.historyGroupSelection();
		const groupOption = historyGroupSelection.getHashKey();

		switch (groupOption) {
			case GroupOptionsEnums.INTAKE_MONTH_GROUP_OPTION:
				enrolments = EnrolmentDateGroupsPresenter.enrolmentsRelevantForSelectedIntakeMonth(dateInfo);
				break;
			case GroupOptionsEnums.INTAKE_YEAR_GROUP_OPTION:
				enrolments = EnrolmentDateGroupsPresenter.enrolmentsRelevantForSelectedIntakeYear(dateInfo);
				break;
			case GroupOptionsEnums.REVIEW_DATE_GROUP_OPTION:
				enrolments = EnrolmentDateGroupsPresenter.enrolmentsRelevantForSelectedReviewSubmission(dateInfo);
				break;
		}

		return enrolments;
	}

	private static markAsUnspecifiedGroup(group: EnrolmentDateGroup): EnrolmentDateGroup {
		group.displayText = UNSPECIFIED_GROUP_LABEL;
		return group;
	}

	private static enrolmentsRelevantForSelectedIntakeMonth(dateInfo: EnrolmentDateGroup): Enrolment[] {
		const filteredEnrolments = store.getters.filteredEnrolments();
		// Include only enrolments that have the same year and month as the selected group (based on intake).
		return filteredEnrolments.filter((enrolment: Enrolment) => {
			if (enrolment.intakeMonth === undefined || enrolment.intakeYear === undefined) {
				return dateInfo.displayText === UNSPECIFIED_GROUP_LABEL;
			}

			return dateInfo.month && enrolment.intakeMonth.value === dateInfo.month.value && enrolment.intakeYear === dateInfo.year;
		});
	}

	private static enrolmentsRelevantForSelectedIntakeYear(dateInfo: EnrolmentDateGroup): Enrolment[] {
		const filteredEnrolments = store.getters.filteredEnrolments();
		// Include only enrolments that have the same year as the selected group (based on intake).
		return filteredEnrolments.filter((enrolment: Enrolment) => enrolment.intakeYear === dateInfo.year);
	}

	private static enrolmentsRelevantForSelectedReviewSubmission(dateInfo: EnrolmentDateGroup): Enrolment[] {
		const filteredEnrolments = store.getters.filteredEnrolments();
		// Include only enrolments that have the same date and month as the selected group (based on review submission).
		return filteredEnrolments.filter((enrolment: Enrolment) => {
			if (enrolment.reviewSubmittedDate === undefined) {
				return dateInfo.displayText === UNSPECIFIED_GROUP_LABEL;
			}

			return (
				dateInfo.month &&
				enrolment.reviewSubmittedDate.getMonth() === dateInfo.month.value &&
				enrolment.reviewSubmittedDate.getFullYear() === dateInfo.year
			);
		});
	}
}
