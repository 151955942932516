import { Enrolment } from '@/models/enrolment';
import FulfillmentStepFlyweightFactory from '@/models/presentation-fulfillment-step-flyweight-factory';
import { UTCDateTime } from '@studyportals/mb-platform-date-time';
import { Country } from './country';
import { Currency } from './currency';
import disqualificationReasonFactory from './disqualification-reason-factory';
import { EnrolmentStatus } from './enrolment-status';
import { Month } from './month';
import NoPayReasonFactory from './no-pay-reason-factory';
import { ProgrammeLevel } from './programme-level';
import { SuccessFee } from './success-fee';
import { EnrolmentIdentificationSource, PresentationEnrolment } from '@studyportals/sp-lord-business-interface';
import advancedAttributionOrganisationPresenter from '@/presenters/advanced-attribution-organisation-presenter';

class EnrolmentFactory {
	public reconstituteEnrolments(input: Readonly<PresentationEnrolment[]>): Enrolment[] {
		return input.map((_) => this.reconstitute(_));
	}

	public reconstitute(input: PresentationEnrolment): Enrolment {
		return new Enrolment(
			input.identity,
			this.determineInternalSourceName(input.source),
			this.determineClientSourceName(input.source),
			FulfillmentStepFlyweightFactory.obtain(input.fulfillmentStep),
			EnrolmentStatus.obtain(input.status),
			input.preDisqualificationReason,
			input.organisationIdentity,
			input.organisationName,
			input.studentIdentity,
			input.studentEmail,
			input.studentRegistrationDateIso ? UTCDateTime.fromISOString(input.studentRegistrationDateIso) : undefined,
			input.firstActivityDateIso ? UTCDateTime.fromISOString(input.firstActivityDateIso) : undefined,
			input.lastActivityDateIso ? UTCDateTime.fromISOString(input.lastActivityDateIso) : undefined,
			input.partnershipSuccessManagerName ? input.partnershipSuccessManagerName : '-',
			EnrolmentStatus.obtain(input.lastQualificationStatus),
			input.studentCountryIso ? Country.obtain(input.studentCountryIso) : undefined,
			input.studentResidenceCountryIso ? Country.obtain(input.studentResidenceCountryIso) : undefined,
			input.studentName || '',
			input.enrichmentSourceLinkedIn || '',
			input.enrichmentSourceFacebook || '',
			undefined !== input.programmeLevel ? ProgrammeLevel.forValue(input.programmeLevel) : undefined,
			input.programmeName || '',
			input.intakeMonth ? Month.forValue(input.intakeMonth) : undefined,
			input.intakeYear ? input.intakeYear : undefined,
			input.tuitionFee,
			input.tuitionFeeCurrencyIso ? Currency.obtain(input.tuitionFeeCurrencyIso) : undefined,
			undefined !== input.successFeeType ? SuccessFee.forValue(input.successFeeType) : undefined,
			NoPayReasonFactory.obtainForValues(input.enrolmentNotInScopeReasons || []),
			input.enrolmentNotRecognizedNote || '',
			input.confirmationUnsureNote || '',
			input.enrolmentNotInScopeReasonsNote || '',
			input.enrolmentConfirmedNote || '',
			input.studentOrganisationRegistrationNumber,
			disqualificationReasonFactory.obtainForValues(input.disqualificationReasons || []),
			input.disqualificationReasonNote || '',
			input.invoicingBatchIdentity,
			input.isChallengedAndUnconfirmed,
			input.isSharedForClientProcessing,
			input.confirmationChallengeNote || '',
			input.reviewSubmissionDateIso ? UTCDateTime.fromISOString(input.reviewSubmissionDateIso) : undefined,
			input.duplicationNote || '',
			input.insertedInERTDateIso ? UTCDateTime.fromISOString(input.insertedInERTDateIso) : undefined,
			input.lastChangedDateIso ? UTCDateTime.fromISOString(input.lastChangedDateIso) : undefined,
			input.crmInvoiceIdentity || '',
			input.firstEmailActivityDateIso ? UTCDateTime.fromISOString(input.firstEmailActivityDateIso) : undefined,
			input.lastEmailActivityDateIso ? UTCDateTime.fromISOString(input.lastEmailActivityDateIso) : undefined,
			advancedAttributionOrganisationPresenter.isAdvancedAttributionOrganisation(input.organisationIdentity)
		);
	}

	private determineInternalSourceName(input: EnrolmentIdentificationSource): string {
		switch (input) {
			case EnrolmentIdentificationSource.MICROSITE_LEAD:
				return 'Microsite lead';
			case EnrolmentIdentificationSource.LANDING_PAGE_LEAD:
				return 'Landing page lead';
			case EnrolmentIdentificationSource.PIXEL_TRACKING:
				return 'Pixel tracking';
			case EnrolmentIdentificationSource.COOKIE_TRACKING:
				return 'Cookie tracking';
			case EnrolmentIdentificationSource.ENROLMENT_IDENTIFICATION_TOOL:
				return 'Student Matching Tool';
			case EnrolmentIdentificationSource.STUDENT_SURVEY:
				return 'Student survey';
			case EnrolmentIdentificationSource.DATA_ENRICHMENT:
				return 'Data enrichment';
			case EnrolmentIdentificationSource.CLIENT_SURVEY:
				return 'Client survey';
			case EnrolmentIdentificationSource.DREAM_APPLY:
				return 'Dream apply';
			default:
				return '';
		}
	}

	private determineClientSourceName(input: EnrolmentIdentificationSource): string {
		if (EnrolmentIdentificationSource.ENROLMENT_IDENTIFICATION_TOOL === input) {
			return 'Student Matching Tool';
		}

		if (EnrolmentIdentificationSource.isClientSideTracking(input)) {
			return 'Tracked by University';
		}

		if (EnrolmentIdentificationSource.isInternalTracking(input)) {
			return 'Studyportals Tracking';
		}

		return '';
	}
}

export default new EnrolmentFactory();
