import { EnrolmentReviewState } from './enrolment-review-state';

export class TriggerSelectionReviewState extends EnrolmentReviewState {
	public display(): void {
		this.review.popups.showTriggerSelectionPopup();
	}

	public requiresAdditionalInput(): boolean {
		// Only when not mass-reviewing and the selected enrolment status requires no further input, no next step is needed.
		return this.review.enrolments.length > 1 || !this.review.selectedTrigger.payloadConfiguration.isEmpty();
	}

	public next(): void {
		if (this.review.selectedTrigger.payloadConfiguration.isEmpty()) {
			this.review.transitionTo(this.review.createTriggerMassReviewConfirmationState());
			return;
		}

		if (this.review.selectedTrigger.payloadConfiguration.includeSuccessFeeInformation) {
			this.review.transitionTo(this.review.createSuccessFeeSelectionState());
		} else {
			this.review.transitionTo(this.review.createTriggerPayloadReviewState());
		}
	}
}
