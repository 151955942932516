import { defineComponent, toRef } from 'vue';
import OptionalFiltersButtonComponent
	from '@/presentation/components/generalizations/optional-filters/optional-filters-button/optional-filters-button-class';

export default defineComponent({
	props: {
		panelVisible: { type: Boolean, required: true }
	},
	setup: (props) => {
		const component = new OptionalFiltersButtonComponent();
		const panelVisible = toRef(props, 'panelVisible');

		return {
			panelVisible,
			isInternal: component.isInternal,
			selectedFiltersCounter: component.selectedFiltersCounter,
			doesAtLeastOneFilterHaveSelectedOption: component.doesAtLeastOneFilterHaveSelectedOption
		};
	}
});
