import { defineComponent } from 'vue';
import SearchEnrolmentsBarComponent from '@/presentation/components/generalizations/search-enrolments-bar/search-enrolments-bar-class';

export default defineComponent({
	setup: () => {
		const component = new SearchEnrolmentsBarComponent();

		return {
			searchBar: component.searchBar,
			isRevealed: component.isRevealed,
			isExpanded: component.isExpanded,
			isActive: component.isActive,
			updateSearchQuery: component.updateSearchQuery.bind(component),
			expandSearchBar: component.expandSearchBar.bind(component),
			hideSearchBar: component.hideSearchBar.bind(component),
			clearSearchQuery: component.clearSearchQuery.bind(component)
		};
	}
});
