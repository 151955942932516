import { Component, defineComponent, onMounted } from 'vue';
import EditEnrolment from '@/presentation/components/internal/edit-enrolment/edit-enrolment.vue';
import EnrolmentsActions from '@/presentation/components/internal/enrolments-actions/enrolments-actions.vue';
import EnrolmentMenu from '@/presentation/components/internal/enrolment-menu/enrolment-menu.vue';
import OptionalFilterTags from '@/presentation/components/generalizations/optional-filters/optional-filter-tags/optional-filter-tags.vue';
import EnrolmentsTable from '@/presentation/components/internal/internal-enrolments-table/internal-enrolments-table.vue';
import EmptyFiltersUnselectedMessage
	from '@/presentation/components/internal/empty-filters-unselected-message/empty-filters-unselected-message.vue';
import InvoiceBatchCreatedNotification
	from '@/presentation/components/internal/invoice-batch-created-notification/invoice-batch-created-notification.vue';
import Loading from '@/presentation/components/generalizations/loading/loading.vue';
import EasterEggClippy from '@/presentation/components/generalizations/easteregg-clippy/easteregg-clippy.vue';
import LoadingModal from '@/presentation/components/generalizations/loading-modal/loading-modal.vue';
import InternalIdentifiedEnrolmentsOverviewComponent
	from '@/presentation/views/internal/internal-identified-enrolments-overview/internal-identified-enrolments-overview-class';

export default defineComponent({
	components: {
		EnrolmentsActions: EnrolmentsActions as Component,
		EnrolmentMenu: EnrolmentMenu as Component,
		OptionalFilterTags: OptionalFilterTags as Component,
		EnrolmentsTable: EnrolmentsTable as Component,
		EmptyFiltersUnselectedMessage: EmptyFiltersUnselectedMessage as Component,
		EditEnrolment: EditEnrolment as Component,
		InvoiceBatchCreatedNotification: InvoiceBatchCreatedNotification as Component,
		Loading: Loading as Component,
		LoadingModal: LoadingModal as Component,
		EasterEggClippy: EasterEggClippy as Component
	},
	setup: () => {
		const component = new InternalIdentifiedEnrolmentsOverviewComponent();

		onMounted(component.mounted.bind(component));

		return {
			nonHistoryEnrolments: component.nonHistoryEnrolments,
			filteredEnrolments: component.filteredEnrolments,
			loading: component.loading,
			showIdentifiedEnrolmentsTable: component.showIdentifiedEnrolmentsTable,
			modalStatusLoadingIsInactive: component.modalStatusLoadingIsInactive
		};
	}
});
