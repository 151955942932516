import { computed, defineComponent, ref, onMounted, toRef } from 'vue';
import MultiSelectComponent from "./SearchBoxClass";
export default defineComponent({
    props: {
        searchPlaceholder: String
    },
    setup: (props, context) => {
        const emit = context.emit;
        const searchQuery = ref('');
        const placeholder = toRef(props, 'searchPlaceholder');
        const searchPlaceholder = computed(() => {
            return placeholder.value || '';
        });
        const component = new MultiSelectComponent();
        onMounted(component.mounted.bind(component));
        return {
            searchBox: component.searchBox,
            emit,
            searchPlaceholder,
            searchQuery
        };
    }
});
