import { computed, ref, Ref } from 'vue';
import { ComplexModal } from '@studyportals/modal';
import { ModalStatusLoadingEnums } from '@/models/modal-status-loading-enum';
import store from '@/store';

export default class LoadingModalComponent {
	public modalContent: Ref<HTMLElement | null> = ref(null);
	private modal: Ref<ComplexModal | undefined> = ref(undefined);

	public modalStatusLoadingIsActive = computed((): boolean => {
		return this.modalStatusLoading.value === ModalStatusLoadingEnums.ACTIVE;
	});

	public modalStatusLoadingIsSuccess = computed((): boolean => {
		return this.modalStatusLoading.value === ModalStatusLoadingEnums.SUCCESS;
	});

	public modalStatusLoadingIsFailed = computed((): boolean => {
		return this.modalStatusLoading.value === ModalStatusLoadingEnums.FAILED;
	});

	private modalStatusLoading = computed((): ModalStatusLoadingEnums => {
		return store.getters.modalStatusLoading();
	});

	public mounted(): void {
		this.prepareModal();
	}

	public unmounted(): void {
		window.ModalManager.close(this.modal.value);
	}

	private prepareModal(): void {
		if (!this.modalContent.value) {
			return;
		}

		this.modal.value = new ComplexModal(this.modalContent.value, {
			cssClassName: 'LoadingModalWrapper',
			destroyOnClose: false,
			onClose: (): void => store.actions.setModalStatusLoading(ModalStatusLoadingEnums.INACTIVE)
		});

		window.ModalManager.open(this.modal.value);
	}
}
