import { computed, Ref, ref, reactive } from 'vue';
import EventBus from '@/event-bus/event-bus';
import { EnrolmentSelectionClearedEvent } from '@/event-bus/enrolment-selection-cleared-event';
import { EnrolmentReviewDoneEvent } from '@/event-bus/enrolment-review-done-event';
import { RouteEnums } from '@/models/route-enums';
import { IEnrolmentTableFieldSettings } from '@/models/i-enrolment-table-field-settings';
import { Enrolment } from '@/models/enrolment';
import { ColDef, ColumnApi, Grid, GridApi, GridOptions, RowSelectedEvent } from 'ag-grid-community';
import EnrolmentTableFieldsProvider from '@/presentation/components/generalizations/enrolments-table/enrolment-table-fields-provider';
import { CountryIcon } from '@/presentation/components/generalizations/custom-table-components/country-icon/country-icon';
import { ReviewStatusIndication }
	from '@/presentation/components/generalizations/custom-table-components/review-status-indication/review-status-indication';
import { EnrolmentReviewEntry }
	from '@/presentation/components/generalizations/custom-table-components/enrolment-review-entry/enrolment-review-entry';
import store from '@/store';

export default class EnrolmentsTableComponent {
	public grid: Ref<HTMLElement | null> = ref(null);
	public gridObject: { gridOptions?: GridOptions } = reactive({});
	public customCellComponents = { EnrolmentReviewEntry, ReviewStatusIndication, CountryIcon };

	private enrolmentReviewDoneEventSubscriptionToken = ref('');
	private enrolmentSelectionClearedEventSubscriptionToken = ref('');

	public isInternal = computed((): boolean => {
		return store.state.isInternal;
	});

	public isHistoryTable = computed((): boolean => {
		const router = store.getters.router();
		return router.current === RouteEnums.HISTORY;
	});

	public fields = computed((): IEnrolmentTableFieldSettings[] => {
		return EnrolmentTableFieldsProvider.getTableFieldsForContext(this.isInternal.value, this.isHistoryTable.value);
	});

	public filteredEnrolments = computed((): Enrolment[] => {
		return store.getters.filteredEnrolments();
	});

	public selectedEnrolments = computed((): Enrolment[] => {
		return store.getters.selectedEnrolments();
	});

	public selectedHistoryGroup = computed((): string => {
		return store.getters.selectedHistoryGroup();
	});

	public displayedEnrolments = computed((): Enrolment[] => {
		if (!this.isHistoryTable.value) {
			return this.filteredEnrolments.value;
		}

		const enrolmentGroups = store.getters.enrolmentGroups();
		// eslint-disable-next-line no-prototype-builtins
		if (!enrolmentGroups.hasOwnProperty(this.selectedHistoryGroup.value)) {
			return [];
		}

		return enrolmentGroups[this.selectedHistoryGroup.value];
	});

	public gridOptions = computed((): GridOptions | null | undefined => {
		return this.gridObject?.gridOptions;
	});

	public gridApi = computed((): GridApi | null | undefined => {
		return this.gridOptions.value?.api;
	});

	public columnApi = computed((): ColumnApi | null | undefined => {
		return this.gridOptions.value?.columnApi;
	});

	public mounted(): void {
		if (!this.grid.value) {
			return;
		}

		this.gridObject = new Grid(this.grid.value, {
			columnDefs: this.fields.value as ColDef[],
			rowData: this.displayedEnrolments.value,
			components: this.customCellComponents,
			headerHeight: 40,
			tooltipShowDelay: 0,
			rowSelection: 'multiple',
			rowMultiSelectWithClick: true,
			onRowDataChanged: this.rebuildSelection.bind(this),
			onFirstDataRendered: this.rebuildSelection.bind(this),
			onRowSelected: this.onRowSelected.bind(this)
		}) as unknown as { gridOptions: GridOptions };

		this.enrolmentReviewDoneEventSubscriptionToken.value = EventBus.getEvent(EnrolmentReviewDoneEvent).subscribe(() =>
			this.clearSelection()
		);
		this.enrolmentSelectionClearedEventSubscriptionToken.value = EventBus.getEvent(EnrolmentSelectionClearedEvent).subscribe(() =>
			this.clearSelection()
		);
	}

	public unmounted(): void {
		EventBus.getEvent(EnrolmentReviewDoneEvent).unsubscribe(this.enrolmentSelectionClearedEventSubscriptionToken.value);
		EventBus.getEvent(EnrolmentSelectionClearedEvent).unsubscribe(this.enrolmentSelectionClearedEventSubscriptionToken.value);
	}

	public onRowSelected(row: RowSelectedEvent): void {
		if (row.node.isSelected()) {
			store.mutations.selectEnrolment(row.data);
		} else {
			store.mutations.deselectEnrolment(row.data);
		}
	}

	public clearSelection(): void {
		if (!this.gridApi.value) {
			return;
		}

		this.gridApi.value.deselectAll();
	}

	public rebuildSelection(): void {
		const selectedEnrolmentIdentitiesSet = new Set<string>();

		for (const enrolment of this.selectedEnrolments.value) {
			selectedEnrolmentIdentitiesSet.add(enrolment.identity);
		}

		if (!this.gridApi.value) {
			return;
		}

		this.gridApi.value.setRowData(this.displayedEnrolments.value);
		this.gridApi.value.forEachNode((node: { data: { identity: string }; setSelected: (isSelected: boolean) => void }) => {
			const isEnrolmentSelected = selectedEnrolmentIdentitiesSet.has(node.data.identity);

			node.setSelected(isEnrolmentSelected);
		});
	}
}
