import store from '@/store';
import { EnrolmentReview } from '@/models/enrolment-review/enrolment-review';
import EventBus from '@/event-bus/event-bus';
import { EnrolmentReviewDoneEvent } from '@/event-bus/enrolment-review-done-event';
import { ModalStatusLoadingEnums } from '@/models/modal-status-loading-enum';

export default class EnrolmentReviewPopupButtonsComponent {
	constructor(public enrolmentReview: EnrolmentReview) {}

	public next(): void {
		// Check whether nothing is preventing us from going to the next screen (such as a validation issue).
		if (!this.enrolmentReview.canProceedGivenCurrentReviewState()) {
			return;
		}

		this.enrolmentReview.next();
	}

	public previous(): void {
		this.enrolmentReview.previous();
	}

	public async submit(): Promise<void> {
		/* Potential validation issues will have to be shown on the currently shown modal,
		so make sure there aren't any before showing the loader modal. */
		if (!this.enrolmentReview.isValid()) {
			return;
		}

		this.showLoader();

		try {
			await this.enrolmentReview.submit();
			this.wrapUpSuccessfulReviewSubmitAction();
			this.showSuccessMessage();
		} catch (error) {
			this.showFailureMessage();
		}
	}

	public showLoader(): void {
		store.actions.setModalStatusLoading(ModalStatusLoadingEnums.ACTIVE);
	}

	public showSuccessMessage(): void {
		store.actions.setModalStatusLoading(ModalStatusLoadingEnums.SUCCESS);
	}

	public showFailureMessage(): void {
		store.actions.setModalStatusLoading(ModalStatusLoadingEnums.FAILED);
	}

	public setTuitionFeeStepSkippingAs(skip: boolean): void {
		// Store whether tuition fee can be skipped before continuing to the next screen.
		this.enrolmentReview.selectedTriggerPayload.enrichmentSpecification.skipTuitionFeeStep = skip;
		this.next();
	}

	private wrapUpSuccessfulReviewSubmitAction(): void {
		EventBus.getEvent(EnrolmentReviewDoneEvent).publish();
	}
}
