import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "EnrolmentsReviewSection" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_EnrolmentsTable = _resolveComponent("EnrolmentsTable")!
  const _component_EnrolmentReviewForm = _resolveComponent("EnrolmentReviewForm")!
  const _component_EmptyAllToReviewMessage = _resolveComponent("EmptyAllToReviewMessage")!
  const _component_EmptyAllReviewedMessage = _resolveComponent("EmptyAllReviewedMessage")!
  const _component_EmptyStartReviewMessage = _resolveComponent("EmptyStartReviewMessage")!
  const _component_EmptySubmittedReviewMessage = _resolveComponent("EmptySubmittedReviewMessage")!
  const _component_DefaultEmptyMessage = _resolveComponent("DefaultEmptyMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.filteredEnrolments.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
            : (_openBlock(), _createBlock(_component_EnrolmentsTable, { key: 1 })),
          _createVNode(_component_EnrolmentReviewForm)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.displayAllToReviewMessage)
            ? (_openBlock(), _createBlock(_component_EmptyAllToReviewMessage, { key: 0 }))
            : (_ctx.displayAllReviewedMessage)
              ? (_openBlock(), _createBlock(_component_EmptyAllReviewedMessage, { key: 1 }))
              : (_ctx.displayStartReviewMessage)
                ? (_openBlock(), _createBlock(_component_EmptyStartReviewMessage, { key: 2 }))
                : (_ctx.displaySubmittedReviewMessage)
                  ? (_openBlock(), _createBlock(_component_EmptySubmittedReviewMessage, { key: 3 }))
                  : (_openBlock(), _createBlock(_component_DefaultEmptyMessage, { key: 4 }))
        ]))
  ]))
}