enum OptionalFilters {
	TUITION_FEE = 'tuitionFee',
	ENROLMENT_STATUS = 'enrolmentStatus',
	INTAKE_PERIOD = 'intakePeriod',
	STUDENT_NATIONALITY = 'studentNationality',
	SOURCE = 'source'
}

type TAvailableOptionalFilters = {
	[key in OptionalFilters]: {
		label: string;
		icon: string;
		description: string;
	};
};

export { TAvailableOptionalFilters, OptionalFilters };
