import { GroupOption } from '@/presentation/components/generalizations/enrolments-table-group-selector/selectors/group-option';

export abstract class GroupSelector {
	public options: GroupOption[] = [];
	private selectedGroupOption: GroupOption | undefined;

	public abstract get defaultOption(): GroupOption;

	public set selectedOption(value: GroupOption) {
		this.selectedGroupOption = value;
	}

	public addAndGetOption(option: new () => GroupOption): GroupOption {
		const newOption = new option();
		this.options.push(newOption);
		return newOption;
	}
}
