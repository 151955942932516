import { Component, defineComponent, onMounted, onUnmounted, watch } from 'vue';
import MassEditModal from '@/presentation/components/generalizations/mass-edit-modal/mass-edit-modal.vue';
import MassSelectionOverviewComponent from '@/presentation/components/internal/mass-selection-overview/mass-selection-overview-class';

export default defineComponent({
	components: {
		MassEditModal: MassEditModal as Component
	},
	setup: () => {
		const component = new MassSelectionOverviewComponent();

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		watch(component.selectedItemsCounter, component.updateVisibilityBasedOnSelectedEnrolments.bind(component));

		return {
			isVisible: component.isVisible,
			isMassEditModalShown: component.isMassEditModalShown,
			multipleItemsSelected: component.multipleItemsSelected,
			selectedItemsCounter: component.selectedItemsCounter,
			contentVisible: component.contentVisible,
			multipleStatusesWarningShown: component.multipleStatusesWarningShown,
			multipleOrganisationsWarningShown: component.multipleOrganisationsWarningShown,
			isContentFocused: component.isContentFocused,
			preventContentSlideUp: component.preventContentSlideUp,
			focusContent: component.focusContent.bind(component),
			loseContentFocus: component.loseContentFocus.bind(component),
			hideWarningMultipleStatuses: component.hideWarningMultipleStatuses.bind(component),
			showWarningMultipleStatuses: component.showWarningMultipleStatuses.bind(component),
			hideWarningMultipleOrganisations: component.hideWarningMultipleOrganisations.bind(component),
			showWarningMultipleOrganisations: component.showWarningMultipleOrganisations.bind(component),
			toggleContentVisible: component.toggleContentVisible.bind(component),
			clearSelection: component.clearSelection.bind(component),
			openMassEditModal: component.openMassEditModal.bind(component)
		};
	}
});
