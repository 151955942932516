import { defineComponent, onMounted, onUnmounted } from 'vue';
import { MultiSelect } from '@studyportals/multiselect';
import OptionalFilterSectionSourceComponent
	from '@/presentation/components/generalizations/optional-filters/optional-filter-section-source/optional-filter-section-source-class';

export default defineComponent({
	components: {
		MultiSelect
	},
	setup: () => {
		const component = new OptionalFilterSectionSourceComponent();

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		return {
			sourceDropdown: component.sourceDropdown,
			sourceOptions: component.sourceOptions
		};
	}
});
