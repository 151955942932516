import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "OptionalFiltersWrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OptionalFiltersButton = _resolveComponent("OptionalFiltersButton")!
  const _component_OptionalFilterTags = _resolveComponent("OptionalFilterTags")!
  const _component_OptionalFiltersPanel = _resolveComponent("OptionalFiltersPanel")!
  const _component_ClearOptionalFiltersButton = _resolveComponent("ClearOptionalFiltersButton")!
  const _component_highlighted_feature = _resolveComponent("highlighted-feature")!

  return (_openBlock(), _createBlock(_component_highlighted_feature, {
    feature: _ctx.highlightedFeatureNameFilter,
    elementClassName: "HighlightedFeatureFilter",
    title: _ctx.highlightedFeatureTitle,
    content: _ctx.highlightedFeatureContent
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_OptionalFiltersButton, {
          panelVisible: _ctx.panelVisible,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.togglePanelVisibility()))
        }, null, 8 /* PROPS */, ["panelVisible"]),
        _createVNode(_component_OptionalFilterTags),
        (_ctx.panelVisible)
          ? (_openBlock(), _createBlock(_component_OptionalFiltersPanel, {
              key: 0,
              onClosePanel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.togglePanelVisibility()))
            }))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_ClearOptionalFiltersButton)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["feature", "title", "content"]))
}