import { defineComponent, onMounted, watch } from 'vue';
import InternalEnrolmentsTableComponent
	from '@/presentation/components/internal/internal-enrolments-table/internal-enrolments-table-class';

export default defineComponent({
	setup: () => {
		const component = new InternalEnrolmentsTableComponent();

		onMounted(component.mounted.bind(component));

		watch(component.filteredEnrolments, component.rebuildSelection.bind(component));

		return {
			grid: component.grid
		};
	}
});
