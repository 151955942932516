import { Component, defineComponent, watch } from 'vue';
import EnrolmentExport from '@/presentation/components/generalizations/enrolment-export/enrolment-export.vue';
import HistoryExportComponent from '@/presentation/components/generalizations/history-export/history-export-class';

export default defineComponent({
	props: {
		historyGroup: String
	},
	components: {
		EnrolmentExport: EnrolmentExport as Component
	},
	setup: (props) => {
		const component = new HistoryExportComponent(props.historyGroup || '');

		watch(component.groupedEnrolments, component.checkExportPossibility.bind(component));

		return {
			canExport: component.canExport,
			groupedEnrolments: component.groupedEnrolments,
			csvExportOptions: component.csvExportOptions,
			groupedEnrolmentsAsString: component.groupedEnrolmentsAsString
		};
	}
});
