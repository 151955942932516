import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Monthpicker = _resolveComponent("Monthpicker")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
		'OptionalFilterSection',
		_ctx.showValidation ? 'ShowValidation' : 'HideValidation'
	])
  }, [
    _createVNode(_component_Monthpicker, {
      defaultMonth: _ctx.selectedMonth,
      defaultYear: _ctx.selectedYear,
      allowPastDates: true,
      pastYearsRange: _ctx.numberOfYearsInPast,
      futureYearsRange: _ctx.numberOfYearsInFuture,
      onDateChanged: _ctx.storeDate
    }, null, 8 /* PROPS */, ["defaultMonth", "defaultYear", "pastYearsRange", "futureYearsRange", "onDateChanged"])
  ], 2 /* CLASS */))
}