import { defineComponent, Component } from 'vue';
import EnrolmentExportModal from '@/presentation/components/generalizations/enrolment-export-modal/enrolment-export-modal.vue';
import EnrolmentExportComponent from '@/presentation/components/generalizations/enrolment-export/enrolment-export-class';

export default defineComponent({
	props: {
		buttonLabel: String,
		exportOptions: Array,
		enrolments: Array,
		canExport: Boolean
	},
	components: {
		EnrolmentExportModal: EnrolmentExportModal as Component
	},
	setup: (props) => {
		const component = new EnrolmentExportComponent();

		return {
			buttonLabel: props.buttonLabel,
			exportOptions: props.exportOptions,
			enrolments: props.enrolments,
			canExport: props.canExport,
			isExportModalShown: component.isExportModalShown,
			onExportButtonClicked: component.onExportButtonClicked.bind(component),
			hideModal: component.hideModal.bind(component)
		};
	}
});
