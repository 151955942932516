import CountryPresenter from '@/presenters/country-presenter';
import EnrolmentPresenter from '@/presenters/enrolment-presenter';
import FulfilmentProcessPresenter from '@/presenters/fulfilment-process-presenter';
import PartnershipPresenter from '@/presenters/partnership-presenter';
import CurrencyPresenter from './presenters/currency-presenter';
import { EnrolmentBatchesEnums } from '@/models/enrolment-batches-enums';

import { UserSession } from './infrastructure';
import { AOPException } from '@studyportals/mb-platform-exceptions-aop';
import { RollbarExceptionReporter } from '@studyportals/mb-platform-exceptions-aop-rollbar';
import UserService from '@/services/user-service';

export default class BootstrapperClient {
	public static async bootstrap(): Promise<void> {
		if (!UserSession.isValid()) {
			const error = new Error('No valid session found');
			error.name = 'NotAuthorizedException';
			throw error;
		}

		await UserService.storeUserDetails();
		BootstrapperClient.defineRollbarExceptionsReporter();

		await UserSession.acquireCredentials();

		CountryPresenter.acquireCountries();
		CurrencyPresenter.acquireCurrencies();

		await Promise.all([
			FulfilmentProcessPresenter.acquireFulfilmentProcessForCurrentUser(),
			PartnershipPresenter.retrieveForCurrentUser()
		]);

		await EnrolmentPresenter.acquireEnrolments(EnrolmentBatchesEnums.ALL);
	}

	private static defineRollbarExceptionsReporter(): void {
		const rollbarToken = webpackDefinitions.VUE_APP_ROLLBAR_TOKEN;
		const rollbarEnv = webpackDefinitions.VUE_APP_ROLLBAR_ENV;

		AOPException.exceptionReporter = RollbarExceptionReporter.create(rollbarToken, rollbarEnv);
	}
}
