import { EnrolmentReviewState } from './enrolment-review-state';

export class NoPayReasonsReviewState extends EnrolmentReviewState {
	public display(): void {
		this.review.popups.showAdditionalNoPayReasonsSelectionPopup();
	}

	public requiresAdditionalInput(): boolean {
		return true;
	}

	public next(): void {
		this.review.transitionTo(this.review.createTriggerPayloadReviewState());
	}

	public canPerformNext(): boolean {
		return this.review.selectedTriggerPayload.successFeeInformation.noPayReasons.length > 0;
	}
}
