import { Component, computed } from 'vue';
import store from '@/store';
import { IRouter, TRoutes } from '@/models/i-router';
import { RouteEnums } from '@/models/route-enums';
import ClientReviewOverview from '@/presentation/views/client/client-review-overview/client-review-overview.vue';
import ClientHistoryOverview from '@/presentation/views/client/client-history-overview/client-history-overview.vue';
import ClientQuickGuide from '@/presentation/views/client/client-quick-guide/client-quick-guide.vue';

export default class ClientEnrolmentsOverviewComponent {
	private routes: TRoutes = {
		[RouteEnums.IDENTIFIED_ENROLMENTS]: ClientReviewOverview as Component,
		[RouteEnums.REVIEW_OVERVIEW]: ClientReviewOverview as Component,
		[RouteEnums.HISTORY]: ClientHistoryOverview as Component,
		[RouteEnums.QUICK_GUIDE]: ClientQuickGuide as Component
	};

	public standAloneLogin = computed((): boolean => {
		return store.state.standAloneLogin;
	});

	public canEnrolmentTableBeShown = computed((): boolean => {
		return this.dataProcessingAgreementConfirmed.value && this.studentImpactVideoDisplayed.value;
	});

	public shouldReviewSubmittedNotificationBeShown = computed((): boolean => {
		return store.getters.shouldReviewSubmittedNotificationBeShown();
	});

	public router = computed((): IRouter => {
		return store.getters.router();
	});

	// eslint-disable-next-line @typescript-eslint/naming-convention
	public CurrentComponent = computed((): Component => {
		return this.routes[this.router.value.current];
	});

	private dataProcessingAgreementConfirmed = computed((): boolean => {
		const partnerships = store.getters.partnerships();
		return !partnerships.some((_) => !_.wasDataProcessingAgreementSigned);
	});

	private studentImpactVideoDisplayed = computed((): boolean => {
		const partnerships = store.getters.partnerships();
		return !partnerships.some((_) => !_.wasStudentImpactVideoDisplayed);
	});
}
