import { computed, ref, Ref, nextTick } from 'vue';
import { ClientEnrolmentReviewStartedEvent } from '@/event-bus/client-enrolment-review-started-event';
import EventBus from '@/event-bus/event-bus';
import { InternalEnrolmentReviewStartedEvent } from '@/event-bus/internal-enrolment-review-started-event';
import { TriggerPayloadPopupConfigurationSharedEvent } from '@/event-bus/trigger-payload-popup-configuration-shared-event';
import { EnrolmentReview } from '@/models/enrolment-review/enrolment-review';
import { InternalEnrolmentReview } from '@/models/enrolment-review/internal-enrolment-review';
import { TriggerPayloadPopupConfiguration } from '@/models/enrolment-review/trigger-payload-popup-configuration';
import { ModalStatusEnrolmentReviewEnums } from '@/models/modal-status-enrolment-review-enum';
import store from '@/store';

export default class EnrolmentReviewFormComponent {
	public enrolmentReview: Ref<EnrolmentReview | undefined> = ref();

	private clientEnrolmentReviewStartedEventSubscriptionToken = ref('');
	private internalEnrolmentReviewStartedEventSubscriptionToken = ref('');

	public transitionTriggersStatusIsInactive = computed((): boolean => {
		return store.getters.modalStatusEnrolmentReview() === ModalStatusEnrolmentReviewEnums.INACTIVE;
	});

	public mounted(): void {
		this.clientEnrolmentReviewStartedEventSubscriptionToken.value = EventBus.getEvent(ClientEnrolmentReviewStartedEvent).subscribe(
			(payload) => this.startReview(new EnrolmentReview(payload.partnership, payload.enrolments, this))
		);
		this.internalEnrolmentReviewStartedEventSubscriptionToken.value = EventBus.getEvent(InternalEnrolmentReviewStartedEvent).subscribe(
			(payload) => this.startReview(new InternalEnrolmentReview(payload.partnership, payload.enrolments, this))
		);
	}

	public unmounted(): void {
		EventBus.getEvent(ClientEnrolmentReviewStartedEvent).unsubscribe(this.clientEnrolmentReviewStartedEventSubscriptionToken.value);
		EventBus.getEvent(InternalEnrolmentReviewStartedEvent).unsubscribe(this.internalEnrolmentReviewStartedEventSubscriptionToken.value);
	}

	public startReview(review: EnrolmentReview): void {
		this.enrolmentReview.value = review;
		// Wait for the next rendering iteration so the popup content is available.
		void nextTick(() => {
			if (!this.enrolmentReview.value) {
				return;
			}

			this.enrolmentReview.value.display();
		});
	}

	public showMassReviewDisclaimerPopup(): void {
		store.mutations.setModalStatusEnrolmentReview(ModalStatusEnrolmentReviewEnums.MASS_REVIEW_DISCLAIMER);
	}

	public showTuitionFeeSkipPopup(): void {
		store.mutations.setModalStatusEnrolmentReview(ModalStatusEnrolmentReviewEnums.TUITION_FEE_SKIP_REVIEW);
	}

	public showTriggerSelectionPopup(): void {
		store.mutations.setModalStatusEnrolmentReview(ModalStatusEnrolmentReviewEnums.TRIGGER_SELECTION_REVIEW);
	}

	public showSuccessFeeSelectionPopup(): void {
		store.mutations.setModalStatusEnrolmentReview(ModalStatusEnrolmentReviewEnums.SUCCESS_FEE_SELECTION_REVIEW);
	}

	public showAdditionalNoPayReasonsSelectionPopup(): void {
		store.mutations.setModalStatusEnrolmentReview(ModalStatusEnrolmentReviewEnums.ADDITIONAL_NO_PAY_REASON_SELECTION_REVIEW);
	}

	public showTriggerPayloadPopup(configuration: TriggerPayloadPopupConfiguration): void {
		EventBus.getEvent(TriggerPayloadPopupConfigurationSharedEvent).publish(configuration);
		store.mutations.setModalStatusEnrolmentReview(ModalStatusEnrolmentReviewEnums.TRIGGER_PAYLOAD_REVIEW_FORM);
	}

	public showMassReviewConfirmationPopup(): void {
		store.mutations.setModalStatusEnrolmentReview(ModalStatusEnrolmentReviewEnums.MASS_REVIEW_CONFIRMATION);
	}
}
