import TableSelectorOption from '@/presentation/components/generalizations/table-selectors/table-selector-option';

enum DefaultTableSelectorOptions {
	DEFAULT = '',
	EMPTY = '0',
	REMAINING = '-1'
}

interface ITableSelector {
	defaultOption: TableSelectorOption | undefined;
	emptyOption: TableSelectorOption | undefined;
	selectedOption: { value: string | number };
	options: { value: TableSelectorOption[] };
	selectedOptionAsOption: { value: TableSelectorOption | undefined };
	selectedOptionIsDefault: { value: boolean };
	setSelectedOption: (option: string | number) => void;
	resetSelectedOption: () => void;
	setOptions: () => void;
	createOptions: () => TableSelectorOption[];
}

export { ITableSelector, DefaultTableSelectorOptions };
