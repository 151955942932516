import { computed, ref, Ref, nextTick } from 'vue';
import { ComplexModal } from '@studyportals/modal';
import GrammaticalNumberTextPresenter from '@/presenters/grammatical-number-text-presenter';
import { Enrolment } from '@/models/enrolment';
import EventBus from '@/event-bus/event-bus';
import { TransitionTriggeredEvent } from '@/event-bus/transition-triggered-event';
import store from '@/store';

export default class DuplicateAlertReasonModalComponent {
	public modalContent: Ref<HTMLElement | null> = ref(null);
	public duplicateAlertReasonDescription: Ref<HTMLTextAreaElement | null> = ref(null);
	private modal: Ref<ComplexModal | undefined> = ref(undefined);

	constructor(private emit: (name: string, value?: string | boolean) => void) {}

	private selectedEnrolments = computed((): Enrolment[] => {
		return store.getters.selectedEnrolments();
	});

	public textPresenter = computed((): GrammaticalNumberTextPresenter => {
		return new GrammaticalNumberTextPresenter(this.selectedEnrolments.value.length);
	});

	public mounted(): void {
		this.prepareModal();
	}

	public unmounted(): void {
		window.ModalManager.close(this.modal.value);
	}

	public continueToConfirmationScreen(event: MouseEvent): void {
		event.preventDefault();

		const reasonField = this.duplicateAlertReasonDescription.value;
		if (!reasonField || !(reasonField instanceof HTMLTextAreaElement)) {
			return;
		}

		this.emit('reasonUpdated', reasonField.value);

		// Before wrapping up the filling in of the reason, give the parent time to update the property so it's included in the transition.
		void nextTick(() => {
			EventBus.getEvent(TransitionTriggeredEvent).publish();
			window.ModalManager.close(this.modal.value);
			this.updateParentOnClosing();
		});
	}

	private updateParentOnClosing(): void {
		this.emit('closed');
	}

	private prepareModal(): void {
		if (!this.modalContent.value) {
			return;
		}

		this.modal.value = new ComplexModal(this.modalContent.value, {
			cssClassName: 'DuplicateAlertReasonModalWrapper',
			destroyOnClose: true,
			onClose: this.updateParentOnClosing.bind(this)
		});

		window.ModalManager.open(this.modal.value);
	}
}
