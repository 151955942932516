import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "ClientEnrolmentsTableComponent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "grid",
      class: "EnrolmentsTable ag-theme-material",
      style: _normalizeStyle(_ctx.isHistoryTable ? `height: calc(${_ctx.displayedEnrolments.length * 3 + 2.5}rem + 17px);` : '')
    }, null, 4 /* STYLE */)
  ]))
}