import { defineComponent } from 'vue';
import { Partnership } from '@/models/partnership';
import PartnershipSelectorOptionComponent from '@/presentation/components/client/partnership-selector/partnership-selector-option-class';

export default defineComponent({
	props: {
		partnership: Object
	},
	setup: (props, context) => {
		const component = new PartnershipSelectorOptionComponent(context.emit, props.partnership as Partnership);

		return {
			isCurrentPartnership: component.isCurrentPartnership,
			partnership: component.partnership,
			changePartnership: component.changePartnership.bind(component)
		};
	}
});
