import { computed, ref } from 'vue';
import EventBus from '@/event-bus/event-bus';
import { ModalStatusMassEditEnum } from '@/models/modal-status-mass-edit-enum';
import { CollapseMassEditRequestedEvent } from '@/event-bus/collapse-mass-edit-requested-event';
import { EnrolmentEditRequestedEvent } from '@/event-bus/enrolment-edit-requested-event';
import { MassEditCollapsedEvent } from '@/event-bus/mass-edit-collapsed-event';
import { MassEditFocusContentEvent } from '@/event-bus/mass-edit-focus-content-event';
import { MassEditLoseContentFocusEvent } from '@/event-bus/mass-edit-lose-content-focus-event';
import { MassEditHideWarningMultipleStatusesEvent } from '@/event-bus/mass-edit-hide-warning-multiple-statuses-event';
import { MassEditShowWarningMultipleStatusesEvent } from '@/event-bus/mass-edit-show-warning-multiple-statuses-event';
import { MassEditHideWarningMultipleOrganisationsEvent } from '@/event-bus/mass-edit-hide-warning-multiple-organisations-event';
import { MassEditShowWarningMultipleOrganisationsEvent } from '@/event-bus/mass-edit-show-warning-multiple-organisations-event';
import { Enrolment } from '@/models/enrolment';
import { ClearEnrolmentSelectionRequestedEvent } from '@/event-bus/clear-enrolment-selection-requested-event';
import store from '@/store';

export default class MassSelectionOverviewComponent {
	public multipleStatusesWarningShown = ref(false);
	public multipleOrganisationsWarningShown = ref(false);
	public isContentFocused = ref(false);
	public contentVisible = ref(false);
	public preventContentSlideUp = ref(true);
	private collapseMassEditRequestedEventSubscriptionToken = ref('');
	private enrolmentEditRequestedEventSubscriptionToken = ref('');
	private focusContentEventSubscriptionToken = ref('');
	private loseContentFocusEventSubscriptionToken = ref('');
	private hideWarningMultipleStatusesEventSubscriptionToken = ref('');
	private showWarningMultipleStatusesEventSubscriptionToken = ref('');
	private hideWarningMultipleOrganisationsEventSubscriptionToken = ref('');
	private showWarningMultipleOrganisationsEventSubscriptionToken = ref('');

	public selectedItemsCounter = computed((): number => {
		return this.selectedEnrolments.value.length;
	});

	public isVisible = computed((): boolean => {
		return this.selectedItemsCounter.value !== 0;
	});

	public multipleItemsSelected = computed((): boolean => {
		return this.selectedItemsCounter.value > 1;
	});

	public isMassEditModalShown = computed((): boolean => {
		const modalStatus = store.getters.modalStatusMassEdit();
		return modalStatus !== ModalStatusMassEditEnum.INACTIVE;
	});

	private selectedEnrolments = computed((): Enrolment[] => {
		return store.getters.selectedEnrolments();
	});

	public mounted(): void {
		this.collapseMassEditRequestedEventSubscriptionToken.value = EventBus.getEvent(CollapseMassEditRequestedEvent).subscribe(() =>
			this.collapseContent()
		);
		this.enrolmentEditRequestedEventSubscriptionToken.value = EventBus.getEvent(EnrolmentEditRequestedEvent).subscribe(() =>
			this.collapseContent()
		);
		this.focusContentEventSubscriptionToken.value = EventBus.getEvent(MassEditFocusContentEvent).subscribe(() => this.focusContent());
		this.loseContentFocusEventSubscriptionToken.value = EventBus.getEvent(MassEditLoseContentFocusEvent).subscribe(() =>
			this.loseContentFocus()
		);
		this.hideWarningMultipleStatusesEventSubscriptionToken.value = EventBus.getEvent(
			MassEditHideWarningMultipleStatusesEvent
		).subscribe(() => this.hideWarningMultipleStatuses());
		this.showWarningMultipleStatusesEventSubscriptionToken.value = EventBus.getEvent(
			MassEditShowWarningMultipleStatusesEvent
		).subscribe(() => this.showWarningMultipleStatuses());
		this.hideWarningMultipleOrganisationsEventSubscriptionToken.value = EventBus.getEvent(
			MassEditHideWarningMultipleOrganisationsEvent
		).subscribe(() => this.hideWarningMultipleOrganisations());
		this.showWarningMultipleOrganisationsEventSubscriptionToken.value = EventBus.getEvent(
			MassEditShowWarningMultipleOrganisationsEvent
		).subscribe(() => this.showWarningMultipleOrganisations());
	}

	public unmounted(): void {
		EventBus.getEvent(CollapseMassEditRequestedEvent).unsubscribe(this.collapseMassEditRequestedEventSubscriptionToken.value);
		EventBus.getEvent(EnrolmentEditRequestedEvent).unsubscribe(this.enrolmentEditRequestedEventSubscriptionToken.value);
		EventBus.getEvent(MassEditFocusContentEvent).unsubscribe(this.focusContentEventSubscriptionToken.value);
		EventBus.getEvent(MassEditLoseContentFocusEvent).unsubscribe(this.loseContentFocusEventSubscriptionToken.value);
		EventBus.getEvent(MassEditHideWarningMultipleStatusesEvent).unsubscribe(
			this.hideWarningMultipleStatusesEventSubscriptionToken.value
		);
		EventBus.getEvent(MassEditShowWarningMultipleStatusesEvent).unsubscribe(
			this.showWarningMultipleStatusesEventSubscriptionToken.value
		);
		EventBus.getEvent(MassEditHideWarningMultipleOrganisationsEvent).unsubscribe(
			this.hideWarningMultipleOrganisationsEventSubscriptionToken.value
		);
		EventBus.getEvent(MassEditShowWarningMultipleOrganisationsEvent).unsubscribe(
			this.showWarningMultipleOrganisationsEventSubscriptionToken.value
		);
	}

	public updateVisibilityBasedOnSelectedEnrolments(): void {
		if (this.selectedItemsCounter.value !== 0) {
			return;
		}

		this.preventContentSlideUp.value = true;
		this.contentVisible.value = false;
	}

	public focusContent(): void {
		this.isContentFocused.value = true;
	}

	public loseContentFocus(): void {
		this.isContentFocused.value = false;
	}

	public toggleContentVisible(): void {
		this.contentVisible.value = !this.contentVisible.value;
		this.preventContentSlideUp.value = false;
		if (this.contentVisible.value === false) {
			EventBus.getEvent(MassEditCollapsedEvent).publish();
		}
	}

	public clearSelection(): void {
		EventBus.getEvent(ClearEnrolmentSelectionRequestedEvent).publish();
	}

	public hideWarningMultipleStatuses(): void {
		this.multipleStatusesWarningShown.value = false;
	}

	public showWarningMultipleStatuses(): void {
		this.multipleStatusesWarningShown.value = true;
	}

	public hideWarningMultipleOrganisations(): void {
		this.multipleOrganisationsWarningShown.value = false;
	}

	public showWarningMultipleOrganisations(): void {
		this.multipleOrganisationsWarningShown.value = true;
	}

	public openMassEditModal(): void {
		store.mutations.setModalStatusMassEdit(ModalStatusMassEditEnum.PROPERTY_SELECTION);
	}

	private collapseContent(): void {
		this.contentVisible.value = false;
	}
}
