import { ITransitionableEnrolmentReview } from '../i-transitionable-enrolment-review';

export abstract class EnrolmentReviewState {
	public constructor(public review: ITransitionableEnrolmentReview) {}

	public canPerformNext(): boolean {
		return true;
	}

	public abstract display(): void;
	public abstract requiresAdditionalInput(): boolean;
	public abstract next(): void;
}
