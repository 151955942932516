import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransitionTriggers = _resolveComponent("TransitionTriggers")!
  const _component_MoveEnrolmentsToOrganisation = _resolveComponent("MoveEnrolmentsToOrganisation")!
  const _component_MoveEnrolmentsToHistory = _resolveComponent("MoveEnrolmentsToHistory")!
  const _component_MassSelectionOverview = _resolveComponent("MassSelectionOverview")!

  return (_openBlock(), _createBlock(_component_MassSelectionOverview, { ref: "massSelectionOverview" }, {
    default: _withCtx(() => [
      (_ctx.areNoMoveActionsVisible)
        ? (_openBlock(), _createBlock(_component_TransitionTriggers, {
            key: 0,
            onSelectionHasSingleStatus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.whenSelectionHasSingleStatus())),
            onSelectionHasMultipleStatuses: _cache[1] || (_cache[1] = ($event: any) => (_ctx.whenSelectionHasMultipleStatuses())),
            onSelectionHasSingleOrganisation: _cache[2] || (_cache[2] = ($event: any) => (_ctx.whenSelectionHasSingleOrganisation())),
            onSelectionHasMultipleOrganisations: _cache[3] || (_cache[3] = ($event: any) => (_ctx.whenSelectionHasMultipleOrganisations()))
          }))
        : _createCommentVNode("v-if", true),
      (!_ctx.isMoveToHistoryActionShown)
        ? (_openBlock(), _createBlock(_component_MoveEnrolmentsToOrganisation, {
            key: 1,
            onExpanded: _cache[4] || (_cache[4] = ($event: any) => (_ctx.whenMoveEnrolmentsToOrganisationExpanded())),
            onCollapsed: _cache[5] || (_cache[5] = ($event: any) => (_ctx.whenMoveEnrolmentsToOrganisationCollapsed())),
            ref: "moveEnrolmentsToOrganisation"
          }, null, 512 /* NEED_PATCH */))
        : _createCommentVNode("v-if", true),
      (!_ctx.isMoveToOrganisationActionShown)
        ? (_openBlock(), _createBlock(_component_MoveEnrolmentsToHistory, {
            key: 2,
            onExpanded: _cache[6] || (_cache[6] = ($event: any) => (_ctx.whenMovedEnrolmentsToHistoryExpanded())),
            onCollapsed: _cache[7] || (_cache[7] = ($event: any) => (_ctx.whenMovedEnrolmentsToHistoryCollapsed())),
            ref: "moveEnrolmentsToHistory"
          }, null, 512 /* NEED_PATCH */))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */))
}