import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, withKeys as _withKeys } from "vue";
const _hoisted_1 = ["tabindex"];
const _hoisted_2 = {
    key: 0,
    class: "MultiSelectPlaceholder"
};
const _hoisted_3 = { key: 1 };
const _hoisted_4 = {
    key: 1,
    class: "MultiSelectSelectedOptions"
};
const _hoisted_5 = {
    key: 0,
    class: "HelperMessage"
};
const _hoisted_6 = {
    key: 1,
    class: "ValidationLabel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SearchBox = _resolveComponent("SearchBox");
    const _component_OptionGroup = _resolveComponent("OptionGroup");
    const _component_Options = _resolveComponent("Options");
    const _component_SelectedOption = _resolveComponent("SelectedOption");
    return (_openBlock(), _createElementBlock("div", null, [
        _createElementVNode("div", {
            onClick: [
                _cache[1] || (_cache[1] = ($event) => (_ctx.toggleMultiSelect())),
                _cache[2] || (_cache[2] = _withModifiers(() => { }, ["stop"]))
            ],
            onBlur: _cache[3] || (_cache[3] = ($event) => (_ctx.searchable ? false : _ctx.deactivate())),
            onKeyup: _cache[4] || (_cache[4] = _withKeys(($event) => (_ctx.deactivate()), ["esc"])),
            tabindex: _ctx.searchable ? -1 : 0,
            class: _normalizeClass([{ 'is-disabled': _ctx.disabled, 'is-invalid': !_ctx.valid }, "MultiSelectWrapper"]),
            role: "combobox"
        }, [
            _createElementVNode("label", {
                class: _normalizeClass(["MultiSelectLabel", { 'is-empty': _ctx.selectedOption === null }])
            }, [
                _createElementVNode("label", {
                    class: _normalizeClass([{ 'is-active': _ctx.isActive || _ctx.optionsSelected }, "FloatingLabel"])
                }, _toDisplayString(_ctx.label), 3 /* TEXT, CLASS */),
                (_ctx.isActive || _ctx.optionsSelected)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.inputPlaceholder), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true)
            ], 2 /* CLASS */),
            _createElementVNode("div", {
                class: _normalizeClass(["MultiSelectIcon", { 'is-active': _ctx.isActive }])
            }, null, 2 /* CLASS */),
            (_ctx.isActive)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    ref: "dropDown",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(() => { }, ["stop"])),
                    "aria-role": "listbox",
                    class: "MultiSelectDropDown"
                }, [
                    (_ctx.searchable)
                        ? (_openBlock(), _createBlock(_component_SearchBox, {
                            key: 0,
                            onSearchUpdated: _ctx.updateSearch,
                            searchPlaceholder: _ctx.searchPlaceholder
                        }, null, 8 /* PROPS */, ["onSearchUpdated", "searchPlaceholder"]))
                        : _createCommentVNode("v-if", true),
                    (_ctx.groups.length > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableGroupedOptions, (group, index) => {
                                return (_openBlock(), _createBlock(_component_OptionGroup, {
                                    onOptionSelected: _ctx.selectOption,
                                    key: index,
                                    groupData: group
                                }, null, 8 /* PROPS */, ["onOptionSelected", "groupData"]));
                            }), 128 /* KEYED_FRAGMENT */))
                        ]))
                        : (_openBlock(), _createBlock(_component_Options, {
                            key: 2,
                            onOptionSelected: _ctx.selectOption,
                            optionData: _ctx.availableOptions
                        }, null, 8 /* PROPS */, ["onOptionSelected", "optionData"]))
                ], 512 /* NEED_PATCH */))
                : _createCommentVNode("v-if", true),
            (_ctx.multipleOptions)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedOptions, (option, index) => {
                        return (_openBlock(), _createBlock(_component_SelectedOption, {
                            onCustomClick: ($event) => (_ctx.deleteOption(option)),
                            key: index
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(option.label), 1 /* TEXT */)
                            ]),
                            _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onCustomClick"]));
                    }), 128 /* KEYED_FRAGMENT */))
                ]))
                : _createCommentVNode("v-if", true)
        ], 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_1),
        (_ctx.valid && _ctx.helperMessage !== '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.helperMessage), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
        (!_ctx.valid && _ctx.validationLabel !== '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.validationLabel), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
    ]));
}
