import { EnrolmentBatchesEnums } from '@/models/enrolment-batches-enums';
import { RouteEnums } from '@/models/route-enums';
import store from '@/store';

export default class EnrolmentBatchSelector {
	public static selectEnrolmentsBatch(): EnrolmentBatchesEnums {
		// Clients should always get all enrolments.
		if (!store.state.isInternal) {
			return EnrolmentBatchesEnums.ALL;
		}

		const router = store.getters.router();
		// When an internal user has the 'history' page open, history enrolments should be retrieved.
		if (router.current === RouteEnums.HISTORY) {
			return EnrolmentBatchesEnums.HISTORY;
		}

		// When a PSM is selected, the enrolments for that PSM should be picked.
		if (store.getters.selectedIdentityPsm()) {
			return EnrolmentBatchesEnums.PSM;
		}

		// TODO: Use 'active' instead of 'all' when active enrolments can be fetched specifically.
		return EnrolmentBatchesEnums.ALL;
	}
}
