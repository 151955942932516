import { defineComponent } from 'vue';

import ColumnSavingButtonsComponent from '@/presentation/components/internal/column-saving-buttons/column-saving-buttons-class';

export default defineComponent({
	setup: () => {
		const component = new ColumnSavingButtonsComponent();

		return {
			disableSaveButton: component.disableSaveButton,
			disableResetButton: component.disableResetButton,
			saveColumnOrder: component.saveColumnOrder.bind(component),
			resetColumnOrder: component.resetColumnOrder.bind(component)
		};
	}
});
