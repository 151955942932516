import { ref } from 'vue';

export default class EnrolmentExportComponent {
	public isExportModalShown = ref(false);

	public onExportButtonClicked(): void {
		this.isExportModalShown.value = true;
	}

	public hideModal(): void {
		this.isExportModalShown.value = false;
	}
}
