import { computed, Ref, ref } from 'vue';
import { IMultiSelect } from '@studyportals/multiselect/src/interfaces/multiselect.interface';
import { IOption } from '@studyportals/multiselect/src/interfaces/options.interface';
import { ClientFiltersAppliedEvent } from '@/event-bus/client-filters-applied-event';
import tableSelectorRetriever from '@/presentation/components/generalizations/table-selectors/table-selector-retriever';
import EventBus from '@/event-bus/event-bus';
import store from '@/store';

export default class OptionalFilterSectionTuitionFeeComponent {
	public tuitionDropdown: Ref<IMultiSelect | null> = ref(null);
	private tuitionFeeFilter = tableSelectorRetriever.retrieveTuitionFeeFilter();
	private eventSubscriptionToken = ref('');

	public tuitionFeeOptions = computed((): IOption[] => {
		return this.tuitionFeeFilter.options.value.filter((option) => !option.isDefaultOption);
	});

	public mounted(): void {
		this.eventSubscriptionToken.value = EventBus.getEvent(ClientFiltersAppliedEvent).subscribe(this.selectTuitionFee.bind(this));
		this.prefillValue();
	}

	public unmounted(): void {
		EventBus.getEvent(ClientFiltersAppliedEvent).unsubscribe(this.eventSubscriptionToken.value);
	}

	public selectTuitionFee(): void {
		if (!this.tuitionDropdown.value?.selectedOption?.value) {
			return;
		}

		const selectedOption = this.tuitionDropdown.value.selectedOption.value as string;
		this.tuitionFeeFilter.setSelectedOption(selectedOption);
		this.filterEnrolments();
	}

	private filterEnrolments(): void {
		store.actions.updateFilteredEnrolments();
	}

	private prefillValue(): void {
		if (
			!this.tuitionFeeFilter.selectedOptionAsOption.value ||
			!this.tuitionDropdown.value ||
			this.tuitionFeeFilter.selectedOptionIsDefault.value
		) {
			return;
		}

		this.tuitionDropdown.value.selectOption(this.tuitionFeeFilter.selectedOptionAsOption.value as IOption);
	}
}
