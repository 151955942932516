import { Enrolment } from '../enrolment';
import { EnrolmentConfirmationUnsureTrigger } from '../transitions/triggers/enrolment-confirmation-unsure-trigger';
import { EnrolmentConfirmedTrigger } from '../transitions/triggers/enrolment-confirmed-trigger';
import { EnrolmentDeferredTrigger } from '../transitions/triggers/enrolment-deferred-trigger';
import { EnrolmentNotRecognizedTrigger } from '../transitions/triggers/enrolment-not-recognized-trigger';
import { StudentNotRecognizedTrigger } from '../transitions/triggers/student-not-recognized-trigger';
import { TransitionTrigger } from '../transitions/triggers/transition-trigger';
import { IEnrolmentReviewFlowPrefill } from './i-enrolment-review-flow-prefill';
import { IPrefillableReview } from './i-prefillable-review';
import { NullSuccessFeeOption } from './success-fee-options/null-success-fee-option';
import { SuccessFeeOption } from './success-fee-options/success-fee-option';
import SuccessFeeOptionFactory from './success-fee-options/success-fee-option-factory';

export class EnrolmentReviewFlowPrefill implements IEnrolmentReviewFlowPrefill {
	public constructor(
		public readonly target: IPrefillableReview,
		public readonly source: Enrolment
	) {}

	public prefill(): void {
		this.target.changeTrigger(this.determineTrigger());
		this.prefillSuccessFeeOption();
		this.target.changeNoteInput(this.source.confirmationNotes);
	}

	private prefillSuccessFeeOption(): void {
		const successFeeOption = this.determineSuccessFeeOption();

		this.target.changeSelectedSuccessFeeOption(successFeeOption);
		this.prefillNoPayReasons(successFeeOption);
	}

	private prefillNoPayReasons(successFeeOption: SuccessFeeOption): void {
		if (successFeeOption.requiresNoPayReasonsInput) {
			this.target.changeSelectedNoPayReasons([...this.source.noPayReasons]);
		}
	}

	private determineSuccessFeeOption(): SuccessFeeOption {
		return SuccessFeeOptionFactory.findForEnrolment(this.source, this.target.availableSuccessFeeOptions) || new NullSuccessFeeOption();
	}

	private determineTrigger(): TransitionTrigger {
		const firstTrigger = this.target.availableTriggers[0];

		return this.findFirstApplicableTrigger() || firstTrigger;
	}

	private findFirstApplicableTrigger(): TransitionTrigger | undefined {
		return this.target.availableTriggers.find((_) => this.isTriggerApplicable(_));
	}

	private isTriggerApplicable(trigger: TransitionTrigger): boolean {
		if (this.source.isAwaitingReview() || this.source.isConfirmed()) {
			return trigger instanceof EnrolmentConfirmedTrigger;
		}

		if (this.source.isPostponed()) {
			return trigger instanceof EnrolmentDeferredTrigger;
		}

		if (this.source.isNotRecognized()) {
			return trigger instanceof EnrolmentNotRecognizedTrigger;
		}

		if (this.source.isStudentNotRecognized()) {
			return trigger instanceof StudentNotRecognizedTrigger;
		}

		if (this.source.isConfirmationUnsure()) {
			return trigger instanceof EnrolmentConfirmationUnsureTrigger;
		}

		return true;
	}
}
