import { computed, nextTick, Ref, ref } from 'vue';
import { ITableSelector, DefaultTableSelectorOptions } from '@/models/i-table-selector';
import TableSelectorOption from '@/presentation/components/generalizations/table-selectors/table-selector-option';

export default abstract class TableSelector implements ITableSelector {
	public selectedOption: Ref<string | number> = ref(DefaultTableSelectorOptions.DEFAULT);
	public options: Ref<TableSelectorOption[]> = ref([]);

	public get defaultOption(): TableSelectorOption | undefined {
		return this.options.value.find((option) => option.isDefaultOption);
	}

	public get emptyOption(): TableSelectorOption | undefined {
		return this.options.value.find((option) => option.isEmptyOption);
	}

	protected abstract get defaultOptions(): TableSelectorOption[];

	public selectedOptionAsOption = computed((): TableSelectorOption | undefined => {
		return this.options.value.find((option) => option.value === this.selectedOption.value);
	});

	public selectedOptionIsDefault = computed((): boolean => {
		return this.selectedOptionAsOption.value !== undefined && this.selectedOptionAsOption.value.isDefaultOption;
	});

	protected constructor(protected optionsWithoutEnrolmentsCanBeShown = false) {
		// Ensure that additional functions that are used to set options in child classes are available.
		void nextTick(() => {
			this.setOptions();
		});
	}

	public setOptions(): void {
		this.options.value = this.createOptions();
		this.sortOptions();
		this.includeDefaultOptions();
		this.filterOutIrrelevantOptions();
	}

	public setSelectedOption(option: string | number): void {
		this.selectedOption.value = option;
	}

	public resetSelectedOption(): void {
		if (this.emptyOption) {
			this.setSelectedOption(this.emptyOption.value);
		} else if (this.defaultOption) {
			this.setSelectedOption(this.defaultOption.value);
		}
	}

	public abstract createOptions(): TableSelectorOption[];

	private includeDefaultOptions(): void {
		this.options.value = this.defaultOptions.concat(this.options.value);
	}

	private sortOptions(): void {
		this.options.value = this.options.value.sort((left, right) => (left.title > right.title ? 1 : -1));
	}

	private filterOutIrrelevantOptions(): void {
		this.options.value = this.options.value.filter((option) => {
			return (
				option.isOptionWithDisplayableName &&
				(option.isDefaultOption ||
					option.isEmptyOption ||
					option.isOptionWithCorrespondingEnrolments ||
					this.optionsWithoutEnrolmentsCanBeShown)
			);
		});
	}
}
