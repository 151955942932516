import { computed, ref, Ref } from 'vue';
import { CsvExport } from '@/presentation/components/generalizations/enrolment-export/csv-export';
import { InternalHistoryCsvExport } from '@/presentation/components/generalizations/enrolment-export/internal-history-csv-export';
import { ClientHistoryCsvExport } from '@/presentation/components/generalizations/enrolment-export/client-history-csv-export';
import { Enrolment } from '@/models/enrolment';
import store from '@/store';

export default class HistoryExportComponent {
	public canExport = ref(true);
	public csvExportOptions: Ref<CsvExport[]> = ref([]);
	private csvExportDetails = ref('');

	constructor(public historyGroup: string) {
		this.csvExportDetails.value = this.enrolmentsBelongToGroup();
		this.csvExportOptions.value = [
			new (store.state.isInternal ? InternalHistoryCsvExport : ClientHistoryCsvExport)(this.csvExportDetails.value)
		];
	}

	public groupedEnrolments = computed((): Enrolment[] => {
		const enrolmentGroups = store.getters.enrolmentGroups();
		// eslint-disable-next-line no-prototype-builtins
		if (!enrolmentGroups.hasOwnProperty(this.historyGroup)) {
			return [];
		}

		return enrolmentGroups[this.historyGroup];
	});

	public groupedEnrolmentsAsString = computed((): string => {
		return JSON.stringify(this.groupedEnrolments.value);
	});

	public checkExportPossibility(): void {
		this.canExport.value = this.enrolmentsBelongToSameOrganisation();
	}

	private enrolmentsBelongToGroup(): string {
		const groupOption = store.getters.historyGroupSelection();
		const groupText = groupOption.displayText;
		const dateGroup = this.historyGroup ? this.historyGroup : '';

		return `${groupText} ${dateGroup}`;
	}

	private enrolmentsBelongToSameOrganisation(): boolean {
		const organisationIdentities = this.groupedEnrolments.value.map((_) => _.organisationIdentity);
		const organisationIdentitiesSet = new Set<string>(organisationIdentities);

		return 1 === organisationIdentitiesSet.size;
	}
}
