import { ref, Ref } from 'vue';
import { GroupOption } from '@/presentation/components/generalizations/enrolments-table-group-selector/selectors/group-option';
import { HistoryClientGroupSelector }
	from '@/presentation/components/generalizations/enrolments-table-group-selector/selectors/history-client-group-selector';
import { HistoryInternalGroupSelector }
	from '@/presentation/components/generalizations/enrolments-table-group-selector/selectors/history-internal-group-selector';
import { EnrolmentGroupingChangedEvent } from '@/event-bus/enrolment-grouping-changed-event';
import EventBus from '@/event-bus/event-bus';
import store from '@/store';

type THistoryClientGroupSelector = Pick<HistoryClientGroupSelector, keyof HistoryClientGroupSelector>;
type THistoryInternalGroupSelector = Pick<HistoryInternalGroupSelector, keyof HistoryInternalGroupSelector>;

export default class EnrolmentsTableGroupSelectorComponent {
	public selectedGroup: Ref<GroupOption | null> = ref(null);
	public groupSelector: Ref<THistoryClientGroupSelector | THistoryInternalGroupSelector> = ref(
		store.state.isInternal ? new HistoryInternalGroupSelector() : new HistoryClientGroupSelector()
	);

	public mounted(): void {
		this.selectedGroup.value = this.groupSelector.value.defaultOption;

		// Make sure the default group selection is also stored in the Vuex store.
		this.changeGroupOption(this.selectedGroup.value);
	}

	public changeGroupOption(clickedOption: GroupOption): void {
		this.groupSelector.value.selectedOption = clickedOption;
		this.selectedGroup.value = clickedOption;
		store.actions.updateHistoryGroupSelection(clickedOption);

		EventBus.getEvent(EnrolmentGroupingChangedEvent).publish();
	}
}
