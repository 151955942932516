import { computed, ref, Ref } from 'vue';
import { Organisation } from '@/models/organisation';
import { Enrolment } from '@/models/enrolment';
import OrganisationPresenter from '@/presenters/organisation-presenter';
import EventBus from '@/event-bus/event-bus';
import { MassEditCollapsedEvent } from '@/event-bus/mass-edit-collapsed-event';
import { OrganisationSearchResult } from '@/presenters/organisation-search-result';
import { ModalStatusLoadingEnums } from '@/models/modal-status-loading-enum';
import { CollapseMassEditRequestedEvent } from '@/event-bus/collapse-mass-edit-requested-event';
import { EnrolmentsOrganisationChangedEvent } from '@/event-bus/enrolments-organisation-changed-event';
import EnrolmentService from '@/services/enrolments-service';
import store from '@/store';

export default class MoveEnrolmentsToOrganisationComponent {
	public searchBoxInput = ref('');
	public organisation: Ref<Organisation | null> = ref(null);
	public isChangeOrganisationAvailable = ref(true);
	public isExpanded = ref(false);
	public isCollapsed = ref(true);
	public isConfirmationPromptVisible = ref(false);
	private massEditCollapsedEventSubscriptionToken = ref('');

	constructor(private emit: (name: string, value?: string | boolean) => void) {}

	public selectedEnrolments = computed((): Enrolment[] => {
		return store.getters.selectedEnrolments();
	});

	public mounted(): void {
		this.massEditCollapsedEventSubscriptionToken.value = EventBus.getEvent(MassEditCollapsedEvent).subscribe(() =>
			this.resetOrganisation()
		);
		this.isChangeOrganisationAvailable.value = !this.wereSelectedEnrolmentsSharedWithClient();
	}

	public unmounted(): void {
		EventBus.getEvent(MassEditCollapsedEvent).unsubscribe(this.massEditCollapsedEventSubscriptionToken.value);
	}

	public onSelectedEnrolmentsChanged(): void {
		this.isChangeOrganisationAvailable.value = !this.wereSelectedEnrolmentsSharedWithClient();
	}

	public onSearchBoxInputChanged(): void {
		this.searchBoxInput.value = this.searchBoxInput.value.trim();

		if (this.organisation.value && this.organisation.value.identity !== this.searchBoxInput.value) {
			this.organisation.value = null;
		}

		if (this.searchBoxInput.value.length > 0) {
			void OrganisationPresenter.searchForIdentity(this.searchBoxInput.value).then((_) => this.whenOrganisationRetrieved(_));
		}
	}

	public wereSelectedEnrolmentsSharedWithClient(): boolean {
		return this.selectedEnrolments.value.some((_) => _.isSharedForClientProcessing);
	}

	public expand(): void {
		this.isExpanded.value = true;
		this.isCollapsed.value = false;

		this.emit('expanded');
	}

	public collapse(): void {
		this.isExpanded.value = false;
		this.isCollapsed.value = true;
		this.searchBoxInput.value = '';
		this.organisation.value = null;
		this.isConfirmationPromptVisible.value = false;

		this.emit('collapsed');
	}

	public showConfirmationPrompt(): void {
		this.isConfirmationPromptVisible.value = true;
	}

	public async confirmOrganisationChange(): Promise<void> {
		await this.moveEnrolments();
	}

	public cancelOrganisationChange(): void {
		this.isConfirmationPromptVisible.value = false;
	}

	public resetOrganisation(): void {
		this.searchBoxInput.value = '';
	}

	public async moveEnrolments(): Promise<void> {
		if (this.organisation.value === null) {
			return;
		}

		this.showLoader();

		try {
			await EnrolmentService.moveEnrolmentsToOrganisation(this.selectedEnrolments.value, this.organisation.value);

			EventBus.getEvent(CollapseMassEditRequestedEvent).publish();

			this.showSuccessMessage();
			this.collapse();
		} catch (e) {
			this.showFailureMessage();
		}

		EventBus.getEvent(EnrolmentsOrganisationChangedEvent).publish(this.selectedEnrolments.value);
	}

	public showLoader(): void {
		store.actions.setModalStatusLoading(ModalStatusLoadingEnums.ACTIVE);
	}

	public showSuccessMessage(): void {
		store.actions.setModalStatusLoading(ModalStatusLoadingEnums.SUCCESS);
	}

	public showFailureMessage(): void {
		store.actions.setModalStatusLoading(ModalStatusLoadingEnums.FAILED);
	}

	private whenOrganisationRetrieved(result: OrganisationSearchResult): void {
		if (result.hasOrganisation() && result.organisation.identity === this.searchBoxInput.value) {
			this.organisation.value = result.organisation;
		}
	}
}
