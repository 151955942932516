import { DefaultTableSelectorOptions } from '@/models/i-table-selector';
import { Enrolment } from '@/models/enrolment';
import { IEnrolmentStatusCounter } from '@/models/i-enrolment-counters';
import TableFilter from '@/presentation/components/generalizations/table-selectors/filters/table-filter';
import TableSelectorOption from '@/presentation/components/generalizations/table-selectors/table-selector-option';

export default class TableFilterStatus extends TableFilter {
	get defaultOptions(): TableSelectorOption[] {
		return [
			new TableSelectorOption(DefaultTableSelectorOptions.DEFAULT, 'All statuses', this.enrolmentsFilteredByOtherFilters.value.length)
		];
	}

	public createOptions(): TableSelectorOption[] {
		const statusCounters: IEnrolmentStatusCounter[] = [];
		this.enrolmentsFilteredByOtherFilters.value.forEach((enrolment) => {
			const existingCounter = statusCounters.find((counter) => counter.status === enrolment.status.value);
			if (existingCounter) {
				existingCounter.facet++;
				return;
			}

			statusCounters.push({
				label: enrolment.status.name,
				status: enrolment.status.value,
				facet: 1
			});
		});

		return statusCounters.map((counter) => new TableSelectorOption(counter.status, counter.label, counter.facet));
	}

	public doesEnrolmentMatchFilter(enrolment: Enrolment): boolean {
		const option = this.selectedOptionAsOption.value;
		if ((!option || option.isDefaultOption) && !this.selectedOption.value) {
			return true;
		}

		const status = enrolment.status.value.toString();
		const selected = this.selectedOption.value.toString();
		return status === selected;
	}
}
