import { defineComponent, onMounted, onUnmounted } from 'vue';
import MassReviewIncentiveComponent from '@/presentation/components/client/mass-review-incentive/mass-review-incentive-class';

export default defineComponent({
	setup: () => {
		const component = new MassReviewIncentiveComponent();

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		return {
			showIncentive: component.showIncentive,
			grammaticalNumberTextPresenter: component.grammaticalNumberTextPresenter,
			startReview: component.startReview.bind(component)
		};
	}
});
