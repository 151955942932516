import { AdvancedAttributionOrganisations } from "@/models/advanced-attribution-organisations";

class AdvancedAttributionOrganisationPresenter {

	private get advancedAttributionOrganisationIds(): number[] {
		return Object.values(AdvancedAttributionOrganisations).filter(value => typeof value === 'number') as number[];
	}

	public isAdvancedAttributionOrganisation(organisationId: string): boolean {
		const advancedAttributionOrganisationIds = this.advancedAttributionOrganisationIds;

		return advancedAttributionOrganisationIds.includes(parseInt(organisationId, 10));
	}
}

export default new AdvancedAttributionOrganisationPresenter();
