import { ValidationResult } from '../../../validation/validation-result';
import { TriggerPayload } from '../trigger-payload';

export class TriggerPayloadValidation {
	public validate(payload: TriggerPayload): ValidationResult {
		return this.validateInExistingResult(payload, new ValidationResult());
	}

	public validateInExistingResult(payload: TriggerPayload, result: ValidationResult): ValidationResult {
		return result;
	}
}
