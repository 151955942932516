import { computed, ref, Ref } from 'vue';
import EventBus from '@/event-bus/event-bus';
import { ComplexModal } from '@studyportals/modal';
import { Enrolment } from '@/models/enrolment';
import { TransitionTriggeredEvent } from '@/event-bus/transition-triggered-event';
import { ModalStatusTransitionTriggersEnums } from '@/models/modal-status-transition-triggers-enum';
import { TransitionTrigger } from '@/models/transitions/triggers/transition-trigger';
import { TriggerPayload } from '@/models/transitions/trigger-payload/trigger-payload';
import { EnrolmentConfirmationChallengedTrigger } from '@/models/transitions/triggers/enrolment-confirmation-challenged-trigger';
import { EnrolmentRevisedTrigger } from '@/models/transitions/triggers/enrolment-revised-trigger';
import { EnrolmentInProcessingRoundScopeTrigger } from '@/models/transitions/triggers/enrolment-in-processing-round-scope-trigger';
import { DisqualificationReason } from '@/models/disqualification-reason';
import { CollapseMassEditRequestedEvent } from '@/event-bus/collapse-mass-edit-requested-event';
import store from '@/store';

export default class TransitionTriggersModalComponent {
	public disqualificationReasons = DisqualificationReason.all();
	public modalContent: Ref<HTMLElement | null> = ref(null);
	private modal: Ref<ComplexModal | undefined> = ref(undefined);

	public noDisqualificationReasonSelectedError = ref(false);
	public noTriggerPayloadExplanationError = ref(false);

	constructor(
		private emit: (name: string, value?: string | boolean) => void,
		public selectedTransitionTrigger: TransitionTrigger | undefined,
		public triggerPayload: TriggerPayload | undefined
	) {}

	public modalStatusTransitionTriggers = computed((): ModalStatusTransitionTriggersEnums => {
		return store.getters.modalStatusTransitionTriggers();
	});

	public modalStatusIsConfirmation = computed((): boolean => {
		return this.modalStatusTransitionTriggers.value === ModalStatusTransitionTriggersEnums.CONFIRMATION;
	});

	public modalStatusIsTriggerPayload = computed((): boolean => {
		return this.modalStatusTransitionTriggers.value === ModalStatusTransitionTriggersEnums.TRIGGER_PAYLOAD;
	});

	public modalStatusIsInvoiceBatch = computed((): boolean => {
		return this.modalStatusTransitionTriggers.value === ModalStatusTransitionTriggersEnums.INVOICE_BATCH;
	});

	public selectedEnrolments = computed((): Enrolment[] => {
		return store.getters.selectedEnrolments();
	});

	public isTriggerConfirmationChallenged = computed((): boolean => {
		return this.selectedTransitionTrigger instanceof EnrolmentConfirmationChallengedTrigger;
	});

	public isTriggerRevised = computed((): boolean => {
		return this.selectedTransitionTrigger instanceof EnrolmentRevisedTrigger;
	});

	private selectionIncludesEnrolmentsWithoutActivityData = computed((): boolean => {
		return (
			this.selectedEnrolments.value.find((enrolment) => !enrolment.studentFirstActiveDate || !enrolment.studentLastActiveDate) !==
			undefined
		);
	});

	public isTriggerInScopeWithIncompleteEnrolments = computed((): boolean => {
		if (!(this.selectedTransitionTrigger instanceof EnrolmentInProcessingRoundScopeTrigger)) {
			return false;
		}

		return this.selectionIncludesEnrolmentsWithoutActivityData.value;
	});

	public mounted(): void {
		this.prepareModal();
	}

	public unmounted(): void {
		window.ModalManager.close(this.modal.value);
	}

	public onTransitionConfirmed(): void {
		if (!this.selectedTransitionTrigger || !this.triggerPayload) {
			return;
		}

		if (this.isInTriggerPayloadMode() && !this.validatePayload()) {
			return;
		}

		store.mutations.setModalStatusTransitionTriggers(ModalStatusTransitionTriggersEnums.INACTIVE);

		EventBus.getEvent(CollapseMassEditRequestedEvent).publish();
		EventBus.getEvent(TransitionTriggeredEvent).publish();
	}

	public onTransitionCanceled(): void {
		this.emit('resetTrigger');

		store.mutations.setModalStatusTransitionTriggers(ModalStatusTransitionTriggersEnums.INACTIVE);
	}

	public isInTriggerPayloadMode(): boolean {
		if (!this.selectedTransitionTrigger) {
			return false;
		}

		return !this.selectedTransitionTrigger.payloadConfiguration.isEmpty();
	}

	private validatePayload(): boolean {
		if (!this.selectedTransitionTrigger || !this.triggerPayload) {
			return false;
		}

		this.resetValidationFlags();

		if (this.selectedTransitionTrigger.payloadConfiguration.includeDisqualificationReasons) {
			if (this.triggerPayload.disqualificationReasonSelection.isEmpty()) {
				this.noDisqualificationReasonSelectedError.value = true;
				return false;
			}

			if (this.triggerPayload.disqualificationReasonSelection.requiresAdditionalExplanation() && !this.triggerPayload.hasNote()) {
				this.noTriggerPayloadExplanationError.value = true;
				return false;
			}
		}

		if (this.isTriggerConfirmationChallenged.value && !this.triggerPayload.hasNote()) {
			this.noTriggerPayloadExplanationError.value = true;
			return false;
		}

		return !this.hasValidationFlags();
	}

	private resetValidationFlags(): void {
		this.noDisqualificationReasonSelectedError.value = false;
		this.noTriggerPayloadExplanationError.value = false;
	}

	private hasValidationFlags(): boolean {
		return this.noDisqualificationReasonSelectedError.value || this.noTriggerPayloadExplanationError.value;
	}

	private prepareModal(): void {
		if (!this.modalContent.value) {
			return;
		}

		this.modal.value = new ComplexModal(this.modalContent.value, {
			cssClassName: 'TransitionTriggersModalWrapper',
			destroyOnClose: false,
			onClose: this.onTransitionCanceled.bind(this)
		});

		window.ModalManager.open(this.modal.value);
	}
}
