import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';
import { computed } from 'vue';
import { Enrolment } from '@/models/enrolment';
import { EnrolmentStatus } from '@/models/enrolment-status';
import { SuccessFee } from '@/models/success-fee';
import { NoPayReason } from '@/models/no-pay-reason';
import SuccessFeeExtension from '@/models/success-fee-extension';
import store from '@/store';

export class EnrolmentConfirmationStatus implements ICellRendererComp {
	private outerWrapper: HTMLSpanElement;
	private params: ICellRendererParams | null = null;
	private propEnrolment: Enrolment | undefined;
	private predefinedWrapper: HTMLElement | undefined;

	private enrolment = computed((): Enrolment | undefined => {
		if (this.propEnrolment) {
			return this.propEnrolment;
		}

		if (this.params && this.params.data) {
			return this.params.data as Enrolment;
		}
	});

	private isInternal = computed((): boolean => {
		return store.state.isInternal;
	});

	constructor(propEnrolment?: Enrolment | undefined, predefinedWrapper?: HTMLElement | undefined) {
		this.propEnrolment = propEnrolment;
		this.predefinedWrapper = predefinedWrapper;

		this.outerWrapper = document.createElement('span');
	}

	public init(params: ICellRendererParams): void {
		this.params = params;
		this.constructElement();
	}

	public constructElement(): void {
		const statusContent = this.addStatusContent();
		if (!statusContent) {
			return;
		}

		const innerWrapper = document.createElement('div');
		innerWrapper.className = 'ConfirmationStatus';
		innerWrapper.appendChild(statusContent);

		if (this.predefinedWrapper) {
			this.predefinedWrapper.appendChild(innerWrapper);
		} else {
			this.outerWrapper.appendChild(innerWrapper);
		}
	}

	public getGui(): HTMLSpanElement {
		if (this.predefinedWrapper) {
			return this.predefinedWrapper;
		}

		return this.outerWrapper;
	}

	public refresh(): boolean {
		return false;
	}

	private addStatusContent(): HTMLSpanElement | undefined {
		const element = document.createElement('span');
		if (!this.enrolment.value) {
			return;
		}

		element.innerHTML = this.displayEnrolmentStatus(this.enrolment.value.status);
		if (this.enrolment.value.hasNoFurtherActions()) {
			element.classList.add('NotConfirmed');
		} else {
			element.classList.add('Confirmed');
		}

		this.addSuccessFeeContentIfRelevant(element);
		this.addNoPayReasonContentIfRelevant(element);
		return element;
	}

	private addSuccessFeeContentIfRelevant(parent: HTMLSpanElement): void {
		if (!this.enrolment.value?.successFee) {
			return;
		}

		const element = document.createElement('span');
		element.innerHTML = this.createSuccessFeeLabel(this.enrolment.value.successFee);
		element.className = this.colorCodeSuccessFeeLabel(this.enrolment.value.successFee).join(' ');
		parent.appendChild(element);
	}

	private addNoPayReasonContentIfRelevant(parent: HTMLSpanElement): void {
		if (!this.enrolment.value?.noPayReasons.length) {
			return;
		}

		const element = document.createElement('span');
		element.innerHTML = this.createNoPayReasonsLabel(this.enrolment.value.noPayReasons as any[]);
		element.className = 'EnrolledLabel';
		parent.appendChild(element);
	}

	private displayEnrolmentStatus(status: EnrolmentStatus): string {
		return this.isInternal.value ? status.name : status.clientFacingName;
	}

	private colorCodeSuccessFeeLabel(successFee: SuccessFee): string[] {
		const classArray = ['EnrolledLabel'];
		classArray.push(SuccessFeeExtension.determineClassForStyling(successFee));

		return classArray;
	}

	private createSuccessFeeLabel(successFee: SuccessFee): string {
		return SuccessFeeExtension.createSuccessFeeLabel(successFee);
	}

	private createNoPayReasonsLabel(noPayReasons: NoPayReason[]): string {
		return noPayReasons.length > 0 ? 'No fee' : '';
	}
}
