export class Country {
	private static countriesByIsoCode = new Map<string, Country>();

	private countryName: string;

	private constructor(
		public readonly isoCode: string,
		name: string
	) {
		this.countryName = name;
	}

	public static create(isoCode: string, name: string): Country {
		const country = this.obtain(isoCode, name);
		country.changeName(name);

		return country;
	}

	public static obtain(isoCode: string, name?: string): Country {
		if (!this.countriesByIsoCode.has(isoCode.toLowerCase())) {
			this.countriesByIsoCode.set(isoCode.toLowerCase(), new Country(isoCode, name || ''));
		}

		return this.countriesByIsoCode.get(isoCode.toLowerCase()) as Country;
	}

	public get name(): string {
		return this.countryName;
	}

	private changeName(name: string): void {
		this.countryName = name;
	}
}
