import { defineComponent, onMounted, onUnmounted } from 'vue';
import { MultiSelect } from '@studyportals/multiselect';
import OptionalFilterSectionTuitionFeeComponent
	from '@/presentation/components/generalizations/optional-filters/optional-filter-section-tuition-fee/optional-filter-section-tuition-fee-class';

export default defineComponent({
	components: {
		MultiSelect
	},
	setup: () => {
		const component = new OptionalFilterSectionTuitionFeeComponent();

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		return {
			tuitionDropdown: component.tuitionDropdown,
			tuitionFeeOptions: component.tuitionFeeOptions
		};
	}
});
