import { Enrolment } from '@/models/enrolment';
import { EnrolmentStatus as EnrolmentStatusEnum } from '@studyportals/sp-lord-business-interface/src/EnrolmentStatus';
import SuccessFeeExtension from '@/models/success-fee-extension';

class EnrolmentStatusPresenter {
	public getClientStatusLabelOfEnrolment(enrolment: Enrolment): string {
		if (enrolment.status.value !== EnrolmentStatusEnum.CONFIRMED) {
			return enrolment.status.clientFacingName;
		}

		if (enrolment.successFee) {
			return SuccessFeeExtension.createSuccessFeeLabel(enrolment.successFee);
		}

		if (enrolment.noPayReasons.length > 0) {
			return 'No fee';
		}

		return '';
	}
}

export default new EnrolmentStatusPresenter();
