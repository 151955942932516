import { defineComponent, onMounted, toRef } from 'vue';
import Options from '../Options/Options.vue';
import OptionGroup from '../OptionGroup/OptionGroup.vue';
import SearchBox from '../SearchBox/SearchBox.vue';
import SelectedOption from '../SelectedOption/SelectedOption.vue';
import MultiSelectComponent from "./MultiSelectClass";
export default defineComponent({
    components: {
        OptionGroup,
        Options,
        SearchBox,
        SelectedOption
    },
    props: {
        options: { type: Array, default: [] },
        label: { type: String, default: '' },
        placeholder: { type: String, default: '' },
        entity: { type: String, default: '' },
        validationLabel: { type: String, default: '' },
        helperMessage: { type: String, default: '' },
        multipleOptions: { type: Boolean, default: false },
        searchable: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        valid: { type: Boolean, default: true },
        groups: { type: Array, default: [] }
    },
    setup: (props, context) => {
        const options = toRef(props, 'options');
        const groups = toRef(props, 'groups');
        const label = toRef(props, 'label');
        const valid = toRef(props, 'valid');
        const placeholder = toRef(props, 'placeholder');
        const entity = toRef(props, 'entity');
        const validationLabel = toRef(props, 'validationLabel');
        const helperMessage = toRef(props, 'helperMessage');
        const multipleOptions = toRef(props, 'multipleOptions');
        const searchable = toRef(props, 'searchable');
        const disabled = toRef(props, 'disabled');
        const component = new MultiSelectComponent(context.emit, options, placeholder, entity, multipleOptions, disabled, groups);
        onMounted(component.mounted.bind(component));
        return {
            valid,
            label,
            validationLabel,
            helperMessage,
            searchable,
            disabled,
            groups,
            dropDown: component.dropDown,
            selectedOption: component.selectedOption,
            selectedOptions: component.selectedOptions,
            isActive: component.isActive,
            optionsSelected: component.optionsSelected,
            searchPlaceholder: component.searchPlaceholder,
            availableOptions: component.availableOptions,
            availableGroupedOptions: component.availableGroupedOptions,
            inputPlaceholder: component.inputPlaceholder,
            toggleMultiSelect: component.toggleMultiSelect.bind(component),
            deactivate: component.deactivate.bind(component),
            updateSearch: component.updateSearch.bind(component),
            selectOption: component.selectOption.bind(component),
            deleteOption: component.deleteOption.bind(component)
        };
    }
});
