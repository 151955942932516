import { EnrolmentReviewState } from './enrolment-review-state';

export class MassReviewDisclaimerReviewState extends EnrolmentReviewState {
	public display(): void {
		this.review.popups.showMassReviewDisclaimerPopup();
	}

	public requiresAdditionalInput(): boolean {
		return true;
	}

	public next(): void {
		this.review.transitionTo(this.review.createTriggerSelectionReviewState());
	}
}
