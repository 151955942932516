import { defineComponent, onMounted } from 'vue';
import HistoryTableFilterComponent from '@/presentation/components/client/history-table-filter/history-table-filter-class';

export default defineComponent({
	setup: () => {
		const component = new HistoryTableFilterComponent();

		onMounted(component.mounted.bind(component));

		return {
			changeFilterOption: component.changeFilterOption.bind(component)
		};
	}
});
