import { computed, nextTick, ref, Ref } from 'vue';
import { HighlightedFeatureStatusEnum } from '@/models/highlighted-feature-status-enum';
import store from '@/store';

export default class HighlightedFeatureComponent {
	public featureHighlightBlock: Ref<HTMLDialogElement | null> = ref(null);
	private allFlowSteps = Object.values(HighlightedFeatureStatusEnum);

	constructor(private feature: HighlightedFeatureStatusEnum) {}

	public shouldFeatureHighlightBeShown = computed((): boolean => {
		return this.currentHighlightedFeatureFlowStatus.value === this.feature;
	});

	public currentHighlightedFeatureFlowStatus = computed(() => {
		return store.getters.flowStatusHighlightedFeature();
	});

	public isFirstFeature = computed((): boolean => {
		return this.indexOfRelevantStepInFlow.value === 1;
	});

	public isFinalFeature = computed((): boolean => {
		return this.indexOfRelevantStepInFlow.value === this.allFlowSteps.length - 2;
	});

	private indexOfRelevantStepInFlow = computed((): number => {
		return this.allFlowSteps.indexOf(this.currentHighlightedFeatureFlowStatus.value);
	});

	public mounted(): void {
		void this.updateFeatureHighlightBlockDisplayStatus(this.currentHighlightedFeatureFlowStatus.value, null);
	}

	public navigateToPreviousFeature(): void {
		store.mutations.navigateToOtherStepHighlightedFeaturesFlow(false);
	}

	public navigateToNextFeature(): void {
		store.mutations.navigateToOtherStepHighlightedFeaturesFlow(true);
	}

	public closeHighlightedFeatureFlow(): void {
		store.mutations.updateFlowStatusHighlightedFeature(HighlightedFeatureStatusEnum.INACTIVE);
	}

	public async updateFeatureHighlightBlockDisplayStatus(
		newValue: HighlightedFeatureStatusEnum,
		oldValue: HighlightedFeatureStatusEnum | null
	): Promise<void> {
		await nextTick();

		if (!this.featureHighlightBlock.value) {
			return;
		}

		if (newValue === this.feature) {
			this.featureHighlightBlock.value.show();
		} else if (oldValue === this.feature) {
			this.featureHighlightBlock.value.close();
		}
	}
}
