import { computed } from 'vue';
import { ColumnSavingTriggeredEvent } from '@/event-bus/column-saving-triggered-event';
import { ColumnResetTriggeredEvent } from '@/event-bus/column-reset-triggered-event';
import EventBus from '@/event-bus/event-bus';
import store from '@/store';

export default class ColumnSavingButtonsComponent {
	public disableSaveButton = computed((): boolean => {
		return !this.showIdentifiedEnrolmentsTable.value || this.loading.value;
	});

	public disableResetButton = computed((): boolean => {
		return !this.showIdentifiedEnrolmentsTable.value || this.loading.value || !this.customColumnOrderInUse.value;
	});

	private showIdentifiedEnrolmentsTable = computed((): boolean => {
		return store.getters.showIdentifiedEnrolmentsTable();
	});

	private loading = computed((): boolean => {
		return store.getters.loading();
	});

	private customColumnOrderInUse = computed((): boolean => {
		return store.getters.customColumnOrderInUse();
	});

	public saveColumnOrder(): void {
		EventBus.getEvent(ColumnSavingTriggeredEvent).publish();
	}

	public resetColumnOrder(): void {
		EventBus.getEvent(ColumnResetTriggeredEvent).publish();
	}
}
