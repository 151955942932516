import cookieHandler from '@/infrastructure/cookie-handler';

class ThingClient {
	private thingUrl = webpackDefinitions.VUE_APP_THING_URL;
	private s3RetrievalPath = 'retrieve-s3-signed-url-by-url';

	public async retrieveQuickGuideUrl(): Promise<string> {
		const jwt = cookieHandler.retrieveTokenIdCookie();
		const response = await fetch(`${this.thingUrl}/${this.s3RetrievalPath}?currentUrl=ERT-quick-guide.pdf`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': jwt
				}
			});

		const json = await response.json() as { s3SignedUrl: string };
		return json.s3SignedUrl;
	}
}

export default new ThingClient();
