import { computed } from 'vue';
import { EnrolmentInvoiceGroup } from '@/models/enrolment-invoice-group';
import { EnrolmentDateGroup } from '@/models/enrolment-date-group';
import { TEnrolmentGroups } from '@/models/i-enrolment-groups';
import store from '@/store';

export default class EnrolmentsHistorySectionComponent {
	public sortedEnrolmentGroups = computed((): EnrolmentInvoiceGroup[] | EnrolmentDateGroup[] => {
		return store.getters.sortedEnrolmentGroups();
	});

	public enrolmentsInGroup = computed((): TEnrolmentGroups => {
		return store.getters.enrolmentGroups();
	});

	public selectedHistoryGroup = computed((): string => {
		return store.getters.selectedHistoryGroup();
	});

	public loading = computed((): boolean => {
		return store.getters.loading();
	});

	public setSelectedGroup(group: string): void {
		const newGroup = group === this.selectedHistoryGroup.value ? '' : group;
		store.mutations.updateSelectedHistoryGroup(newGroup);
	}
}
