import { computed, ref, Ref } from 'vue';
import EventBus from '@/event-bus/event-bus';
import { ComplexModal } from '@studyportals/modal';
import { ModalStatusInvoiceBatchCreatedNotificationEnums } from '@/models/modal-status-invoice-batch-created-notification-enum';
import { InvoiceBatchCreatedEventPayload } from '@/event-bus/invoice-batch-created-event-payload';
import { Enrolment } from '@/models/enrolment';
import { InvoiceBatchExportAcquiredEvent } from '@/event-bus/invoice-batch-export-acquired-event';
import { PresentationInvoicingBatchExport } from '@studyportals/sp-lord-business-interface';
import { InvoiceBatchExporter } from '@/presentation/components/internal/invoice-batch-created-notification/invoice-batch-exporter';
import InvoiceBatchExportPresenter from '@/presenters/invoice-batch-presenter';
import store from '@/store';

export default class InvoiceBatchCreatedNotificationModalComponent {
	public modalContent: Ref<HTMLElement | null> = ref(null);
	private modal: Ref<ComplexModal | undefined> = ref(undefined);

	public invoiceBatchIdentity = ref('');
	public invoiceBatchPartial = ref(false);
	public enrolmentsNotPlacedInInvoiceBatch = ref(0);
	public csvExport: Ref<{ fileName: string; contents: string } | null> = ref(null);
	private invoiceBatchExportAcquiredEventSubscriptionToken = ref('');

	constructor(
		private emit: (name: string, payload: ModalStatusInvoiceBatchCreatedNotificationEnums) => void,
		public modalStatus: ModalStatusInvoiceBatchCreatedNotificationEnums,
		public payload: InvoiceBatchCreatedEventPayload
	) {}

	public invoiceExportAvailable = computed((): boolean => {
		return this.csvExport.value !== null;
	});

	public selectedEnrolments = computed((): Enrolment[] => {
		return store.getters.selectedEnrolments();
	});

	public modalStatusIsActive = computed((): boolean => {
		return this.modalStatus === ModalStatusInvoiceBatchCreatedNotificationEnums.ACTIVE;
	});

	public modalStatusIsFailed = computed((): boolean => {
		return this.modalStatus === ModalStatusInvoiceBatchCreatedNotificationEnums.FAILED;
	});

	public mounted(): void {
		this.prepareModal();

		if (this.payload) {
			this.invoiceBatchIdentity.value = this.payload.invoiceBatchIdentity;
			this.invoiceBatchPartial.value = this.payload.isPartial;
			this.enrolmentsNotPlacedInInvoiceBatch.value = this.payload.enrolmentsNotPlacedInInvoiceBatch.length;
		}

		void this.retrieveInvoiceBatchExport();

		this.invoiceBatchExportAcquiredEventSubscriptionToken.value = EventBus.getEvent(InvoiceBatchExportAcquiredEvent).subscribe(
			(payload: PresentationInvoicingBatchExport) => this.prepareExport(payload)
		);
	}

	public unmounted(): void {
		EventBus.getEvent(InvoiceBatchExportAcquiredEvent).unsubscribe(this.invoiceBatchExportAcquiredEventSubscriptionToken.value);
		window.ModalManager.close(this.modal.value);
	}

	public hideModal(): void {
		this.emit('updateModalStatus', ModalStatusInvoiceBatchCreatedNotificationEnums.INACTIVE);
	}

	private prepareExport(payload: PresentationInvoicingBatchExport): void {
		const exporter = new InvoiceBatchExporter();
		this.csvExport.value = exporter.exportToString(payload);
	}

	private async retrieveInvoiceBatchExport(): Promise<void> {
		await InvoiceBatchExportPresenter.retrieveInvoiceBatchExport(this.invoiceBatchIdentity.value);
	}

	private prepareModal(): void {
		if (!this.modalContent.value) {
			return;
		}

		this.modal.value = new ComplexModal(this.modalContent.value, {
			cssClassName: 'InvoiceBatchCreatedNotificationModalWrapper',
			destroyOnClose: false,
			onClose: this.hideModal.bind(this)
		});

		window.ModalManager.open(this.modal.value);
	}
}
