import { Enrolment } from '../enrolment';
import { NoPayReason } from '../no-pay-reason';
import { Partnership } from '../partnership';
import { TriggerPayload } from '../transitions/trigger-payload/trigger-payload';
import { TriggerPayloadConfiguration } from '../transitions/trigger-payload/trigger-payload-configuration';
import { NullTrigger } from '../transitions/triggers/null-trigger';
import { TransitionTrigger } from '../transitions/triggers/transition-trigger';
import { ValidationResult } from '../validation/validation-result';
import { DefaultReviewFlowPrefill } from './default-review-flow-prefill';
import { EnrolmentReviewFlowPrefill } from './enrolment-review-flow-prefill';
import { IEnrolmentReviewPopups } from './i-enrolment-review-popups';
import { IPrefillableReview } from './i-prefillable-review';
import { ITransitionableEnrolmentReview } from './i-transitionable-enrolment-review';
import { MassReviewDisclaimerReviewState } from '@/models/enrolment-review/state/mass-review-disclaimer-review-state';
import { MassReviewConfirmationReviewState } from '@/models/enrolment-review/state/mass-review-confirmation-review-state';
import { EnrolmentReviewState } from './state/enrolment-review-state';
import { NoPayReasonsReviewState } from './state/no-pay-reasons-selection-review-state';
import { NullEnrolmentReviewState } from './state/null-enrolment-review-state';
import { SuccessFeeSelectionReviewState } from './state/successfee-selection-review-state';
import { TriggerPayloadReviewState } from './state/trigger-payload-review-state';
import { TriggerSelectionReviewState } from './state/trigger-selection-review-state';
import { NullSuccessFeeOption } from './success-fee-options/null-success-fee-option';
import SelectableNoPayReasons from './success-fee-options/selectable-no-pay-reasons';
import { SuccessFeeOption } from './success-fee-options/success-fee-option';
import SuccessFeeOptionFactory from './success-fee-options/success-fee-option-factory';
import { DataStorage } from '@studyportals/data-storage';
import { TuitionFeeSkipReviewState } from '@/models/enrolment-review/state/tuition-fee-skip-review-state';
import { DataStorageLabelsEnum } from '@/models/data-storage-labels-enum';

export class EnrolmentReview implements ITransitionableEnrolmentReview, IPrefillableReview {
	public validationResult: ValidationResult = new ValidationResult();

	public selectedTrigger: TransitionTrigger = new NullTrigger();
	public selectedTriggerPayload: TriggerPayload = new TriggerPayload();
	public selectedTriggerPayloadConfiguration: TriggerPayloadConfiguration = new TriggerPayloadConfiguration();
	public selectedSuccessFeeOption: SuccessFeeOption = new NullSuccessFeeOption();

	public availableTriggers: TransitionTrigger[] = [];
	public availableSuccessFeeOptions: SuccessFeeOption[] = [];
	public availableNoPayReasons: NoPayReason[] = [];
	private navigationStack: EnrolmentReviewState[] = [];
	private enrolmentReviewState: EnrolmentReviewState = new NullEnrolmentReviewState(this);

	public constructor(
		public partnership: Partnership,
		public readonly enrolments: Enrolment[],
		public popups: IEnrolmentReviewPopups
	) {}

	public display(): void {
		this.changeAvailableTriggers(this.determineAvailableTriggers());
		this.changeAvailableSuccessFeeOptions(this.determineAvailableSuccessFeeOptions());
		this.changeAvailableNoPayReasons(this.determineAvailableNoPayReasons());

		this.prefillFlow();

		this.enrolmentReviewState = this.createInitialState();
		this.enrolmentReviewState.display();
	}

	public hasPrevious(): boolean {
		return this.navigationStack.length > 0;
	}

	public previous(): void {
		if (!this.hasPrevious()) {
			return;
		}

		this.transitionBackTo(this.navigationStack.pop() as EnrolmentReviewState);
	}

	public canProceedGivenCurrentReviewState(): boolean {
		return this.isValid() || !(this.enrolmentReviewState instanceof TriggerPayloadReviewState);
	}

	public async submit(): Promise<void> {
		if (!this.isValid()) {
			throw new Error('EnrolmentReview.submit: input is not valid');
		}

		try {
			await this.selectedTrigger.triggerTransitions(this.enrolments, this.selectedTriggerPayload);
		} catch (error: any) {
			throw new Error(error);
		}
	}

	public changeSelectedSuccessFeeOption(option: SuccessFeeOption): void {
		this.resetInputForSuccessFeeOption(option);

		this.selectedSuccessFeeOption.apply(this.selectedTriggerPayload);
	}

	public changeSelectedNoPayReasons(reasons: NoPayReason[]): void {
		this.selectedTriggerPayload.successFeeInformation.noPayReasons = reasons;
	}

	public changeAvailableSuccessFeeOptions(options: SuccessFeeOption[]): void {
		this.availableSuccessFeeOptions = options;
	}

	public changeAvailableNoPayReasons(noPayReasons: NoPayReason[]): void {
		this.availableNoPayReasons = noPayReasons;
	}

	public changeAvailableTriggers(triggers: TransitionTrigger[]): void {
		this.availableTriggers = triggers;
	}

	public changeNoteInput(note: string): void {
		this.selectedTriggerPayload.note = note;
	}

	public changeTrigger(trigger: TransitionTrigger): void {
		this.resetInputForTrigger(trigger);

		this.prefillEnrolmentInformation();
		this.selectedTriggerPayloadConfiguration = this.selectedTrigger.payloadConfiguration;
	}

	public requiresAdditionalInput(): boolean {
		return this.enrolmentReviewState.requiresAdditionalInput();
	}

	public requiresTuitionFee(): boolean {
		return this.selectedTriggerPayload.successFeeInformation.requiresTuitionFee();
	}

	public requiresNationality(): boolean {
		return this.selectedTriggerPayload.successFeeInformation.requiresNationality();
	}

	public requiresIntakeInformation(): boolean {
		return (
			this.selectedTriggerPayload.successFeeInformation.requiresIntakeInformation() ||
			this.selectedTriggerPayloadConfiguration.includeIntakeInformation
		);
	}

	public requiresProgrammeInformation(): boolean {
		return this.selectedTriggerPayload.successFeeInformation.requiresProgrammeInformation();
	}

	public includeNote(): boolean {
		return this.selectedTriggerPayload.successFeeInformation.includeNote() || this.selectedTriggerPayloadConfiguration.includeNote;
	}

	public isNoteOptional(): boolean {
		return this.selectedTriggerPayload.successFeeInformation.isNoteOptional() && !this.selectedTriggerPayloadConfiguration.includeNote;
	}

	public isOnMassReviewDisclaimerState(): boolean {
		return this.enrolmentReviewState instanceof MassReviewDisclaimerReviewState;
	}

	public isOnMassReviewConfirmationState(): boolean {
		return this.enrolmentReviewState instanceof MassReviewConfirmationReviewState;
	}

	public isOnTuitionFeeSkipState(): boolean {
		return this.enrolmentReviewState instanceof TuitionFeeSkipReviewState;
	}

	public next(): void {
		if (!this.requiresAdditionalInput() || !this.canPerformNext()) {
			return;
		}

		this.enrolmentReviewState.next();
	}

	public canPerformNext(): boolean {
		return this.enrolmentReviewState.canPerformNext();
	}

	public setMassReviewDisclaimerShowStatus(event: { target: HTMLInputElement | undefined }): void {
		if (!event.target) {
			return;
		}

		// Store the no disclaimer flag for 1 month.
		DataStorage.store(
			DataStorageLabelsEnum.NO_MASS_REVIEW_DISCLAIMER_STORAGE_LABEL,
			JSON.stringify(event.target.checked),
			60 * 60 * 24 * 30
		);
	}

	public transitionTo(state: EnrolmentReviewState): void {
		// The screen that is navigated away from should be on the navigation stack, only when this is not the disclaimer screen.
		if (!(this.enrolmentReviewState instanceof MassReviewDisclaimerReviewState)) {
			this.navigationStack.push(this.enrolmentReviewState);
		}

		this.performTransitionTo(state);
	}

	public createTriggerSelectionReviewState(): EnrolmentReviewState {
		return new TriggerSelectionReviewState(this);
	}

	public createSuccessFeeSelectionState(): EnrolmentReviewState {
		return new SuccessFeeSelectionReviewState(this);
	}

	public createNoPayReasonsSelectionState(): EnrolmentReviewState {
		return new NoPayReasonsReviewState(this);
	}

	public createTriggerPayloadReviewState(): EnrolmentReviewState {
		return new TriggerPayloadReviewState(this);
	}

	public createTriggerMassReviewConfirmationState(): EnrolmentReviewState {
		return new MassReviewConfirmationReviewState(this);
	}

	public isValid(): boolean {
		if (!this.selectedTrigger) {
			return false;
		}

		const validation = this.selectedTrigger.payloadConfiguration.createValidation();
		this.validationResult = validation.validate(this.selectedTriggerPayload);

		return this.validationResult.isValid();
	}

	protected createInitialState(): EnrolmentReviewState {
		if (this.enrolments.length > 1 && this.shouldMassReviewDisclaimerBeShown()) {
			return this.createMassReviewDisclaimerState();
		}

		return this.createTriggerSelectionReviewState();
	}

	protected determineAvailableTriggers(): TransitionTrigger[] {
		return this.enrolments && this.enrolments.length > 0 ? [...this.enrolments[0].availableTriggers()] : [];
	}

	protected determineAvailableSuccessFeeOptions(): SuccessFeeOption[] {
		return SuccessFeeOptionFactory.createForPartnership(this.partnership);
	}

	protected determineAvailableNoPayReasons(): NoPayReason[] {
		return SelectableNoPayReasons.obtainForPartnership(this.partnership);
	}

	private shouldMassReviewDisclaimerBeShown(): boolean {
		const show = DataStorage.retrieve(DataStorageLabelsEnum.NO_MASS_REVIEW_DISCLAIMER_STORAGE_LABEL) as string | null;
		return !show || JSON.parse(show) !== true;
	}

	private transitionBackTo(state: EnrolmentReviewState): void {
		this.performTransitionTo(state);
	}

	private createMassReviewDisclaimerState(): EnrolmentReviewState {
		return new MassReviewDisclaimerReviewState(this);
	}

	private prefillFlow(): void {
		const firstEnrolment = this.enrolments[0];

		const shouldPrefillFromEnrolment = this.enrolments.length === 1 && firstEnrolment.isReviewed();
		const enrolmentFlowPrefill = shouldPrefillFromEnrolment
			? new EnrolmentReviewFlowPrefill(this, firstEnrolment)
			: new DefaultReviewFlowPrefill(this);

		enrolmentFlowPrefill.prefill();
	}

	private performTransitionTo(state: EnrolmentReviewState): void {
		this.resetValidation();
		this.enrolmentReviewState = state;
		this.enrolmentReviewState.display();
	}

	private resetValidation(): void {
		this.validationResult = new ValidationResult();
	}

	private resetInputForTrigger(trigger: TransitionTrigger): void {
		this.selectedTrigger = trigger;

		this.selectedTriggerPayload = new TriggerPayload();
		this.changeSelectedSuccessFeeOption(new NullSuccessFeeOption());
	}

	private prefillEnrolmentInformation(): void {
		const firstEnrolment = this.enrolments[0];
		const shouldPrefillFromEnrolment = this.enrolments.length === 1;

		if (shouldPrefillFromEnrolment) {
			this.selectedTriggerPayload.enrichmentSpecification = firstEnrolment.createEnrichmentSpecification();
		}
	}

	private resetInputForSuccessFeeOption(successFeeOption: SuccessFeeOption): void {
		this.selectedSuccessFeeOption = successFeeOption;

		this.changeNoteInput('');
	}
}
