import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "ClientEnrolmentsTableFilter" }
const _hoisted_2 = { class: "FilterContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MultiSelect, {
        ref: "reviewStepsDropdown",
        class: "Filter Medium",
        label: "Review steps",
        placeholder: "Nothing selected",
        options: _ctx.reviewStepsCluster.selector.options,
        onUpdated: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectorClusterHandler.markOptionAsSelected(_ctx.reviewStepsCluster)))
      }, null, 8 /* PROPS */, ["options"])
    ])
  ]))
}