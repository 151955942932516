import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "LogInWidget" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "ChampionButton SignInButton",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.signInInternal()))
    }, " Sign in as internal user "),
    _createElementVNode("button", {
      class: "DriverButton SignInButton",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.signInClient()))
    }, " Sign in as client ")
  ]))
}