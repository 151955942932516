import { defineComponent, Component } from 'vue';
import InternalEnrolmentsExportComponent
	from '@/presentation/components/internal/internal-enrolments-export/internal-enrolments-export-class';
import EnrolmentExport from '@/presentation/components/generalizations/enrolment-export/enrolment-export.vue';

export default defineComponent({
	props: {
		canExport: Boolean
	},
	components: {
		EnrolmentExport: EnrolmentExport as Component
	},
	setup: () => {
		const component = new InternalEnrolmentsExportComponent();

		return {
			filteredEnrolments: component.filteredEnrolments,
			csvExportOptions: component.csvExportOptions
		};
	}
});
