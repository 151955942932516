import { Enrolment } from './enrolment';
import { NoPayReason } from './no-pay-reason';
import { SuccessFee } from './success-fee';

export class Partnership {
	private wasAgreementSigned = false;
	private wasVideoDisplayed = false;
	public constructor(
		public readonly organisationId: string,
		public readonly organisationName: string,
		public readonly successFees: SuccessFee[],
		public readonly noPayReasons: NoPayReason[],
		wasDataProcessingAgreementSigned: boolean,
		wasStudentImpactVideoDisplayed: boolean
	) {
		this.wasAgreementSigned = wasDataProcessingAgreementSigned;
		this.wasVideoDisplayed = wasStudentImpactVideoDisplayed;
	}

	public static createForClientFacingUse(
		organisationId: string,
		organisationName: string,
		successFees: SuccessFee[],
		noPayReasons: NoPayReason[],
		wasDataProcessingAgreementSigned: boolean,
		wasStudentImpactVideoDisplayed: boolean
	): Partnership {
		return new Partnership(
			organisationId,
			organisationName,
			successFees,
			noPayReasons,
			wasDataProcessingAgreementSigned,
			wasStudentImpactVideoDisplayed
		);
	}

	public static createForInternalUse(organisationId: string, successFees: SuccessFee[], noPayReasons: NoPayReason[]): Partnership {
		return new Partnership(organisationId, '', successFees, noPayReasons, true, true);
	}

	public get wasDataProcessingAgreementSigned(): boolean {
		return this.wasAgreementSigned;
	}

	public get wasStudentImpactVideoDisplayed(): boolean {
		return this.wasVideoDisplayed;
	}

	public acknowledgeDataProcessingAgreementSignature(): void {
		this.wasAgreementSigned = true;
	}

	public considerStudentImpactVideoAsDisplayed(): void {
		this.wasVideoDisplayed = true;
	}

	public includesEnrolment(enrolment: Enrolment): boolean {
		return this.organisationId === enrolment.organisationIdentity;
	}

	public equals(that: Partnership): boolean {
		return this.organisationId === that.organisationId;
	}
}
