import { Component, defineComponent, onMounted } from 'vue';
import QuickGuidePreview from '@/presentation/components/client/quick-guide-preview/quick-guide-preview.vue';
import ClientQuickGuideComponent from '@/presentation/views/client/client-quick-guide/client-quick-guide-class';

export default defineComponent({
	components: {
		QuickGuidePreview: QuickGuidePreview as Component
	},
	setup: () => {
		const component = new ClientQuickGuideComponent();

		onMounted(component.mounted.bind(component));

		return {};
	}
});
