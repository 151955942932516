import { IEnrolmentTableCellParams } from '@/models/i-enrolment-table-field-settings';

class HotjarFieldSuppressor {
	public static suppressCellContent = (params: IEnrolmentTableCellParams): string | void => {
		// Fallback: if expected parent information is missing, place the hj-property deeper inside and still show the cell value.
		if (!params || !params.eParentOfValue || !params.eParentOfValue.dataset) {
			return `<span data-hj-suppress="true">${params.value}</span>`;
		}

		// Put the 'data-hj-suppress' value on the parent and show the cell value directly inside the parent element.
		params.eParentOfValue.dataset.hjSuppress = true;
		params.eParentOfValue.innerHTML = params.value;
		return params.eParentOfValue.innerHTML;
	};
}

export default HotjarFieldSuppressor;
