export class CsvExportResult {
	public constructor(
		public readonly fileName: string,
		public readonly contents: Blob,
		public readonly isEmpty: boolean
	) {}

	public static empty(): CsvExportResult {
		return new CsvExportResult('', new Blob([]), true);
	}
}
