import { Enrolment } from '@/models/enrolment';
import { EnrolmentStatus } from '@/models/enrolment-status';
import EnrolmentService from '@/services/enrolments-service';

import { TransitionTrigger } from './transition-trigger';

export class EnrolmentReadyForConfirmationTrigger extends TransitionTrigger {
	public orderingNumber = 30;

	public constructor(excludedEnrolmentStatuses: EnrolmentStatus[]) {
		super('Ready to be sent', excludedEnrolmentStatuses);
	}

	public equals(that: TransitionTrigger): boolean {
		return that instanceof EnrolmentReadyForConfirmationTrigger;
	}

	protected async performTransitions(enrolments: Readonly<Enrolment[]>): Promise<void> {
		await EnrolmentService.triggerEnrolmentReadyForConfirmation(enrolments);
	}
}
