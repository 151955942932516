import state from './state';
import store from '@/store/index';
import { IActions } from '@/models/store/i-actions';
import { RouteEnums } from '@/models/route-enums';
import { ModalStatusLoadingEnums } from '@/models/modal-status-loading-enum';
import { Enrolment } from '@/models/enrolment';
import { Partnership } from '@/models/partnership';
import { EnrolmentsReplacedEvent } from '@/event-bus/enrolments-replaced-event';
import { EnrolmentsReplacedEventPayload } from '@/event-bus/enrolments-replaced-event-payload';
import { EnrolmentsAcquiredEvent } from '@/event-bus/enrolments-acquired-event';
import { PartnershipsRetreivedEvent } from '@/event-bus/partnerships-retrieved-event';
import { PartnershipSelectedEvent } from '@/event-bus/partnership-selected-event';
import { FulfilmentProcessAcquiredEvent } from '@/event-bus/fulfilment-process-acquired-event';
import { ClearEnrolmentSelectionRequestedEvent } from '@/event-bus/clear-enrolment-selection-requested-event';
import { CountriesAcquiredEvent } from '@/event-bus/countries-acquired-event';
import { CurrenciesAcquiredEvent } from '@/event-bus/currencies-acquired-event';
import { ReviewSubmittedEvent } from '@/event-bus/review-submitted-event';
import { DataProcessingAgreementConfirmedEvent } from '@/event-bus/data-processing-agreement-confirmed-event';
import { StudentImpactVideoDisplayedEvent } from '@/event-bus/student-impact-video-displayed-event';
import tableSelectorRetriever from '@/presentation/components/generalizations/table-selectors/table-selector-retriever';
import enrolmentFilter from '@/presentation/components/generalizations/table-selectors/filters/enrolment-filter';
import EventBus from '@/event-bus/event-bus';

const actions: IActions = {
	rebuildEnrolmentSelection: () => {
		store.mutations.changeSelectedEnrolments(Enrolment.findEquivalentEnrolments(state.selectedEnrolments, state.filteredEnrolments));
	},
	setCurrentUser: (user) => {
		store.mutations.setUser(user);

		// Only mark a PSM as selected when the user is a PSM and can be found in the list of PSMs.
		if (user.isPsm && state.projectSuccessManagers.find((psm) => user.identity === psm.id)) {
			store.mutations.setSelectedPSM(user.identity);
		}
	},
	setModalStatusLoading: (status) => {
		store.mutations.updateModalStatusLoading(status);

		if (status !== ModalStatusLoadingEnums.SUCCESS) {
			return;
		}

		// After the user has had enough time to see the "success" message, hide the modal again.
		setTimeout(() => store.mutations.updateModalStatusLoading(ModalStatusLoadingEnums.INACTIVE), 1500);
	},
	setWasReviewSubmitted: (wasReviewSubmitted) => {
		// If a review was explicitly marked as submitted, take away any notification dismissed flag from the past.
		if (wasReviewSubmitted) {
			store.mutations.updateWasReviewSubmittedNotificationDismissed(false);
		}

		// If no value was provided for 'wasReviewSubmitted', conclude it from partnership enrolments data.
		if (wasReviewSubmitted === undefined) {
			wasReviewSubmitted =
				state.partnershipEnrolments.length > 0 &&
				!state.partnershipEnrolments.some((_) => _.isAwaitingReview() || _.isReadyForReviewSubmission());
		}

		store.mutations.updateWasReviewSubmitted(wasReviewSubmitted);
	},
	subscribeToEventBus: () => {
		const changePartnershipEnrolments = (): void => {
			store.mutations.updatePartnershipEnrolments();
			actions.setWasReviewSubmitted();
		};
		const changePartnership = (partnership: Partnership): void => {
			store.mutations.updatePartnership(partnership);

			changePartnershipEnrolments();
		};
		const respondToEnrolmentsAcquiring = (enrolments: Enrolment[]): void => {
			store.mutations.updateEnrolments(enrolments);

			changePartnershipEnrolments();

			EventBus.getEvent(EnrolmentsReplacedEvent).publish(
				new EnrolmentsReplacedEventPayload(Enrolment.findEquivalentEnrolments(state.selectedEnrolments, state.enrolments))
			);
		};

		EventBus.getEvent(EnrolmentsAcquiredEvent).subscribe((enrolments) => {
			respondToEnrolmentsAcquiring(enrolments);
		});

		EventBus.getEvent(PartnershipsRetreivedEvent).subscribe((_) => {
			store.mutations.updatePartnerships(_);

			if (state.partnerships.length > 0) {
				const firstPartnership = state.partnerships[0];

				changePartnership(firstPartnership);
			}
		});

		EventBus.getEvent(PartnershipSelectedEvent).subscribe((partnership) => changePartnership(partnership));
		EventBus.getEvent(FulfilmentProcessAcquiredEvent).subscribe((fulfilmentProcess) =>
			store.mutations.updateFulfilmentProcess(fulfilmentProcess)
		);
		EventBus.getEvent(ClearEnrolmentSelectionRequestedEvent).subscribe(() => store.mutations.clearSelectedEnrolments());
		EventBus.getEvent(CountriesAcquiredEvent).subscribe((countries) => store.mutations.updateCountries(countries));
		EventBus.getEvent(CurrenciesAcquiredEvent).subscribe((currencies) => store.mutations.updateCurrencies(currencies));
		EventBus.getEvent(ReviewSubmittedEvent).subscribe(() => actions.setWasReviewSubmitted(true));
		EventBus.getEvent(DataProcessingAgreementConfirmedEvent).subscribe(() =>
			store.mutations.updatePartnershipsToAcknowledgeDataProcessingAgreementSignature()
		);
		EventBus.getEvent(StudentImpactVideoDisplayedEvent).subscribe(() => store.mutations.considerStudentImpactVideoAsDisplayed());
	},
	updateFilteredEnrolments: () => {
		let enrolments = enrolmentFilter.filteredEnrolments.value;

		// Only filter based on a search query if the search bar is being used.
		if (state.internalEnrolmentsSearchQuery.length) {
			enrolments = enrolments.filter((enrolment) => {
				// Search on student email and student name.
				return (
					(enrolment.studentEmail && enrolment.studentEmail.toLowerCase().includes(state.internalEnrolmentsSearchQuery)) ||
					(enrolment.studentName && enrolment.studentName.toLowerCase().includes(state.internalEnrolmentsSearchQuery))
				);
			});
		}

		enrolments = enrolments.filter((enrolment) => {
			return tableSelectorRetriever.allFilters.find((filter) => !filter.doesEnrolmentMatchFilter(enrolment)) === undefined;
		});

		store.mutations.changeFilteredEnrolments(enrolments);
		actions.rebuildEnrolmentSelection();

		store.mutations.updateVisibilityIdentifiedEnrolmentsTable();

		if (state.router.current === RouteEnums.HISTORY) {
			store.mutations.setHistoryGroupsData();
			store.mutations.setEnrolmentGroups();
		}
	},
	updateHistoryGroupSelection: (groupOption) => {
		store.mutations.setHistoryGroupSelection(groupOption);
		store.mutations.setHistoryGroupsData();
		store.mutations.setEnrolmentGroups();
	}
};

export default actions;
