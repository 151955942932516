import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "Buttons" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.enrolmentReview)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.enrolmentReview.isOnTuitionFeeSkipState())
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("button", {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setTuitionFeeStepSkippingAs(false))),
                  class: "NavigatorButton"
                }, " Override "),
                _createElementVNode("button", {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setTuitionFeeStepSkippingAs(true))),
                  class: "DriverButton RightButton"
                }, " Skip tuition fee ")
              ], 64 /* STABLE_FRAGMENT */))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.enrolmentReview.hasPrevious())
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.previous())),
                      class: "NavigatorButton"
                    }, " Previous "))
                  : _createCommentVNode("v-if", true),
                (_ctx.enrolmentReview.isOnMassReviewDisclaimerState())
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.next())),
                      class: "DriverButton FullWidthButton"
                    }, " Start bulk reviewing "))
                  : (_ctx.enrolmentReview.requiresAdditionalInput())
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 2,
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.next())),
                        disabled: !_ctx.enrolmentReview.canPerformNext(),
                        class: "NavigatorButton RightButton"
                      }, " Next ", 8 /* PROPS */, _hoisted_3))
                    : (_ctx.enrolmentReview.isOnMassReviewConfirmationState())
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 3,
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.submit())),
                          class: "DriverButton RightButton"
                        }, " Apply to all "))
                      : (_openBlock(), _createElementBlock("button", {
                          key: 4,
                          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.submit())),
                          class: "DriverButton RightButton"
                        }, " Confirm "))
              ], 64 /* STABLE_FRAGMENT */))
        ])
      ]))
    : _createCommentVNode("v-if", true)
}