import { SuccessFee } from '@/models/success-fee';
import { PresentationInvoicingBatchExport } from '@studyportals/sp-lord-business-interface';

export class InvoiceBatchExporter {
	public exportToString(invoiceBatchExport: PresentationInvoicingBatchExport): { fileName: string; contents: string } {
		const exportRows = this.createExportRows(invoiceBatchExport);
		const exportContent = this.writeToString(exportRows);

		return {
			fileName: this.determineExportFileName(invoiceBatchExport),
			contents: encodeURI(`data:text/csv;charset=utf-8,${exportContent}`)
		};
	}

	private writeToString(rows: string[][]): string {
		return `data:text/csv;charset=utf-8${rows.map((e) => e.join(',')).join('\n')}`;
	}

	private determineExportFileName(invoiceBatchExport: PresentationInvoicingBatchExport): string {
		return `Invoice batch ${invoiceBatchExport.invoicingBatch.identity} - ${invoiceBatchExport.invoicingBatch.organisationName}.csv`;
	}

	private createExportRows(invoiceBatchExport: PresentationInvoicingBatchExport): string[][] {
		const invoiceBatch = invoiceBatchExport.invoicingBatch;

		const headerRow = this.createHeaderRow();
		const itemRows = invoiceBatchExport.items.map((_) => [
			invoiceBatch.organisationIdentity,
			invoiceBatch.organisationName,
			invoiceBatch.identity,
			_.reviewSubmissionDateIso,
			_.reviewerIdentity,
			_.reviewerName,
			`${SuccessFee.forValue(_.successFeeType).name}`,
			`${_.numberOfEnrolments}`
		]);

		return [headerRow, ...itemRows];
	}

	private createHeaderRow(): string[] {
		return [
			'University ID',
			'University name',
			'Invoice batch ID',
			'Client Review Date',
			'Client Reviewer ID',
			'Client Reviewer Name',
			'Success fee category',
			'Number of enrolments'
		];
	}
}
