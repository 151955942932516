import { computed } from 'vue';
import { DefaultTableSelectorOptions } from '@/models/i-table-selector';
import { Enrolment } from '@/models/enrolment';
import { RouteEnums } from '@/models/route-enums';
import { IEnrolmentSourceCounter } from '@/models/i-enrolment-counters';
import TableFilter from '@/presentation/components/generalizations/table-selectors/filters/table-filter';
import TableSelectorOption from '@/presentation/components/generalizations/table-selectors/table-selector-option';
import store from '@/store';

export default class TableFilterSource extends TableFilter {
	get defaultOptions(): TableSelectorOption[] {
		return [new TableSelectorOption(DefaultTableSelectorOptions.DEFAULT, 'All sources')];
	}

	private onHistoryPage = computed((): boolean => {
		const router = store.getters.router();
		return router.current === RouteEnums.HISTORY;
	});

	public createOptions(): TableSelectorOption[] {
		const statusCounters: IEnrolmentSourceCounter[] = [];
		this.enrolmentsFilteredByOtherFilters.value.forEach((enrolment) => {
			const existingCounter = statusCounters.find((counter) => counter.source === enrolment.clientSourceName);
			if (existingCounter) {
				existingCounter.facet++;
				return;
			}

			statusCounters.push({
				label: enrolment.clientSourceName,
				source: enrolment.clientSourceName,
				facet: 1
			});
		});

		return statusCounters.map((counter) => new TableSelectorOption(counter.source, counter.label, counter.facet));
	}

	public doesEnrolmentMatchFilter(enrolment: Enrolment): boolean {
		const option = this.selectedOptionAsOption.value;
		if (((!option || option.isDefaultOption) && !this.selectedOption.value) || this.onHistoryPage.value) {
			return true;
		}

		return enrolment.clientSourceName === this.selectedOption.value.toString();
	}
}
