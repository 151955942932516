import { computed, ref } from 'vue';
import { CsvExport } from '@/presentation/components/generalizations/enrolment-export/csv-export';
import { ClientCsvExport } from '@/presentation/components/generalizations/enrolment-export/client-csv-export';
import { Enrolment } from '@/models/enrolment';
import store from '@/store';

export default class ClientEnrolmentExportComponent {
	public canExport = ref(true);
	public csvExportOptions: CsvExport[] = [new ClientCsvExport()];

	public filteredEnrolments = computed((): Enrolment[] => {
		return store.getters.filteredEnrolments();
	});

	public filteredEnrolmentsAsString = computed((): string => {
		return JSON.stringify(this.filteredEnrolments.value);
	});

	public checkExportPossibility(): void {
		this.canExport.value = this.enrolmentsBelongToSameOrganisation();
	}

	private enrolmentsBelongToSameOrganisation(): boolean {
		const organisationIdentities = this.filteredEnrolments.value.map((_) => _.organisationIdentity);
		const organisationIdentitiesSet = new Set<string>(organisationIdentities);

		return organisationIdentitiesSet.size === 1;
	}
}
