import { defineComponent, onMounted, onUnmounted } from 'vue';
import DuplicateAlertReasonModalComponent
	from '@/presentation/components/internal/duplicate-alert-reason-modal/duplicate-alert-reason-modal-class';

export default defineComponent({
	props: {
		isShown: Boolean,
		markedAsDuplicated: Boolean,
		duplicateAlertReason: Boolean
	},
	setup: (props, context) => {
		const component = new DuplicateAlertReasonModalComponent(context.emit);

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		return {
			modalContent: component.modalContent,
			duplicateAlertReasonDescription: component.duplicateAlertReasonDescription,
			textPresenter: component.textPresenter,
			markedAsDuplicated: props.markedAsDuplicated,
			continueToConfirmationScreen: component.continueToConfirmationScreen.bind(component)
		};
	}
});
