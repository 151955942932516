import { defineComponent, onMounted, watch } from 'vue';
import HighlightedFeaturesIntroductionModalComponent
	from '@/presentation/components/client/highlighted-features-introduction-modal/highlighted-features-introduction-modal-class';

export default defineComponent({
	setup: () => {
		const component = new HighlightedFeaturesIntroductionModalComponent();

		onMounted(component.mounted.bind(component));

		watch(component.currentHighlightedFeatureFlowStatus, component.updateModalDisplayStatus.bind(component));

		return {
			modalContent: component.modalContent,
			navigateToFirstHighlightedFeature: component.navigateToFirstHighlightedFeature.bind(component)
		};
	}
});
