import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "SearchBarWrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_enrolments_bar = _resolveComponent("search-enrolments-bar")!
  const _component_highlighted_feature = _resolveComponent("highlighted-feature")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_highlighted_feature, {
      feature: _ctx.highlightedFeatureNameSearch,
      elementClassName: "HighlightedFeatureSearch",
      title: _ctx.highlightedFeatureTitle,
      content: _ctx.highlightedFeatureContent
    }, {
      default: _withCtx(() => [
        _createVNode(_component_search_enrolments_bar)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["feature", "title", "content"])
  ]))
}