import { computed, defineComponent, toRef } from 'vue';
import Option from '../Option/Option.vue';
export default defineComponent({
    components: {
        Option
    },
    props: {
        groupData: Object
    },
    setup: (props, context) => {
        const emit = context.emit;
        const groups = toRef(props, 'groupData');
        const groupData = computed(() => {
            return (groups.value || { label: '', options: [] });
        });
        return { emit, groupData };
    }
});
