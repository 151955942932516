import { Component, defineComponent, onMounted } from 'vue';
import EnrolmentsTableGroupSelector
	from '@/presentation/components/generalizations/enrolments-table-group-selector/enrolments-table-group-selector.vue';
import EnrolmentsHistorySection from '@/presentation/components/generalizations/enrolments-history-section/enrolments-history-section.vue';
import EnrolmentsTableFilter from '@/presentation/components/internal/enrolments-table-filter/enrolments-table-filter.vue';
import SearchBarWrapper from '@/presentation/components/client/search-bar-wrapper/search-bar-wrapper.vue';
import InternalHistoryOverviewComponent from '@/presentation/views/internal/internal-history-overview/internal-history-overview-class';
import EasterEggClippy from '@/presentation/components/generalizations/easteregg-clippy/easteregg-clippy.vue';

export default defineComponent({
	components: {
		EnrolmentsTableGroupSelector: EnrolmentsTableGroupSelector as Component,
		EnrolmentsHistorySection: EnrolmentsHistorySection as Component,
		EnrolmentsTableFilter: EnrolmentsTableFilter as Component,
		SearchBarWrapper: SearchBarWrapper as Component,
		EasterEggClippy: EasterEggClippy as Component
	},
	setup: () => {
		const component = new InternalHistoryOverviewComponent();

		onMounted(component.mounted.bind(component));

		return {};
	}
});
