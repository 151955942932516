import { defineComponent } from 'vue';

import InvoiceSummaryComponent from '@/presentation/components/internal/invoice-summary/invoice-summary-class';

export default defineComponent({
	setup: () => {
		const component = new InvoiceSummaryComponent();

		return {
			organisationName: component.organisationName,
			organisationIdentity: component.organisationIdentity,
			getNumberOfRegularSuccessFee: component.getNumberOfRegularSuccessFee.bind(component),
			getNumberOfReducedBecauseAgentInvolvedSuccessFee: component.getNumberOfReducedBecauseAgentInvolvedSuccessFee.bind(component),
			getNumberOfReducedBecauseNationalitySuccessFee: component.getNumberOfReducedBecauseNationalitySuccessFee.bind(component),
			getNumberOfOtherSuccessFee: component.getNumberOfOtherSuccessFee.bind(component)
		};
	}
});
