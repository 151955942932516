import { TriggerPayloadConfiguration } from './trigger-payload-configuration';
import { EnrolmentConfirmedTriggerPayloadValidation } from './validation/enrolment-confirmed-trigger-payload-validation';
import { TriggerPayloadValidation } from './validation/trigger-payload-validation';

export class EnrolmentConfirmedTriggerPayloadConfiguration extends TriggerPayloadConfiguration {
	public constructor() {
		super(false, false, true, false);
	}

	public createValidation(): TriggerPayloadValidation {
		return new EnrolmentConfirmedTriggerPayloadValidation();
	}
}
