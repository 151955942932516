import { defineComponent, onMounted, watch } from 'vue';
import SuspectedDuplicateDetailsModalComponent
	from '@/presentation/components/internal/suspected-duplicate-details-modal/suspected-duplicate-details-modal-class';

export default defineComponent({
	setup: () => {
		const component = new SuspectedDuplicateDetailsModalComponent();

		onMounted(component.mounted.bind(component));

		watch(component.suspectedDuplicateEnrolments, component.constructTableAndModal.bind(component));

		return {
			grid: component.grid,
			modalContent: component.modalContent,
			suspectedDuplicateEnrolments: component.suspectedDuplicateEnrolments
		};
	}
});
