import { DataStorage } from '@studyportals/data-storage';
import { DataStorageLabelsEnum } from '@/models/data-storage-labels-enum';
import { IPerformance } from '@/models/i-performance';
import { TransitionSpeedEnum } from '@/models/transition-speed-enum';
import { Enrolment } from '@/models/enrolment';
import { Product, Action } from '@studyportals/datalake-event-tracker';
import DataLakeEventTracker from '@/infrastructure/data-lake-event-tracker';
import store from '@/store';

export default class RedshiftTracker {
	private tracker: DataLakeEventTracker;

	private get performanceApi(): IPerformance {
		return window.performance as IPerformance;
	}

	private get allEnrolments(): Enrolment[] {
		return store.getters.enrolments();
	}

	private get selectedEnrolments(): Enrolment[] {
		return store.getters.selectedEnrolments();
	}

	private get userId(): string | undefined {
		const user = store.state.user;
		if (!user) {
			return '';
		}

		return user.identity;
	}

	constructor() {
		this.tracker = new DataLakeEventTracker(Product.ERT);
	}

	public trackTransitionInitiated(): void {
		if (!RedshiftTracker.isPerformanceApiAvailable) {
			return;
		}

		this.performanceApi.clearMarks();
		this.performanceApi.clearMeasures();
		this.performanceApi.mark(TransitionSpeedEnum.TRANSITION_INITIATED);

		this.trackEvent({
			action: Action.NON_INTERACTIVE,
			label: 'transition_initiated',
			property: this.determineLoadingSpeedDetails(this.selectedEnrolments)
		});

		DataStorage.store(
			DataStorageLabelsEnum.AMOUNT_OF_ENROLMENTS_FOR_LATEST_TRANSITION,
			this.selectedEnrolments.length.toString()
		);
	}

	public trackTransitionSpeed(): void {
		if (!RedshiftTracker.isPerformanceApiAvailable) {
			return;
		}

		this.performanceApi.mark(TransitionSpeedEnum.TRANSITION_FINISHED);
		const measurement = this.performanceApi.measure(
			TransitionSpeedEnum.TRANSITION_SPEED,
			TransitionSpeedEnum.TRANSITION_INITIATED,
			TransitionSpeedEnum.TRANSITION_FINISHED
		);

		this.trackEvent({
			action: Action.NON_INTERACTIVE,
			label: 'transition_speed',
			property: this.determineLoadingSpeedDetails(this.selectedEnrolments, true),
			value: Math.round(measurement.duration)
		});
	}

	public trackInitialLoadingSpeed(isHistory: boolean): void {
		if (RedshiftTracker.cannotProceedWithTrackingLoadingTime) {
			return;
		}

		store.mutations.markInitialLoadingSpeedAsTracked();

		const loadingTime = Math.round(this.performanceApi.now());

		if (window.DatalakeEventTrackerLoaded) {
			this.wrapUpInitialLoadingSpeedTracking(loadingTime, isHistory);
			return;
		}

		document.addEventListener(
			'DatalakeEventTrackerLoaded',
			() => {
				this.wrapUpInitialLoadingSpeedTracking(loadingTime, isHistory);
			},
			{ once: true }
		);
	}

	private wrapUpInitialLoadingSpeedTracking(loadingTime: number, isHistory: boolean): void {
		this.trackEvent({
			action: Action.NON_INTERACTIVE,
			label: `initial_load_speed${isHistory ? '_history' : ''}`,
			property: this.determineLoadingSpeedDetails(this.allEnrolments),
			value: loadingTime
		});
	}

	private determineLoadingSpeedDetails(enrolments: Enrolment[], useDataStorageValueAsFallback = false): string {
		let details = this.userId ? `${this.userId}_` : '';

		const placeholderElement: HTMLElement | null = document.querySelector('.js-enrolmentTool');
		if (placeholderElement && placeholderElement.dataset.role) {
			details += placeholderElement.dataset.role;
		} else {
			details += 'undetermined_role';
		}

		const enrolmentAmount = this.determineEnrolmentAmount(enrolments, useDataStorageValueAsFallback);
		return `${details}_enrolments_${enrolmentAmount}`;
	}

	private determineEnrolmentAmount(enrolments: Enrolment[], useDataStorageValueAsFallback = false): number {
		if (!useDataStorageValueAsFallback) {
			return enrolments.length;
		}

		const storedAmount = DataStorage.retrieve(DataStorageLabelsEnum.AMOUNT_OF_ENROLMENTS_FOR_LATEST_TRANSITION);
		const storedAmountAsNumber = parseInt(storedAmount, 10);
		return isNaN(storedAmountAsNumber) ? enrolments.length : storedAmountAsNumber;
	}

	private trackEvent(payload: any): void {
		if (!this.tracker.isTrackingFunctionalityAvailable) {
			return;
		}

		this.tracker.trackStructuredEvent(payload);
	}

	private static get cannotProceedWithTrackingLoadingTime(): boolean {
		return !RedshiftTracker.isPerformanceApiAvailable || RedshiftTracker.wasLoadingTimeAlreadyTracked;
	}

	private static get isPerformanceApiAvailable(): boolean {
		return window.performance && typeof window.performance.now === 'function';
	}

	private static get wasLoadingTimeAlreadyTracked(): boolean {
		return store.state.initialLoadingSpeedTracked;
	}
}
