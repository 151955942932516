import { Enrolment } from '@/models/enrolment';
import NoPayReasonFactory from '@/models/no-pay-reason-factory';
import { Partnership } from '@/models/partnership';
import { SuccessFee } from '@/models/success-fee';
import { EnrolmentNotInScopeReason, SuccessFeeType } from '@studyportals/sp-lord-business-interface';
import SelectableNoPayReasons from './selectable-no-pay-reasons';
import { SuccessFeeOption } from './success-fee-option';

class SuccessFeeOptionFactory {
	public createForPartnership(partnership: Partnership): SuccessFeeOption[] {
		const successFeeOptions = partnership.successFees.map((_) => this.createForSuccessFee(_));
		const noPaySuccessFeeOptions = this.createForNoPayReasonsInPartnership(partnership);

		return this.sort([...successFeeOptions, ...noPaySuccessFeeOptions]);
	}

	public findForEnrolment(enrolment: Enrolment, haystack: SuccessFeeOption[]): SuccessFeeOption | undefined {
		return haystack.find((_) => this.isApplicableToEnrolment(enrolment, _));
	}

	public createNoPayBecauseAgentInvolvedSuccessFeeOption(): SuccessFeeOption {
		return new SuccessFeeOption('No commission, agent involved', null, [
			NoPayReasonFactory.obtain(EnrolmentNotInScopeReason.AGENT_INVOLVED)
		]);
	}

	public createNoPayBecauseNationalitySuccessFeeOption(): SuccessFeeOption {
		return new SuccessFeeOption(
			'No commission, based on nationality',
			null,
			[NoPayReasonFactory.obtain(EnrolmentNotInScopeReason.NATIONALITY)],
			true
		);
	}

	public createNoPayBecauseOtherReasonsSuccessFeeOption(): SuccessFeeOption {
		return new SuccessFeeOption('No commission, specify reason', null, [], true, true);
	}

	private isApplicableToEnrolment(enrolment: Enrolment, successFeeOption: SuccessFeeOption): boolean {
		const successFeeInEnrolment = !!enrolment.successFee;
		const successFeeInOption = !!successFeeOption.successFee;
		const successFeeInBoth = successFeeInEnrolment && successFeeInOption;
		const successFeeMissingInBoth = !successFeeInEnrolment && !successFeeInOption;

		if (successFeeInBoth) {
			return enrolment.successFee.equals(successFeeOption.successFee);
		}

		if (successFeeMissingInBoth) {
			const isNoPayAgentInvolved = enrolment.noPayReasons.some((_) => _.type === EnrolmentNotInScopeReason.AGENT_INVOLVED);
			if (isNoPayAgentInvolved) {
				return successFeeOption.noPayReasons.some((_) => _.type === EnrolmentNotInScopeReason.AGENT_INVOLVED);
			}

			const isNoPayNationality = enrolment.noPayReasons.some((_) => _.type === EnrolmentNotInScopeReason.NATIONALITY);
			if (isNoPayNationality) {
				return successFeeOption.noPayReasons.some((_) => _.type === EnrolmentNotInScopeReason.NATIONALITY);
			}

			const isNoPayOther = !isNoPayAgentInvolved && !isNoPayNationality && enrolment.noPayReasons.length > 0;
			if (isNoPayOther) {
				return successFeeOption.requiresNoPayReasonsInput;
			}
		}

		return false;
	}

	private createForNoPayReasonsInPartnership(partnership: Partnership): SuccessFeeOption[] {
		const options: SuccessFeeOption[] = [];

		if (partnership.noPayReasons.some((_) => _.type === EnrolmentNotInScopeReason.AGENT_INVOLVED)) {
			options.push(this.createNoPayBecauseAgentInvolvedSuccessFeeOption());
		}

		if (partnership.noPayReasons.some((_) => _.type === EnrolmentNotInScopeReason.NATIONALITY)) {
			options.push(this.createNoPayBecauseNationalitySuccessFeeOption());
		}

		const shouldIncludeOtherReason = SelectableNoPayReasons.obtainForPartnership(partnership).length > 0;
		if (shouldIncludeOtherReason) {
			options.push(this.createNoPayBecauseOtherReasonsSuccessFeeOption());
		}

		return options;
	}

	private createForSuccessFee(successFee: SuccessFee): SuccessFeeOption {
		const requiresAdditionalInput = successFee.requiresTuitionFee || successFee.requiresNationality || successFee.isOtherSuccessFee();

		return new SuccessFeeOption(successFee.name, successFee, [], requiresAdditionalInput);
	}

	private sort(options: SuccessFeeOption[]): SuccessFeeOption[] {
		return options.sort((left, right) => this.determineSortWeight(left) - this.determineSortWeight(right));
	}

	private determineSortWeight(option: SuccessFeeOption): number {
		if (undefined === option.successFee) {
			return 0;
		}

		if (null === option.successFee) {
			if (option.noPayReasons.some((_) => _.type === EnrolmentNotInScopeReason.AGENT_INVOLVED)) {
				return 40;
			}

			if (option.noPayReasons.some((_) => _.type === EnrolmentNotInScopeReason.NATIONALITY)) {
				return 50;
			}

			return 60;
		}

		if (option.successFee.value === SuccessFeeType.REGULAR) {
			return 10;
		}

		if (option.successFee.value === SuccessFeeType.REDUCED_BECAUSE_AGENT_INVOLVED) {
			return 20;
		}

		if (option.successFee.value === SuccessFeeType.REDUCED_BECAUSE_NATIONALITY) {
			return 30;
		}

		return 100;
	}
}

const successFeeOptionFactory = new SuccessFeeOptionFactory();
export default successFeeOptionFactory;
