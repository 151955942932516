import { Component, defineComponent, onBeforeMount } from 'vue';
import ReviewSubmittedNotification from '@/presentation/components/client/review-submitted-notification/review-submitted-notification.vue';
import ClientEnrolmentsOverviewComponent from '@/presentation/views/client/client-enrolments-overview/client-enrolments-overview-class';
import TabMenu from '@/presentation/components/client/tab-menu/tab-menu.vue';
import ClientIntroductionModal from '@/presentation/components/client/client-introduction-modal/client-introduction-modal.vue';
import LogOut from '@/stand-alone/log-out.vue';
import InitialRoutePresenter from '@/presenters/initial-route-presenter';

export default defineComponent({
	components: {
		ReviewSubmittedNotification: ReviewSubmittedNotification as Component,
		ClientIntroductionModal: ClientIntroductionModal as Component,
		TabMenu: TabMenu as Component,
		LogOut: LogOut as Component
	},
	setup: () => {
		const component = new ClientEnrolmentsOverviewComponent();

		onBeforeMount(() => InitialRoutePresenter.prepareForRoutingActions());

		return {
			standAloneLogin: component.standAloneLogin,
			router: component.router,
			canEnrolmentTableBeShown: component.canEnrolmentTableBeShown,
			shouldReviewSubmittedNotificationBeShown: component.shouldReviewSubmittedNotificationBeShown,
			CurrentComponent: component.CurrentComponent
		};
	}
});
