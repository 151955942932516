import { defineComponent, onMounted, watch } from 'vue';
import { HighlightedFeatureStatusEnum } from '@/models/highlighted-feature-status-enum';
import HighlightedFeatureComponent from '@/presentation/components/client/highlighted-feature/highlighted-feature-class';

export default defineComponent({
	props: {
		feature: String,
		elementClassName: String,
		title: String,
		content: String
	},
	setup: (props) => {
		const component = new HighlightedFeatureComponent(props.feature as HighlightedFeatureStatusEnum);

		onMounted(component.mounted.bind(component));

		watch(component.currentHighlightedFeatureFlowStatus, component.updateFeatureHighlightBlockDisplayStatus.bind(component));

		return {
			elementClassName: props.elementClassName,
			title: props.title,
			content: props.content,
			featureHighlightBlock: component.featureHighlightBlock,
			shouldFeatureHighlightBeShown: component.shouldFeatureHighlightBeShown,
			isFinalFeature: component.isFinalFeature,
			isFirstFeature: component.isFirstFeature,
			navigateToPreviousFeature: component.navigateToPreviousFeature.bind(component),
			navigateToNextFeature: component.navigateToNextFeature.bind(component),
			closeHighlightedFeatureFlow: component.closeHighlightedFeatureFlow.bind(component)
		};
	}
});
