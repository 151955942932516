import { computed, nextTick, ref, Ref, watch } from 'vue';
import { IMultiselectSelectorCluster } from '@/models/i-multiselect-selector-cluster';
import { DefaultTableSelectorOptions } from '@/models/i-table-selector';
import { Enrolment } from '@/models/enrolment';
import { RouteEnums } from '@/models/route-enums';
import { EnrolmentBatchesEnums } from '@/models/enrolment-batches-enums';
import EnrolmentPresenter from '@/presenters/enrolment-presenter';
import store from '@/store';

export default class SelectorClusterHandler {
	private previousOrganisationFilterSelection: Ref<string> = ref(DefaultTableSelectorOptions.EMPTY);

	private noOrganisationsSelected = computed((): boolean => {
		return this.selectedOrganisation.value === null;
	});

	private onIdentifiedEnrolmentsPage = computed((): boolean => {
		const router = store.getters.router();
		return router.current === RouteEnums.IDENTIFIED_ENROLMENTS;
	});

	private enrolmentBatchForFilterSelection = computed((): EnrolmentBatchesEnums => {
		if (this.allPSMOptionSelected.value && this.allOrganisationsSelected.value) {
			return EnrolmentBatchesEnums.ALL;
		}

		if (this.allPSMOptionSelected.value) {
			return EnrolmentBatchesEnums.ORGANISATION;
		}

		if (this.allOrganisationsSelected.value) {
			return EnrolmentBatchesEnums.PSM;
		}

		return EnrolmentBatchesEnums.PSM_AND_ORGANISATION;
	});

	private allOrganisationsSelected = computed((): boolean => {
		return this.selectedOrganisation.value === '';
	});

	private allPSMOptionSelected = computed((): boolean => {
		return store.getters.selectedPsm() === '';
	});

	private selectedOrganisation = computed((): string | null => {
		return store.getters.selectedOrganisation();
	});

	private areEmptyFiltersSelected = computed((): boolean => {
		return store.getters.areEmptyFiltersSelected();
	});

	private enrolments = computed((): Enrolment[] => {
		return store.getters.enrolments();
	});

	public markOptionAsSelected(cluster: IMultiselectSelectorCluster): void {
		if (!cluster.multiselect.selectedOption) {
			return;
		}

		cluster.selector.setSelectedOption(cluster.multiselect.selectedOption.value);

		if (cluster.isFilter || !this.onIdentifiedEnrolmentsPage.value) {
			return;
		}

		this.handleAdditionalSelectionRelatedUpdates(cluster);
	}

	public async prefillMultiselect(cluster: IMultiselectSelectorCluster): Promise<void> {
		await nextTick();
		const selectedOption = cluster.selector.selectedOptionAsOption.value;

		// If no selected option can be found, it's most likely because the option that used to be selected no longer exists, so reset it.
		if (!selectedOption) {
			cluster.selector.resetSelectedOption();
			return;
		}

		// Close the dropdown if it is currently open (so that an updated list of options is available once re-opened).
		if (cluster.multiselect.isActive) {
			cluster.multiselect.toggleMultiSelect();
		}

		// Don't do any prefilling if the correct label is already selected.
		if (cluster.multiselect.selectedOption && cluster.multiselect.selectedOption.label === selectedOption.label) {
			return;
		}

		cluster.multiselect.selectOption(selectedOption);
	}

	public enableWatchers(clusters: IMultiselectSelectorCluster[]): void {
		watch(this.enrolments, this.filterEnrolments.bind(this));

		clusters.forEach((cluster) => {
			watch(cluster.selector.selectedOption, () => void this.prefillMultiselect(cluster));

			if (!cluster.isDynamicOptionsSelector) {
				return;
			}

			watch(cluster.selector.options, () => void this.prefillMultiselect(cluster));
		});
	}

	private filterEnrolments(): void {
		store.actions.updateFilteredEnrolments();
	}

	private handleAdditionalSelectionRelatedUpdates(cluster: IMultiselectSelectorCluster): void {
		if (!this.isSelectorUpdateNecessary(cluster)) {
			return;
		}

		const selectedOption = cluster.selector.selectedOptionAsOption.value;
		if (!selectedOption) {
			return;
		}

		if (selectedOption.isDefaultOption) {
			this.updateSelectedEntity(cluster.isDynamicOptionsSelector, '');
		} else if (!selectedOption.isEmptyOption) {
			this.updateSelectedEntity(cluster.isDynamicOptionsSelector, selectedOption.value.toString());
		}

		// See whether enrolments need to be retrieved from the back-end with this selection.
		void this.updateEnrolmentsBasedOnSelection(cluster);
	}

	private isSelectorUpdateNecessary(cluster: IMultiselectSelectorCluster): boolean {
		let shouldContinue = true;

		// If this is not the organisation selector, bail out.
		if (!cluster.isDynamicOptionsSelector) {
			return shouldContinue;
		}

		// If the last selection of the organisation filter hasn't changed, stop here.
		if (this.previousOrganisationFilterSelection.value === cluster.selector.selectedOption.value.toString()) {
			shouldContinue = false;
		}

		this.previousOrganisationFilterSelection.value = cluster.selector.selectedOption.value.toString();
		return shouldContinue;
	}

	private updateSelectedEntity(isDynamicOptionsSelector: boolean, selection: string): void {
		if (isDynamicOptionsSelector) {
			store.mutations.setSelectedOrganisation(selection);
		} else {
			store.mutations.setSelectedPSM(selection);
		}
	}

	private async updateEnrolmentsBasedOnSelection(cluster: IMultiselectSelectorCluster): Promise<void> {
		// Clear enrolments so that, while fetching enrolments or if nothing is fetched, no outdated enrolments are shown.
		store.mutations.updateEnrolments([]);

		if (this.noOrganisationsSelected.value || this.areEmptyFiltersSelected.value) {
			return;
		}

		if (cluster.selector.selectedOptionAsOption.value?.isRemainingOption) {
			await EnrolmentPresenter.acquireEnrolments(EnrolmentBatchesEnums.NO_VALID_WORK_ORDER);
			return;
		}

		await EnrolmentPresenter.acquireEnrolments(this.enrolmentBatchForFilterSelection.value);
	}
}
