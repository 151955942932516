import { UserSession } from '@/infrastructure';
import { AuthorizedS3Adapter, AuthorizedS3TransferMedium } from '@studyportals/client-internal-platform-aws-authorization';
import { BaseSuperAgentRequestFactory } from '@studyportals/mb-platform-http-requests';
import { LordBusinessClient } from '@studyportals/sp-lord-business-interface';

import { RequestSender } from './';

const lordBusinessQueryUrl = webpackDefinitions.VUE_APP_LORD_BUSINESS_QUERY_URL;
const lordBusinessCommandUrl = webpackDefinitions.VUE_APP_LORD_BUSINESS_COMMAND_URL;
const bucketName = webpackDefinitions.VUE_APP_PRESENTATION_ENROLMENTS_S3_BUCKET;
const bucketRegion = webpackDefinitions.VUE_APP_PRESENTATION_ENROLMENTS_S3_BUCKET_REGION;
const bucketContentAcceleration = webpackDefinitions.VUE_APP_PRESENTATION_ENROLMENTS_S3_BUCKET_CONTENT_ACCELERATION === 'ENABLED';

const lordBusiness = new LordBusinessClient(
	new AuthorizedS3TransferMedium(
		new AuthorizedS3Adapter(UserSession.credentialsLifetimeManager, bucketRegion, bucketContentAcceleration, bucketName)
	),
	new BaseSuperAgentRequestFactory(),
	RequestSender,
	lordBusinessQueryUrl,
	lordBusinessCommandUrl
);

export default lordBusiness;
