export enum ModalStatusEnrolmentReviewEnums {
	INACTIVE = 'inactive',
	MASS_REVIEW_DISCLAIMER = 'massReviewDisclaimer',
	TUITION_FEE_SKIP_REVIEW = 'tuitionFeeSkipReview',
	TRIGGER_SELECTION_REVIEW = 'triggerSelectionReview',
	SUCCESS_FEE_SELECTION_REVIEW = 'successFeeSelectionReview',
	ADDITIONAL_NO_PAY_REASON_SELECTION_REVIEW = 'additionalNoPayReasonSelectionReview',
	TRIGGER_PAYLOAD_REVIEW_FORM = 'triggerPayloadReviewForm',
	MASS_REVIEW_CONFIRMATION = 'massReviewConfirmation'
}
