import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { id: "EnrolmentsHistorySection" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "AmountOfEnrolmentsInGroup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_HistoryExport = _resolveComponent("HistoryExport")!
  const _component_EnrolmentsTable = _resolveComponent("EnrolmentsTable")!
  const _component_EmptyHistoryMessage = _resolveComponent("EmptyHistoryMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.sortedEnrolmentGroups.length > 0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.sortedEnrolmentGroups, (group) => {
                return (_openBlock(), _createElementBlock("div", null, [
                  _createElementVNode("div", {
                    class: _normalizeClass([
							group.displayText === _ctx.selectedHistoryGroup ? 'SelectedEnrolmentGroup' : '',
							'HistoryGroupTitleBlock'
						]),
                    onClick: ($event: any) => (_ctx.setSelectedGroup(group.displayText))
                  }, [
                    _createElementVNode("div", null, [
                      _createElementVNode("i", {
                        class: _normalizeClass(`lnr-chevron-${group.displayText === _ctx.selectedHistoryGroup ? 'down' : 'right'}`)
                      }, null, 2 /* CLASS */),
                      _createTextVNode(" " + _toDisplayString(group.displayText) + " ", 1 /* TEXT */),
                      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.enrolmentsInGroup[group.displayText].length) + " enrolment" + _toDisplayString(_ctx.enrolmentsInGroup[group.displayText].length !== 1 ? 's' : ''), 1 /* TEXT */)
                    ]),
                    _createElementVNode("div", null, [
                      (_openBlock(), _createBlock(_component_HistoryExport, {
                        key: group.displayText,
                        isInternal: _ctx.isInternal,
                        historyGroup: group.displayText
                      }, null, 8 /* PROPS */, ["isInternal", "historyGroup"]))
                    ])
                  ], 10 /* CLASS, PROPS */, _hoisted_2),
                  (_ctx.selectedHistoryGroup === group.displayText)
                    ? (_openBlock(), _createBlock(_component_EnrolmentsTable, { key: 0 }))
                    : _createCommentVNode("v-if", true)
                ]))
              }), 256 /* UNKEYED_FRAGMENT */))
            : (_openBlock(), _createBlock(_component_EmptyHistoryMessage, { key: 1 }))
        ], 64 /* STABLE_FRAGMENT */))
  ]))
}