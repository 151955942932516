import { computed, nextTick, Ref, ref } from 'vue';
import { RouteEnums } from '@/models/route-enums';
import { EnrolmentGroupingChangedEvent } from '@/event-bus/enrolment-grouping-changed-event';
import { ClientFiltersAppliedEvent } from '@/event-bus/client-filters-applied-event';
import { IMultiSelect } from '@studyportals/multiselect/src/interfaces/multiselect.interface';
import { Enrolment } from '@/models/enrolment';
import { IMultiselectSelectorCluster } from '@/models/i-multiselect-selector-cluster';
import SelectorClusterHandler from '@/presentation/components/generalizations/table-selectors/selector-cluster-handler';
import NullMultiselect from '@/presentation/components/generalizations/table-selectors/null-multiselect';
import tableSelectorRetriever from '@/presentation/components/generalizations/table-selectors/table-selector-retriever';
import TableSelectorOption from '@/presentation/components/generalizations/table-selectors/table-selector-option';
import EventBus from '@/event-bus/event-bus';
import store from '@/store';

export default class EnrolmentsTableFilterComponent {
	public selectorClusterHandler = new SelectorClusterHandler();
	public psmDropdown: Ref<IMultiSelect | null> = ref(null);
	public organisationDropdown: Ref<IMultiSelect | null> = ref(null);
	public statusDropdown: Ref<IMultiSelect | null> = ref(null);
	public stepDropdown: Ref<IMultiSelect | null> = ref(null);
	public panelVisible = ref(false);
	private psmSelector = tableSelectorRetriever.retrievePsmSelectorOrFilter();
	private organisationSelector = tableSelectorRetriever.retrieveOrganisationSelectorOrFilter();
	private statusFilter = tableSelectorRetriever.retrieveStatusFilter();
	private stepFilter = tableSelectorRetriever.retrieveStepFilter();
	private nullMultiselect = new NullMultiselect();

	private enrolmentGroupingChangedSubscriptionToken = ref('');
	private filtersAppliedSubscriptionToken = ref('');

	public allClusters = computed((): IMultiselectSelectorCluster[] => {
		return [this.psmCluster.value, this.organisationCluster.value, this.statusCluster.value, this.stepCluster.value];
	});

	public psmCluster = computed((): IMultiselectSelectorCluster => {
		const multiselect = this.psmDropdown.value ? this.psmDropdown.value : this.nullMultiselect;
		return {
			selector: this.psmSelector,
			multiselect,
			isDynamicOptionsSelector: false,
			isFilter: false
		};
	});

	public organisationCluster = computed((): IMultiselectSelectorCluster => {
		const multiselect = this.organisationDropdown.value ? this.organisationDropdown.value : this.nullMultiselect;
		return {
			selector: this.organisationSelector,
			multiselect,
			isDynamicOptionsSelector: true,
			isFilter: false
		};
	});

	public statusCluster = computed((): IMultiselectSelectorCluster => {
		const multiselect = this.statusDropdown.value ? this.statusDropdown.value : this.nullMultiselect;
		return {
			selector: this.statusFilter,
			multiselect,
			isDynamicOptionsSelector: true,
			isFilter: true
		};
	});

	public stepCluster = computed((): IMultiselectSelectorCluster => {
		const multiselect = this.stepDropdown.value ? this.stepDropdown.value : this.nullMultiselect;
		return {
			selector: this.stepFilter,
			multiselect,
			isDynamicOptionsSelector: true,
			isFilter: true
		};
	});

	public psmOptions = computed((): TableSelectorOption[] => {
		return this.psmCluster.value.selector.options.value;
	});

	public organisationOptions = computed((): TableSelectorOption[] => {
		return this.organisationCluster.value.selector.options.value;
	});

	public statusOptions = computed((): TableSelectorOption[] => {
		return this.statusCluster.value.selector.options.value;
	});

	public stepOptions = computed((): TableSelectorOption[] => {
		return this.stepCluster.value.selector.options.value;
	});

	public enrolments = computed((): Enrolment[] => {
		return store.getters.enrolments();
	});

	public onIdentifiedEnrolmentsPage = computed((): boolean => {
		const router = store.getters.router();
		return router.current === RouteEnums.IDENTIFIED_ENROLMENTS;
	});

	public mounted(): void {
		this.enableWatchers();
		this.prefillAllMultiselects();

		this.enrolmentGroupingChangedSubscriptionToken.value = EventBus.getEvent(EnrolmentGroupingChangedEvent).subscribe(
			this.filterEnrolments.bind(this)
		);
		this.filtersAppliedSubscriptionToken.value = EventBus.getEvent(ClientFiltersAppliedEvent).subscribe(
			() => void this.hidePanelIfNoValidationErrors()
		);
	}

	public unmounted(): void {
		EventBus.getEvent(EnrolmentGroupingChangedEvent).unsubscribe(this.enrolmentGroupingChangedSubscriptionToken.value);
		EventBus.getEvent(ClientFiltersAppliedEvent).unsubscribe(this.filtersAppliedSubscriptionToken.value);
	}

	public clearFilters(): void {
		const optionalClientFilters = tableSelectorRetriever.optionalInternalFilters;
		optionalClientFilters.forEach((filter) => filter.resetSelectedOption());

		this.statusFilter.resetSelectedOption();
		this.stepFilter.resetSelectedOption();

		if (this.onIdentifiedEnrolmentsPage.value) {
			return;
		}

		this.organisationSelector.resetSelectedOption();
	}

	private async hidePanelIfNoValidationErrors(): Promise<void> {
		await nextTick();
		const errorElement = document.querySelector('.ErrorMessage');
		if (errorElement) {
			return;
		}

		this.togglePanelVisibility();
	}

	private filterEnrolments(): void {
		store.actions.updateFilteredEnrolments();
	}

	private enableWatchers(): void {
		this.selectorClusterHandler.enableWatchers(this.allClusters.value);
	}

	private prefillAllMultiselects(): void {
		this.allClusters.value.forEach((cluster) => void this.selectorClusterHandler.prefillMultiselect(cluster));
	}

	public togglePanelVisibility(): void {
		this.panelVisible.value = !this.panelVisible.value;
	}
}
