import { computed, reactive, ref, Ref } from 'vue';
import { Enrolment } from '@/models/enrolment';
import { CsvExport } from '@/presentation/components/generalizations/enrolment-export/csv-export';
import { CsvExportResult } from '@/presentation/components/generalizations/enrolment-export/csv-export-result';
import { CsvSeparators, CsvSeparatorNames, TAvailableCsvSeparators } from '@/models/i-csv-separators';
import { ComplexModal } from '@studyportals/modal';
import SpAnalyticsTracker from '@/infrastructure/sp-analytics-tracker';
import GrammaticalNumberTextPresenter from '@/presenters/grammatical-number-text-presenter';
import store from '@/store';

export default class EnrolmentExportModalComponent {
	public availableCsvSeparators: TAvailableCsvSeparators = reactive({
		[CsvSeparatorNames.COMMA]: {
			symbol: CsvSeparators.COMMA,
			name: CsvSeparatorNames.COMMA,
			title: 'CSV (Comma delimited) (*.csv)',
			learnMoreToggled: false,
			description:
				'This is a CSV file that can be imported into other programs. ' +
				'Each value in the response is separated by a comma, ' +
				'and each response is separated by a newline character.'
		},
		[CsvSeparatorNames.SEMICOLON]: {
			symbol: CsvSeparators.SEMICOLON,
			name: CsvSeparatorNames.SEMICOLON,
			title: 'CSV (Semicolon delimited) (*.csv)',
			learnMoreToggled: false,
			description:
				'This is a CSV file that can be imported into other programs. ' +
				'Each value in the response is separated by a semicolon, ' +
				'and each response is separated by a newline character.'
		}
	});
	public csvExportResult: Ref<CsvExportResult | null> = ref(null);
	public modalContent: Ref<HTMLElement | null> = ref(null);
	public selectedExportOption: Ref<CsvExport | null> = ref(null);
	private modal: Ref<ComplexModal | undefined> = ref(undefined);
	private enrolments: Ref<Enrolment[]> = ref([]);

	private get isInternal(): boolean {
		return store.getters.isInternal();
	}

	constructor(
		private emit: (name: string) => void,
		enrolments: Enrolment[],
		exportOptions: CsvExport[]
	) {
		this.enrolments.value = enrolments;
		this.selectedExportOption.value = exportOptions[0];
	}

	public csvExportEmpty = computed((): boolean => {
		return this.csvExportResult.value !== null && this.csvExportResult.value.isEmpty;
	});

	public selectedCsvSeparator = computed((): CsvSeparatorNames => {
		return store.getters.selectedCsvSeparator();
	});

	public textPresenter = computed((): GrammaticalNumberTextPresenter => {
		return new GrammaticalNumberTextPresenter(this.enrolments.value.length);
	});

	public mounted(): void {
		this.prepareModal();
		this.generateExport();
	}

	public unmounted(): void {
		window.ModalManager.close(this.modal.value);
	}

	public isSeparatorSelected(separator: CsvSeparatorNames): boolean {
		return this.selectedCsvSeparator.value === separator;
	}

	public updateSelectedCsvSeparator(separator: CsvSeparatorNames): void {
		store.mutations.updateSelectedCsvSeparator(separator);
	}

	public isLearnMoreToggled(separator: CsvSeparatorNames): boolean {
		return this.availableCsvSeparators[separator].learnMoreToggled;
	}

	public toggleLearnMore(separator: CsvSeparatorNames): void {
		const existingStatus = this.availableCsvSeparators[separator].learnMoreToggled;
		this.closeAllLearnMoreBlocks();
		this.availableCsvSeparators[separator].learnMoreToggled = !existingStatus;
	}

	public onExportCanceled(): void {
		this.clearExport();
		this.hideExportModal();
	}

	public onExportDownloaded(): void {
		if (!this.selectedExportOption.value) {
			return;
		}

		this.download();
		SpAnalyticsTracker.trackEventExportOption(this.selectedExportOption.value);
		this.hideExportModal();
	}

	private prepareModal(): void {
		if (!this.modalContent.value) {
			return;
		}

		this.modal.value = new ComplexModal(this.modalContent.value, {
			cssClassName: this.isInternal ? 'EnrolmentExportModalWrapperInternal' : 'EnrolmentExportModalWrapperClient',
			destroyOnClose: false,
			onClose: this.onExportCanceled.bind(this)
		});

		window.ModalManager.open(this.modal.value);
	}

	public onSelectedExportOptionChanged(): void {
		this.clearExport();
		this.generateExport();
	}

	public generateExport(): void {
		if (!this.selectedExportOption.value) {
			return;
		}

		this.csvExportResult.value = this.selectedExportOption.value.generate(this.enrolments.value);
	}

	private download(): void {
		if (this.csvExportResult.value === null) {
			return;
		}
		const url = window.URL.createObjectURL(this.csvExportResult.value.contents);
		const temporaryLink = document.createElement('a');
		temporaryLink.style.display = 'none';
		temporaryLink.href = url;
		temporaryLink.download = this.csvExportResult.value.fileName;
		document.body.appendChild(temporaryLink);
		temporaryLink.click();

		window.URL.revokeObjectURL(url);
		document.body.removeChild(temporaryLink);
	}

	private hideExportModal(): void {
		this.emit('hideModal');
	}

	private clearExport(): void {
		this.csvExportResult.value = null;
	}

	private closeAllLearnMoreBlocks(): void {
		const keys = Object.values(CsvSeparatorNames) as CsvSeparatorNames[];
		for (const key of keys) {
			this.availableCsvSeparators[key].learnMoreToggled = false;
		}
	}
}
