import { computed, ref } from 'vue';
import { Enrolment } from '@/models/enrolment';
import { ReviewStepsFilterOptionsEnum } from '@/models/review-steps-filter-options-enum';
import tableSelectorRetriever from '@/presentation/components/generalizations/table-selectors/table-selector-retriever';
import store from '@/store';

export default class EnrolmentsReviewSectionComponent {
	public displayAllToReviewMessage = ref(false);
	public displayAllReviewedMessage = ref(false);
	public displayStartReviewMessage = ref(false);
	public displaySubmittedReviewMessage = ref(false);

	public filteredEnrolments = computed((): Enrolment[] => {
		return store.getters.filteredEnrolments();
	});

	public loading = computed((): boolean => {
		return store.getters.loading();
	});

	public updateFilterDisplayState(): void {
		if (!this.filteredEnrolments.value.length) {
			this.displayCorrectEmptyState();
		} else {
			this.resetEmptyState();
		}
	}

	public displayCorrectEmptyState(): void {
		this.resetEmptyState();

		const reviewStepsFilter = tableSelectorRetriever.retrieveReviewStepsFilter();

		switch (reviewStepsFilter.selectedOption.value) {
			case ReviewStepsFilterOptionsEnum.TO_REVIEW:
				this.displayAllToReviewMessage.value = true;
				break;
			case ReviewStepsFilterOptionsEnum.REVIEWED:
				this.displayStartReviewMessage.value = true;
				break;
			case ReviewStepsFilterOptionsEnum.REVIEWED_AND_SUBMITTED:
				this.displaySubmittedReviewMessage.value = true;
				break;
			default:
				this.displayAllToReviewMessage.value = true;
				break;
		}
	}

	public resetEmptyState(): void {
		this.displayAllReviewedMessage.value = false;
		this.displayStartReviewMessage.value = false;
		this.displaySubmittedReviewMessage.value = false;
		this.displayAllToReviewMessage.value = false;
	}
}
