import { computed, nextTick } from 'vue';
import { Enrolment } from '@/models/enrolment';
import store from '@/store';

export default class HistoryTableFilterComponent {
	public enrolments = computed((): Enrolment[] => {
		return store.getters.partnershipEnrolments();
	});

	public mounted(): void {
		this.setFilter(false);
	}

	public changeFilterOption(event: { target: HTMLInputElement }): void {
		// Wait on the next rendering iteration so the new checkbox status is available.
		void nextTick(() => {
			const onlyInvoicable = event.target && !event.target.checked;
			this.setFilter(!onlyInvoicable);
		});
	}

	private setFilter(showNonInvoicable: boolean): void {
		store.mutations.changeVisibilityClientHistoryNonInvoicableEnrolments(showNonInvoicable);
		store.actions.updateFilteredEnrolments();
	}
}
