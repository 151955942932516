import { computed, defineComponent } from 'vue';
export default defineComponent({
    props: {
        disabled: Boolean
    },
    setup: (props, context) => {
        const emit = context.emit;
        const disabled = computed(() => {
            return props.disabled || false;
        });
        return { emit, disabled };
    }
});
