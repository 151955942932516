import { computed } from 'vue';
import { ModalStatusMassEditEnum } from '@/models/modal-status-mass-edit-enum';
import { HighlightedFeatureStatusEnum } from '@/models/highlighted-feature-status-enum';
import { Enrolment } from '@/models/enrolment';
import store from '@/store';

export default class MassEditComponent {
	public highlightedFeatureTitle = 'Mass editing your qualified enrolments';
	public highlightedFeatureContent = `Easily review and edit multiple enrolments' details in one go.
		Make your enrolment review process more efficient and effortless by bulk editing and reviewing
		selected enrolments with various details, all in a single action. Simply select the enrolments
		you want to mass edit and click here to change their details.`;

	public get highlightedFeatureNameMassEdit(): string {
		return HighlightedFeatureStatusEnum.MASS_EDIT;
	}

	public areEnrolmentsSelected = computed((): boolean => {
		return this.selectedEnrolments.length > 0;
	});

	public isMassEditModalShown = computed((): boolean => {
		const modalStatus = store.getters.modalStatusMassEdit();
		return modalStatus !== ModalStatusMassEditEnum.INACTIVE;
	});

	public openMassEditModal(): void {
		store.mutations.setModalStatusMassEdit(ModalStatusMassEditEnum.PROPERTY_MODIFICATION);
	}

	private get selectedEnrolments(): Enrolment[] {
		return store.getters.selectedEnrolments();
	}
}
