import { defineComponent, toRef } from 'vue';
import ClearOptionalFiltersButtonComponent
	from '@/presentation/components/generalizations/optional-filters/clear-optional-filters-button/clear-optional-filters-button-class';

export default defineComponent({
	setup: () => {
		const component = new ClearOptionalFiltersButtonComponent();

		return {
			doesAtLeastOneFilterHaveSelectedOption: component.doesAtLeastOneFilterHaveSelectedOption,
			clearFilters: component.clearFilters.bind(component)
		};
	}
});
