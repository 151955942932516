import { Component, defineComponent, onMounted } from 'vue';
import EnrolmentsTableGroupSelector
	from '@/presentation/components/generalizations/enrolments-table-group-selector/enrolments-table-group-selector.vue';
import PartnershipSelector from '@/presentation/components/client/partnership-selector/partnership-selector.vue';
import EnrolmentsHistorySection from '@/presentation/components/generalizations/enrolments-history-section/enrolments-history-section.vue';
import HistoryTableFilter from '@/presentation/components/client/history-table-filter/history-table-filter.vue';
import SearchBarWrapper from '@/presentation/components/client/search-bar-wrapper/search-bar-wrapper.vue';
import ClientHistoryOverviewComponent from '@/presentation/views/client/client-history-overview/client-history-overview-class';

export default defineComponent({
	components: {
		EnrolmentsTableGroupSelector: EnrolmentsTableGroupSelector as Component,
		EnrolmentsHistorySection: EnrolmentsHistorySection as Component,
		PartnershipSelector: PartnershipSelector as Component,
		HistoryTableFilter: HistoryTableFilter as Component,
		SearchBarWrapper: SearchBarWrapper as Component
	},
	setup: () => {
		const component = new ClientHistoryOverviewComponent();

		onMounted(component.mounted.bind(component));

		return {
			organisationName: component.organisationName
		};
	}
});
