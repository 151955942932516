import { computed, Ref, ref } from 'vue';
import { EnrolmentReviewStartedEventPayload } from '@/event-bus/enrolment-review-started-event-payload';
import EventBus from '@/event-bus/event-bus';
import { InternalEnrolmentReviewStartedEvent } from '@/event-bus/internal-enrolment-review-started-event';
import { TransitionTriggeredEvent } from '@/event-bus/transition-triggered-event';
import { Enrolment } from '@/models/enrolment';
import { Partnership } from '@/models/partnership';
import { TriggerPayload } from '@/models/transitions/trigger-payload/trigger-payload';
import { EnrolmentPlacedInInvoicingBatchTrigger } from '@/models/transitions/triggers/enrolment-placed-in-invoicing-batch-trigger';
import { EnrolmentPreconfirmedTrigger } from '@/models/transitions/triggers/enrolment-preconfirmed-trigger';
import { EnrolmentDuplicationConfirmedTrigger } from '@/models/transitions/triggers/enrolment-duplication-confirmed-trigger';
import { TransitionTrigger } from '@/models/transitions/triggers/transition-trigger';
import FulfillmentStepIndication from '@/models/fulfillment-step-indication';
import { ModalStatusLoadingEnums } from '@/models/modal-status-loading-enum';
import { ModalStatusTransitionTriggersEnums } from '@/models/modal-status-transition-triggers-enum';
import store from '@/store';

export default class TransitionTriggersComponent {
	public selectionHasSameStatus = ref(false);
	public selectionHasSameOrganisation = ref(false);
	public duplicateAlertReason = ref('');

	public isDuplicateAlertReasonModalShown = ref(false);

	public selectedTransitionTrigger: Ref<TransitionTrigger | undefined> = ref();
	public triggerPayload: Ref<TriggerPayload | undefined> = ref();

	private transitionTriggeredEventSubscriptionToken = ref('');

	constructor(private emit: (name: string) => void) {}

	public selectedEnrolments = computed((): Enrolment[] => {
		return store.getters.selectedEnrolments();
	});

	public triggers = computed((): TransitionTrigger[] => {
		return this.selectedEnrolments.value && this.selectedEnrolments.value.length > 0
			? this.sortTriggers(this.getOverlappingTriggers())
			: [];
	});

	public transitionTriggersStatusIsInactive = computed((): boolean => {
		return store.getters.modalStatusTransitionTriggers() === ModalStatusTransitionTriggersEnums.INACTIVE;
	});

	public isTriggerDuplicated = computed((): boolean => {
		return this.selectedTransitionTrigger.value instanceof EnrolmentDuplicationConfirmedTrigger;
	});

	public stepIsDuplicateSuspected = computed((): boolean => {
		const firstEnrolmentInSelection = this.selectedEnrolments.value[0];
		return firstEnrolmentInSelection.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.DUPLICATION_SUSPECTED);
	});

	public updateDuplicateAlertReason(): void {
		if (!this.triggerPayload.value) {
			return;
		}

		this.triggerPayload.value.note = this.duplicateAlertReason.value;
	}

	public onSelectedEnrolmentsChanged(): void {
		const previousSelectionHadSameStatus = this.selectionHasSameStatus.value;
		const previousSelectionHadSameOrganisation = this.selectionHasSameOrganisation.value;
		this.selectionHasSameStatus.value = this.haveSelectedEnrolmentsTheSameStatus();
		this.selectionHasSameOrganisation.value = this.haveSelectedEnrolmentsTheSameOrganisation();

		if (previousSelectionHadSameStatus && !this.selectionHasSameStatus.value) {
			this.emit('selectionHasMultipleStatuses');
		}
		if (!previousSelectionHadSameStatus && this.selectionHasSameStatus.value) {
			this.emit('selectionHasSingleStatus');
		}
		if (previousSelectionHadSameOrganisation && !this.selectionHasSameOrganisation.value) {
			this.emit('selectionHasMultipleOrganisations');
		}
		if (!previousSelectionHadSameOrganisation && this.selectionHasSameOrganisation.value) {
			this.emit('selectionHasSingleOrganisation');
		}
	}

	public mounted(): void {
		this.selectionHasSameStatus.value = this.haveSelectedEnrolmentsTheSameStatus();
		this.selectionHasSameOrganisation.value = this.haveSelectedEnrolmentsTheSameOrganisation();

		this.transitionTriggeredEventSubscriptionToken.value = EventBus.getEvent(TransitionTriggeredEvent).subscribe(() => {
			if (!this.selectedTransitionTrigger.value || !this.triggerPayload.value) {
				return;
			}

			void this.triggerTransition(this.selectedTransitionTrigger.value, this.triggerPayload.value);
		});
	}

	public unmounted(): void {
		EventBus.getEvent(TransitionTriggeredEvent).unsubscribe(this.transitionTriggeredEventSubscriptionToken.value);
	}

	public requestTransition(trigger: TransitionTrigger): void {
		this.selectedTransitionTrigger.value = trigger;
		this.triggerPayload.value = new TriggerPayload();

		if (this.isInTriggerInvoiceMode()) {
			this.showInvoiceSummaryModal();
		} else if (this.isInPreconfirmationMode()) {
			this.startReview();
		} else if (this.isInTriggerPayloadMode()) {
			this.showTriggerPayloadModal();
		} else if (this.stepIsDuplicateSuspected.value) {
			this.showDuplicateAlertReasonModal();
		} else {
			this.showTransitionConfirmationModal();
		}
	}

	public sortTriggers(triggers: TransitionTrigger[]): TransitionTrigger[] {
		const sortedTriggers = triggers.sort((left, right) => {
			return left.orderingNumber > right.orderingNumber ? 1 : -1;
		});

		return sortedTriggers;
	}

	public async triggerTransition(trigger: TransitionTrigger, payload: TriggerPayload): Promise<void> {
		this.showLoader();

		try {
			await trigger.triggerTransitions(this.selectedEnrolments.value, payload);

			this.selectedTransitionTrigger.value = undefined;
			this.triggerPayload.value = undefined;
			store.mutations.setModalStatusTransitionTriggers(ModalStatusTransitionTriggersEnums.INACTIVE);
			this.showSuccessMessage();
		} catch (error) {
			this.showFailureMessage();
		}
	}

	public showLoader(): void {
		store.actions.setModalStatusLoading(ModalStatusLoadingEnums.ACTIVE);
	}

	public showSuccessMessage(): void {
		store.actions.setModalStatusLoading(ModalStatusLoadingEnums.SUCCESS);
	}

	public showFailureMessage(): void {
		store.actions.setModalStatusLoading(ModalStatusLoadingEnums.FAILED);
	}

	public showTransitionConfirmationModal(): void {
		store.mutations.setModalStatusTransitionTriggers(ModalStatusTransitionTriggersEnums.CONFIRMATION);
	}

	public showTriggerPayloadModal(): void {
		store.mutations.setModalStatusTransitionTriggers(ModalStatusTransitionTriggersEnums.TRIGGER_PAYLOAD);
	}

	public showInvoiceSummaryModal(): void {
		store.mutations.setModalStatusTransitionTriggers(ModalStatusTransitionTriggersEnums.INVOICE_BATCH);
		this.triggerPayload.value = new TriggerPayload(
			undefined,
			undefined,
			undefined,
			undefined,
			this.selectedEnrolments.value[0].organisationIdentity
		);
	}

	public showDuplicateAlertReasonModal(): void {
		this.isDuplicateAlertReasonModalShown.value = true;
	}

	public hideDuplicateAlertReasonModal(): void {
		this.isDuplicateAlertReasonModalShown.value = false;
	}

	public updateDuplicateAlertReasonFromChild(reason: string): void {
		this.duplicateAlertReason.value = reason;
	}

	public resetTrigger(): void {
		this.selectedTransitionTrigger.value = undefined;
		this.triggerPayload.value = undefined;
	}

	private getOverlappingTriggers(): TransitionTrigger[] {
		let overlappingTriggers: TransitionTrigger[] = [...this.selectedEnrolments.value[0].availableTriggers()];

		for (let i = 1; i < this.selectedEnrolments.value.length; i++) {
			const otherTriggers = [...this.selectedEnrolments.value[i].availableTriggers()];
			overlappingTriggers = this.determineIntersectionTriggers(overlappingTriggers, otherTriggers);
		}

		if (!this.haveSelectedEnrolmentsTheSameOrganisation()) {
			overlappingTriggers = this.extractTriggersForSingleOrganisation(overlappingTriggers);
		}

		return overlappingTriggers;
	}

	private extractTriggersForSingleOrganisation(overlappingTriggers: TransitionTrigger[]): TransitionTrigger[] {
		return overlappingTriggers.filter(
			(_) => !(_ instanceof EnrolmentPreconfirmedTrigger) && !(_ instanceof EnrolmentPlacedInInvoicingBatchTrigger)
		);
	}

	private determineIntersectionTriggers(left: TransitionTrigger[], right: TransitionTrigger[]): TransitionTrigger[] {
		return left.filter((leftItem) => right.some((rightItem) => rightItem.equals(leftItem)));
	}

	private isInTriggerPayloadMode(): boolean {
		return this.selectedTransitionTrigger.value ? !this.selectedTransitionTrigger.value.payloadConfiguration.isEmpty() : false;
	}

	private isInTriggerInvoiceMode(): boolean {
		return (
			undefined !== this.selectedTransitionTrigger.value &&
			this.selectedTransitionTrigger.value instanceof EnrolmentPlacedInInvoicingBatchTrigger
		);
	}

	private isInPreconfirmationMode(): boolean {
		return (
			undefined !== this.selectedTransitionTrigger.value &&
			this.selectedTransitionTrigger.value instanceof EnrolmentPreconfirmedTrigger
		);
	}

	private haveSelectedEnrolmentsTheSameStatus(): boolean {
		if (0 === this.selectedEnrolments.value.length) {
			return true;
		}

		const firstEnrolmentInSelection = this.selectedEnrolments.value[0];
		const selectedEnrolmentsFulfillmentSteps = this.selectedEnrolments.value.map((_) => _.fulfillmentStep);
		const selectedEnrolmentsStatuses = this.selectedEnrolments.value.map((_) => _.status);

		return (
			!selectedEnrolmentsFulfillmentSteps.some((_) => !firstEnrolmentInSelection.fulfillmentStep.equals(_)) &&
			!selectedEnrolmentsStatuses.some((_) => !firstEnrolmentInSelection.status.equals(_))
		);
	}

	private haveSelectedEnrolmentsTheSameOrganisation(): boolean {
		if (0 === this.selectedEnrolments.value.length) {
			return true;
		}

		const firstEnrolmentInSelection = this.selectedEnrolments.value[0];
		return !this.selectedEnrolments.value.some((_) => _.organisationIdentity !== firstEnrolmentInSelection.organisationIdentity);
	}

	private startReview(): void {
		const partnership = this.determineApplicablePartnership();

		EventBus.getEvent(InternalEnrolmentReviewStartedEvent).publish(
			new EnrolmentReviewStartedEventPayload(partnership, this.selectedEnrolments.value)
		);
	}

	private determineApplicablePartnership(): Partnership {
		if (!this.haveSelectedEnrolmentsTheSamePartnership()) {
			throw new Error('Cannot preconfirm enrolments belonging to different partnerships');
		}

		const firstEnrolmentInSelection = this.selectedEnrolments.value[0];

		const partnerships = store.getters.partnerships();
		const partnership = partnerships.find((_) => _.organisationId === firstEnrolmentInSelection.organisationIdentity);

		if (undefined === partnership) {
			throw new Error('Could not find the appropriate partnership');
		}

		return partnership;
	}

	private haveSelectedEnrolmentsTheSamePartnership(): boolean {
		const organisationIdentities = this.selectedEnrolments.value.map((_) => _.organisationIdentity);
		const organisationIdentitiesSet = new Set<string>(organisationIdentities);

		return 1 === organisationIdentitiesSet.size;
	}
}
