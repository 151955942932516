import { EnrolmentNotInScopeReason } from '@studyportals/sp-lord-business-interface';
import { NoPayReason } from './no-pay-reason';

class NoPayReasonFactory {
	private static noPayReasonsByValue = new Map<EnrolmentNotInScopeReason, NoPayReason>();

	public obtainForValues(values: Readonly<EnrolmentNotInScopeReason[]>): NoPayReason[] {
		return values.map((_) => this.obtain(_));
	}

	public obtain(value: EnrolmentNotInScopeReason): NoPayReason {
		if (!NoPayReasonFactory.noPayReasonsByValue.has(value)) {
			NoPayReasonFactory.noPayReasonsByValue.set(value, this.createForValue(value));
		}

		return NoPayReasonFactory.noPayReasonsByValue.get(value) as NoPayReason;
	}

	private createForValue(value: EnrolmentNotInScopeReason): NoPayReason {
		switch (value) {
			case EnrolmentNotInScopeReason.INTAKE_DATE:
				return new NoPayReason('Prior or future intake', EnrolmentNotInScopeReason.INTAKE_DATE);
			case EnrolmentNotInScopeReason.PROGRAMME:
				return new NoPayReason('Programme not in scope', EnrolmentNotInScopeReason.PROGRAMME);
			case EnrolmentNotInScopeReason.NATIONALITY:
				return new NoPayReason('Nationality', EnrolmentNotInScopeReason.NATIONALITY);
			case EnrolmentNotInScopeReason.AGENT_INVOLVED:
				return new NoPayReason('Agent involved', EnrolmentNotInScopeReason.AGENT_INVOLVED);
			case EnrolmentNotInScopeReason.OTHER:
				return new NoPayReason('Other reason', EnrolmentNotInScopeReason.OTHER);
		}
	}
}

const noPayReasonFactory = new NoPayReasonFactory();
export default noPayReasonFactory;
