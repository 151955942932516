import { TriggerPayloadValidation } from './validation/trigger-payload-validation';

export class TriggerPayloadConfiguration {
	public constructor(
		public readonly includeDisqualificationReasons = false,
		public readonly includeNote = false,
		public readonly includeSuccessFeeInformation = false,
		public readonly includeIntakeInformation = false
	) {}

	public isEmpty(): boolean {
		return (
			!this.includeDisqualificationReasons &&
			!this.includeNote &&
			!this.includeSuccessFeeInformation &&
			!this.includeIntakeInformation
		);
	}

	public createValidation(): TriggerPayloadValidation {
		return new TriggerPayloadValidation();
	}
}
