import { computed } from 'vue';
import { RouteEnums } from '@/models/route-enums';
import { IRouter } from '@/models/i-router';
import store from '@/store';

export default class TabMenuComponent {
	public reviewingPageSelected = computed((): boolean => {
		return this.router.value.current === RouteEnums.REVIEW_OVERVIEW;
	});

	public historyPageSelected = computed((): boolean => {
		return this.router.value.current === RouteEnums.HISTORY;
	});

	public quickGuidePageSelected = computed((): boolean => {
		return this.router.value.current === RouteEnums.QUICK_GUIDE;
	});

	private router = computed((): IRouter => {
		return store.state.router;
	});

	public navigateToReviewingPage(): void {
		store.mutations.setCurrentRoute(RouteEnums.REVIEW_OVERVIEW);
	}

	public navigateToHistoryPage(): void {
		store.mutations.setCurrentRoute(RouteEnums.HISTORY);
	}

	public navigateToQuickGuidePage(): void {
		store.mutations.setCurrentRoute(RouteEnums.QUICK_GUIDE);
	}
}
