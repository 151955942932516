import { Component, defineComponent } from 'vue';
import MassEditComponent from '@/presentation/components/client/mass-edit/mass-edit-class';
import MassEditModal from '@/presentation/components/generalizations/mass-edit-modal/mass-edit-modal.vue';
import HighlightedFeature from '@/presentation/components/client/highlighted-feature/highlighted-feature.vue';

export default defineComponent({
	components: {
		MassEditModal: MassEditModal as Component,
		HighlightedFeature: HighlightedFeature as Component
	},
	setup: () => {
		const component = new MassEditComponent();

		return {
			highlightedFeatureTitle: component.highlightedFeatureTitle,
			highlightedFeatureContent: component.highlightedFeatureContent,
			highlightedFeatureNameMassEdit: component.highlightedFeatureNameMassEdit,
			areEnrolmentsSelected: component.areEnrolmentsSelected,
			isMassEditModalShown: component.isMassEditModalShown,
			openMassEditModal: component.openMassEditModal.bind(component)
		};
	}
});
