import { ValidationResult } from '../../../validation/validation-result';
import { TriggerPayload } from '../trigger-payload';
import { TriggerPayloadValidation } from './trigger-payload-validation';

export class IntakeInformationValidation extends TriggerPayloadValidation {
	public validateInExistingResult(payload: TriggerPayload, result: ValidationResult): ValidationResult {
		if (
			undefined === payload.enrichmentSpecification.intakeYear ||
			payload.enrichmentSpecification.intakeYear === null ||
			payload.enrichmentSpecification.intakeYear < 1000 ||
			payload.enrichmentSpecification.intakeYear > 9999
		) {
			result.isIntakeYearValid = false;
		}

		if (undefined === payload.enrichmentSpecification.intakeMonth || null === payload.enrichmentSpecification.intakeMonth) {
			result.isIntakeMonthValid = false;
		}

		return result;
	}
}
