import { Enrolment } from '@/models/enrolment';
import { SuccessFeeEnum } from '@/models/success-fee-enum';
import { EnrolmentNotInScopeReason } from '@studyportals/sp-lord-business-interface';
import { IComponentParams } from '@/models/i-component-params';

const tableSortByEnrolmentReview = (
	leftValue: IComponentParams,
	rightValue: IComponentParams,
	leftNode: IComponentParams,
	rightNode: IComponentParams
): number => {
	const leftWeight = determineEnrolmentReviewWeight(leftNode.data);
	const rightWeight = determineEnrolmentReviewWeight(rightNode.data);

	return leftWeight < rightWeight ? 1 : -1;
};

const determineEnrolmentReviewWeight = (enrolment: Enrolment): number => {
	if (enrolment.isChallengedAndUnconfirmed) {
		return determineEnrolmentStatusWeight(enrolment);
	}

	if (enrolment.isAwaitingReview()) {
		return 100;
	}

	if (enrolment.isReadyForReviewSubmission()) {
		return determineEnrolmentStatusWeight(enrolment) + 100;
	}

	return determineEnrolmentStatusWeight(enrolment) + 1000;
};

const tableSortByEnrolmentStatus = (
	leftValue: IComponentParams,
	rightValue: IComponentParams,
	leftNode: IComponentParams,
	rightNode: IComponentParams
): number => {
	const leftWeight = determineEnrolmentStatusWeight(leftNode.data);
	const rightWeight = determineEnrolmentStatusWeight(rightNode.data);

	return leftWeight < rightWeight ? 1 : -1;
};

const tableSortByIdentificationDate = (
	leftValue: IComponentParams,
	rightValue: IComponentParams,
	leftNode: IComponentParams,
	rightNode: IComponentParams
): number => {
	const leftDate = leftNode.data.insertedInERTDateIso;
	const rightDate = rightNode.data.insertedInERTDateIso;

	if (!leftDate) {
		return 1;
	}
	if (!rightDate) {
		return -1;
	}

	return leftDate.getUnixTime() < rightDate.getUnixTime() ? 1 : -1;
};

const tableSortByLastChangedDate = (
	leftValue: IComponentParams,
	rightValue: IComponentParams,
	leftNode: IComponentParams,
	rightNode: IComponentParams
): number => {
	const leftDate = leftNode.data.lastChangedDateIso;
	const rightDate = rightNode.data.lastChangedDateIso;

	if (!leftDate) {
		return 1;
	}
	if (!rightDate) {
		return -1;
	}

	return leftDate.getUnixTime() < rightDate.getUnixTime() ? 1 : -1;
};

const determineEnrolmentStatusWeight = (enrolment: Enrolment): number => {
	if (enrolment.isConfirmed() && enrolment.successFee) {
		switch (enrolment.successFee.value) {
			case SuccessFeeEnum.REGULAR:
				return 1;
			case SuccessFeeEnum.REDUCED_BECAUSE_AGENT_INVOLVED:
				return 2;
			case SuccessFeeEnum.REDUCED_BECAUSE_NATIONALITY:
				return 3;
			case SuccessFeeEnum.OTHER:
				return 9;
		}
	}

	if (enrolment.isConfirmed() && undefined === enrolment.successFee) {
		if (enrolment.noPayReasons.some((_) => _.type === EnrolmentNotInScopeReason.AGENT_INVOLVED)) {
			return 4;
		}

		if (enrolment.noPayReasons.some((_) => _.type === EnrolmentNotInScopeReason.NATIONALITY)) {
			return 5;
		}

		if (enrolment.noPayReasons.some((_) => _.type === EnrolmentNotInScopeReason.INTAKE_DATE)) {
			return 6;
		}

		if (enrolment.noPayReasons.some((_) => _.type === EnrolmentNotInScopeReason.PROGRAMME)) {
			return 7;
		}

		if (enrolment.noPayReasons.some((_) => _.type === EnrolmentNotInScopeReason.OTHER)) {
			return 8;
		}
	}

	if (enrolment.isPostponed()) {
		return 10;
	}

	if (enrolment.isNotRecognized()) {
		return 11;
	}

	if (enrolment.isStudentNotRecognized()) {
		return 12;
	}

	return 13;
};

export { tableSortByEnrolmentReview, tableSortByEnrolmentStatus, tableSortByIdentificationDate, tableSortByLastChangedDate };
