import { computed, ref, Ref } from 'vue';
import { ComplexModal } from '@studyportals/modal';
import { DataProcessingAgreementConfirmedEvent } from '@/event-bus/data-processing-agreement-confirmed-event';
import { StudentImpactVideoDisplayedEvent } from '@/event-bus/student-impact-video-displayed-event';
import EventBus from '@/event-bus/event-bus';
import RedshiftTracker from '@/infrastructure/redshift-tracker';
import PartnershipService from '@/services/partnership-service';
import store from '@/store';

export default class ClientIntroductionModalComponent {
	public modalContent: Ref<HTMLElement | null> = ref(null);
	private modal: Ref<ComplexModal | undefined> = ref(undefined);

	public dataProcessingAgreementConfirmed = computed((): boolean => {
		const partnerships = store.getters.partnerships();
		return !partnerships.some((_) => !_.wasDataProcessingAgreementSigned);
	});

	public studentImpactVideoDisplayed = computed((): boolean => {
		const partnerships = store.getters.partnerships();
		return !partnerships.some((_) => !_.wasStudentImpactVideoDisplayed);
	});

	public shouldModalBeShown = computed((): boolean => {
		return !this.dataProcessingAgreementConfirmed.value || !this.studentImpactVideoDisplayed.value;
	});

	private redshiftTracker = computed((): RedshiftTracker => {
		return store.state.redshiftTracker;
	});

	public mounted(): void {
		this.prepareModal();

		if (!this.shouldModalBeShown.value) {
			return;
		}

		window.ModalManager.open(this.modal.value);

		/* If the client introduction modal will be opened, mark the tool as "loaded" for the initial-loading-speed metric.
		Otherwise the metric will be influenced by how fast clients close the modal. */
		this.redshiftTracker.value.trackInitialLoadingSpeed(false);
	}

	public unmounted(): void {
		window.ModalManager.close(this.modal.value);
	}

	public onDataProcessingAgreementConfirmedChanged(): void {
		if (this.shouldModalBeShown.value) {
			window.ModalManager.open(this.modal.value);
		} else {
			window.ModalManager.close(this.modal.value);
		}
	}

	public acceptAgreementAndConsiderVideoAsDisplayed(): void {
		this.acceptDataProcessingAgreement();
		this.considerStudentImpactVideoAsDisplayed();
	}

	public acceptDataProcessingAgreement(): void {
		PartnershipService.signAgreement();

		EventBus.getEvent(DataProcessingAgreementConfirmedEvent).publish();
	}

	public considerStudentImpactVideoAsDisplayed(): void {
		PartnershipService.considerStudentImpactVideoAsDisplayed();

		EventBus.getEvent(StudentImpactVideoDisplayedEvent).publish();
	}

	private prepareModal(): void {
		if (!this.modalContent.value) {
			return;
		}

		this.modal.value = new ComplexModal(this.modalContent.value, {
			cssClassName: 'ClientIntroductionModalWrapper',
			destroyOnClose: true,
			onClose: this.considerStudentImpactVideoAsDisplayed.bind(this)
		});
	}
}
