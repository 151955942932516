import { Enrolment } from '@/models/enrolment';
import FulfillmentStepIndication from '@/models/fulfillment-step-indication';
import { CsvExport } from './csv-export';

export class BusinessUnitDirectorReviewCsvExport extends CsvExport {
	public constructor() {
		super('All the enrolments that are confirmed and invoicable');
	}

	protected findExportableEnrolments(enrolments: Enrolment[]): Enrolment[] {
		return enrolments.filter(
			(_) =>
				_.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.CONFIRMATION_SUBMITTED) &&
				_.isConfirmed() &&
				_.successFee !== undefined
		);
	}

	protected createHeaderCells(): string[] {
		return [
			'Student email address',
			'Student name',
			'Nationality',
			'Success fee category',
			'Notes',
			'Tuition fee value',
			'Tuition fee currency'
		];
	}

	protected createEnrolmentLineCells(enrolment: Enrolment): string[] {
		return [
			enrolment.studentEmail ? enrolment.studentEmail : '',
			enrolment.studentName,
			enrolment.studentNationality ? enrolment.studentNationality.name : '',
			enrolment.successFee ? enrolment.successFee.name : '',
			enrolment.confirmationNotes,
			enrolment.tuitionFee ? enrolment.tuitionFee.toString() : '',
			enrolment.tuitionFeeCurrency ? enrolment.tuitionFeeCurrency.isoCode : ''
		];
	}

	protected determineEmptyExportFileName(): string {
		return 'Empty invoicable enrolments export';
	}

	protected determineExportFileNameFromEnrolment(enrolment: Enrolment): string {
		return `Invoicable enrolments for ${enrolment.organisationName}`;
	}
}
