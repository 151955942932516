import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "EnrolmentReview"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EnrolmentReviewModal = _resolveComponent("EnrolmentReviewModal")!

  return (_ctx.enrolmentReview && _ctx.enrolmentReview.enrolments.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.transitionTriggersStatusIsInactive)
          ? (_openBlock(), _createBlock(_component_EnrolmentReviewModal, {
              key: 0,
              enrolmentReview: _ctx.enrolmentReview
            }, null, 8 /* PROPS */, ["enrolmentReview"]))
          : _createCommentVNode("v-if", true)
      ]))
    : _createCommentVNode("v-if", true)
}