import { Component, defineComponent, watch } from 'vue';
import EnrolmentsTable from '@/presentation/components/generalizations/enrolments-table/enrolments-table.vue';
import DefaultEmptyMessage from '@/presentation/components/client/default-empty-message/default-empty-message.vue';
import EmptyAllToReviewMessage from '@/presentation/components/client/empty-all-to-review-message/empty-all-to-review-message.vue';
import EmptyAllReviewedMessage from '@/presentation/components/client/empty-all-reviewed-message/empty-all-reviewed-message.vue';
import EmptyStartReviewMessage from '@/presentation/components/client/empty-start-review-message/empty-start-review-message.vue';
import EmptySubmittedReviewMessage from '@/presentation/components/client/empty-submitted-message/empty-submitted-message.vue';
import EnrolmentReviewForm from '@/presentation/components/generalizations/enrolment-review-form/enrolment-review-form.vue';
import Loading from '@/presentation/components/generalizations/loading/loading.vue';
import EnrolmentsReviewSectionComponent from '@/presentation/components/client/enrolments-review-section/enrolments-review-section-class';

export default defineComponent({
	components: {
		EnrolmentReviewForm: EnrolmentReviewForm as Component,
		EnrolmentsTable: EnrolmentsTable as Component,
		EmptyAllToReviewMessage: EmptyAllToReviewMessage as Component,
		EmptyAllReviewedMessage: EmptyAllReviewedMessage as Component,
		EmptyStartReviewMessage: EmptyStartReviewMessage as Component,
		EmptySubmittedReviewMessage: EmptySubmittedReviewMessage as Component,
		DefaultEmptyMessage: DefaultEmptyMessage as Component,
		Loading: Loading as Component
	},
	setup: () => {
		const component = new EnrolmentsReviewSectionComponent();

		watch(component.filteredEnrolments, component.updateFilterDisplayState.bind(component));

		return {
			filteredEnrolments: component.filteredEnrolments,
			loading: component.loading,
			displayAllToReviewMessage: component.displayAllToReviewMessage,
			displayAllReviewedMessage: component.displayAllReviewedMessage,
			displayStartReviewMessage: component.displayStartReviewMessage,
			displaySubmittedReviewMessage: component.displaySubmittedReviewMessage
		};
	}
});
