import { EnrolmentsReplacedEvent } from '@/event-bus/enrolments-replaced-event';
import { EnrolmentsReplacedEventPayload } from '@/event-bus/enrolments-replaced-event-payload';
import EventBus from '@/event-bus/event-bus';
import { InvoiceBatchCreatedEvent } from '@/event-bus/invoice-batch-created-event';
import { InvoiceBatchCreatedEventPayload } from '@/event-bus/invoice-batch-created-event-payload';
import { InvoiceBatchCreationFailedEvent } from '@/event-bus/invoice-batch-creation-failed-event';
import { Enrolment } from '@/models/enrolment';

export class EnrolmentPlacedInInvoicingBatchTriggerMonitor {
	private enrolmentReplacedEventSubscriptionToken = '';

	public start(): void {
		this.enrolmentReplacedEventSubscriptionToken = EventBus.getEvent(EnrolmentsReplacedEvent).subscribe((_) =>
			this.onEnrolmentsReplaced(_)
		);
	}

	public commandSucceeded(): void {
		// nothing
	}

	public commandFailed(): void {
		// nothing
	}

	private onEnrolmentsReplaced(payload: EnrolmentsReplacedEventPayload): void {
		try {
			this.processInvoiceBatchCreation(payload.selectedEnrolments);
		} finally {
			this.destroy();
		}
	}

	private processInvoiceBatchCreation(enrolments: Readonly<Enrolment[]>): void {
		const enrolmentsNotPlacedInInvoicingBatch: Enrolment[] = [];
		const enrolmentsPlacedInInvoicingBatch: Enrolment[] = [];

		for (const enrolment of enrolments) {
			if (undefined === enrolment.invoicingBatchIdentity) {
				enrolmentsNotPlacedInInvoicingBatch.push(enrolment);
			} else {
				enrolmentsPlacedInInvoicingBatch.push(enrolment);
			}
		}

		this.processInvoiceBatchCreationOutcome(enrolmentsNotPlacedInInvoicingBatch, enrolmentsPlacedInInvoicingBatch);
	}

	private processInvoiceBatchCreationOutcome(enrolmentsNotPlacedInBatch: Enrolment[], enrolmentsPlacedInBatch: Enrolment[]): void {
		const isSuccess = enrolmentsNotPlacedInBatch.length === 0 && enrolmentsPlacedInBatch.length > 0;
		const isCompleteFailure = enrolmentsPlacedInBatch.length === 0;
		const isPartialFailure = enrolmentsNotPlacedInBatch.length > 0 && enrolmentsPlacedInBatch.length > 0;

		if (isSuccess || isPartialFailure) {
			const invoiceBatchIdentity = enrolmentsPlacedInBatch[0].invoicingBatchIdentity as string;
			EventBus.getEvent(InvoiceBatchCreatedEvent).publish(
				new InvoiceBatchCreatedEventPayload(invoiceBatchIdentity, enrolmentsNotPlacedInBatch)
			);
		}

		if (isCompleteFailure) {
			EventBus.getEvent(InvoiceBatchCreationFailedEvent).publish();
		}
	}

	private destroy(): void {
		EventBus.getEvent(EnrolmentsReplacedEvent).unsubscribe(this.enrolmentReplacedEventSubscriptionToken);
	}
}
