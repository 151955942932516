import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = {
  key: 0,
  class: "ViewContent"
}
const _hoisted_3 = { class: "EnrolmentsHeaderWrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClientIntroductionModal = _resolveComponent("ClientIntroductionModal")!
  const _component_ReviewSubmittedNotification = _resolveComponent("ReviewSubmittedNotification")!
  const _component_TabMenu = _resolveComponent("TabMenu")!
  const _component_LogOut = _resolveComponent("LogOut")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "ClientEnrolmentsOverview",
      class: _normalizeClass(["EnrolmentsOverview", { AccommodateNotification: _ctx.shouldReviewSubmittedNotificationBeShown }])
    }, [
      _createVNode(_component_ClientIntroductionModal),
      (_ctx.canEnrolmentTableBeShown)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.shouldReviewSubmittedNotificationBeShown)
                ? (_openBlock(), _createBlock(_component_ReviewSubmittedNotification, { key: 0 }))
                : _createCommentVNode("v-if", true),
              _createVNode(_component_TabMenu)
            ]),
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.CurrentComponent), _mergeProps(_ctx.router.props, { class: "AppContainer" }), null, 16 /* FULL_PROPS */)),
            (_ctx.standAloneLogin)
              ? (_openBlock(), _createBlock(_component_LogOut, { key: 0 }))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */)
  ]))
}