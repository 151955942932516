import { DisqualificationReasonSelection } from '@/models/disqualification-reason-selection';
import { EnrichmentSpecification } from '@/models/enrichment-specification';
import { SuccessFeeInformation } from './success-fee-information';

export class TriggerPayload {
	public constructor(
		public disqualificationReasonSelection = new DisqualificationReasonSelection(),
		public note?: string,
		public successFeeInformation = new SuccessFeeInformation(),
		public enrichmentSpecification = new EnrichmentSpecification(),
		public organisationIdentity = ''
	) {}

	public hasNote(): boolean {
		return this.note ? this.note.length > 0 : false;
	}
}
