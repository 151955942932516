import { Enrolment } from '@/models/enrolment';
import { EnrolmentStatus } from '@/models/enrolment-status';
import EnrolmentService from '@/services/enrolments-service';

import { TransitionTrigger } from './transition-trigger';

export class StudentNotRecognizedTrigger extends TransitionTrigger {
	public constructor(excludedEnrolmentStatuses: EnrolmentStatus[]) {
		super('Student unknown', excludedEnrolmentStatuses);
	}

	public equals(that: TransitionTrigger): boolean {
		return that instanceof StudentNotRecognizedTrigger;
	}

	protected async performTransitions(enrolments: Readonly<Enrolment[]>): Promise<void> {
		await EnrolmentService.triggerStudentNotRecognized(enrolments);
	}
}
