import { SubscriptionTokenEnums } from '@/models/enrolment-subscriptions-enums';
import { ReviewSubmittedEvent } from '@/event-bus/review-submitted-event';

type EventConstructor<TEvent> = new () => TEvent;

interface IEnrolmentSubscriptionsEvents {
	[SubscriptionTokenEnums.REVIEW_SUBMITTED]: EventConstructor<ReviewSubmittedEvent>;
}

type IEnrolmentSubscriptionEvent = EventConstructor<ReviewSubmittedEvent>;

class EnrolmentSubscriptionsEventsClass implements IEnrolmentSubscriptionsEvents {
	public [SubscriptionTokenEnums.REVIEW_SUBMITTED] = ReviewSubmittedEvent;
}

export { IEnrolmentSubscriptionEvent };

export default new EnrolmentSubscriptionsEventsClass();
