import { computed } from 'vue';
import { Enrolment } from '@/models/enrolment';
import { ClientReviewCsvExport } from '@/presentation/components/generalizations/enrolment-export/client-review-csv-export';
import { BusinessUnitDirectorReviewCsvExport }
	from '@/presentation/components/generalizations/enrolment-export/business-unit-director-review-csv-export';
import { InternalAllColumnsCsvExport } from '@/presentation/components/generalizations/enrolment-export/internal-all-columns-csv-export';
import { CsvExport } from '@/presentation/components/generalizations/enrolment-export/csv-export';
import store from '@/store';

export default class InternalEnrolmentsExportComponent {
	public csvExportOptions: CsvExport[] = [
		new ClientReviewCsvExport(),
		new BusinessUnitDirectorReviewCsvExport(),
		new InternalAllColumnsCsvExport()
	];

	public filteredEnrolments = computed((): Enrolment[] => {
		return store.getters.filteredEnrolments();
	});
}
