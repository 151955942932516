import { computed } from 'vue';
import { ITableFilter } from '@/models/i-table-filter';
import { ITableSelector } from '@/models/i-table-selector';
import tableSelectorRetriever from '@/presentation/components/generalizations/table-selectors/table-selector-retriever';

export default class OptionalFilterTagsComponent {
	private get optionalFilters(): ITableFilter[] {
		return tableSelectorRetriever.optionalClientFilters;
	}

	public relevantOptionalFilters = computed((): ITableSelector[] => {
		return this.optionalFilters.filter((filter) => {
			return !filter.defaultOption || filter.defaultOption.value !== filter.selectedOption.value;
		});
	});

	public retrieveTitleOfFilter(filter: ITableSelector): string {
		if (filter.selectedOptionAsOption.value) {
			return filter.selectedOptionAsOption.value.title;
		}

		if (typeof filter.selectedOption.value === 'string') {
			return filter.selectedOption.value;
		}

		return filter.selectedOption.value.toString();
	}

	public removeFilter(filter: ITableSelector): void {
		filter.resetSelectedOption();
	}
}
