import { Ref, ref, computed } from 'vue';
import { IMultiSelect } from '@studyportals/multiselect/src/interfaces/multiselect.interface';
import { IMultiselectSelectorCluster } from '@/models/i-multiselect-selector-cluster';
import NullMultiselect from '@/presentation/components/generalizations/table-selectors/null-multiselect';
import SelectorClusterHandler from '@/presentation/components/generalizations/table-selectors/selector-cluster-handler';
import tableSelectorRetriever from '@/presentation/components/generalizations/table-selectors/table-selector-retriever';
import store from '@/store';

export default class EnrolmentsTableFilterComponent {
	public selectorClusterHandler = new SelectorClusterHandler();
	private nullMultiselect = new NullMultiselect();
	public reviewStepsDropdown: Ref<IMultiSelect | null> = ref(null);
	private reviewStepsFilter = tableSelectorRetriever.retrieveReviewStepsFilter();

	public allClusters = computed((): IMultiselectSelectorCluster[] => {
		return [this.reviewStepsCluster.value];
	});

	public reviewStepsCluster = computed((): IMultiselectSelectorCluster => {
		const multiselect = this.reviewStepsDropdown.value ? this.reviewStepsDropdown.value : this.nullMultiselect;
		return {
			selector: this.reviewStepsFilter,
			multiselect,
			isDynamicOptionsSelector: true,
			isFilter: true
		};
	});

	public mounted(): void {
		this.filterEnrolments();
		this.enableWatchers();
		this.prefillAllMultiselects();
	}

	private filterEnrolments(): void {
		store.actions.updateFilteredEnrolments();
	}

	private enableWatchers(): void {
		this.selectorClusterHandler.enableWatchers(this.allClusters.value);
	}

	private prefillAllMultiselects(): void {
		this.allClusters.value.forEach((cluster) => void this.selectorClusterHandler.prefillMultiselect(cluster));
	}
}
