import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ClientEnrolmentsExport" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EnrolmentExport = _resolveComponent("EnrolmentExport")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_component_EnrolmentExport, {
      enrolments: _ctx.filteredEnrolments,
      exportOptions: _ctx.csvExportOptions,
      canExport: _ctx.canExport,
      key: _ctx.filteredEnrolmentsAsString
    }, null, 8 /* PROPS */, ["enrolments", "exportOptions", "canExport"]))
  ]))
}