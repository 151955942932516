import { defineComponent } from 'vue';
import SingleSignOnSessionManager from '@/stand-alone/single-sign-on-session-manager';
import store from '@/store';

class LogOutComponent {
	private singleSignOnSessionManager = new SingleSignOnSessionManager();

	public hideWidget(): void {
		store.mutations.setStandAloneLogin(false);
	}

	public signOut(): void {
		this.singleSignOnSessionManager.logout();
	}
}

export default defineComponent({
	setup: () => {
		const component = new LogOutComponent();

		return {
			hideWidget: component.hideWidget.bind(component),
			signOut: component.signOut.bind(component)
		};
	}
});
