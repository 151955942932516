import { Component, defineComponent, onMounted } from 'vue';
import { MultiSelect } from '@studyportals/multiselect';
import EditEnrolmentComponent from '@/presentation/components/internal/edit-enrolment/edit-enrolment-class';
import Drawer from '@/presentation/components/generalizations/drawer/drawer.vue';
import SuspectedDuplicateDetailsModal
	from '@/presentation/components/internal/suspected-duplicate-details-modal/suspected-duplicate-details-modal.vue';

export default defineComponent({
	components: {
		MultiSelect,
		Drawer: Drawer as Component,
		SuspectedDuplicateDetailsModal: SuspectedDuplicateDetailsModal as Component
	},
	setup: () => {
		const component = new EditEnrolmentComponent();

		onMounted(component.mounted.bind(component));

		return {
			nationalityDropdown: component.nationalityDropdown,
			residenceDropdown: component.residenceDropdown,
			levelDropdown: component.levelDropdown,
			currencyDropdown: component.currencyDropdown,
			monthDropdown: component.monthDropdown,
			monthsAsOptions: component.monthsAsOptions,
			countriesAsOptions: component.countriesAsOptions,
			currenciesAsOptions: component.currenciesAsOptions,
			programmeLevelsAsOptions: component.programmeLevelsAsOptions,
			stepIsDuplicateSuspected: component.stepIsDuplicateSuspected,
			validationResult: component.validationResult,
			suspectedDuplicateDetailsModalShown: component.suspectedDuplicateDetailsModalShown,
			enrolment: component.enrolment,
			enrichmentSpecification: component.enrichmentSpecification,
			isTuitionFeeValid: component.isTuitionFeeValid,
			isTuitionFeeCurrencyValid: component.isTuitionFeeCurrencyValid,
			formatDate: component.formatDate.bind(component),
			isLinkedInLinkAvailable: component.isLinkedInLinkAvailable.bind(component),
			isFacebookLinkAvailable: component.isFacebookLinkAvailable.bind(component),
			save: component.save.bind(component),
			cancel: component.cancel.bind(component),
			showSuspectedDuplicates: component.showSuspectedDuplicates.bind(component),
			setIntakeMonth: component.setIntakeMonth.bind(component),
			setProgrammeLevel: component.setProgrammeLevel.bind(component),
			setStudentNationality: component.setStudentNationality.bind(component),
			setStudentCountryOfResidence: component.setStudentCountryOfResidence.bind(component),
			setTuitionFeeCurrency: component.setTuitionFeeCurrency.bind(component)
		};
	}
});
