export enum AdvancedAttributionOrganisations {
	SRH_BERLIN = 308,
	TBS = 634,
	IU = 421,
	CENTENNIAL_COLLEGE = 17213,
	AUCKLAND_UNIVERSITY = 9951,
	DENMARK_TECHNICAL_UNIVERSITY = 102,
	ENGINEERING_INSTITUTE_OF_TECHNOLOGY = 12928,
	ENGLAND_WEST_UNIVERSITY = 150,
	GUS_1 = 21260,
	GUS_2 = 15864,
	GUS_3 = 19945,
	GUS_4 = 18308,
	GUS_5 = 19803,
	GUS_6 = 15849,
	GUS_7 = 20377,
	GUS_8 = 885,
	GUS_9 = 23085,
	GUS_10 = 12362,
	GUS_11 = 17279,
	GUS_12 = 1888,
	GUS_13 = 14017
}
