import { Enrolment } from '@/models/enrolment';
import { CsvExport } from './csv-export';
import store from '@/store';

export class InternalAllColumnsCsvExport extends CsvExport {
	public constructor() {
		super('All the columns for the selected enrolments ');
	}

	protected findExportableEnrolments(): Enrolment[] {
		// Export only selected enrolments.
		return store.getters.selectedEnrolments();
	}

	protected determineEmptyExportFileName(): string {
		return 'All data of selection';
	}

	protected determineExportFileNameFromEnrolment(enrolment: Enrolment): string {
		return `All data of selection for ${enrolment.organisationName}`;
	}

	protected createHeaderCells(): string[] {
		return [
			'Student email address',
			'Student name',
			'Client identification number',
			'Student nationality',
			'Country of residence',
			'University name',
			'Degree level',
			'Programme name',
			'Intake year',
			'Intake month',
			'Source',
			'Registration date',
			'First active date',
			'Last active date',
			'Last email active date',
			'Enrolment identification date',
			'Last changed date',
			'Process step',
			'Review status',
			'Reason(s)',
			'Pre-disqualification reason',
			"Client's remarks",
			'Notes',
			'Invoice batch ID',
			'Success fee category',
			'Tuition fee value',
			'Tuition fee currency'
		];
	}

	protected createEnrolmentLineCells(enrolment: Enrolment): string[] {
		return [
			enrolment.studentEmail ? enrolment.studentEmail : '',
			enrolment.studentName,
			enrolment.studentOrganisationRegistrationNumber ? enrolment.studentOrganisationRegistrationNumber : '',
			enrolment.studentNationality ? enrolment.studentNationality.name : '',
			enrolment.studentCountryOfResidence && enrolment.studentCountryOfResidence.name ? enrolment.studentCountryOfResidence.name : '',
			enrolment.organisationName,
			enrolment.programmeLevel ? enrolment.programmeLevel.name : '',
			enrolment.programmeName,
			enrolment.intakeYear ? enrolment.intakeYear.toString() : '',
			enrolment.intakeMonth ? enrolment.intakeMonth.name : '',
			enrolment.clientSourceName,
			enrolment.studentRegistrationDateString,
			enrolment.studentFirstActiveDateString,
			enrolment.studentLastActiveDateString,
			enrolment.lastEmailActivityDateString,
			enrolment.insertedInERTDateString,
			enrolment.lastChangedDateString,
			enrolment.fulfillmentStep.name,
			this.determineReviewStatus(enrolment),
			enrolment.reason,
			enrolment.preDisqualificationReason,
			enrolment.remarks,
			enrolment.confirmationNotes,
			enrolment.invoicingBatchIdentity ? enrolment.invoicingBatchIdentity : '',
			enrolment.successFeeText,
			enrolment.tuitionFee ? enrolment.tuitionFee.toString() : '',
			enrolment.tuitionFeeCurrency ? enrolment.tuitionFeeCurrency.isoCode : ''
		];
	}

	private determineReviewStatus(enrolment: Enrolment): string {
		if (enrolment.isAwaitingReview() || enrolment.isReadyToShareWithClient()) {
			return '';
		}

		return enrolment.status.clientFacingName;
	}
}
