import { Component, defineComponent, onMounted, onUnmounted } from 'vue';
import EnrolmentReviewFormComponent from '@/presentation/components/generalizations/enrolment-review-form/enrolment-review-form-class';
import EnrolmentReviewModal from '@/presentation/components/generalizations/enrolment-review-modal/enrolment-review-modal.vue';

export default defineComponent({
	components: {
		EnrolmentReviewModal: EnrolmentReviewModal as Component
	},
	setup: () => {
		const component = new EnrolmentReviewFormComponent();

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		return {
			enrolmentReview: component.enrolmentReview,
			transitionTriggersStatusIsInactive: component.transitionTriggersStatusIsInactive
		};
	}
});
