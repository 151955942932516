import EventBus from '@/event-bus/event-bus';
import { PartnershipsRetreivedEvent } from '@/event-bus/partnerships-retrieved-event';
import { LordBusiness } from '@/infrastructure';
import NoPayReasonFactory from '@/models/no-pay-reason-factory';
import { Partnership } from '@/models/partnership';
import { SuccessFee } from '@/models/success-fee';
import { DataStorage } from '@studyportals/data-storage';
import { PresentationPartnership } from '@studyportals/sp-lord-business-interface';
import { AbstractAndReportExceptionAsync } from '@studyportals/mb-platform-exceptions-aop';
import { DataStorageLabelsEnum } from '@/models/data-storage-labels-enum';

import OrganisationPresenter from './organisation-presenter';

class PartnershipPresenter {
	@AbstractAndReportExceptionAsync()
	public async retrieveKnownPartnerships(): Promise<void> {
		const presentationPartnerships = await LordBusiness.retrievePartnerships();

		EventBus.getEvent(PartnershipsRetreivedEvent).publish(
			presentationPartnerships.map((_) => this.reconstitutePartnershipForInternalUse(_))
		);
	}

	@AbstractAndReportExceptionAsync()
	public async retrieveForCurrentUser(): Promise<void> {
		const presentationPartnerships = await LordBusiness.retrievePartnerships();
		const partnerships: Partnership[] = [];

		for (const presentationPartnership of presentationPartnerships) {
			partnerships.push(await this.reconstitutePartnershipForClientFacingUse(presentationPartnership));
		}

		EventBus.getEvent(PartnershipsRetreivedEvent).publish(partnerships);
	}

	private reconstitutePartnershipForInternalUse(input: PresentationPartnership): Partnership {
		const successFees = input.successFees.map((_) => SuccessFee.forValue(_.successFeeType, _.tuitionFee));
		const noPayReasons = NoPayReasonFactory.obtainForValues(input.enrolmentNotInScopeReasons);

		return Partnership.createForInternalUse(input.clientIdentity, successFees, noPayReasons);
	}

	private async reconstitutePartnershipForClientFacingUse(input: PresentationPartnership): Promise<Partnership> {
		const organisationSearchResult = await OrganisationPresenter.searchForIdentity(input.clientIdentity);
		const organisationName = organisationSearchResult.hasOrganisation() ? organisationSearchResult.organisation.name : '';

		const successFees = input.successFees.map((_) => SuccessFee.forValue(_.successFeeType, _.tuitionFee));
		const noPayReasons = NoPayReasonFactory.obtainForValues(input.enrolmentNotInScopeReasons);

		if (successFees.length === 0) {
			successFees.push(SuccessFee.regular(true));
		}

		return Partnership.createForClientFacingUse(
			input.clientIdentity,
			organisationName,
			successFees,
			noPayReasons,
			this.isDataProcessingAgreementSigned(),
			this.isStudentImpactVideoDisplayed()
		);
	}

	private isDataProcessingAgreementSigned(): boolean {
		return DataStorage.retrieve(DataStorageLabelsEnum.DATA_PROCESSING_AGREEMENT_SIGNED_LABEL) === 'true';
	}

	private isStudentImpactVideoDisplayed(): boolean {
		return DataStorage.retrieve(DataStorageLabelsEnum.STUDENT_IMPACT_VIDEO_DISPLAYED_LABEL) === 'true';
	}
}

export default new PartnershipPresenter();
