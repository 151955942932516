import { ref } from 'vue';
export default class MultiSelectComponent {
    searchBox = ref(null);
    mounted() {
        if (!this.searchBox.value) {
            return;
        }
        this.searchBox.value.focus();
    }
}
