import { Enrolment } from '@/models/enrolment';
import { EnrolmentStatus } from '@/models/enrolment-status';
import EnrolmentService from '@/services/enrolments-service';
import { ArrayOperations } from '@studyportals/mb-platform-arrays';

import { TransitionTrigger } from './transition-trigger';

export class EnrolmentRevisedTrigger extends TransitionTrigger {
	public orderingNumber = 104;
	public readonly permittedEnrolmentStatuses: EnrolmentStatus[];

	public constructor(excludedEnrolmentStatuses: EnrolmentStatus[]) {
		super('Identified', excludedEnrolmentStatuses);

		this.permittedEnrolmentStatuses = [];
	}

	public get isRevise(): boolean {
		return true;
	}

	public isAvailableForEnrolment(enrolment: Enrolment): boolean {
		if (0 === this.permittedEnrolmentStatuses.length) {
			return super.isAvailableForEnrolment(enrolment);
		}

		return this.permittedEnrolmentStatuses.some((_) => enrolment.status.equals(_));
	}

	public equals(that: TransitionTrigger): boolean {
		return (
			that instanceof EnrolmentRevisedTrigger &&
			ArrayOperations.equivalent(this.excludedEnrolmentStatuses, that.excludedEnrolmentStatuses, (_) => _.value) &&
			ArrayOperations.equivalent(this.permittedEnrolmentStatuses, that.permittedEnrolmentStatuses, (_) => _.value)
		);
	}

	protected async performTransitions(enrolments: Readonly<Enrolment[]>): Promise<void> {
		await EnrolmentService.triggerEnrolmentRevised(enrolments);
	}
}
