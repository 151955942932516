import { Component, defineComponent, onMounted, onUnmounted } from 'vue';
import TransitionTriggersModalComponent
	from '@/presentation/components/internal/transition-triggers-modal/transition-triggers-modal-class';
import { TransitionTrigger } from '@/models/transitions/triggers/transition-trigger';
import { TriggerPayload } from '@/models/transitions/trigger-payload/trigger-payload';
import InvoiceSummary from '@/presentation/components/internal/invoice-summary/invoice-summary.vue';

export default defineComponent({
	components: {
		InvoiceSummary: InvoiceSummary as Component
	},
	props: {
		selectedTransitionTrigger: Object,
		triggerPayload: Object
	},
	setup: (props, context) => {
		const component = new TransitionTriggersModalComponent(
			context.emit,
			props.selectedTransitionTrigger as TransitionTrigger | undefined,
			props.triggerPayload as TriggerPayload | undefined
		);

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		return {
			modalContent: component.modalContent,
			selectedEnrolments: component.selectedEnrolments,
			modalStatusIsConfirmation: component.modalStatusIsConfirmation,
			modalStatusIsInvoiceBatch: component.modalStatusIsInvoiceBatch,
			isTriggerRevised: component.isTriggerRevised,
			isTriggerInScopeWithIncompleteEnrolments: component.isTriggerInScopeWithIncompleteEnrolments,
			isTriggerConfirmationChallenged: component.isTriggerConfirmationChallenged,
			modalStatusIsTriggerPayload: component.modalStatusIsTriggerPayload,
			noDisqualificationReasonSelectedError: component.noDisqualificationReasonSelectedError,
			noTriggerPayloadExplanationError: component.noTriggerPayloadExplanationError,
			disqualificationReasons: component.disqualificationReasons,
			triggerPayload: component.triggerPayload,
			onTransitionConfirmed: component.onTransitionConfirmed.bind(component),
			onTransitionCanceled: component.onTransitionCanceled.bind(component)
		};
	}
});
