import { EnrolmentReviewState } from './enrolment-review-state';

export class NullEnrolmentReviewState extends EnrolmentReviewState {
	public display(): void {
		// Abstract parent function should be implemented, but no functionality required.
	}

	public requiresAdditionalInput(): boolean {
		return false;
	}

	public next(): void {
		// Abstract parent function should be implemented, but no functionality required.
	}
}
