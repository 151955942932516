import { defineComponent, onMounted, onUnmounted } from 'vue';
import { ModalStatusInvoiceBatchCreatedNotificationEnums } from '@/models/modal-status-invoice-batch-created-notification-enum';
import { InvoiceBatchCreatedEventPayload } from '@/event-bus/invoice-batch-created-event-payload';
import InvoiceBatchCreatedNotificationModalComponent
	from '@/presentation/components/internal/invoice-batch-created-notification-modal/invoice-batch-created-notification-modal-class';

export default defineComponent({
	props: {
		modalStatus: String,
		payload: Object
	},
	setup: (props, context) => {
		const component = new InvoiceBatchCreatedNotificationModalComponent(
			context.emit,
			props.modalStatus as ModalStatusInvoiceBatchCreatedNotificationEnums,
			props.payload as InvoiceBatchCreatedEventPayload
		);

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		return {
			modalContent: component.modalContent,
			modalStatusIsActive: component.modalStatusIsActive,
			invoiceBatchIdentity: component.invoiceBatchIdentity,
			invoiceBatchPartial: component.invoiceBatchPartial,
			enrolmentsNotPlacedInInvoiceBatch: component.enrolmentsNotPlacedInInvoiceBatch,
			invoiceExportAvailable: component.invoiceExportAvailable,
			csvExport: component.csvExport,
			modalStatusIsFailed: component.modalStatusIsFailed,
			hideModal: component.hideModal.bind(component)
		};
	}
});
