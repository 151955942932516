import { EnrolmentStatus as EnrolmentStatusEnum } from '@studyportals/sp-lord-business-interface';

export class EnrolmentStatus {
	private static enrolmentStatusesByValue = new Map<EnrolmentStatusEnum, EnrolmentStatus>();

	public clientFacingName: string;

	private constructor(
		public readonly value: EnrolmentStatusEnum,
		public readonly name: string,
		clientFacingName?: string
	) {
		this.clientFacingName = undefined !== clientFacingName ? clientFacingName : name;
	}
	public static obtainForValues(values: Readonly<EnrolmentStatusEnum[]>): EnrolmentStatus[] {
		return values.map((_) => this.obtain(_));
	}

	public static obtain(value: EnrolmentStatusEnum): EnrolmentStatus {
		if (!this.enrolmentStatusesByValue.has(value)) {
			this.enrolmentStatusesByValue.set(value, this.create(value));
		}

		return this.enrolmentStatusesByValue.get(value) as EnrolmentStatus;
	}

	private static create(value: EnrolmentStatusEnum): EnrolmentStatus {
		switch (value) {
			case EnrolmentStatusEnum.QUALIFIED:
				return new EnrolmentStatus(EnrolmentStatusEnum.QUALIFIED, 'Qualified');

			case EnrolmentStatusEnum.UNCLEAR:
				return new EnrolmentStatus(EnrolmentStatusEnum.UNCLEAR, 'Unclear');

			case EnrolmentStatusEnum.DISQUALIFIED:
				return new EnrolmentStatus(EnrolmentStatusEnum.DISQUALIFIED, 'Disqualified');

			case EnrolmentStatusEnum.PREQUALIFIED:
				return new EnrolmentStatus(EnrolmentStatusEnum.PREQUALIFIED, 'Pre-qualified');

			case EnrolmentStatusEnum.PREUNCLEAR:
				return new EnrolmentStatus(EnrolmentStatusEnum.PREUNCLEAR, 'Pre-unclear');

			case EnrolmentStatusEnum.PREDISQUALIFIED:
				return new EnrolmentStatus(EnrolmentStatusEnum.PREDISQUALIFIED, 'Pre-disqualified');

			case EnrolmentStatusEnum.POSTPONED:
				return new EnrolmentStatus(EnrolmentStatusEnum.POSTPONED, 'Future round', 'Future intake');

			case EnrolmentStatusEnum.NOT_TRUSTED:
				return new EnrolmentStatus(EnrolmentStatusEnum.NOT_TRUSTED, 'Not trusted');

			case EnrolmentStatusEnum.NOT_FOR_INVOICING:
				return new EnrolmentStatus(EnrolmentStatusEnum.NOT_FOR_INVOICING, 'Not for invoicing');

			case EnrolmentStatusEnum.DUPLICATED:
				return new EnrolmentStatus(EnrolmentStatusEnum.DUPLICATED, 'Duplicated');

			case EnrolmentStatusEnum.IDENTIFIED:
				return new EnrolmentStatus(EnrolmentStatusEnum.IDENTIFIED, 'Identified');

			case EnrolmentStatusEnum.CONFIRMED:
				return new EnrolmentStatus(EnrolmentStatusEnum.CONFIRMED, 'Enrolled');

			case EnrolmentStatusEnum.ENROLMENT_NOT_RECOGNIZED:
				return new EnrolmentStatus(EnrolmentStatusEnum.ENROLMENT_NOT_RECOGNIZED, 'Not enrolled');

			case EnrolmentStatusEnum.STUDENT_NOT_RECOGNIZED:
				return new EnrolmentStatus(EnrolmentStatusEnum.STUDENT_NOT_RECOGNIZED, 'Student unknown');

			case EnrolmentStatusEnum.CONFIRMATION_UNSURE:
				return new EnrolmentStatus(EnrolmentStatusEnum.CONFIRMATION_UNSURE, 'Unsure', 'Other');

			case EnrolmentStatusEnum.UNKNOWN:
				return new EnrolmentStatus(EnrolmentStatusEnum.UNKNOWN, 'Not processed');
		}

		throw new Error('Unknown status');
	}

	public equals(that: EnrolmentStatus): boolean {
		return this.value === that.value;
	}
}
