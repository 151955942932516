import { FulfillmentStep } from '@/models/fulfillment-step';
import FulfillmentStepIndication from '@/models/fulfillment-step-indication';
import { FulfillmentStepFlyweightFactory } from '@studyportals/sp-lord-business-interface';

class PresentationFulfillmentStepFlyweightFactory extends FulfillmentStepFlyweightFactory<FulfillmentStep> {
	protected createPendingConfirmationReviewStep(): FulfillmentStep {
		return new FulfillmentStep(FulfillmentStepIndication.PENDING_CONFIRMATION_REVIEW, 'Pending confirmation review');
	}

	protected createConfirmationChallengedStep(): FulfillmentStep {
		return new FulfillmentStep(FulfillmentStepIndication.CONFIRMATION_CHALLENGED, 'Challenged');
	}

	protected createAwaitingInvoicingStep(): FulfillmentStep {
		return new FulfillmentStep(FulfillmentStepIndication.AWAITING_INVOICING, 'Ready to be invoiced');
	}

	protected createDuplicationSuspectedStep(): FulfillmentStep {
		return new FulfillmentStep(FulfillmentStepIndication.DUPLICATION_SUSPECTED, 'Duplication alert');
	}

	protected createConfirmationSubmittedStep(): FulfillmentStep {
		return new FulfillmentStep(FulfillmentStepIndication.CONFIRMATION_SUBMITTED, 'Confirmation submitted');
	}

	protected createIdentificationPerformedStep(): FulfillmentStep {
		return new FulfillmentStep(FulfillmentStepIndication.IDENTIFICATION_PERFORMED, '');
	}

	protected createFinalStep(): FulfillmentStep {
		return new FulfillmentStep(FulfillmentStepIndication.FINAL, 'Final');
	}

	protected createPrequalificationCompletedStep(): FulfillmentStep {
		return new FulfillmentStep(FulfillmentStepIndication.PREQUALIFICATION_COMPLETED, 'Prequalification done');
	}

	protected createQualificationCompletedStep(): FulfillmentStep {
		return new FulfillmentStep(FulfillmentStepIndication.QUALIFICATION_COMPLETED, 'Qualification done');
	}

	protected createAwaitingConfirmationRequestStep(): FulfillmentStep {
		return new FulfillmentStep(FulfillmentStepIndication.AWAITING_CONFIRMATION_REQUEST, 'Ready to be sent');
	}

	protected createAwaitingConfirmationStep(): FulfillmentStep {
		return new FulfillmentStep(FulfillmentStepIndication.AWAITING_CONFIRMATION, 'Client is reviewing');
	}

	protected createConfirmationCompletedStep(): FulfillmentStep {
		return new FulfillmentStep(FulfillmentStepIndication.CONFIRMATION_COMPLETED, 'Confirmation completed');
	}

	protected createSentToFinanceStep(): FulfillmentStep {
		return new FulfillmentStep(FulfillmentStepIndication.SENT_TO_FINANCE, 'Sent to finance');
	}

	protected createHistoryStep(): FulfillmentStep {
		return new FulfillmentStep(FulfillmentStepIndication.HISTORY, 'History');
	}

	protected createPostponedStep(): FulfillmentStep {
		throw new Error('Method not implemented.');
	}
}

const fulfillmentStepFlyweightFactory = new PresentationFulfillmentStepFlyweightFactory();
export default fulfillmentStepFlyweightFactory;
