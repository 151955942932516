import { Component, defineComponent, watch } from 'vue';
import EnrolmentExport from '@/presentation/components/generalizations/enrolment-export/enrolment-export.vue';
import ClientEnrolmentExportComponent from '@/presentation/components/client/client-enrolments-export/client-enrolments-export-class';

export default defineComponent({
	components: {
		EnrolmentExport: EnrolmentExport as Component
	},
	setup: () => {
		const component = new ClientEnrolmentExportComponent();

		watch(component.filteredEnrolments, component.checkExportPossibility.bind(component));

		return {
			filteredEnrolments: component.filteredEnrolments,
			filteredEnrolmentsAsString: component.filteredEnrolmentsAsString,
			csvExportOptions: component.csvExportOptions,
			canExport: component.canExport
		};
	}
});
