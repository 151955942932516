import { nextTick, ref } from 'vue';
import { ClientFiltersAppliedEvent } from '@/event-bus/client-filters-applied-event';
import EventBus from '@/event-bus/event-bus';
import { HighlightedFeatureStatusEnum } from '@/models/highlighted-feature-status-enum';

export default class OptionalFiltersWrapperComponent {
	public highlightedFeatureTitle = 'Enhanced enrolment filtering and improved user interface';
	public highlightedFeatureContent = `Easily find and qualify your enrolments based on their identification source, 
	and experience a more intuitive and user-friendly interface that enables you to optimise your enrolment review process.`;

	public panelVisible = ref(false);
	private eventSubscriptionToken = ref('');

	public get highlightedFeatureNameFilter(): string {
		return HighlightedFeatureStatusEnum.FILTER;
	}

	public mounted(): void {
		this.eventSubscriptionToken.value = EventBus.getEvent(ClientFiltersAppliedEvent).subscribe(
			() => void this.hidePanelIfNoValidationErrors()
		);
	}

	public unmounted(): void {
		EventBus.getEvent(ClientFiltersAppliedEvent).unsubscribe(this.eventSubscriptionToken.value);
	}

	public togglePanelVisibility(): void {
		this.panelVisible.value = !this.panelVisible.value;
	}

	private async hidePanelIfNoValidationErrors(): Promise<void> {
		await nextTick();
		const errorElement = document.querySelector('.ErrorMessage');
		if (errorElement) {
			return;
		}

		this.togglePanelVisibility();
	}
}
