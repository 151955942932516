import { computed } from 'vue';
import { DefaultTableSelectorOptions } from '@/models/i-table-selector';
import { IEnrolmentOrganisationCounter } from '@/models/i-enrolment-counters';
import { Enrolment } from '@/models/enrolment';
import { RouteEnums } from '@/models/route-enums';
import TableSelectorOption from '@/presentation/components/generalizations/table-selectors/table-selector-option';
import TableFilter from '@/presentation/components/generalizations/table-selectors/filters/table-filter';
import store from '@/store';

export default class TableFilterOrganisationHistory extends TableFilter {
	get defaultOptions(): TableSelectorOption[] {
		return [
			new TableSelectorOption(
				DefaultTableSelectorOptions.DEFAULT,
				'All universities',
				this.enrolmentsFilteredByOtherFilters.value.length
			)
		];
	}

	private onIdentifiedEnrolmentsPage = computed((): boolean => {
		const router = store.getters.router();
		return router.current === RouteEnums.IDENTIFIED_ENROLMENTS;
	});

	public createOptions(): TableSelectorOption[] {
		const organisationCounters: IEnrolmentOrganisationCounter[] = [];
		this.enrolmentsFilteredByOtherFilters.value.forEach((enrolment) => {
			const existingCounter = organisationCounters.find((counter) => counter.organisation === enrolment.organisationIdentity);
			if (existingCounter) {
				existingCounter.facet++;
				return;
			}

			organisationCounters.push({
				label: enrolment.organisationName,
				organisation: enrolment.organisationIdentity,
				facet: 1
			});
		});

		return organisationCounters.map((counter) => new TableSelectorOption(counter.organisation, counter.label, counter.facet));
	}

	public doesEnrolmentMatchFilter(enrolment: Enrolment): boolean {
		const option = this.selectedOptionAsOption.value;
		if (!option || option.isDefaultOption || this.onIdentifiedEnrolmentsPage.value) {
			return true;
		}

		const organisation = enrolment.organisationIdentity.toString();
		const selected = this.selectedOption.value.toString();
		return organisation === selected;
	}
}
