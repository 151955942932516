import { TriggerPayloadConfiguration } from './trigger-payload-configuration';
import { EnrolmentNotRecognizedPayloadValidation } from './validation/enrolment-not-recognized-trigger-payload-validation';
import { TriggerPayloadValidation } from './validation/trigger-payload-validation';

export class EnrolmentNotRecognizedTriggerPayloadConfiguration extends TriggerPayloadConfiguration {
	public constructor() {
		super(false, true, false, false);
	}

	public createValidation(): TriggerPayloadValidation {
		return new EnrolmentNotRecognizedPayloadValidation();
	}
}
