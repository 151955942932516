import { ITableFilter } from '@/models/i-table-filter';
import { Enrolment } from '@/models/enrolment';
import NullTableSelector from '@/presentation/components/generalizations/table-selectors/selectors/null-table-selector';

export default class NullTableFilter extends NullTableSelector implements ITableFilter {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public doesEnrolmentMatchFilter(enrolment: Enrolment): boolean {
		return true;
	}
}
