import { computed } from 'vue';
import { IRouter } from '@/models/i-router';
import { Enrolment } from '@/models/enrolment';
import { RouteEnums } from '@/models/route-enums';
import { GroupOptionsEnums } from '@/models/group-options-enums';
import { EnrolmentStatus } from '@studyportals/sp-lord-business-interface';
import FulfillmentStepIndication from '@/models/fulfillment-step-indication';
import store from '@/store';

class EnrolmentFilter {
	public filteredEnrolments = computed((): Enrolment[] => {
		if (this.isOnIdentifiedEnrolmentsPage.value) {
			return this.enrolmentsFilteredForIdentifiedEnrolmentsPage.value;
		}

		if (this.isOnReviewOverviewPage.value) {
			return this.enrolmentsFilteredForClientReviewPage.value;
		}

		if (this.isInternal.value) {
			return this.enrolmentsFilteredForInternalHistoryPage.value;
		}

		return this.enrolmentsFilteredForClientHistoryPage.value;
	});

	private enrolmentsFilteredForIdentifiedEnrolmentsPage = computed((): Enrolment[] => {
		return this.enrolments.value.filter((enrolment) => {
			return !enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.HISTORY);
		});
	});

	private enrolmentsFilteredForInternalHistoryPage = computed((): Enrolment[] => {
		return this.enrolments.value.filter((enrolment) => {
			return (
				enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.HISTORY) &&
				!this.isIrrelevantDueToHistoryGroupingByInvoice(enrolment)
			);
		});
	});

	private enrolmentsFilteredForClientReviewPage = computed((): Enrolment[] => {
		return this.enrolments.value.filter((enrolment) => {
			return (
				enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.AWAITING_CONFIRMATION) ||
				enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.CONFIRMATION_COMPLETED) ||
				enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.CONFIRMATION_SUBMITTED) ||
				enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.CONFIRMATION_CHALLENGED) ||
				enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.PENDING_CONFIRMATION_REVIEW)
			);
		});
	});

	private enrolmentsFilteredForClientHistoryPage = computed((): Enrolment[] => {
		const showNonInvoicable = this.clientHistoryShowNonInvoicableEnrolments.value;
		if (showNonInvoicable) {
			return this.enrolments.value.filter((enrolment) => {
				return (
					(enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.SENT_TO_FINANCE) ||
						enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.AWAITING_INVOICING) ||
						enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.FINAL) ||
						enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.HISTORY)) &&
					!this.isIrrelevantDueToHistoryGroupingByInvoice(enrolment)
				);
			});
		} else {
			return this.enrolments.value.filter((enrolment) => {
				return (
					(enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.SENT_TO_FINANCE) ||
						enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.AWAITING_INVOICING) ||
						enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.HISTORY)) &&
					!this.isIrrelevantDueToHistoryGroupingByInvoice(enrolment)
				);
			});
		}
	});

	private isInternal = computed((): boolean => {
		return store.getters.isInternal();
	});

	private isOnIdentifiedEnrolmentsPage = computed((): boolean => {
		return this.router.value.current === RouteEnums.IDENTIFIED_ENROLMENTS;
	});

	private isOnReviewOverviewPage = computed((): boolean => {
		return this.router.value.current === RouteEnums.REVIEW_OVERVIEW;
	});

	private router = computed((): IRouter => {
		return store.getters.router();
	});

	private enrolments = computed((): Enrolment[] => {
		if (this.isInternal.value) {
			return store.getters.enrolments();
		} else {
			return store.getters.partnershipEnrolments();
		}
	});

	private clientHistoryShowNonInvoicableEnrolments = computed((): boolean => {
		return store.getters.clientHistoryShowNonInvoicableEnrolments();
	});

	private isIrrelevantDueToHistoryGroupingByInvoice(enrolment: Enrolment): boolean {
		const historyGroupSelection = store.getters.historyGroupSelection();
		/* Enrolments that have the fulfilment step "history" and aren't "confirmed" are not relevant when grouping by invoice.
		"Final" enrolments that would be shown in the history overview with another grouping (only for clients) should also be excluded. */
		return (
			historyGroupSelection.getHashKey() === GroupOptionsEnums.INVOICE &&
			((enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.HISTORY) &&
				enrolment.status.value !== EnrolmentStatus.CONFIRMED) ||
				enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.FINAL))
		);
	}
}

export default new EnrolmentFilter();
