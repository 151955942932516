import { Enrolment } from '@/models/enrolment';
import { EnrolmentStatus } from '@/models/enrolment-status';
import EnrolmentService from '@/services/enrolments-service';

import { TransitionTrigger } from './transition-trigger';

export class EnrolmentConfirmationAcceptedTrigger extends TransitionTrigger {
	public orderingNumber = 50;

	public constructor(excludedEnrolmentStatuses: EnrolmentStatus[]) {
		super('Accepted', excludedEnrolmentStatuses);
	}

	public equals(that: TransitionTrigger): boolean {
		return that instanceof EnrolmentConfirmationAcceptedTrigger;
	}

	protected async performTransitions(enrolments: Readonly<Enrolment[]>): Promise<void> {
		await EnrolmentService.triggerEnrolmentConfirmationAccepted(enrolments);
	}
}
