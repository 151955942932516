import { LordBusiness } from '@/infrastructure';
import { AbstractAndReportExceptionAsync } from '@studyportals/mb-platform-exceptions-aop';
import { User } from '@studyportals/sp-lord-business-interface';
import store from '@/store';

class UserService {
	public static async storeUserDetails(): Promise<void> {
		const user = await UserService.retrieveLoggedInUser();
		if (user) {
			store.actions.setCurrentUser(user);
		}
	}

	@AbstractAndReportExceptionAsync()
	private static async retrieveLoggedInUser(): Promise<User> {
		const user = await LordBusiness.retrieveLoggedInUser();
		return new User(user.identity, user.email, user.isClientRepresentative, user.isPsm);
	}
}

export default UserService;
