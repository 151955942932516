import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "SelectionIndicatorContainer" }
const _hoisted_2 = { class: "PartnershipLabel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "PartnershipSelectorOption",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changePartnership()))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: _normalizeClass(["SelectionIndicator", { Selected: _ctx.isCurrentPartnership }])
      }, " ● ", 2 /* CLASS */)
    ]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.partnership.organisationName), 1 /* TEXT */)
  ]))
}