import { Randomization } from '@studyportals/sp-hs-misc/bin';

type SubscriberFn<TPayload> = (payload: TPayload) => void;

export class Event<TPayload = any> {
	private readonly subscriptionsByToken: Map<string, SubscriberFn<TPayload>> = new Map<string, SubscriberFn<TPayload>>();
	private readonly subscriptions: SubscriberFn<TPayload>[] = [];

	public subscribe(subscriberFn: SubscriberFn<TPayload>): string {
		const token = new Randomization().guid();

		this.subscriptions.push(subscriberFn);
		this.subscriptionsByToken.set(token, subscriberFn);

		return token;
	}

	public unsubscribe(token: string): void {
		if (this.subscriptionsByToken.has(token)) {
			const subscription = this.subscriptionsByToken.get(token);
			const subscriptionIndex = this.subscriptions.findIndex((_) => _ === subscription);

			this.subscriptionsByToken.delete(token);
			if (subscriptionIndex > -1) {
				this.subscriptions.splice(subscriptionIndex, 1);
			}
		}
	}

	public publish(payload: TPayload): void {
		this.subscriptions.forEach((fn) => fn(payload));
	}
}
