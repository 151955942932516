import { SuccessFeeEnum } from './success-fee-enum';

export class SuccessFee {
	private constructor(
		public readonly name: string,
		public readonly value: SuccessFeeEnum,
		public readonly requiresTuitionFee: boolean,
		public readonly requiresNationality: boolean
	) {}

	public static regular(requiresTuitionFee: boolean): SuccessFee {
		return new SuccessFee('Regular qualified enrolment', SuccessFeeEnum.REGULAR, requiresTuitionFee, false);
	}

	public static reducedBecauseAgentInvolved(requiresTuitionFee: boolean): SuccessFee {
		return new SuccessFee(
			'Reduced commission, agent involved',
			SuccessFeeEnum.REDUCED_BECAUSE_AGENT_INVOLVED,
			requiresTuitionFee,
			false
		);
	}

	public static reducedBecauseNationality(requiresTuitionFee: boolean): SuccessFee {
		return new SuccessFee(
			'Reduced commission, based on nationality',
			SuccessFeeEnum.REDUCED_BECAUSE_NATIONALITY,
			requiresTuitionFee,
			true
		);
	}

	public static other(requiresTuitionFee: boolean): SuccessFee {
		return new SuccessFee('Other, please specify', SuccessFeeEnum.OTHER, requiresTuitionFee, false);
	}

	public static forValue(value: SuccessFeeEnum, requiresTuitionFee?: boolean): SuccessFee {
		const isTuitionFeeRequired = undefined === requiresTuitionFee ? true : requiresTuitionFee;

		switch (value) {
			case SuccessFeeEnum.REGULAR:
				return SuccessFee.regular(isTuitionFeeRequired);
			case SuccessFeeEnum.REDUCED_BECAUSE_AGENT_INVOLVED:
				return SuccessFee.reducedBecauseAgentInvolved(isTuitionFeeRequired);
			case SuccessFeeEnum.REDUCED_BECAUSE_NATIONALITY:
				return SuccessFee.reducedBecauseNationality(isTuitionFeeRequired);
			case SuccessFeeEnum.OTHER:
				return SuccessFee.other(isTuitionFeeRequired);
		}

		throw new Error('Unknown success fee');
	}

	public equals(that: SuccessFee | null | undefined): boolean {
		return that ? that.value === this.value : false;
	}

	public isRegularFee(): boolean {
		return this.value === SuccessFeeEnum.REGULAR;
	}

	public isReducedFee(): boolean {
		return this.value === SuccessFeeEnum.REDUCED_BECAUSE_AGENT_INVOLVED || this.value === SuccessFeeEnum.REDUCED_BECAUSE_NATIONALITY;
	}

	public isReducedBecauseAgentInvolvedFee(): boolean {
		return this.value === SuccessFeeEnum.REDUCED_BECAUSE_AGENT_INVOLVED;
	}

	public isReducedBecauseNationalityFee(): boolean {
		return this.value === SuccessFeeEnum.REDUCED_BECAUSE_NATIONALITY;
	}

	public isOtherSuccessFee(): boolean {
		return this.value === SuccessFeeEnum.OTHER;
	}
}
