import { computed } from 'vue';
import { DefaultTableSelectorOptions } from '@/models/i-table-selector';
import { Enrolment } from '@/models/enrolment';
import { ReviewStepsFilterOptionsEnum } from '@/models/review-steps-filter-options-enum';
import { RouteEnums } from '@/models/route-enums';
import TableFilter from '@/presentation/components/generalizations/table-selectors/filters/table-filter';
import TableSelectorOption from '@/presentation/components/generalizations/table-selectors/table-selector-option';
import FulfillmentStepIndication from '@/models/fulfillment-step-indication';
import store from '@/store';

export default class TableFilterReviewSteps extends TableFilter {
	get defaultOptions(): TableSelectorOption[] {
		return [
			new TableSelectorOption(
				DefaultTableSelectorOptions.DEFAULT,
				'All review steps',
				this.enrolmentsFilteredByOtherFilters.value.length
			)
		];
	}

	get reviewStepsFilterOptions(): ReviewStepsFilterOptionsEnum[] {
		return Object.values(ReviewStepsFilterOptionsEnum);
	}

	private onHistoryPage = computed((): boolean => {
		const router = store.getters.router();
		return router.current === RouteEnums.HISTORY;
	});

	constructor() {
		super(true);
	}

	public createOptions(): TableSelectorOption[] {
		return this.reviewStepsFilterOptions.map((option) => {
			return new TableSelectorOption(
				option,
				this.retrieveDisplayNameOfReviewStage(option),
				this.retrieveEnrolmentsInReviewStage(option).length
			);
		});
	}

	public doesEnrolmentMatchFilter(enrolment: Enrolment): boolean {
		const option = this.selectedOptionAsOption.value;
		if (!option || option.isDefaultOption || this.onHistoryPage.value) {
			return true;
		}

		const allOptions = this.reviewStepsFilterOptions as string[];
		if (!allOptions.includes(this.selectedOption.value.toString())) {
			return false;
		}

		const reviewStage = this.selectedOption.value as ReviewStepsFilterOptionsEnum;

		const doesEnrolmentMatchSelectedStage = this.retrieveFilteringMethodForReviewStage(reviewStage);
		return doesEnrolmentMatchSelectedStage(enrolment);
	}

	private retrieveEnrolmentsInReviewStage(stage: ReviewStepsFilterOptionsEnum): Enrolment[] {
		const method = this.retrieveFilteringMethodForReviewStage(stage);
		return this.enrolmentsFilteredByOtherFilters.value.filter(method.bind(this));
	}

	private retrieveFilteringMethodForReviewStage(stage: ReviewStepsFilterOptionsEnum): (enrolment: Enrolment) => boolean {
		switch (stage) {
			case ReviewStepsFilterOptionsEnum.TO_REVIEW:
				return this.doesEnrolmentMatchToReviewStage.bind(this);
			case ReviewStepsFilterOptionsEnum.REVIEWED:
				return this.doesEnrolmentMatchReviewedStage.bind(this);
			case ReviewStepsFilterOptionsEnum.REVIEWED_AND_SUBMITTED:
				return this.doesEnrolmentMatchReviewedAndSubmittedStage.bind(this);
		}
	}

	private doesEnrolmentMatchToReviewStage(enrolment: Enrolment): boolean {
		return (
			enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.AWAITING_CONFIRMATION) ||
			enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.PENDING_CONFIRMATION_REVIEW)
		);
	}

	private doesEnrolmentMatchReviewedStage(enrolment: Enrolment): boolean {
		return (
			enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.CONFIRMATION_COMPLETED) &&
			// TODO: Remove this after the enrolments with the flag in CONFIRMATION_COMPLETED are processed
			!enrolment.isChallengedAndUnconfirmed
		);
	}

	private doesEnrolmentMatchReviewedAndSubmittedStage(enrolment: Enrolment): boolean {
		return (
			enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.CONFIRMATION_SUBMITTED) ||
			enrolment.fulfillmentStep.isIndicatedBy(FulfillmentStepIndication.CONFIRMATION_CHALLENGED)
		);
	}

	private retrieveDisplayNameOfReviewStage(stage: ReviewStepsFilterOptionsEnum): string {
		switch (stage) {
			case ReviewStepsFilterOptionsEnum.TO_REVIEW:
				return 'To review';
			case ReviewStepsFilterOptionsEnum.REVIEWED:
				return 'Reviewed';
			case ReviewStepsFilterOptionsEnum.REVIEWED_AND_SUBMITTED:
				return 'Reviewed & submitted';
		}
	}
}
