import { computed, Ref, ref } from 'vue';
import { IMultiSelect } from '@studyportals/multiselect/src/interfaces/multiselect.interface';
import { IOption } from '@studyportals/multiselect/src/interfaces/options.interface';
import { ClientFiltersAppliedEvent } from '@/event-bus/client-filters-applied-event';
import tableSelectorRetriever from '@/presentation/components/generalizations/table-selectors/table-selector-retriever';
import EventBus from '@/event-bus/event-bus';
import store from '@/store';

export default class OptionalFilterSectionStatusComponent {
	public statusDropdown: Ref<IMultiSelect | null> = ref(null);
	private statusFilter = tableSelectorRetriever.retrieveClientStatusFilter();
	private eventSubscriptionToken = ref('');

	public statusOptions = computed((): IOption[] => {
		return this.statusFilter.options.value.filter((option) => !option.isDefaultOption);
	});

	public mounted(): void {
		this.eventSubscriptionToken.value = EventBus.getEvent(ClientFiltersAppliedEvent).subscribe(this.selectStatus.bind(this));
		this.prefillValue();
	}

	public unmounted(): void {
		EventBus.getEvent(ClientFiltersAppliedEvent).unsubscribe(this.eventSubscriptionToken.value);
	}

	public selectStatus(): void {
		if (!this.statusDropdown.value?.selectedOption?.value) {
			return;
		}

		const selectedOption = this.statusDropdown.value.selectedOption.value as string;
		this.statusFilter.setSelectedOption(selectedOption);
		this.filterEnrolments();
	}

	private filterEnrolments(): void {
		store.actions.updateFilteredEnrolments();
	}

	private prefillValue(): void {
		if (
			!this.statusFilter.selectedOptionAsOption.value ||
			!this.statusDropdown.value ||
			this.statusFilter.selectedOptionIsDefault.value
		) {
			return;
		}

		this.statusDropdown.value.selectOption(this.statusFilter.selectedOptionAsOption.value as IOption);
	}
}
