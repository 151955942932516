import { CountriesAcquiredEvent } from '@/event-bus/countries-acquired-event';
import EventBus from '@/event-bus/event-bus';
import { Country } from '@/models/country';

import CountriesData from './countries';

class CountryPresenter {
	public acquireCountries(): void {
		const countriesData = this.retrieveCountriesData();
		const countries = this.instantiateCountries(countriesData);

		countries.sort((left, right) => (left.name < right.name ? -1 : 1));

		EventBus.getEvent(CountriesAcquiredEvent).publish(countries);
	}

	private retrieveCountriesData(): { [isoCode: string]: string } {
		return CountriesData;
	}

	private instantiateCountries(countriesData: { [isoCode: string]: string }): Country[] {
		return Object.keys(countriesData).map((isoCode) => Country.create(isoCode, countriesData[isoCode]));
	}
}

export default new CountryPresenter();
