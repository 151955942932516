import { Enrolment } from '@/models/enrolment';
import { EnrolmentStatus } from '@/models/enrolment-status';
import EnrolmentService from '@/services/enrolments-service';

import { TransitionTrigger } from './transition-trigger';

export class EnrolmentNotYetInvoiceableTrigger extends TransitionTrigger {
	public orderingNumber = 100;

	public constructor(excludedEnrolmentStatuses: EnrolmentStatus[]) {
		super('Future round', excludedEnrolmentStatuses);
	}

	public equals(that: TransitionTrigger): boolean {
		return that instanceof EnrolmentNotYetInvoiceableTrigger;
	}

	protected async performTransitions(enrolments: Readonly<Enrolment[]>): Promise<void> {
		await EnrolmentService.triggerEnrolmentNotYetInvoiceable(enrolments);
	}
}
