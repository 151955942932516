import { computed, Ref, ref } from 'vue';
import { Enrolment } from '@/models/enrolment';
import { EnrolmentActionsEnums } from '@/models/enrolment-actions-enums';
import { MassEditFocusContentEvent } from '@/event-bus/mass-edit-focus-content-event';
import { MassEditLoseContentFocusEvent } from '@/event-bus/mass-edit-lose-content-focus-event';
import { MassEditHideWarningMultipleStatusesEvent } from '@/event-bus/mass-edit-hide-warning-multiple-statuses-event';
import { MassEditShowWarningMultipleStatusesEvent } from '@/event-bus/mass-edit-show-warning-multiple-statuses-event';
import { MassEditHideWarningMultipleOrganisationsEvent } from '@/event-bus/mass-edit-hide-warning-multiple-organisations-event';
import { MassEditShowWarningMultipleOrganisationsEvent } from '@/event-bus/mass-edit-show-warning-multiple-organisations-event';
import store from '@/store';
import EventBus from '@/event-bus/event-bus';

export default class EnrolmentsActionsComponent {
	public massSelectionOverview: Ref<HTMLElement | null> = ref(null);
	public moveEnrolmentsToOrganisation: Ref<HTMLElement | null> = ref(null);
	public moveEnrolmentsToHistory: Ref<HTMLElement | null> = ref(null);
	public shownEnrolmentsAction = ref(EnrolmentActionsEnums.EMPTY);

	public areNoMoveActionsVisible = computed((): boolean => {
		return this.shownEnrolmentsAction.value === EnrolmentActionsEnums.EMPTY;
	});

	public isMoveToOrganisationActionShown = computed((): boolean => {
		return this.shownEnrolmentsAction.value === EnrolmentActionsEnums.MOVE_TO_ORGANISATION;
	});

	public isMoveToHistoryActionShown = computed((): boolean => {
		return this.shownEnrolmentsAction.value === EnrolmentActionsEnums.MOVE_TO_HISTORY;
	});

	public selectedEnrolments = computed((): Enrolment[] => {
		return store.getters.selectedEnrolments();
	});

	public whenMoveEnrolmentsToOrganisationExpanded(): void {
		this.shownEnrolmentsAction.value = EnrolmentActionsEnums.MOVE_TO_ORGANISATION;
		EventBus.getEvent(MassEditFocusContentEvent).publish();
	}

	public whenMoveEnrolmentsToOrganisationCollapsed(): void {
		this.shownEnrolmentsAction.value = EnrolmentActionsEnums.EMPTY;
		EventBus.getEvent(MassEditLoseContentFocusEvent).publish();
	}

	public whenMovedEnrolmentsToHistoryExpanded(): void {
		this.shownEnrolmentsAction.value = EnrolmentActionsEnums.MOVE_TO_HISTORY;
		EventBus.getEvent(MassEditFocusContentEvent).publish();
	}

	public whenMovedEnrolmentsToHistoryCollapsed(): void {
		this.shownEnrolmentsAction.value = EnrolmentActionsEnums.EMPTY;
		EventBus.getEvent(MassEditLoseContentFocusEvent).publish();
	}

	public whenSelectionHasSingleStatus(): void {
		EventBus.getEvent(MassEditHideWarningMultipleStatusesEvent).publish();
	}

	public whenSelectionHasMultipleStatuses(): void {
		EventBus.getEvent(MassEditShowWarningMultipleStatusesEvent).publish();
	}

	public whenSelectionHasSingleOrganisation(): void {
		EventBus.getEvent(MassEditHideWarningMultipleOrganisationsEvent).publish();
	}

	public whenSelectionHasMultipleOrganisations(): void {
		EventBus.getEvent(MassEditShowWarningMultipleOrganisationsEvent).publish();
	}
}
