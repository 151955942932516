import { computed, ref } from 'vue';
import { Partnership } from '@/models/partnership';
import store from '@/store';

export default class PartnershipSelectorComponent {
	public isContentVisible = ref(false);

	public partnerships = computed((): Partnership[] => {
		return store.getters.partnerships();
	});

	public toggleContent(): void {
		this.isContentVisible.value = !this.isContentVisible.value;
	}

	public hideDropDownContent(): void {
		this.isContentVisible.value = false;
	}
}
