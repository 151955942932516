import { EnrolmentGroup } from '@/models/enrolment-group';
import { EnrolmentDateMonth } from '@/models/enrolment-date-month';

export class EnrolmentDateGroup extends EnrolmentGroup {
	constructor(
		public year: number | undefined,
		public month: EnrolmentDateMonth | undefined
	) {
		super('');
	}
}
