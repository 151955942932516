import { defineComponent, onMounted } from 'vue';
import VideoStudentImpactComponent from '@/presentation/components/client/video-student-impact/video-student-impact-class';

export default defineComponent({
	setup: () => {
		const component = new VideoStudentImpactComponent();

		onMounted(component.mounted.bind(component));
	}
});
