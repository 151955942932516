import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "ReviewOverview" }
const _hoisted_2 = { class: "EnrolmentOverviewActionsContainer" }
const _hoisted_3 = { class: "EnrolmentsOverviewHeader" }
const _hoisted_4 = { class: "Title" }
const _hoisted_5 = { class: "EnrolmentOverviewActionsContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MassReviewIncentive = _resolveComponent("MassReviewIncentive")!
  const _component_PartnershipSelector = _resolveComponent("PartnershipSelector")!
  const _component_SubmitReview = _resolveComponent("SubmitReview")!
  const _component_SearchBarWrapper = _resolveComponent("SearchBarWrapper")!
  const _component_MassEdit = _resolveComponent("MassEdit")!
  const _component_ClientEnrolmentsExport = _resolveComponent("ClientEnrolmentsExport")!
  const _component_EnrolmentsTableFilter = _resolveComponent("EnrolmentsTableFilter")!
  const _component_OptionalFiltersWrapper = _resolveComponent("OptionalFiltersWrapper")!
  const _component_EnrolmentsReviewSection = _resolveComponent("EnrolmentsReviewSection")!
  const _component_HighlightedFeaturesIntroductionModal = _resolveComponent("HighlightedFeaturesIntroductionModal")!
  const _component_LoadingModal = _resolveComponent("LoadingModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MassReviewIncentive),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.organisationName), 1 /* TEXT */),
        _createVNode(_component_PartnershipSelector)
      ]),
      _createVNode(_component_SubmitReview),
      _createVNode(_component_SearchBarWrapper),
      _createVNode(_component_MassEdit),
      _createVNode(_component_ClientEnrolmentsExport)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_EnrolmentsTableFilter),
      _createVNode(_component_OptionalFiltersWrapper)
    ]),
    _createVNode(_component_EnrolmentsReviewSection),
    _createVNode(_component_HighlightedFeaturesIntroductionModal),
    (!_ctx.modalStatusLoadingIsInactive)
      ? (_openBlock(), _createBlock(_component_LoadingModal, { key: 0 }))
      : _createCommentVNode("v-if", true)
  ]))
}