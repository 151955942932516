import { computed } from 'vue';
import { Enrolment } from '@/models/enrolment';
import { ICountDetailsOfEnrolment, IOrganisationChange, IOrganisationDetails } from '@/models/i-organisation-details';
import organisationFilterPresenter from '@/presenters/organisation-filter-presenter';
import tableSelectorRetriever from '@/presentation/components/generalizations/table-selectors/table-selector-retriever';
import store from '@/store';

class OrganisationDetailsTransitionHandler {
	private noWorkOrderOrganisationIds = computed((): string[] => {
		return store.getters.noWorkOrderOrganisationIds();
	});

	private noWorkOrderOrganisationDetails = computed((): IOrganisationDetails | undefined => {
		return store.getters.noWorkOrderOrganisationDetails();
	});

	public updateOrganisationDetailsAfterTransition(enrolments: Enrolment[], organisationChanges: IOrganisationChange[]): void {
		const cluster = store.getters.organisationDetailsCluster();

		cluster.listOfDetails.forEach((details) => {
			this.processOrganisationChangesForEnrolmentsWithWorkOrder(details, enrolments, organisationChanges);
			this.processStatusAndStepChangesForEnrolmentsWithWorkOrder(details, enrolments);
		});

		this.processOrganisationChangesForEnrolmentsWithoutWorkOrder(enrolments, organisationChanges);
		this.processStatusAndStepChangesForEnrolmentsWithoutWorkOrder(enrolments);

		// Increment the cluster id so that the update will be processed.
		cluster.id = organisationFilterPresenter.incrementOrganisationDetailsClusterId();
		store.mutations.updateOrganisationDetailsCluster(cluster);
	}

	private processOrganisationChangesForEnrolmentsWithWorkOrder(
		organisationDetails: IOrganisationDetails,
		enrolments: Enrolment[],
		changes: IOrganisationChange[]
	): void {
		changes.forEach((change) => {
			this.processOrganisationChange(organisationDetails, enrolments, change, true);
		});
	}

	private processOrganisationChangesForEnrolmentsWithoutWorkOrder(enrolments: Enrolment[], changes: IOrganisationChange[]): void {
		if (!this.noWorkOrderOrganisationDetails.value || this.noWorkOrderOrganisationDetails.value.enrolments === undefined) {
			return;
		}

		const noWorkOrderOrganisationDetails: IOrganisationDetails = this.noWorkOrderOrganisationDetails.value;

		changes.forEach((change) => {
			this.processOrganisationChange(noWorkOrderOrganisationDetails, enrolments, change, false);
		});
	}

	private processOrganisationChange(
		organisationDetails: IOrganisationDetails,
		enrolments: Enrolment[],
		change: IOrganisationChange,
		organisationHasWorkOrder: boolean
	): void {
		const indexOfEnrolment = enrolments.findIndex((searchedEnrolment) => searchedEnrolment.identity === change.enrolmentIdentity);
		if (indexOfEnrolment === -1) {
			return;
		}

		const enrolment = enrolments[indexOfEnrolment];

		this.handleEnrolmentRemovedFromOrganisationUseCase(organisationDetails, enrolment, change, organisationHasWorkOrder);
		this.handleEnrolmentAddedToOrganisationUseCase(organisationDetails, enrolment, change, organisationHasWorkOrder);

		if (change.organisationIdentityPrevious !== '' || change.organisationIdentityCurrent !== '') {
			return;
		}

		enrolments.splice(indexOfEnrolment, 1);
	}

	private handleEnrolmentRemovedFromOrganisationUseCase(
		organisationDetails: IOrganisationDetails,
		enrolment: Enrolment,
		change: IOrganisationChange,
		organisationHasWorkOrder: boolean
	): void {
		if (!this.doesOrganisationMatchChangeUseCase(change.organisationIdentityPrevious, organisationDetails, organisationHasWorkOrder)) {
			return;
		}

		change.organisationIdentityPrevious = '';

		if (!organisationDetails.enrolments) {
			return;
		}

		const indexOfRemovedEnrolment = organisationDetails.enrolments.findIndex(
			(searchedEnrolment) => searchedEnrolment.identity === enrolment.identity
		);
		if (indexOfRemovedEnrolment === -1) {
			return;
		}

		organisationDetails.enrolments.splice(indexOfRemovedEnrolment, 1);
	}

	private handleEnrolmentAddedToOrganisationUseCase(
		organisationDetails: IOrganisationDetails,
		enrolment: Enrolment,
		change: IOrganisationChange,
		organisationHasWorkOrder: boolean
	): void {
		if (!this.doesOrganisationMatchChangeUseCase(change.organisationIdentityCurrent, organisationDetails, organisationHasWorkOrder)) {
			return;
		}

		change.organisationIdentityCurrent = '';

		if (!organisationDetails.enrolments) {
			return;
		}

		organisationDetails.enrolments.push({
			identity: enrolment.identity,
			fulfillmentStep: enrolment.fulfillmentStep,
			status: enrolment.status,
			intakePeriod: enrolment.intakePeriodString,
			studentNationality: enrolment.studentNationality?.isoCode || '',
			source: enrolment.clientSourceName,
			tuitionFee: enrolment.tuitionFeeString
		});
	}

	private doesOrganisationMatchChangeUseCase(
		organisationOfChange: string,
		organisationDetails: IOrganisationDetails,
		organisationHasWorkOrder: boolean
	): boolean {
		return (
			(organisationHasWorkOrder && organisationDetails.organisationIdentity === organisationOfChange) ||
			(!organisationHasWorkOrder && this.noWorkOrderOrganisationIds.value.includes(organisationOfChange))
		);
	}

	private processStatusAndStepChangesForEnrolmentsWithWorkOrder(
		organisationDetails: IOrganisationDetails,
		enrolments: Enrolment[]
	): void {
		for (let i = enrolments.length - 1; i >= 0; i--) {
			const enrolment = enrolments[i];
			if (enrolment.organisationIdentity !== organisationDetails.organisationIdentity || !organisationDetails.enrolments) {
				return;
			}

			const enrolmentProcessed = this.processStatusAndStepChangesForOneEnrolment(organisationDetails, enrolment);
			if (!enrolmentProcessed) {
				return;
			}

			// Remove any enrolments that have been processed so that enrolments without a valid work order can be identified.
			enrolments.splice(i, 1);
		}
	}

	private processStatusAndStepChangesForEnrolmentsWithoutWorkOrder(enrolments: Enrolment[]): void {
		if (!this.noWorkOrderOrganisationDetails.value || this.noWorkOrderOrganisationDetails.value.enrolments === undefined) {
			return;
		}

		const noWorkOrderOrganisationDetails: IOrganisationDetails = this.noWorkOrderOrganisationDetails.value;

		// The remaining enrolments belong to an organisation without a work order, so check the invalid work order details.
		for (const enrolment of enrolments) {
			this.processStatusAndStepChangesForOneEnrolment(noWorkOrderOrganisationDetails, enrolment);
		}
	}

	private processStatusAndStepChangesForOneEnrolment(organisationDetails: IOrganisationDetails, enrolment: Enrolment): boolean {
		if (!organisationDetails.enrolments) {
			return false;
		}

		const enrolmentInList = organisationDetails.enrolments.find(
			(searchedEnrolment) => searchedEnrolment.identity === enrolment.identity
		);
		if (!enrolmentInList) {
			return false;
		}

		this.onMissingOptionResetFilters(organisationDetails, enrolmentInList);

		// Check all the properties that can affect active filters.
		if (
			enrolmentInList.fulfillmentStep.fulfillmentStepIndication === enrolment.fulfillmentStep.fulfillmentStepIndication &&
			enrolmentInList.status.value === enrolment.status.value &&
			enrolmentInList.intakePeriod === enrolment.intakePeriodString &&
			enrolmentInList.studentNationality === (enrolment.studentNationality?.isoCode || '') &&
			enrolmentInList.source === enrolment.clientSourceName &&
			enrolmentInList.tuitionFee === enrolment.tuitionFeeString
		) {
			return false;
		}

		enrolmentInList.fulfillmentStep = enrolment.fulfillmentStep;
		enrolmentInList.status = enrolment.status;
		enrolmentInList.intakePeriod = enrolment.intakePeriodString;
		enrolmentInList.studentNationality = enrolment.studentNationality?.isoCode || '';
		enrolmentInList.source = enrolment.clientSourceName;
		enrolmentInList.tuitionFee = enrolment.tuitionFeeString;
		return true;
	}

	private onMissingOptionResetFilters(organisationDetails: IOrganisationDetails, countDetails: ICountDetailsOfEnrolment): void {
		const organisationSelector = tableSelectorRetriever.retrieveOrganisationSelector();
		const selectedOrganisation = organisationSelector.selectedOption.value;
		if (selectedOrganisation !== organisationDetails.organisationIdentity) {
			return;
		}

		this.onMissingOptionResetStatusFilter(organisationDetails, countDetails);
		this.onMissingOptionResetStepFilter(organisationDetails, countDetails);
	}

	private onMissingOptionResetStatusFilter(organisationDetails: IOrganisationDetails, countDetails: ICountDetailsOfEnrolment): void {
		if (!organisationDetails.enrolments) {
			return;
		}

		const statusFilter = tableSelectorRetriever.retrieveStatusFilter();
		if (statusFilter.selectedOption.value !== countDetails.status.value) {
			return;
		}

		const remainingEnrolmentWithStatus = organisationDetails.enrolments.find((enrolment) => {
			return enrolment.status.value === countDetails.status.value && countDetails.identity !== enrolment.identity;
		});

		// Only reset the filter if no enrolments can be found that match the selected option.
		if (remainingEnrolmentWithStatus) {
			return;
		}

		statusFilter.resetSelectedOption();
	}

	private onMissingOptionResetStepFilter(organisationDetails: IOrganisationDetails, countDetails: ICountDetailsOfEnrolment): void {
		if (!organisationDetails.enrolments) {
			return;
		}

		const stepFilter = tableSelectorRetriever.retrieveStepFilter();
		if (stepFilter.selectedOption.value !== countDetails.fulfillmentStep.fulfillmentStepIndication) {
			return;
		}

		const remainingEnrolmentWithStep = organisationDetails.enrolments.find((enrolment) => {
			return (
				enrolment.fulfillmentStep.fulfillmentStepIndication === countDetails.fulfillmentStep.fulfillmentStepIndication &&
				countDetails.identity !== enrolment.identity
			);
		});

		// Only reset the filter if no enrolments can be found that match the selected option.
		if (remainingEnrolmentWithStep) {
			return;
		}

		stepFilter.resetSelectedOption();
	}
}

export default new OrganisationDetailsTransitionHandler();
