import EventBus from '@/event-bus/event-bus';
import { TransitionFailedEvent } from '@/event-bus/transition-failed-event';
import { MassReviewTransitionsFailedEvent } from '@/event-bus/mass-review-transitions-failed-event';
import { TransitionSucceededEvent } from '@/event-bus/transitions-succeeded-event';
import { EnrolmentStatus } from '@/models/enrolment-status';
import { Enrolment } from '@/models/enrolment';
import { EmptyTriggerPayloadConfiguration } from '@/models/transitions/trigger-payload/empty-trigger-payload-configuration';
import { TriggerPayload } from '@/models/transitions/trigger-payload/trigger-payload';
import { TriggerPayloadConfiguration } from '@/models/transitions/trigger-payload/trigger-payload-configuration';
import RedshiftTracker from '@/infrastructure/redshift-tracker';
import store from '@/store';

export abstract class TransitionTrigger {
	public orderingNumber = 0;

	public constructor(
		public readonly name: string,
		public readonly excludedEnrolmentStatuses: EnrolmentStatus[],
		public readonly payloadConfiguration: TriggerPayloadConfiguration = new EmptyTriggerPayloadConfiguration()
	) {}

	private get redshiftTracker(): RedshiftTracker {
		return store.state.redshiftTracker;
	}

	public get isRevise(): boolean {
		return false;
	}

	public isAvailableForEnrolment(enrolment: Enrolment): boolean {
		return !this.excludedEnrolmentStatuses.some((_) => enrolment.status.equals(_));
	}

	public async triggerTransitions(enrolments: Readonly<Enrolment[]>, payload: TriggerPayload): Promise<void> {
		try {
			this.redshiftTracker.trackTransitionInitiated();
			await this.performTransitions(enrolments, payload);
			EventBus.getEvent(TransitionSucceededEvent).publish();
			this.redshiftTracker.trackTransitionSpeed();
		} catch (error: any) {
			// The event that should be published to announce the error depends on whether or not multiple enrolments are being reviewed.
			EventBus.getEvent(enrolments.length === 1 ? TransitionFailedEvent : MassReviewTransitionsFailedEvent).publish(error);

			throw new Error(error);
		}
	}

	public abstract equals(that: TransitionTrigger): boolean;
	protected abstract performTransitions(enrolments: Readonly<Enrolment[]>, payload: TriggerPayload): Promise<void>;
}
